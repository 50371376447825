import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import { getClientKycDetail,createClientKycPdf } from "./../../../config/client_api_calls";

import $ from "jquery";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  APP_LIVE_URL,
  KYC_URL,
} from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const auth = new HelperClass();

class KycForm extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      tableDataSingle: [],
      serviceData: [],
      tableLoaderListing: true,
      errorMessage: "",
      successMessage: "",
      kyc_details: [],
      kyc_key: "1",
      steps_allowed: 0,
      steps_completed: 0,
      completed_percentage: 0,
      client_register_date: "",
      kyc_link: "",
    };
  }

  async componentDidMount() {
    const servicesResponce = await getClientKycDetail(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("KYC Details: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 406) {
      //window.location.replace("/error");
      this.setState({
        errorMessage:
          "There is an error while getting client details. Please try again later.",
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        errorMessage:
          "There is an error while getting client details. Please try again later.",
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else if (servicesResponce.data.status === 200) {
      let completed_percentage =
        (100 / servicesResponce.data.steps_allowed) *
        servicesResponce.data.steps_completed;
      this.setState({
        kyc_details: servicesResponce.data.kyc_data.data,
        kyc_key: servicesResponce.data.kyc_key,
        steps_allowed: servicesResponce.data.steps_allowed,
        steps_completed: servicesResponce.data.steps_completed,
        completed_percentage: completed_percentage.toFixed(0),
        client_register_date: this.format_date(
          servicesResponce.data.kyc_data.register_date
        ),
        kyc_link: KYC_URL + "client/register/" + servicesResponce.data.kyc_key,
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        errorMessage: "",
        tableLoader: false,
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    }
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM TT");
  };

  downloadPdf = async () => {
    const { kyc_key } = this.state;
    const servicesResponce = await createClientKycPdf(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("KYC Details: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 406) {
      //window.location.replace("/error");
      this.setState({
        kycErrorMessage: "There is some error while downloading the PDF.",
      });
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        kycErrorMessage: "There is some error while downloading the PDF.",
      });
    } else if (servicesResponce.data.status === 200) {
      let client_pdf = servicesResponce.data.client_pdf;
      if (client_pdf !== "") {
        fetch(client_pdf)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = kyc_key;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            kycErrorMessage: "There is some error while downloading the PDF.",
          });
          setTimeout(() => {
            this.setState({ kycErrorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
      }
      
    } else {
      this.setState({
        kycErrorMessage: "There is some error while downloading the PDF.",
        
      });
      //window.location.replace("/error");
    }
  };
  

  downloadPdfs = async (d_f_name) => {
    if (d_f_name !== "") {
      d_f_name = d_f_name + ".pdf";
      var url = APP_LIVE_URL + "files_data/kyc_signup/" + d_f_name;
      console.log("url: ", url);
      let file_name = d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            kycErrorMessage: "There is some error while downloading the PDF.",
          });
          setTimeout(() => {
            this.setState({ kycErrorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    }
  };

  handle_accordion_head = (number) => {
    if (number === 0) {
      return "accordion-head";
    } else {
      return "accordion-head collapsed";
    }
  };

  handle_accordion_body = (number) => {
    if (number === 0) {
      return "accordion-body collapse show";
    } else {
      return "accordion-body collapse";
    }
  };

  setStatus = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === "pending") {
      return (
        <div>
          <span className="badge badge-outline-warning">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> Pending
          </span>
        </div>
      );
    } else if (domain_str === "approved") {
      return (
        <div>
          <span className="badge badge-outline-success">
            {" "}
            <em className="icon ni ni-done "></em> Approved
          </span>
        </div>
      );
    } else if (domain_str === "rejected") {
      return (
        <div>
          <span className="badge badge-outline-danger">
            {" "}
            <em className="icon ni ni-alert-c"></em> Rejected
          </span>
        </div>
      );
    }
  };

  render() {
    const { step } = this.state;
    const { email, VerifyUsernameButton } = this.state;
    const values = {
      email,
      VerifyUsernameButton,
    };
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="Support_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}

              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="KYC Form"
                        icon="icon ni ni-user-list-fill"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert example-alert-revoke"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {this.state.tableLoader === true ? (
                            tableLoader()
                          ) : (
                            <>
                              {this.state.kyc_key === "" ? (
                                <div className="nk-block">
                                  <div className="card card-bordered">
                                    <div className="card-inner card-inner-lg">
                                      <div className="nk-help">
                                        <div className="nk-help-img">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 90 90"
                                          >
                                            <rect
                                              x="5"
                                              y="7"
                                              width="60"
                                              height="56"
                                              rx="7"
                                              ry="7"
                                              fill="#e3e7fe"
                                              stroke="#6576ff"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                            ></rect>
                                            <rect
                                              x="25"
                                              y="27"
                                              width="60"
                                              height="56"
                                              rx="7"
                                              ry="7"
                                              fill="#e3e7fe"
                                              stroke="#6576ff"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                            ></rect>
                                            <rect
                                              x="15"
                                              y="17"
                                              width="60"
                                              height="56"
                                              rx="7"
                                              ry="7"
                                              fill="#fff"
                                              stroke="#6576ff"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                            ></rect>
                                            <line
                                              x1="15"
                                              y1="29"
                                              x2="75"
                                              y2="29"
                                              fill="none"
                                              stroke="#6576ff"
                                              strokeMiterlimit="10"
                                              strokeWidth="2"
                                            ></line>
                                            <circle
                                              cx="53"
                                              cy="23"
                                              r="2"
                                              fill="#c4cefe"
                                            ></circle>
                                            <circle
                                              cx="60"
                                              cy="23"
                                              r="2"
                                              fill="#c4cefe"
                                            ></circle>
                                            <circle
                                              cx="67"
                                              cy="23"
                                              r="2"
                                              fill="#c4cefe"
                                            ></circle>
                                            <rect
                                              x="22"
                                              y="39"
                                              width="20"
                                              height="20"
                                              rx="2"
                                              ry="2"
                                              fill="none"
                                              stroke="#6576ff"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                            ></rect>
                                            <circle
                                              cx="32"
                                              cy="45.81"
                                              r="2"
                                              fill="none"
                                              stroke="#6576ff"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                            ></circle>
                                            <path
                                              d="M29,54.31a3,3,0,0,1,6,0"
                                              fill="none"
                                              stroke="#6576ff"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                            ></path>
                                            <line
                                              x1="49"
                                              y1="40"
                                              x2="69"
                                              y2="40"
                                              fill="none"
                                              stroke="#6576ff"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                            ></line>
                                            <line
                                              x1="49"
                                              y1="51"
                                              x2="69"
                                              y2="51"
                                              fill="none"
                                              stroke="#c4cefe"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                            ></line>
                                            <line
                                              x1="49"
                                              y1="57"
                                              x2="59"
                                              y2="57"
                                              fill="none"
                                              stroke="#c4cefe"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                            ></line>
                                            <line
                                              x1="64"
                                              y1="57"
                                              x2="66"
                                              y2="57"
                                              fill="none"
                                              stroke="#c4cefe"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                            ></line>
                                            <line
                                              x1="49"
                                              y1="46"
                                              x2="59"
                                              y2="46"
                                              fill="none"
                                              stroke="#c4cefe"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                            ></line>
                                            <line
                                              x1="64"
                                              y1="46"
                                              x2="66"
                                              y2="46"
                                              fill="none"
                                              stroke="#c4cefe"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                            ></line>
                                          </svg>
                                        </div>
                                        <div className="nk-help-text">
                                          <h5>Initiate KYC Process</h5>
                                          <p className="text-soft">
                                            Register your customer with KYC to
                                            complete their profile. Click on
                                            "Initiate KYC" button. An email with
                                            KYC registration link will be sent
                                            to the customer.
                                          </p>
                                        </div>
                                        <div className="nk-help-action">
                                          {this.state.loading_button ===
                                          false ? (
                                            <a
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                this.initiateKyc();
                                              }}
                                              disabled={this.state.disabled}
                                              href="#"
                                              className="btn btn-lg btn-outline-primary"
                                            >
                                              <em className="icon ni ni-file-docs"></em>{" "}
                                              Initiate KYC
                                            </a>
                                          ) : null}
                                          {this.state.loading_button ===
                                          true ? (
                                            <button
                                              className="btn btn-primary btn-lg"
                                              type="button"
                                              disabled
                                            >
                                              <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                              ></span>
                                              <span> Initiating... </span>
                                            </button>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {this.state.kyc_key !== "1" &&
                              this.state.kyc_key !== "" ? (
                                <div id="printablediv">
                                  <div className="nk-content-body">
                                    <div className="nk-block-head nk-block-head-sm">
                                      <div className="nk-block-between g-3">
                                        <div className="nk-block-head-content">
                                          <h3 className="nk-block-title page-title">
                                            KYCs /{" "}
                                            <strong className="text-primary small">
                                              {auth.getCompNameClient()}
                                            </strong>
                                          </h3>
                                          <div className="nk-block-des text-soft">
                                            <ul className="list-inline">
                                              <li>
                                                Submited At:{" "}
                                                <span className="text-base">
                                                  {
                                                    this.state
                                                      .client_register_date
                                                  }
                                                </span>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>

                                        <div className="nk-block-head-content">
                                          <div className="btn-group">
                                            {this.state.completed_percentage <
                                            100 ? (
                                              <>
                                                {auth.getUserTypeClient() ===
                                                "Super" ? (
                                                  <a
                                                    href={this.state.kyc_link}
                                                    className="btn btn-primary"
                                                    target="_blank"
                                                  >
                                                    <span>Continue</span>
                                                  </a>
                                                ) : null}
                                              </>
                                            ) : (
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  this.downloadPdf();
                                                }}
                                                className="btn btn-outline-primary"
                                              >
                                                <em className="icon ni ni-download"></em>
                                                <span>Download PDF</span>
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {this.state.showSendEmail ? (
                                      <div id="showSendEmailItems">
                                        <div className="form-group mt-1">
                                          <MutextField
                                            required
                                            id="kyc_email_address"
                                            name="kyc_email_address"
                                            type="text"
                                            label="Email Address"
                                            defaultValue={
                                              this.state.kyc_email_address
                                            }
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                          />
                                        </div>
                                        <div
                                          className="form-group text-right"
                                          style={{
                                            marginTop: "15px",
                                            marginBottom: "15px",
                                          }}
                                        >
                                          <button
                                            type="button"
                                            style={{ marginRight: 5 }}
                                            className="btn btn-light"
                                            onClick={() => {
                                              this.setState({
                                                showSendEmail: false,
                                              });
                                            }}
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => {
                                              this.email_kyc_Details();
                                            }}
                                            disabled={this.state.disabled}
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="nk-block-head">
                                      <div className="nk-block-head-content">
                                        <h5 className="nk-block-title title">
                                          Profile Completed{" "}
                                          <span className="text-primary">
                                            ({this.state.completed_percentage}%)
                                          </span>
                                        </h5>
                                        <div className="card card-bordered card-preview">
                                          <div className="card-inner">
                                            <div className="progress progress-lg">
                                              <div
                                                className="progress-bar"
                                                data-progress={
                                                  this.state
                                                    .completed_percentage
                                                }
                                                style={{
                                                  width: `${this.state.completed_percentage}%`,
                                                }}
                                              >
                                                {
                                                  this.state
                                                    .completed_percentage
                                                }
                                                %
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {this.state.kyc_details &&
                                  this.state.kyc_details.length > 0 ? (
                                    <div id="accordion" className="accordion">
                                      {this.state.kyc_details.map((kyc, i) => (
                                        <div
                                          className="accordion-item"
                                          key={`kycid${i}`}
                                        >
                                          <a
                                            href="#"
                                            className={this.handle_accordion_head(
                                              i
                                            )}
                                            data-toggle="collapse"
                                            data-target={`#accordion-item-${i}`}
                                            style={{
                                              backgroundColor: "#f5f6fa",
                                            }}
                                          >
                                            <h6 className="title">
                                              {kyc.steps_information.step_title}
                                              <br />
                                              <small>
                                                {
                                                  kyc.steps_information
                                                    .step_description
                                                }
                                              </small>
                                            </h6>
                                            <span className="accordion-icon"></span>
                                          </a>
                                          <div
                                            className={this.handle_accordion_body(
                                              i
                                            )}
                                            id={`accordion-item-${i}`}
                                            data-parent="#accordion"
                                          >
                                            <div className="accordion-inner">
                                              {kyc.steps_data &&
                                              kyc.steps_data.length > 0 ? (
                                                <div className="card">
                                                  <ul className="data-list is-compact">
                                                    {kyc.steps_data.map(
                                                      (kyc_steps, i) => (
                                                        <li
                                                          className="data-item"
                                                          key={`step${i}`}
                                                        >
                                                          <div className="data-col">
                                                            <div className="data-label">
                                                              {
                                                                kyc_steps.field_title
                                                              }
                                                            </div>
                                                            <div className="data-value">
                                                              {kyc_steps.field_type ===
                                                              "image" ? (
                                                                <img
                                                                  src={
                                                                    kyc_steps.field_value
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "60%",
                                                                  }}
                                                                />
                                                              ) : (
                                                                kyc_steps.field_value
                                                              )}
                                                            </div>
                                                          </div>
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(KycForm);
