import ContentLoader from "react-content-loader";
export const tableLoaderLine = () => {
  return (
    <ContentLoader
      width="100%"
      speed={3}
      primarycolor="#f3f3f3"
      secondarycolor="#ecebeb"
    >
      <rect x="0" y="0" width="100%" height="10" />
      <rect x="0" y="15" width="100%" height="10" />
      <rect x="0" y="30" width="100%" height="10" />
    </ContentLoader>
  );
};
