import ContentLoader from "react-content-loader";
export const tableLoader = () => {
  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 620 500"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ marginLeft: "-5px" }}
    >
      <rect x="5" y="10" rx="0" ry="0" width="99%" height="1" />
      <rect x="5" y="230" rx="0" ry="0" width="99%" height="1" />
      <rect x="5" y="10" rx="0" ry="0" width="0.2%" height="260" />
      <rect x="618" y="10" rx="0" ry="0" width="0.2%" height="260" />
      <rect x="20" y="30" rx="3" ry="3" width="35%" height="25" />
      <rect x="480" y="30" rx="3" ry="3" width="4%" height="25" />
      <rect x="510" y="30" rx="3" ry="3" width="4%" height="25" />
      <rect x="540" y="30" rx="3" ry="3" width="4%" height="25" />
      <rect x="570" y="30" rx="3" ry="3" width="4%" height="25" />
      <rect x="20" y="70" rx="3" ry="3" width="25%" height="25" />
      <rect x="250" y="70" rx="3" ry="3" width="25%" height="25" />
      <rect x="490" y="70" rx="3" ry="3" width="15%" height="25" />
      <rect x="20" y="110" rx="3" ry="3" width="25%" height="25" />
      <rect x="250" y="110" rx="3" ry="3" width="25%" height="25" />
      <rect x="510" y="110" rx="3" ry="3" width="4%" height="25" />
      <rect x="540" y="110" rx="3" ry="3" width="4%" height="25" />
      <rect x="20" y="150" rx="3" ry="3" width="25%" height="25" />
      <rect x="250" y="150" rx="3" ry="3" width="25%" height="25" />
      <rect x="510" y="150" rx="3" ry="3" width="4%" height="25" />
      <rect x="540" y="150" rx="3" ry="3" width="4%" height="25" />
      <rect x="20" y="190" rx="3" ry="3" width="25%" height="25" />
      <rect x="250" y="190" rx="3" ry="3" width="25%" height="25" />
      <rect x="510" y="190" rx="3" ry="3" width="4%" height="25" />
      <rect x="540" y="190" rx="3" ry="3" width="4%" height="25" />
      <rect x="410" y="240" rx="3" ry="3" width="10%" height="15" />
      <rect x="515" y="240" rx="3" ry="3" width="7%" height="15" />
      <rect x="570" y="240" rx="3" ry="3" width="2%" height="15" />
      <rect x="590" y="240" rx="3" ry="3" width="2%" height="15" />
      <rect x="5" y="270" rx="0" ry="0" width="99%" height="1" />
    </ContentLoader>
  );
};
