import { React } from "react";
import { Component } from "react";

import { listAdminData } from "./../../../config/api_calls";
import HelperClass from "./../../../config/helperClass";
import { THEME_COLOR, LOGO, ADMIN_URL } from "../../../config/config"; // You can add your theme color if needed
import { ThreeDots } from "../../../components/ContentLoaders/newLoaders/ThreeDots";

const auth = new HelperClass();

export default class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comp_name: "",
      logo: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      phone: "",
      email: "",
      pageContent: "",
      pageTitle: "",
      imageLoader: true,
      haveData: "",
    };
  }

  async componentDidMount() {
    const { pageUrl } = this.props.match.params;
    const adminresponse = await listAdminData(
      auth.getAccount(),
      auth.getToken(),
      pageUrl
    );
    console.log("Page Info: ", adminresponse.data);

    if (
      adminresponse.data.status === 403 ||
      adminresponse.data.errors === "authentication missing" ||
      adminresponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      adminresponse.data.status === 404 ||
      adminresponse.data.note === "missing"
    ) {
      this.setState({ tableLoader: false });
    } else if (
      adminresponse.data.status === 200 &&
      adminresponse.data.message === "success"
    ) {
      const data = adminresponse.data.data[0];

      const fullAddress = `${data.address}, ${data.city}, ${data.state}, ${data.country} - ${data.zip}`;

      // Replace placeholders with actual values
      let fixedContent = "";
      let pageContentData = [];
      let page_title = "";
      let haveData = "";
      if (
        adminresponse.data.pageContent &&
        adminresponse.data.pageContent.length > 0
      ) {
        pageContentData = adminresponse.data.pageContent[0];
        fixedContent = pageContentData.page_content;

        // Replace dynamic placeholders with actual values
        fixedContent = fixedContent
          .replace(/{email}/g, data.email)
          .replace(/{phone}/g, data.phone)
          .replace(/{fullAddress}/g, fullAddress)
          .replace(/{comp_name}/g, data.comp_name)
          .replace(/{LOGO}/g, LOGO)
          .replace(/{title}/g, pageContentData.page_title);
        page_title = pageContentData.page_title;
        haveData = "Yes";
      } else {
        haveData = "No";
      }

      this.setState({
        comp_name: data.comp_name,
        logo: data.logo,
        address: data.address,
        city: data.city,
        state: data.state,
        country: data.country,
        zip: data.zip,
        phone: data.phone,
        email: data.email,
        pageContent: fixedContent,
        pageTitle: page_title,
        imageLoader: false,
        haveData: haveData,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  render() {
    // Combine address into one line
    const fullAddress = `${this.state.address}, ${this.state.city}, ${this.state.state}, ${this.state.country} - ${this.state.zip}`;

    return (
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="col-md-12 text-center mb-4">
                {this.state.imageLoader === true ? (
                  ThreeDots()
                ) : (
                  <img
                    src={`${ADMIN_URL}files_data/logos/${this.state.logo}`}
                  ></img>
                )}
              </div>
              {this.state.haveData === "Yes" ? (
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.pageContent }}
                />
              ) : this.state.haveData === "No" ? (
                <div class="alert alert-pro alert-warning">
                    <div class="alert-text">
                        <h6>Page Not Found</h6>
                        <p>Page you are looking for does not exist at the moment. </p>
                    </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
