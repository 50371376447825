import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listAccountActivity,
  singleCreditApplication,
} from "./../../../config/client_api_calls";

import $ from "jquery";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  APP_LIVE_URL,
} from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();

class AccountActivity extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      tableDataSingle: [],
      serviceData: [],
      tableLoaderListing: true,
      errorMessage: "",
      successMessage: "",
      columnServices: [
        {
          name: "Created Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatDateTime(value)}</div>;
            },
          },
        },
        {
          name: "Company",
        },
        {
          name: "Activity Type",
        },
        {
          name: "Activity Title",
        },
        {
          name: "Activity Description",
        },
      ],
    };
  }

  async componentDidMount() {
    const servicesResponce = await listAccountActivity(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("Available Credit Applications: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    }
  }

  setStatus = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === "pending") {
      return (
        <div>
          <span className="badge badge-outline-warning">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> Pending
          </span>
        </div>
      );
    } else if (domain_str === "approved") {
      return (
        <div>
          <span className="badge badge-outline-success">
            {" "}
            <em className="icon ni ni-done "></em> Approved
          </span>
        </div>
      );
    } else if (domain_str === "rejected") {
      return (
        <div>
          <span className="badge badge-outline-danger">
            {" "}
            <em className="icon ni ni-alert-c"></em> Rejected
          </span>
        </div>
      );
    }
  };

  render() {
    const { step } = this.state;
    const { email, VerifyUsernameButton } = this.state;
    const values = {
      email,
      VerifyUsernameButton,
    };
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="Support_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              {/* RIGHTS MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalFormDetails">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {" "}
                        <em className="icon ni ni-unlock"></em> Other Details
                      </h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}

                      <div className=" g-4">
                        {this.state.tableLoaderListing === true ? (
                          tableLoaderListing()
                        ) : (
                          <>
                            <div id="no-more-tables-new">
                              <table class="table table-bordered">
                                <thead class="thead-light">
                                  <tr>
                                    <th class="tb-col-os">
                                      <span class="overline-title">Title</span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">Value</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.tableDataSingle &&
                                  this.state.tableDataSingle.length > 0 ? (
                                    <>
                                      <tr>
                                        <td class="tb-col-os">
                                          <strong>Step #1:</strong> Business
                                          Information
                                        </td>
                                        <td class="tb-col-ip">
                                          {this.state.tableDataSingle[0][
                                            "business_information_status"
                                          ] === 1 ? (
                                            <div className="text-success">
                                              <em className="icon ni ni-list-check"></em>{" "}
                                              Completed
                                            </div>
                                          ) : (
                                            <div className="text-danger">
                                              <em className="icon ni ni-na"></em>{" "}
                                              Remaining
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="tb-col-os">
                                          <strong>Step #2:</strong> Financial
                                          Information
                                        </td>
                                        <td class="tb-col-ip">
                                          {this.state.tableDataSingle[0][
                                            "financial_information_status"
                                          ] === 1 ? (
                                            <div className="text-success">
                                              <em className="icon ni ni-list-check"></em>{" "}
                                              Completed
                                            </div>
                                          ) : (
                                            <div className="text-danger">
                                              <em className="icon ni ni-na"></em>{" "}
                                              Remaining
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="tb-col-os">
                                          <strong>Step #3:</strong> Trade
                                          References
                                        </td>
                                        <td class="tb-col-ip">
                                          {this.state.tableDataSingle[0][
                                            "trade_ref_status"
                                          ] === 1 ? (
                                            <div className="text-success">
                                              <em className="icon ni ni-list-check"></em>{" "}
                                              Completed
                                            </div>
                                          ) : (
                                            <div className="text-danger">
                                              <em className="icon ni ni-na"></em>{" "}
                                              Remaining
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="tb-col-os">
                                          <strong>Step #4:</strong> Signatory
                                          Details
                                        </td>
                                        <td class="tb-col-ip">
                                          {this.state.tableDataSingle[0][
                                            "signatory_status"
                                          ] === 1 ? (
                                            <div className="text-success">
                                              <em className="icon ni ni-list-check"></em>{" "}
                                              Completed
                                            </div>
                                          ) : (
                                            <div className="text-danger">
                                              <em className="icon ni ni-na"></em>{" "}
                                              Remaining
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      {this.state.tableDataSingle[0][
                                        "reason"
                                      ] &&
                                      this.state.tableDataSingle[0][
                                        "reason"
                                      ] !== "" ? (
                                        <>
                                          <tr>
                                            <td class="tb-col-os" colSpan={2}>
                                              <strong>
                                                Reason of rejection
                                              </strong>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td class="tb-col-os" colSpan={2}>
                                              {
                                                this.state.tableDataSingle[0][
                                                  "reason"
                                                ]
                                              }
                                            </td>
                                          </tr>
                                        </>
                                      ) : null}
                                    </>
                                  ) : null}
                                </tbody>
                              </table>
                            </div>
                          </>
                        )}
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* RIGHTS MODAL End */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Account Activity"
                        icon="icon ni ni-list"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {this.state.tableLoader === true ? (
                            tableLoader()
                          ) : (
                            <DataTableExtended
                              columns={this.state.columnServices}
                              tableData={this.state.tableData}
                              title=""
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountActivity);
