import React, { Component, Fragment } from "react";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { LOGO_VERIFY } from "../../../config/config";
import { ValidateEmail, normalize } from "../../../config/utility";

import HelperClass from "../../../config/helperClass";
import { tableLoader } from "../../ContentLoaders/table_loader";
import Skeleton from "@material-ui/lab/Skeleton";
import { Helmet, HelmetProvider } from "react-helmet-async";
const auth = new HelperClass();
export class Step2 extends Component {
  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForms = () => {
    const { values } = this.props;
    if (values.name === "") {
      this.setState({
        errorMessage: "Please enter the full name.",
      });
    } else if (values.company_name === "") {
      this.setState({
        errorMessage: "Please enter the company name.",
      });
    } else if (values.email === "") {
      this.setState({
        errorMessage: "Please enter the valid email.",
      });
    } else if (values.phone === "") {
      this.setState({
        errorMessage: "Please enter the valid phone number.",
      });
    } else if (ValidateEmail(values.email) === false) {
      this.setState({
        errorMessage: "Invalid email format.",
      });
      return false;
    } else if (normalize(values.phone) === null) {
      this.setState({
        errorPhone: "error",
        errorEmail: "",
        errorMessage: "Invalid phone format.",
        errorcPass: "",
      });
      return false;
    } else {
      this.continue();
    }

    setTimeout(() => {
      this.setState({
        errorMessage: "",
      });
    }, 3000);
  };

  constructor() {
    super();
    this.state = {
      disabled: false,
      errorMessage: "",
      tableLoader: false,
      dnsList: [],
    };
  }

  modalHideIns = () => {
    window.$("#instructionsModal").modal("hide");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  render() {
    const { values, handleChange } = this.props;

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/csstheme.css?ver=3.0.3"
            />
          </Helmet>
        </HelmetProvider>
        <div className="nk-block nk-block-lg" style={{ width: "100%" }}>
          <div
            className="card card-bordered"
            style={{
              border: "none",
              paddingTop: "10%",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <form
              className="nk-stepper stepper-init is-alter nk-stepper-s1"
              action="#"
              id="stepper-create-profile"
            >
              {this.state.errorMessage !== "" ? (
                <div className="example-alert">
                  <div className="alert alert-pro alert-danger">
                    <div className="alert-text">
                      <h4>Error</h4>
                      <p>{this.state.errorMessage}</p>
                    </div>
                  </div>
                </div>
              ) : null}
              {values.errorMessage !== "" ? (
                <div className="example-alert">
                  <div className="alert alert-pro alert-danger">
                    <div className="alert-text">
                      <h4>Error</h4>
                      <p>{values.errorMessage}</p>
                    </div>
                  </div>
                </div>
              ) : null}
              {values.successMessage !== "" ? (
                <div className="example-alert">
                  <div className="alert alert-pro alert-success">
                    <div className="alert-text">
                      <h4>Success</h4>
                      <p>{values.successMessage}</p>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row g-0 col-sep col-sep-md col-sep-xl">
                <div className="col-md-4 col-xl-4">
                  <div className="card-inner">
                    <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                      {/*<li className="done">
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Company Info</div>
                            <div className="sub-text">
                              Company name and 499 Filer ID
                            </div>
                          </div>
                        </div>
              </li>*/}
                      <li className="current">
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Contact Info</div>
                            <div className="sub-text">
                              Other contact information
                            </div>
                          </div>
                        </div>
                      </li>
                      {/*<li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Other Info</div>
                            <div className="sub-text">
                              Number of employes in the company
                            </div>
                          </div>
                        </div>
            </li>*/}
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Account Password</div>
                            <div className="sub-text">
                              Account password to access the portal
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Preview Details</div>
                            <div className="sub-text">Review and Submit</div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Completed</div>
                            <div className="sub-text">You are done.</div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="card-inner">
                    <div className="nk-stepper-content">
                      <div className="nk-stepper-steps stepper-steps">
                        <div className="nk-stepper-step done"></div>
                        <div className="nk-stepper-step active">
                          <h5 className="title mb-4">Contact info</h5>
                          <div className="row g-3">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="cp1-first-name"
                                >
                                  Full Name
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    placeholder="Full Name"
                                    defaultValue={values.name}
                                    onChange={handleChange}
                                    maxLength={100}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="cp1-first-name"
                                >
                                  Company Name
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="company_name"
                                    name="company_name"
                                    placeholder="Company Name"
                                    defaultValue={values.company_name}
                                    onChange={handleChange}
                                    maxLength={100}
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="cp1-contact-email"
                                >
                                  Email
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    defaultValue={values.email}
                                    onChange={handleChange}
                                    placeholder="info@example.com"
                                    maxLength={100}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="fv-phone"
                                >
                                  Phone
                                </label>
                                <div className="form-control-wrap">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="fv-phone"
                                      >
                                        +1
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      maxLength={10}
                                      defaultValue={values.phone}
                                      onChange={handleChange}
                                      name="phone"
                                      id="phone"
                                      onKeyPress={() => {
                                        this.checkNumber();
                                      }}
                                      placeholder="Enter 10 digit US phone number"
                                      required=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step text-center"></div>
                      </div>
                      <ul className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination">
                        <li className="step-prev">
                          <button
                            type="button"
                            className="btn btn-dim btn-primary"
                            onClick={this.back}
                          >
                            Back
                          </button>
                        </li>
                        <li className="step-next">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.submitForms}
                          >
                            Continue
                          </button>
                        </li>
                        {/*<li className="step-submit">
                          <button className="btn btn-primary">Submit</button>
          </li>*/}
                      </ul>
                      <div
                        className="text-center"
                        style={{ marginTop: "25px" }}
                      >
                        Already have an account ?{" "}
                        <a
                          href={`${auth.getResellerIdentifier()}/client/login`}
                        >
                          Sign in instead
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Step2;
