import react, { Component } from "react";
import ContentLoader from "react-content-loader";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LOGO, THEME_COLOR, THEME_TEXT_COLOR } from "../../config/config";
import { ValidateEmail } from "../../config/utility";
import AdminFooter from "./AdminFooter";
import AdminHeader from "./AdminHeader";
import AdminLeftSideBar from "./AdminLeftSideBar";
import AdminDashboard from "../../Pages/Admin/AdminDashboard";
import AdminServices from "../../Pages/Admin/AdminServices";
import AdminAgreements from "../../Pages/Admin/AdminAgreements";
import Addagreement from "../../Pages/Admin/AdminAgreements/addagreement";
import Editagreement from "../../Pages/Admin/AdminAgreements/editagreement";

import ErrorPage from "../Errors/404";
import AdminClients from "../../Pages/Admin/AdminClients";
import AdminReports from "../../Pages/Admin/AdminReports";
import Services from "../../Pages/Reseller/Services";
import AdminDownloads from "../../Pages/Admin/AdminDownloads";

import DashboardLoader from "../ContentLoaders/dashboard_loader";
import {
  leftMenu,
  validateRights,
  profileUpdate,
} from "../../config/admin_apis";

import HelperClass from "./../../config/helperClass";

//import TextField from "../InputFields/TextField";
import TextField from "@material-ui/core/TextField";

const auth = new HelperClass();
class Admin extends Component {
  //export default class Admin extends Component {
  constructor(props) {
    super(props);

    if (auth.isAdminLogin() === false) {
      auth.adminlogout();
    }

    this.state = {
      view: this.props.view,
      activePage: "",
      Agreement_Content: "",
      title_agreement: "",
      bgColor: "#f5f6fa",
      vendor_signor_signature_file: "",
      published_date: "",
      defaultLoader: true,
      errorCompName: "",
      comp_name: "",
      errorName: "",
      name: "",
      full_name: auth.getAdminFullName(),
      password: "",
      errorPassword: "",
      cpassword: "",
      errorcPassword: "",
      errorTitle: "",
      title: "",
      errorEmail: "",
      email: auth.getAdminEmail(),
      errorPhone: "",
      phone: "",
      errorPass: "",
      password: "",
      errorMessage: "",
      disabled: "",
      successMessage: "",
      leftMenuArr: [],
    };
  }

  getLeftMenu = async () => {
    let res = await leftMenu(auth.getAdminAccount(), auth.getAdminToken());

    if (
      res.data.status === 200 &&
      res.data.message === "success" &&
      res.data.data.length > 0
    ) {
      this.setState({ leftMenuArr: res.data.data });
    }
  };

  async componentDidMount() {
    this.setState({
      comp_name: auth.getCompName(),
      name: auth.getAdminName(),
      email: auth.getAdminEmail(),
      full_name: auth.getAdminFullName(),
      title: auth.getTitle(),
    });
    await this.getLeftMenu();

    var getUri = window.location.pathname.split("/");
    let rightsIdentify = await validateRights(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      getUri[1]
    );
    //console.log("rightsIdentify: ", rightsIdentify);
    if (
      rightsIdentify.data.status === 200 &&
      rightsIdentify.data.message === "success"
    ) {
    } else {
      auth.adminlogout();
      window.location.replace("error");
    }
  }

  formUpdateAdmin = async () => {
    let { full_name, email, password, cpassword } = this.state;
    if (full_name === "") {
      this.setState({
        errorName: "error",
        errorMessage: "Full Name is required.",
      });
      return false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
      });
      return false;
    } else {
      console.log("Password: ", password);
      console.log("cPassword: ", cpassword);
      if (password != "") {
        if (password.length < 8) {
          this.setState({
            errorPassword: "error",
            errorMessage: "Use 8 characters or more for your password",
          });
          return false;
        }
        if (password !== cpassword) {
          this.setState({
            errorPassword: "error",
            errorMessage: "Password and confirm password must be same",
          });
          return false;
        }
      }
      this.setState({
        errorPassword: "",
        errorcPassword: "",
        errorEmail: "",
        errorMessage: "",
        errorConfirm: "",
        errorName: "",
        errorTitle: "",
        disabled: true,
      });

      let apiResponce = await profileUpdate(
        auth.getAdminAccount(),
        auth.getAdminToken(),
        full_name,
        password,
        email
      );

      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "success"
      ) {
        Cookies.set(
          "full_name__",
          CryptoJS.AES.encrypt(
            full_name,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );

        this.setState({
          successMessage: "Profile updated successfully.",
          disabled: false,
        });

        setTimeout(async function () {
          window.$("#modalAdminProfile").modal("hide");
        }, 3000);
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessage: "Something went wrong,try later.",
          disabled: false,
        });
        return false;
      } else {
        auth.adminlogout();
      }
    }
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChanges = async (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  pageView = () => {
    console.log("PAGE PROP ADMIN" + this.state.view);
    if (this.state.view === "admin_dashboard") {
      return <AdminDashboard props={this.props} />;
    } else if (this.state.view === "clients_admin") {
      return <AdminClients props={this.props} />;
    } else if (this.state.view === "admin_services") {
      return <AdminServices props={this.props} />;
    } else if (this.state.view === "admin_agreements") {
      return <AdminAgreements props={this.props} />;
    } else if (this.state.view === "addagreement") {
      return <Addagreement props={this.props} />;
    } else if (this.state.view === "editagreement") {
      return <Editagreement props={this.props} />;
    } else if (this.state.view === "reports_admin") {
      return <AdminReports props={this.props} />;
    } else if (this.state.view === "admin_downloads") {
      return <AdminDownloads props={this.props} />;
    } else if (this.state.view === "services") {
      return <Services props={this.props} />;
    } else {
      return <ErrorPage props={this.props} />;
    }
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  pageChange = (data) => {
    this.props.history.push("/" + data);
  };

  profileView = () => {
    this.setState({ successMessage: "", errorMessage: "" });
    window.$("#modalAdminProfile").modal("show");
  };

  render() {
    return (
      <div className="nk-body bg-lighter npc-general has-sidebar ">
        <div className="nk-app-root">
          <div className="nk-main">
            <div>
              {auth.isAdminLogin() !== false ? (
                <AdminLeftSideBar
                  mainAdminProps={this.props}
                  {...this.props}
                  menuArr={this.state.leftMenuArr}
                />
              ) : null}
            </div>
            <div className="nk-wrap ">
              {/* {this.state.defaultLoader === true ? <DashboardLoader /> : null} */}
              <AdminHeader
                modalOpenAdmin={() => {
                  this.profileView();
                }}
              />
              {this.pageView()}
              {/* MODAL EDIT PROFILE START */}
              <div className="modal fade" tabIndex="-1" id="modalAdminProfile">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Edit Profile</h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      <form method="post">
                        <div className="row">
                          {/* Error Start */}
                          {this.state.errorMessage !== "" ? (
                            <div className="example-alert  col-sm-12">
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div className="example-alert  col-sm-12">
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <br />
                          <div
                            className="col-sm-12"
                            style={{ marginTop: "20px" }}
                          >
                            <TextField
                              label="Full Name"
                              type="text"
                              error={this.state.errorName}
                              value={this.state.full_name}
                              placeholder="Full Name"
                              onChange={this.handleChanges}
                              id="full_name"
                              name="full_name"
                              variant="outlined"
                              fullWidth
                              inputProps={{
                                maxLength: 100,
                              }}
                            />
                          </div>

                          <div
                            className="col-sm-12"
                            style={{ marginTop: "20px" }}
                          >
                            <TextField
                              label="Email"
                              type="email"
                              error={this.state.errorEmail}
                              value={this.state.email}
                              placeholder="Enter your email address"
                              onChange={this.handleChanges}
                              readonly={true}
                              id="email"
                              name="email"
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                          <div
                            className="col-sm-12"
                            style={{ marginTop: "20px" }}
                          >
                            <TextField
                              label="Password"
                              type="password"
                              error={this.state.errorPassword}
                              value={this.state.password}
                              placeholder="Enter your password here"
                              onChange={this.handleChanges}
                              id="password"
                              name="password"
                              variant="outlined"
                              fullWidth
                              inputProps={{
                                maxLength: 50,
                              }}
                            />
                          </div>
                          <div
                            className="col-sm-12"
                            style={{ marginTop: "20px" }}
                          >
                            <TextField
                              label="Confirm Password"
                              type="password"
                              error={this.state.errorcPassword}
                              value={this.state.cpassword}
                              placeholder="Enter confirm password here"
                              onChange={this.handleChanges}
                              id="cpassword"
                              name="cpassword"
                              variant="outlined"
                              fullWidth
                              inputProps={{
                                maxLength: 50,
                              }}
                            />
                          </div>

                          <div
                            className="form-group col-sm-12"
                            style={{ marginTop: "20px" }}
                          >
                            <button
                              type="button"
                              className="btn btn-lg"
                              onClick={() => {
                                this.formUpdateAdmin();
                              }}
                              style={{
                                backgroundColor: THEME_COLOR,
                                color: THEME_TEXT_COLOR,
                                float: "right",
                              }}
                              disabled={this.state.disabled}
                            >
                              Update Profile
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* END MODAL EDIT PROFILE */}
              <AdminFooter />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Admin);
