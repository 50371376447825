import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listAgentForms,
  saveAgentResponse,
} from "./../../../config/agent_forms_api_calls";
import $ from "jquery";
import Tooltip from "@material-ui/core/Tooltip";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import FormRenderer from "../../../components/formRender";
import { TableLoader } from "../../../components/ContentLoaders/newLoaders/TableLoader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import dateFormat from "dateformat";

const auth = new HelperClass();

class Forms extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      tableDataSingle: [],
      serviceData: [],
      tableLoaderListing: true,
      tableLoader: true,
      errorMessage: "",
      successMessage: "",
      showModal: false,
      modalContent: null,
      formAccNo: "",
      successMessageSaveResponse: "",
      errorMessageSaveResponse: "",
      columnServices: [
        { name: "form_title", label: "Form Title" },
        {
          name: "allow_multiple",
          label: "Multiple Submission",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.handleSubmission(value, tableMeta)}</div>;
            },
          },
        },
        { name: "is_filled", label: "Filled" },
        {
          name: "filled_date",
          label: "Filled Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "form_accountno",
          options: {
            display: false,
          },
        },
        {
          name: "Respond to_form",
          label: "Actions",
          options: {
            customBodyRender: (value, tableMeta) => {
              const formAccNo =
                this.state.tableData[tableMeta.rowIndex].form_link;
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            style={{ cursor: "pointer" }}
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-md dropdown-menu-right customStyle_dropdown-menu-md_forms">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <Tooltip title="Preview Form" placement="top">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.addMenuHistory(
                                        
                                          "/agent_form_submissions/" +
                                          formAccNo
                                      )
                                    }
                                  >
                                    <em class="icon ni ni-card-view"></em>
                                    <span>Submissions</span>
                                  </a>
                                </Tooltip>
                              </li>
                              <li>
                                <Tooltip title="Edit Form" placement="top">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.addMenuHistory(
                                        
                                          "/agent_form_submit/" +
                                          formAccNo
                                      )
                                    }
                                  >
                                    <em className="icon ni ni-edit"></em>
                                    <span>Fill Form</span>
                                  </a>
                                </Tooltip>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    const servicesResponce = await listAgentForms(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Available Client Forms: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    }
  }

  format_date = (value, tableMeta) => {
    let date = value;
    if (date && date !== "") {
      //return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
      return dateFormat(date, "mmmm dS, yyyy");
    }
    return "---";
  };

  handleSubmission = (value, tableMeta) => {
    
    if (value === 1) {
      //return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
      return (
        <span className="badge badge-outline-success">
            <em class="icon ni ni-done"></em> Yes
          </span>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
            <em class="icon ni ni-caution"></em> No
          </span>
      );
    }
    return "---";
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  openModal = (formData, formAccNo) => {
    const modalContent = (
      <FormRenderer
        formData={JSON.parse(formData)}
        formTitle={this.state.form_title}
        onSubmit={this.handleSubmitForm}
        // id={this.state.forms[0].id}
        // setShowResponse={(value) => this.setState({ showResponse: value })}
      />
    );
    this.setState({ modalContent: modalContent, formAccNo: formAccNo }, () => {
      window.$("#modalPreviewForm").modal("show");
    });
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  closeModal = () => {
    this.setState({ modalContent: null, formAccNo: "" }, () => {
      window.$("#modalPreviewForm").modal("hide");
    });
  };

  handleSubmitForm = (formData) => {
    let response = JSON.stringify(formData);
    this.saveResponse(response, this.state.formAccNo);
  };

  saveResponse = async (response, formAccNo) => {
    const submitFormReponse = await saveAgentResponse(
      auth.getAccount(),
      auth.getToken(),
      response,
      formAccNo
    );
    console.log("saveResponse response: ", submitFormReponse.data);

    if (
      submitFormReponse.data.status === 403 ||
      submitFormReponse.data.errors === "authentication missing" ||
      submitFormReponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (submitFormReponse.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        errorMessageSaveResponse:
          "There was some error while saving the response.",
      });
    } else if (submitFormReponse.data.status === 200) {
      this.setState({
        successMessageSaveResponse: "Response saved successfully.",
      });
      setTimeout(() => {
        window.location.reload();
      }, 3500);
    }
    setTimeout(() => {
      this.setState({
        errorMessageSaveResponse: "",
        successMessageSaveResponse: "",
      });
    }, 2000);
  };

  render() {
    const { step } = this.state;
    const { email, VerifyUsernameButton } = this.state;
    const values = {
      email,
      VerifyUsernameButton,
    };
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="Support_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              {/* RIGHTS MODAL START */}

              {/* RIGHTS MODAL End */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Forms" icon="icon ni ni-file-docs" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent"></div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert example-alert-revoke mt-3 mb-3"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.tableLoader === true ? (
                            TableLoader()
                          ) : (
                            <DataTableExtended
                              columns={this.state.columnServices}
                              tableData={this.state.tableData}
                              title=""
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="modalPreviewForm">
            <div
              className="modal-dialog modal-lg modal-dialog-top"
              role="document"
            >
              <div className="modal-content">
                <a
                  style={{ cursor: "pointer" }}
                  className="close"
                  onClick={this.closeModal}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body">
                  {this.state.errorMessageSaveResponse !== "" ? (
                    <div
                      className="example-alert "
                      style={{ marginBottom: "15px" }}
                    >
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessageSaveResponse}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessageSaveResponse !== "" ? (
                    <div
                      className="example-alert "
                      style={{ marginBottom: "15px" }}
                    >
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessageSaveResponse}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="nk-modal">{this.state.modalContent}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Forms);
