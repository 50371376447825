import { React } from "react";
import { Component } from "react";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { connect } from "react-redux";
import { addSettingsAdmin } from "../../../actions";
import { COMPANY_NAME } from "../../../config/config";
import { getClients, getReportsData } from "./../../../config/admin_apis";
import $ from "jquery";
import HelperClass from "./../../../config/helperClass";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MutextField from "@material-ui/core/TextField";
const auth = new HelperClass();

class AdminDownloads extends Component {
  constructor() {
    super();
    this.state = {
      reseller_accountno: "925329925329",
      clientData: [],
      reseller_accountno: "",
    };
  }

  async componentDidMount() {
    let page_id = atob(this.props.props.match.params.id);
    console.log("this.props = ", page_id);
  }

  render() {
    console.log("this.props: ", this.props.props.match);
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      Download Reports
                    </h3>
                  </div>
                </div>
              </div>

              <div className="nk-block" style={{ marginTop: "15px" }}>
                <div className="card card-bordered card-stretch">
                  <div className="card-inner-group">
                    {this.state.tableLoader === true
                      ? tableLoader()
                      : tableLoader()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addSettingsAdmin: state.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettingsAdmin: (payload) => dispatch(addSettingsAdmin(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminDownloads);
