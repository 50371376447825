import React from "react";
import ContentLoader from "react-content-loader";

export const Invoice = (props) => {
  return (
    <ContentLoader
    speed={2}
    width={'100%'}
    height={300}
    viewBox="0 0 100% 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    {/* Header Section */}
    <rect x="0" y="0" rx="0" ry="0" width="100%" height="60" />

    {/* Invoice Info Section */}
    <rect x="10%" y="80" rx="0" ry="0" width="80%" height="20" />
    <rect x="10%" y="110" rx="0" ry="0" width="30%" height="20" />
    <rect x="45%" y="110" rx="0" ry="0" width="30%" height="20" />
    <rect x="80%" y="110" rx="0" ry="0" width="10%" height="20" />

    {/* Items Header Section */}
    <rect x="0" y="150" rx="0" ry="0" width="100%" height="20" />

    {/* Item 1 Section */}
    <rect x="0" y="180" rx="0" ry="0" width="100%" height="20" />

    {/* Item 2 Section */}
    <rect x="0" y="210" rx="0" ry="0" width="100%" height="20" />

    {/* Item 3 Section */}
    <rect x="0" y="240" rx="0" ry="0" width="100%" height="20" />

    {/* Footer Section */}
    <rect x="0" y="280" rx="0" ry="0" width="100%" height="20" />
  </ContentLoader>
  );
};
