import React, { Component, Fragment, createRef } from "react";
import axios from "axios";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { COMPANY_NAME, THEME_COLOR } from "../../../config/config";
import { HTML5_FMT } from "moment";
import {
  ValidateEmail,
  normalize,
  ValidateDomain,
} from "../../../config/utility";
import MutextField from "@material-ui/core/TextField";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import Select from "react-select";

export class VerifyUsername extends Component {
  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };

  constructor() {
    super();
    this.state = {
      errorMessage: "",
      dnsList: [],
      verifyEmailButton: false,
    };
    this.phoneInputRef = createRef();
  }

  SubmitFirst = async () => {
    const { values, submitBusinessInformation } = this.props;
    let firstResult = await submitBusinessInformation();
  };

  phoneUtil = PhoneNumberUtil.getInstance();

  handleFocus = () => {
    // Move cursor to the end of the input value
    const input = this.phoneInputRef.current;
    if (input) {
      const value = input.value;
      input.setSelectionRange(value.length, value.length);
    }
  };

  render() {
    const { values, handleChange, addMenuHistory, handleAddressSelect } =
      this.props;

    return (
      <div className="nk-block inner_wizard_container">
        <div className="row g-gs ">
          <div className="col-md-3 ">
            <div className="support-topic-item card card-bordered selected_block">
              <a className="support-topic-block card-inner">
                <div className="support-topic-media">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    <rect
                      x="5"
                      y="7"
                      width="60"
                      height="56"
                      rx="7"
                      ry="7"
                      fill="#e3e7fe"
                      stroke="#6576ff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <rect
                      x="25"
                      y="27"
                      width="60"
                      height="56"
                      rx="7"
                      ry="7"
                      fill="#e3e7fe"
                      stroke="#6576ff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <rect
                      x="15"
                      y="17"
                      width="60"
                      height="56"
                      rx="7"
                      ry="7"
                      fill="#fff"
                      stroke="#6576ff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="15"
                      y1="29"
                      x2="75"
                      y2="29"
                      fill="none"
                      stroke="#6576ff"
                      strokeMiterlimit="10"
                      strokeWidth="2"
                    />
                    <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                    <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                    <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                    <rect
                      x="22"
                      y="39"
                      width="20"
                      height="20"
                      rx="2"
                      ry="2"
                      fill="none"
                      stroke="#6576ff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <circle
                      cx="32"
                      cy="45.81"
                      r="2"
                      fill="none"
                      stroke="#6576ff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      d="M29,54.31a3,3,0,0,1,6,0"
                      fill="none"
                      stroke="#6576ff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="49"
                      y1="40"
                      x2="69"
                      y2="40"
                      fill="none"
                      stroke="#6576ff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="49"
                      y1="51"
                      x2="69"
                      y2="51"
                      fill="none"
                      stroke="#c4cefe"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="49"
                      y1="57"
                      x2="59"
                      y2="57"
                      fill="none"
                      stroke="#c4cefe"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="64"
                      y1="57"
                      x2="66"
                      y2="57"
                      fill="none"
                      stroke="#c4cefe"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="49"
                      y1="46"
                      x2="59"
                      y2="46"
                      fill="none"
                      stroke="#c4cefe"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="64"
                      y1="46"
                      x2="66"
                      y2="46"
                      fill="none"
                      stroke="#c4cefe"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                </div>
                <div className="support-topic-context">
                  <h4 className="support-topic-title title">Step 1</h4>
                  <h6 className="support-topic-title title text-body">
                    Business Information
                  </h6>
                </div>
              </a>
            </div>
          </div>

          <div className="col-md-3 ">
            <div className="support-topic-item card card-bordered">
              <a className="support-topic-block card-inner">
                <div className="support-topic-media">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    <rect
                      x="9"
                      y="21"
                      width="55"
                      height="39"
                      rx="6"
                      ry="6"
                      fill="#fff"
                      stroke="#6576ff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="17"
                      y1="44"
                      x2="25"
                      y2="44"
                      fill="none"
                      stroke="#c4cefe"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="30"
                      y1="44"
                      x2="38"
                      y2="44"
                      fill="none"
                      stroke="#c4cefe"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="42"
                      y1="44"
                      x2="50"
                      y2="44"
                      fill="none"
                      stroke="#c4cefe"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="17"
                      y1="50"
                      x2="36"
                      y2="50"
                      fill="none"
                      stroke="#c4cefe"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <rect
                      x="16"
                      y="31"
                      width="15"
                      height="8"
                      rx="1"
                      ry="1"
                      fill="#c4cefe"
                    />
                    <path
                      d="M76.79,72.87,32.22,86.73a6,6,0,0,1-7.47-4L17,57.71A6,6,0,0,1,21,50.2L65.52,36.34a6,6,0,0,1,7.48,4l7.73,25.06A6,6,0,0,1,76.79,72.87Z"
                      fill="#fff"
                      stroke="#6576ff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <polygon
                      points="75.27 47.3 19.28 64.71 17.14 57.76 73.12 40.35 75.27 47.3"
                      fill="#6576ff"
                    />
                    <path
                      d="M30,77.65l-1.9-6.79a1,1,0,0,1,.69-1.23l4.59-1.3a1,1,0,0,1,1.23.7l1.9,6.78A1,1,0,0,1,35.84,77l-4.59,1.3A1,1,0,0,1,30,77.65Z"
                      fill="#c4cefe"
                    />
                    <path
                      d="M41.23,74.48l-1.9-6.78A1,1,0,0,1,40,66.47l4.58-1.3a1,1,0,0,1,1.23.69l1.9,6.78A1,1,0,0,1,47,73.88l-4.58,1.29A1,1,0,0,1,41.23,74.48Z"
                      fill="#c4cefe"
                    />
                    <path
                      d="M52.43,71.32l-1.9-6.79a1,1,0,0,1,.69-1.23L55.81,62A1,1,0,0,1,57,62.7l1.9,6.78a1,1,0,0,1-.69,1.23L53.66,72A1,1,0,0,1,52.43,71.32Z"
                      fill="#c4cefe"
                    />
                    <ellipse
                      cx="55.46"
                      cy="19.1"
                      rx="16.04"
                      ry="16.1"
                      fill="#fff"
                      stroke="#6576ff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <ellipse
                      cx="55.46"
                      cy="19.1"
                      rx="12.11"
                      ry="12.16"
                      fill="#e3e7fe"
                    />
                    <text
                      transform="translate(50.7 23.72) scale(0.99 1)"
                      fontSize="16.12"
                      fill="#6576ff"
                      fontFamily="Nunito-Black, Nunito Black"
                    >
                      $
                    </text>
                  </svg>
                </div>
                <div className="support-topic-context">
                  <h4 className="support-topic-title title">Step 2</h4>
                  <h6 className="support-topic-title title text-body">
                    Financial Information
                  </h6>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-3 ">
            <div className="support-topic-item card card-bordered">
              <a className="support-topic-block card-inner">
                <div className="support-topic-media">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    <path
                      d="M29,74H11a7,7,0,0,1-7-7V17a7,7,0,0,1,7-7H61a7,7,0,0,1,7,7V30"
                      fill="#fff"
                      stroke="#6576ff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      d="M11,11H61a6,6,0,0,1,6,6v4a0,0,0,0,1,0,0H5a0,0,0,0,1,0,0V17A6,6,0,0,1,11,11Z"
                      fill="#e3e7fe"
                    />
                    <circle cx="11" cy="16" r="2" fill="#6576ff" />
                    <circle cx="17" cy="16" r="2" fill="#6576ff" />
                    <circle cx="23" cy="16" r="2" fill="#6576ff" />
                    <rect
                      x="11"
                      y="27"
                      width="19"
                      height="19"
                      rx="1"
                      ry="1"
                      fill="#c4cefe"
                    />
                    <path
                      d="M33.8,53.79c.33-.43.16-.79-.39-.79H12a1,1,0,0,0-1,1V64a1,1,0,0,0,1,1H31a1,1,0,0,0,1-1V59.19a10.81,10.81,0,0,1,.23-2Z"
                      fill="#c4cefe"
                    />
                    <line
                      x1="36"
                      y1="29"
                      x2="60"
                      y2="29"
                      fill="none"
                      stroke="#c4cefe"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="36"
                      y1="34"
                      x2="55"
                      y2="34"
                      fill="none"
                      stroke="#c4cefe"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="36"
                      y1="39"
                      x2="50"
                      y2="39"
                      fill="none"
                      stroke="#c4cefe"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      x1="36"
                      y1="44"
                      x2="46"
                      y2="44"
                      fill="none"
                      stroke="#c4cefe"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <rect x="4" y="21" width="64" height="2" fill="#6576ff" />
                    <rect
                      x="36"
                      y="56"
                      width="38"
                      height="24"
                      rx="5"
                      ry="5"
                      fill="#fff"
                      stroke="#e3e7fe"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <rect
                      x="41"
                      y="60"
                      width="12"
                      height="9"
                      rx="1"
                      ry="1"
                      fill="#c4cefe"
                    />
                    <path
                      d="M84.74,53.51,66.48,35.25a4.31,4.31,0,0,0-6.09,0l-9.13,9.13a4.31,4.31,0,0,0,0,6.09L69.52,68.73a4.31,4.31,0,0,0,6.09,0l9.13-9.13A4.31,4.31,0,0,0,84.74,53.51Zm-15-5.89L67,50.3a2.15,2.15,0,0,1-3,0l-4.76-4.76a2.16,2.16,0,0,1,0-3l2.67-2.67a2.16,2.16,0,0,1,3,0l4.76,4.76A2.15,2.15,0,0,1,69.72,47.62Z"
                      fill="#6576ff"
                    />
                  </svg>
                </div>
                <div className="support-topic-context">
                  <h4 className="support-topic-title title">Step 3</h4>
                  <h6 className="support-topic-title title text-body">
                    Trade References
                  </h6>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-3 ">
            <div className="support-topic-item card card-bordered">
              <a className="support-topic-block card-inner">
                <div className="support-topic-media">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    <rect
                      x="3"
                      y="12.5"
                      width="64"
                      height="63.37"
                      rx="7"
                      ry="7"
                      fill="#fff"
                      stroke="#6576ff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      d="M10,13.49H60a6,6,0,0,1,6,6v3.9a0,0,0,0,1,0,0H4a0,0,0,0,1,0,0v-3.9A6,6,0,0,1,10,13.49Z"
                      fill="#e3e7fe"
                    />
                    <rect
                      x="3"
                      y="23.39"
                      width="64"
                      height="1.98"
                      fill="#6576ff"
                    />
                    <path
                      d="M65.37,31.31H76.81A12.24,12.24,0,0,0,87,42S88.12,66.31,65.37,77.5C42.62,66.31,43.75,42,43.75,42A12.23,12.23,0,0,0,53.93,31.31Z"
                      fill="#fff"
                      stroke="#6576ff"
                      strokeMiterlimit="10"
                      strokeWidth="2"
                    />
                    <path
                      d="M66,72.62c19-9.05,18.1-28.71,18.1-28.71s-7.47-.94-8.52-8.64H66Z"
                      fill="#e3e7fe"
                    />
                    <polygon
                      points="56 46.16 55 46.16 55 42.2 59 42.2 59 43.2 56 43.2 56 46.16"
                      fill="#010863"
                    />
                    <polygon
                      points="59 65.97 55 65.97 55 62.01 56 62.01 56 64.98 59 64.98 59 65.97"
                      fill="#010863"
                    />
                    <polygon
                      points="78 65.97 74 65.97 74 64.98 77 64.98 77 62.01 78 62.01 78 65.97"
                      fill="#010863"
                    />
                    <polygon
                      points="78 46.16 77 46.16 77 43.2 74 43.2 74 42.2 78 42.2 78 46.16"
                      fill="#010863"
                    />
                    <path
                      d="M70,51.12H62V48.86a3.74,3.74,0,0,1,3.17-3.57c2.56-.46,4.83,1.28,4.83,3.49Zm-7-1h6V48.56a2.78,2.78,0,0,0-2-2.63,3,3,0,0,0-4,2.64Z"
                      fill="#6576ff"
                    />
                    <path
                      d="M58,57.28V50.13H74V57.5c0,4.62-4.65,8.26-9.86,7.17A7.63,7.63,0,0,1,58,57.28Z"
                      fill="#e5effe"
                    />
                    <path
                      d="M59,51.12v6.7A7,7,0,0,0,73,58V51.12Z"
                      fill="#6576ff"
                    />
                    <ellipse cx="66" cy="55.08" rx="2" ry="1.98" fill="#fff" />
                    <polygon
                      points="68.91 62.01 63.84 62.01 65.18 56.07 67.57 56.07 68.91 62.01"
                      fill="#fff"
                    />
                    <path
                      d="M72,51.12H60V48.66a5.41,5.41,0,0,1,4.06-5.14c4.13-1.14,7.94,1.54,7.94,5Zm-11-1H71V48.49A4.69,4.69,0,0,0,67.08,44c-3.23-.6-6.08,1.58-6.08,4.33Z"
                      fill="#6576ff"
                    />
                    <rect
                      x="13"
                      y="32.3"
                      width="22"
                      height="5.94"
                      rx="1"
                      ry="1"
                      fill="none"
                      stroke="#6576ff"
                      strokeMiterlimit="10"
                      strokeWidth="2"
                    />
                    <rect
                      x="12"
                      y="45.17"
                      width="22"
                      height="5.94"
                      rx="1"
                      ry="1"
                      fill="none"
                      stroke="#6576ff"
                      strokeMiterlimit="10"
                      strokeWidth="2"
                    />
                    <rect
                      x="12"
                      y="57.06"
                      width="12"
                      height="5.94"
                      rx="1"
                      ry="1"
                      fill="none"
                      stroke="#6576ff"
                      strokeMiterlimit="10"
                      strokeWidth="2"
                    />
                  </svg>
                </div>
                <div className="support-topic-context">
                  <h4 className="support-topic-title title">Step 4</h4>
                  <h6 className="support-topic-title title text-body">
                    Signatory Info
                  </h6>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="row g-4 justify-content-center">
          <div className="col-md-11 mt-5">
            <div className="nk-block-head mt-3 mb-3">
              <div className="nk-block-head-content">
                <h4 className="nk-block-title">Business Information</h4>
                <div className="nk-block-des">
                  Please provide key details about your business, including its
                  name, address, contact information, and any other relevant
                  information.
                </div>
              </div>
            </div>
            {values.errorMessage !== "" ? (
              <div className="example-alert mb-5">
                <div className="alert alert-pro alert-danger">
                  <div className="alert-text">
                    <h4>Error</h4>
                    <p>{values.errorMessage}</p>
                  </div>
                </div>
              </div>
            ) : null}
            {values.successMessage !== "" ? (
              <div className="example-alert mb-5">
                <div className="alert alert-pro alert-success">
                  <div className="alert-text">
                    <h4>Success</h4>
                    <p>{values.successMessage}</p>
                  </div>
                </div>
              </div>
            ) : null}
            <br />
            <form method="post" encType="multipart/form-data">
              <div className="row g-4">
                <div className="form-group col-md-6">
                  <MutextField
                    required
                    id="customer_name"
                    name="customer_name"
                    type="text"
                    label="Customer Name"
                    inputProps={{ maxLength: 64 }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^a-zA-Z0-9 ]/gi,
                        ""
                      );
                    }}
                    value={values.customer_name}
                    onChange={handleChange("customer_name")}
                    helperText="Enter the customer full name."
                    variant="outlined"
                    fullWidth
                  />
                </div>

                <div className="form-group col-md-6">
                  {/*<MutextField
                    required
                    id="customer_phone"
                    name="customer_phone"
                    type="text"
                    label="Phone Number"
                    inputProps={{ maxLength: 11, minLength: 7 }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/gi, "");
                    }}
                    value={values.customer_phone}
                    onChange={handleChange("customer_phone")}
                    helperText="Enter the phone number here. Only numbers, 7 to 10 characters"
                    variant="outlined"
                    fullWidth
                  />*/}
                  <PhoneInput
                    style={{ height: "70%" }}
                    required
                    defaultCountry={"us"}
                    inputProps={{
                      name: "customer_phone",
                      id: "customer_phone",
                      maxLength: 20,
                    }}
                    value={values.customer_phone}
                    onChange={(value) =>
                      handleChange({
                        target: { name: "customer_phone", value },
                      })
                    }
                    helperText=""
                    label="Phone Number"
                    ref={this.phoneInputRef}
                    onFocus={this.handleFocus}
                  />
                </div>
                <div className="form-group col-md-12">
                  {/*<MutextField
                    required
                    id="customer_address1"
                    name="customer_address1"
                    type="text"
                    inputProps={{ maxLength: 100 }}
                    label="Address 1"
                    value={values.customer_address1}
                    onChange={handleChange("customer_address1")}
                    helperText="Please provide your complete address, excluding city, state, and zip."
                    variant="outlined"
                    fullWidth
                  />*/}
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY}
                    selectProps={{
                      value: values.customer_address1
                        ? {
                            label: values.customer_address1,
                            value: values.customer_address1,
                          }
                        : null,
                      onChange: handleAddressSelect,
                      placeholder: values.customer_address1
                        ? null
                        : "Enter your address..",
                      styles: {
                        control: (provided) => ({
                          ...provided,
                          minHeight: "56px",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: "9",
                        }),
                      },
                    }}
                    id="googleLocation-autocomplete"
                  />
                </div>

                <div className="form-group col-md-12">
                  <MutextField
                    id="customer_address2"
                    name="customer_address2"
                    type="text"
                    label="Address 2"
                    value={values.customer_address2}
                    onChange={handleChange("customer_address2")}
                    inputProps={{ maxLength: 100 }}
                    helperText="If needed, enter additional address details, such as apartment number, suite, or unit."
                    variant="outlined"
                    fullWidth
                  />
                </div>

                <div className="form-group col-md-6">
                  <MutextField
                    required
                    id="customer_city"
                    name="customer_city"
                    type="text"
                    label="City"
                    value={values.customer_city}
                    inputProps={{ maxLength: 32 }}
                    onChange={handleChange("customer_city")}
                    helperText=""
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <div className="form-group col-md-6">
                  <MutextField
                    required
                    id="customer_state"
                    name="customer_state"
                    type="text"
                    label="State"
                    value={values.customer_state}
                    inputProps={{ maxLength: 32 }}
                    onChange={handleChange("customer_state")}
                    helperText=""
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <div className="form-group col-md-6">
                  <MutextField
                    required
                    id="customer_zip"
                    name="customer_zip"
                    type="text"
                    label="Zip"
                    value={values.customer_zip}
                    inputProps={{ maxLength: 8 }}
                    onChange={handleChange("customer_zip")}
                    helperText=""
                    variant="outlined"
                    fullWidth
                  />
                </div>

                <div style={{ clear: "both" }}></div>
                <hr />
              </div>
              <div className="d-flex justify-content-end">
                <div className="form-group text-right">
                  {values.VerifyBusinessButton ? (
                    <button
                      className="btn btn-lg btn-primary btn-block"
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Processing...</span>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary btn-block"
                      disabled={this.state.disabled}
                      onClick={this.SubmitFirst}
                    >
                      <span> Next</span>
                      <em className="icon ni ni-arrow-right-c"></em>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifyUsername;
