import { React } from "react";
import { Component } from "react";
import { createRef } from "react";
import axios from "axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listSharelinks,
  getSharelinkTheme,
  getSharelinkServices,
  getSharelinkLogos,
  getSharelinkSettings,
  saveSharelinkDetail,
  saveSharelinkTheme,
  saveSharelinkServices,
  getSharelinkBundles,
  saveSharelinkBundles,
  saveSharelinkLogos,
  saveSharelinkContact,
  saveSharelinkSocial,
  saveSharelinkSEO,
  saveSharelinkAbout,
  regenerateSharelink,
  sendEmailSharelink,
  getSharelinkStats,
  getDomains,
  connectSharelinkSubmit,
  getAssignedDomain,
  getContactLogs,
  saveSharelinkOtherSettings,
  saveSharelinkTestimonials,
  getSharelinkTestimonials,
  saveNewTestimonial,
  updateTestimonial,
  deleteTestimonial,
  listServiceTypes
} from "./../../../config/shortlink_api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import DataTableSmall from "../../../components/Tables/DataTableSmall";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { tableLoaderBlockImage } from "../../../components/ContentLoaders/table_loader_imageblock";
import { tableLoaderSimple } from "../../../components/ContentLoaders/table_loader_simple";
import { tableLoaderLine } from "../../../components/ContentLoaders/table_loader_line";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/FormLoader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  APP_LIVE_URL,
  API_URL,
} from "./../../../config/config";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MutextField from "@material-ui/core/TextField";
//import { Editor } from "@tinymce/tinymce-react";
//import { CKEditor } from "@ckeditor/ckeditor5-react";
//import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import RichTextEditorComponent from "../../../components/Reseller/RichTextEditorComponent";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import QRCode from "qrcode.react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";
import Select from "react-select";

const auth = new HelperClass();

class Sharelink extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      tableDataContactLogs: [],
      serviceData: [],
      selected_bundle: [],
      bundlesData: [],
      defaultServices: [],
      errorMessage: "",
      uErrorMessage: "",
      uSuccessMessage: "",
      linkSuccessMessage: "",
      linkErrorMessage: "",
      getThemeErrorMessage: "",
      getServiceErrorMessage: "",
      getBundleErrorMessage: "",
      getLogoErrorMessage: "",
      getAboutErrorMessage: "",
      getContactErrorMessage: "",
      getSocialErrorMessage: "",
      getSeoErrorMessage: "",
      docErrorMessage: "",
      docSuccessMessage: "",
      formLoader: true,
      showImageLoader: false,
      edit_picture: "",
      edit_picture_name: "",
      edit_images_type: "logo",
      edit_image_title: "",
      serviceId: "",
      successMessage: "",
      waitMessage: "",
      tableLoaderListing: true,
      tableLoaderBlockImage: true,
      tableLoaderBlockService: true,
      tableLoaderBlockBundle: true,
      tableLoaderBlockLogo: true,
      tableLoaderBlockAbout: true,
      tableLoaderBlockSocial: true,
      tableLoaderBlockContact: true,
      tableLoaderBlockSEO: true,
      tableLoaderLine: true,
      eErrorMessage: "",
      eSuccessMessage: "",
      copied: false,
      cpied_value: "",
      send_email: "",
      title: "",
      status: "",
      landing_page: "",
      about_me_settings: false,
      contact_settings: false,
      social_settings: false,
      theme: "",
      meta_description: "",
      meta_title: "",
      sharelink_id: "",
      complete_link: "",
      themesList: [],
      selected_service: [],
      select_logo: false,
      select_avatar: false,
      selected_logo: "",
      selected_avatar: "",
      avatarData: [],
      about_me_short: "",
      about_me: "",
      instagram_link: "",
      facebook_link: "",
      twitter_link: "",
      linkedin_link: "",
      youtube_link: "",
      settingData: [],
      logoData: [],
      reg_button: false,
      reg_email_button: false,
      sharelink2_id: "",
      signup_link: "",
      show_regenerate: "",
      custom_link: "",
      email_to: "",
      contact_address: auth.getAddress(),
      contact_email: auth.getEmail(),
      contact_phone: auth.getPhone(),
      stat_hits: 0,
      last_visit: "",
      statErrorMessage: "",
      available_domains: [],
      btn_loader: false,
      assigned_domain: "",
      sharelink_limit: 0,
      sharelink_added: 0,
      select_banner: false,
      selected_banner: "",
      bannerData: [],
      emailError: "",
      phoneError: "",
      addressError: "",
      instagramError: "",
      facebookError: "",
      twitterError: "",
      linkedinError: "",
      youtubeError: "",
      isEditorReady: false,
      brightness: 100,
      showBrightnessRange: false,
      other_settings: {},
      selected_avatar2: "",
      avatarData2: [],

      currentPage: {
        avatar: 1,
        avatar2: 1,
        logo: 1,
        banner: 1,
      },
      avatarsPerPage: 3,
      currentPageAvatar: 1,
      currentPageAvatar2: 1,
      currentPageBanner: 1,
      currentPageLogo: 1,

      all_service_types: [],
      selected_service_types: [],
      service_type_id: [],

      isAddingTestimonial: false,
      testimonials: [],
      selectedTestimonials: [],
      testimonial_name: "",
      testimonial_title: "",
      testimonial_comment: "",
      testimonial_img: "",
      selectedImageName: "",
      testimonialErrors: {
        nameError: "",
        titleError: "",
        commentError: "",
      },
      save_testimonial_btn_disable: false,
      columnTestimonials: [
        { name: "Id", options: { display: false } },
        {
          name: "Select",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={this.handleTestimonialSelection(
                        tableMeta.rowData[0]
                      )}
                      color="primary"
                      checked={Array.isArray(this.state.selectedTestimonials) && this.state.selectedTestimonials.includes(tableMeta.rowData[0])}
                    />
                  }
                />
              );
            },
          },
        },

        { name: "Name" },
        { name: "Title" },
        { name: "Comment" },
        {
          name: "Image",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  {tableMeta.rowData[5] && tableMeta.rowData[5] !== "" ? (
                    <img
                      style={{
                        height: "50px",
                        width: "auto",
                      }}
                      src={`${APP_LIVE_URL}files_data/sharelinks/${tableMeta.rowData[5]}`}
                      alt="Testimonial Image"
                    />
                  ) : (
                    <span>-</span>
                  )}
                </div>
              );
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() => this.editTestimonial(value)}
                                  title="Edit Testimonial"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit Testimonial</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() => this.deleteTestimonial(value)}
                                  title="Delete Testimonial"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-trash-empty"></em>
                                  <span>Delete Testimonial</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
      columnServices: [
        {
          name: "Title",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  {/* Add onClick function to the title */}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.showDetails(tableMeta.rowData[10], tableMeta)
                    }
                  >
                    {value}
                  </a>
                </div>
              );
            },
          },
        },
        { name: "Date Added" },
        { name: "Last Visit" },
        {
          name: "Total Hits",
        },
        {
          name: "About Me",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultSettings(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Contact Settings",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultSettings(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Social Settings",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultSettings(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Landing Page",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.landinPage(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Agent Link",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <>
                  <a
                    style={{ cursor: "pointer", color: "#0000EE" }}
                    onClick={() => this.agentLink(value, tableMeta)}
                    title="Agent Share Link"
                    disabled={
                      this.props.is_msa_signed === "No" ||
                      this.props.defaultSettings === "missing"
                        ? true
                        : false
                    }
                  >
                    View
                    <em
                      className="icon ni ni-external-alt"
                      style={{ marginLeft: "3px" }}
                    ></em>
                  </a>
                </>
              );
              // return (
              //   <div>
              //     <div
              //       className="nk-tb-col nk-tb-col-tools"
              //       style={{ padding: "0px" }}
              //     >
              //       <ul className="nk-tb-actions gx-1">
              //         <li>
              //           <div className="">
              //             <a
              //               style={{ cursor: "pointer" }}
              //               onClick={() => this.agentLink(value, tableMeta)}
              //               title="Agent Share Link"
              //               disabled={
              //                 this.props.is_msa_signed === "No" ||
              //                 this.props.defaultSettings === "missing"
              //                   ? true
              //                   : false
              //               }
              //               className=" btn btn-icon  btn-outline-light"
              //             >
              //               <em className="icon ni ni-link-h"></em>
              //             </a>
              //           </div>
              //         </li>
              //       </ul>
              //     </div>
              //   </div>
              // );
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              const landingPageValue = tableMeta.rowData[8];
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            style={{ cursor: "pointer" }}
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div
                            className="dropdown-menu dropdown-menu-right"
                            style={{ minWidth: "220px" }}
                          >
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.showDetails(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Service Details"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em class="icon ni ni-edit"></em>
                                  <span>Edit</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.connectDomain(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Sharelink Stats"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-link"></em>
                                  <span>Sharelink Domain</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{
                                    cursor:
                                      landingPageValue === "No"
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                  onClick={() =>
                                    landingPageValue === "No"
                                      ? console.log(
                                          "No landing page for this sharelink."
                                        )
                                      : this.contactLogs(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Contact Logs"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-contact"></em>
                                  <span>Contact Logs</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
      columnContactLogs: [
        { name: "Date Added" },
        { name: "Name" },
        { name: "Email" },

        { name: "Phone" },
        { name: "Subject" },
        { name: "Message" },
      ],
    };
    this.cropperRef = createRef();
    this.phoneInputRef = createRef();
  }

  showDetails = async (value, tableMeta) => {
    this.setState({
      sharelink_id: "",
    });
    let services_data = this.state.tableData;
    console.log("value: ", value, ", services_data: ", services_data);
    for (let i = 0; i < services_data.length; i++) {
      if (services_data[i][10] === value) {
        console.log(
          "value: ",
          value,
          ", services_data[i][10]: ",
          services_data[i][10]
        );
        let about_setting = false;
        if (services_data[i][4] === "Yes") {
          about_setting = true;
        }
        let cont_setting = false;
        if (services_data[i][5] === "Yes") {
          cont_setting = true;
        }
        let sm_setting = false;
        if (services_data[i][6] === "Yes") {
          sm_setting = true;
        }
        this.setState({
          sharelink_id: services_data[i][10],
          title: services_data[i][0],
          status: services_data[i][7],
          about_me_settings: about_setting,
          contact_settings: cont_setting,
          social_settings: sm_setting,
          complete_link: services_data[i][9],
          landing_page: services_data[i][8],
        });
      }
    }

    //$('a[href="#tab-1"]').click();
    $("#detail_tabs ul li a").removeClass("active"); // remove from all buttons
    $(".tab-content .tab-pane").removeClass("active"); // remove from all tabs

    $("#detail_tabs ul li a[href='#tab-1']").addClass("active"); // add to the targeted button
    $(".tab-content #tab-1").addClass("active"); // add to the targedted tabs
    //console.log("title: ", this.state.title);
    window.$("#modalDetails").modal("show");
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };

  agentLink = (value, tableMeta) => {
    let agent_link = tableMeta.rowData[9];
    let sharelink_id = tableMeta.rowData[10];
    this.getAssignedDomain(sharelink_id);
    console.log("agent_link: ", agent_link);
    this.setState({
      copied: false,
      signup_link: agent_link,
      sharelink2_id: sharelink_id,
    });
    window.$("#modalSignupLink").modal("show");
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = tableMeta.rowData[7];

    if (is_default === 0) {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-na"></em> Inactive
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    }
  };

  landinPage = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = tableMeta.rowData[8];

    if (is_default === "No") {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    }
  };

  defaultSettings = (value, tableMeta) => {
    let is_default = value;
    if (is_default === "No") {
      return (
        <span className="badge badge-outline-warning">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    }
  };

  getAssignedDomain = async (sharelink_id) => {
    const servicesResponce = await getAssignedDomain(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id
    );
    console.log("getAssignedDomain: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "errors"
    ) {
      this.setState({
        tableLoaderLine: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        assigned_domain: "https://" + servicesResponce.data.domain,
        tableLoaderLine: false,
      });
    } else {
      this.setState({
        tableLoaderLine: false,
      });
    }
  };

  sharelinkStats = async (value, tableMeta) => {
    let sharelink_id = tableMeta.rowData[10];
    const servicesResponce = await getSharelinkStats(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id
    );
    //console.log("sharelinkStats: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "errors"
    ) {
      this.setState({
        statErrorMessage: servicesResponce.data.data,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        statErrorMessage: "",

        stat_hits: servicesResponce.data.hits,
        last_visit: servicesResponce.data.last_visit,
      });
      //modalStats
      window.$("#modalStats").modal("show");
    } else {
      this.setState({
        statErrorMessage: "There is some error while getting the stats.",
      });
      // window.location.replace("/error");
    }
  };

  contactLogs = async (value, tableMeta) => {
    let sharelink_id = tableMeta.rowData[10];
    const servicesResponce = await getContactLogs(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id
    );
    this.setState({
      tableDataContactLogs: [],
    });
    //console.log("contactLogs: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "errors"
    ) {
      this.setState({
        tableDataContactLogs: [],
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableDataContactLogs: servicesResponce.data.data,
        tableLoaderListing: false,
      });
      //modalStats
      window.$("#modalContactLogs").modal("show");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        tableDataContactLogs: [],
      });
      //modalStats
      window.$("#modalContactLogs").modal("show");
    } else {
      this.setState({
        tableDataContactLogs: [],
      });
      // window.location.replace("/error");
    }
  };

  send_email = async () => {
    const { sharelink2_id, email_to, assigned_domain } = this.state;

    this.setState({
      reg_email_button: true,
      eErrorMessage: "",
      eSuccessMessage: "",
    });

    if (email_to === "") {
      this.setState({
        eErrorMessage: "Please enter the email address.",
        eSuccessMessage: "",
        reg_email_button: false,
      });
    } else {
      const servicesResponce = await sendEmailSharelink(
        auth.getAccount(),
        auth.getToken(),
        sharelink2_id,
        email_to,
        this.state.signup_link,
        assigned_domain
      );
      console.log("send_email: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        servicesResponce.data.status === 404 &&
        servicesResponce.data.message === "errors"
      ) {
        this.setState({
          eErrorMessage: servicesResponce.data.data,
          eSuccessMessage: "",
          reg_email_button: false,
        });
        //window.location.replace("/error");
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          eErrorMessage: "",
          eSuccessMessage: "Sharelink sent successfully.",
          reg_email_button: false,
          email_to: "",
          //send_email: ""
        });
      } else {
        this.setState({
          eErrorMessage: "There is some error while emailing the sharelink.",
          eSuccessMessage: "",
          reg_email_button: false,
        });
        // window.location.replace("/error");
      }
    }
    setTimeout(() => {
      this.setState({ eSuccessMessage: "", eErrorMessage: "" });
    }, 5000);
  };

  async componentDidMount() {
    const servicesResponce = await listSharelinks(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("listSharelinks: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableData: [],
        tableLoaderListing: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoaderListing: false,
        sharelink_added: servicesResponce.data.data.length,
        sharelink_limit: servicesResponce.data.sharelink_limit,
      });
    } else {
      this.setState({
        tableData: [],
        tableLoaderListing: false,
      });
    }
  }

  groupBy = (arr, property) => {
    let intArray = arr;
    let evenArray = [];
    intArray.map((x) => {
      if (x.images_type === property) {
        evenArray.push(x);
      }
    });

    return evenArray;
  };

  handleEditorChange = (updatedContent) => {
    this.setState({
      about_me: updatedContent,
    });
  };

  handleEditorChangeShort = (updatedContent) => {
    this.setState({
      about_me_short: updatedContent,
    });
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  open_add_form = () => {
    window.$("#modalAddSharelink").modal("show");
  };

  show_email = () => {
    this.setState({
      send_email: "1",
      show_regenerate: "",
    });
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;

    const { name } = target;

    if (name === "contact_email") {
      if (value.trim() !== "") {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        this.setState({
          [name]: value,
          emailError: isValidEmail ? "" : "Invalid email address",
        });
        return;
      } else {
        this.setState({
          [name]: value,
          emailError: "",
        });
      }
    }

    if (name === "contact_phone") {
      if (value.trim() !== "") {
        const isValidPhone = /^\+?\d{7,}$/.test(value);
        this.setState({
          [name]: value,
          phoneError: isValidPhone ? "" : "Invalid phone number",
        });
        return;
      } else {
        this.setState({
          [name]: value,
          phoneError: "",
        });
      }
    }

    if (name === "contact_address") {
      if (value.trim() !== "") {
        const isValidAddress =
          value.trim().length >= 5 && !/^\d+$/.test(value.trim());
        this.setState({
          [name]: value,
          addressError: isValidAddress ? "" : "Invalid address",
        });
      } else {
        this.setState({
          [name]: value,
          addressError: "",
        });
      }
    }

    if (name === "instagram_link") {
      if (value.trim() !== "") {
        const instagramRegex =
          /^https:\/\/www\.instagram\.com\/[a-zA-Z0-9_]{4,}\/?$/;
        const isValidInstagramLink = instagramRegex.test(value);
        this.setState({
          [name]: value,
          instagramError: isValidInstagramLink ? "" : "Invalid Instagram link",
        });
      } else {
        this.setState({
          [name]: value,
          instagramError: "",
        });
      }
      return;
    }

    if (name === "facebook_link") {
      if (value.trim() !== "") {
        const facebookRegex =
          /^https:\/\/www\.facebook\.com\/[a-zA-Z0-9_.-]{4,}\/?$/;
        const isValidfacebookLink = facebookRegex.test(value);
        this.setState({
          [name]: value,
          facebookError: isValidfacebookLink ? "" : "Invalid Facebook link",
        });
      } else {
        this.setState({
          [name]: value,
          facebookError: "",
        });
      }
      return;
    }

    if (name === "twitter_link") {
      if (value.trim() !== "") {
        const twitterRegex = /^https:\/\/twitter\.com\/[a-zA-Z0-9_]{4,}\/?$/;
        const isValidtwitterLink = twitterRegex.test(value);
        this.setState({
          [name]: value,
          twitterError: isValidtwitterLink ? "" : "Invalid Twitter link",
        });
      } else {
        this.setState({
          [name]: value,
          twitterError: "",
        });
      }
      return;
    }

    if (name === "linkedin_link") {
      if (value.trim() !== "") {
        const linkedinRegex =
          /^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9_.-]{4,}\/?$/;
        const isValidlinkedinLink = linkedinRegex.test(value);
        this.setState({
          [name]: value,
          linkedinError: isValidlinkedinLink ? "" : "Invalid Linkedin link",
        });
      } else {
        this.setState({
          [name]: value,
          linkedinError: "",
        });
      }
      return;
    }

    if (name === "youtube_link") {
      if (value.trim() !== "") {
        const youtubeRegex =
          /^https:\/\/www\.youtube\.com\/channel\/[a-zA-Z0-9_-]{4,}\/?$/;
        const isValidyoutubeLink = youtubeRegex.test(value);
        this.setState({
          [name]: value,
          youtubeError: isValidyoutubeLink ? "" : "Invalid Youtube link",
        });
      } else {
        this.setState({
          [name]: value,
          youtubeError: "",
        });
      }
      return;
    }

    if (name === "edit_images_type") {
      const inputElement = document.getElementById("edit_picture");
      if (inputElement) {
        const selectedFile = inputElement.files[0];
        if (selectedFile) {
          inputElement.value = null;
          this.setState({
            docErrorMessage: "",
            edit_picture: "",
            edit_picture_name: "",
          });
        }
      }
    }
    this.setState({
      [name]: value,
    });
  };

  setTheme = (id) => {
    //console.log("Theme: ", id);
    this.setState({
      theme: id,
    });
  };

  setThemeClass = (id) => {
    //console.log("setThemeClass: ", this.state.theme + " " + id);
    if (this.state.theme === id) {
      return "read active";
    } else {
      return "read";
    }
  };

  setThemeClassMain = (id) => {
    if (this.state.theme === id) {
      return "pricingTable active2";
    } else {
      return "pricingTable";
    }
  };

  setService = (id) => {
    //console.log("Service: ", id);
    if (this.state.selected_service.includes(id)) {
      var checked = this.state.selected_service;
      var values = checked.indexOf(id);
      checked.splice(values, 1);
      this.setState({ selected_service: checked });
    } else {
      this.setState({
        selected_service: [...this.state.selected_service, id],
      });
    }
  };

  setServiceClass = (id) => {
    if (this.state.selected_service.includes(id)) {
      return "btn btn-primary active3";
    } else {
      return "btn btn-dim btn-white btn-outline-primary";
    }
  };

  setServiceClassMain = (id) => {
    if (this.state.selected_service.includes(id)) {
      return "card card-bordered sp-plan active2";
    } else {
      return "card card-bordered sp-plan";
    }
    /*if (this.state.selected_service.includes(id)) {
      return "pricingTable active2";
    } else {
      return "pricingTable";
    }*/
  };

  setBundleClassMainNew = (id) => {
    if (this.state.selected_bundle.includes(id)) {
      return "card card-bordered sp-plan active2";
    } else {
      return "card card-bordered sp-plan";
    }
  };

  setBundleClassNew = (id) => {
    if (this.state.selected_bundle.includes(id)) {
      return "btn btn-primary active3";
    } else {
      return "btn btn-dim btn-white btn-outline-primary";
    }
  };

  setBundles = (id) => {
    //console.log("Service: ", id);
    if (this.state.selected_bundle.includes(id)) {
      var checked = this.state.selected_bundle;
      var values = checked.indexOf(id);
      checked.splice(values, 1);
      this.setState({ selected_bundle: checked });
    } else {
      this.setState({
        selected_bundle: [...this.state.selected_bundle, id],
      });
    }
  };

  setLogo = (id) => {
    //console.log("Logo: ", id);
    this.setState({
      selected_logo: id,
    });
  };

  setLogoClass = (id) => {
    if (this.state.selected_logo === id) {
      return "read active";
    } else {
      return "read";
    }
  };

  setLogoClassMain = (id) => {
    if (this.state.selected_logo === id) {
      return "pricingTable active2";
    } else {
      return "pricingTable";
    }
  };

  setAvatar = (id) => {
    // console.log("Avatar: ", id);
    this.setState({
      selected_avatar: id,
    });
  };

  setAvatarClass = (id) => {
    if (this.state.selected_avatar === id) {
      return "read active";
    } else {
      return "read";
    }
  };

  setAvatarClassMain = (id) => {
    if (this.state.selected_avatar === id) {
      return "pricingTable active2";
    } else {
      return "pricingTable";
    }
  };

  setAvatar2 = (id) => {
    // console.log("Avatar: ", id);
    this.setState({
      selected_avatar2: id,
    });
  };

  setAvatar2Class = (id) => {
    if (this.state.selected_avatar2 === id) {
      return "read active";
    } else {
      return "read";
    }
  };

  setAvatar2ClassMain = (id) => {
    if (this.state.selected_avatar2 === id) {
      return "pricingTable active2";
    } else {
      return "pricingTable";
    }
  };

  setBanner = (id) => {
    // console.log("banner: ", id);
    this.setState({
      selected_banner: id,
    });
  };

  setBannerClass = (id) => {
    if (this.state.selected_banner === id) {
      return "read active";
    } else {
      return "read";
    }
  };

  setBannerClassMain = (id) => {
    if (this.state.selected_banner === id) {
      return "pricingTable active2";
    } else {
      return "pricingTable";
    }
  };

  getSharelinkTheme = async () => {
    const { theme, sharelink_id } = this.state;
    this.setState({
      tableLoaderBlockImage: true,
      getThemeErrorMessage: "",
    });
    const servicesResponce = await getSharelinkTheme(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      theme
    );
    //console.log("sharelink_id: ", sharelink_id);
    console.log("getSharelinkTheme: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        tableLoaderBlockImage: false,
        getThemeErrorMessage: "Theme not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        themesList: servicesResponce.data.data,
        theme: servicesResponce.data.theme,
        tableLoaderBlockImage: false,
        getThemeErrorMessage: "",
      });
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "sharelink_not_found"
    ) {
      this.setState({
        themesList: [],
        theme: "",
        tableLoaderBlockImage: false,
        getThemeErrorMessage:
          "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
      });
    } else {
      this.setState({
        tableLoaderBlockImage: false,
        getThemeErrorMessage: "There is some error while getting themes.",
      });
    }
  };

  getServiceTypes = async () => {
    const servicesResponce = await listServiceTypes(
      auth.getAccount(),
      auth.getToken()
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          let newArr = {
            label: servicesResponce.data.data[i]["product_title"],
            value: servicesResponce.data.data[i]["product_id"],
          };
          all_clients.push(newArr);
        }
      }
      this.setState({
        all_service_types: all_clients,
      });
    } else {
      this.setState({
        all_service_types: [],
      });
    }
  };

  handleChangeSearch = async (changed_value) => {
    this.setState({
      tableLoaderBlockService: true,
    });
    const delay = 1000;

    if (changed_value) {
      const selectedIds = changed_value.map((item) => item.value);

      setTimeout(() => {
        this.setState(
          {
            service_type_id: selectedIds,
            selected_service_types: changed_value,
            tableLoaderBlockService: false,
          },
          () => {
            let services = this.state.serviceData.filter((service) =>
              selectedIds.includes(service.product_id)
            );
            const filteredSelectedServices = this.state.selected_service.filter(
              (serviceId) =>
                services.some((service) => service.id === serviceId)
            );

            this.setState({
              selected_service: filteredSelectedServices,
            });
          }
        );
      }, delay);
    } else {
      setTimeout(() => {
        this.setState(
          {
            service_type_id: [],
            selected_service_types: [],
            selected_service: [],
            tableLoaderBlockService: false,
          },
          () => {
            let services = [];
          }
        );
      }, delay);
    }
  };

  getSharelinkServices = async () => {
    const { theme, sharelink_id } = this.state;
    this.setState({
      tableLoaderBlockService: true,
      getServiceErrorMessage: "",
    });
    const servicesResponce = await getSharelinkServices(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id
    );
    //console.log("getSharelinkServices: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        tableLoaderBlockService: false,
        getServiceErrorMessage: "Services not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      const serviceData = servicesResponce.data.data;
      const selectedService = servicesResponce.data.assigned_services;
      const serviceTypeMap = new Map();

      serviceData
        .filter((service) => selectedService.includes(service.id))
        .forEach((service) => {
          if (!serviceTypeMap.has(service.product_id)) {
            serviceTypeMap.set(service.product_id, {
              value: service.product_id,
              label: service.product_title,
            });
          }
        });
      const selectedServiceTypes = Array.from(serviceTypeMap.values());
      const serviceTypeIds = selectedServiceTypes.map((type) => type.value);

      this.setState({
        serviceData: servicesResponce.data.data,
        selected_service: servicesResponce.data.assigned_services,
        selected_service_types: selectedServiceTypes,
        service_type_id: serviceTypeIds,
        tableLoaderBlockService: false,
        getServiceErrorMessage: "",
      });

      await this.getServiceTypes();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "sharelink_not_found"
    ) {
      this.setState({
        serviceData: [],
        selected_service: "",
        tableLoaderBlockService: false,
        getServiceErrorMessage:
          "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
      });
    } else {
      this.setState({
        tableLoaderBlockService: false,
        getServiceErrorMessage: "There is some error while getting services.",
      });
    }
  };

  getSharelinkBundles = async () => {
    const { theme, sharelink_id } = this.state;
    this.setState({
      tableLoaderBlockBundle: true,
      getBundleErrorMessage: "",
    });
    const servicesResponce = await getSharelinkBundles(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id
    );
    console.log("getSharelinkBundles: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        tableLoaderBlockBundle: false,
        getBundleErrorMessage: "Bundles not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        bundlesData: servicesResponce.data.data,
        selected_bundle: servicesResponce.data.assigned_services,
        tableLoaderBlockBundle: false,
        getBundleErrorMessage: "",
      });
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "sharelink_not_found"
    ) {
      this.setState({
        tableLoaderBlockBundle: false,
        getBundleErrorMessage:
          "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
      });
    } else {
      this.setState({
        tableLoaderBlockBundle: false,
        getBundleErrorMessage: "There is some error while getting the bundles.",
      });
    }
  };
  getSharelinkLogos = async () => {
    const { theme, sharelink_id, avatarsPerPage } = this.state;
    this.setState({
      tableLoaderBlockLogo: true,
      getLogoErrorMessage: "",
    });
    const servicesResponce = await getSharelinkLogos(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id
    );
    console.log("getSharelinkLogos: ", servicesResponce.data);
    let logo_array = [];
    let avatar_array = [];
    let avatar2_array = [];
    let banner_array = [];
    if (servicesResponce.data.data) {
      logo_array = this.groupBy(servicesResponce.data.data, "logo");
      avatar_array = this.groupBy(servicesResponce.data.data, "image");
      avatar2_array = this.groupBy(servicesResponce.data.data, "image2");
      banner_array = this.groupBy(servicesResponce.data.data, "banner");
    }

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        tableLoaderBlockLogo: false,
        getLogoErrorMessage: "Logos not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      const selectedAvatarIndex = avatar_array.findIndex(
        (avatar) => avatar.id === servicesResponce.data.avatar
      );
      const selectedAvatar2Index = avatar2_array.findIndex(
        (avatar2) => avatar2.id === servicesResponce.data.avatar2
      );
      const selectedLogoIndex = logo_array.findIndex(
        (logo) => logo.id === servicesResponce.data.logo
      );
      const selectedBannerIndex = banner_array.findIndex(
        (banner) => banner.id === servicesResponce.data.banner
      );
      const selectedAvatarPage =
        Math.floor(selectedAvatarIndex / avatarsPerPage) + 1;
      const selectedAvatar2Page =
        Math.floor(selectedAvatar2Index / avatarsPerPage) + 1;
      const selectedLogoPage =
        Math.floor(selectedLogoIndex / avatarsPerPage) + 1;
      const selectedBannerPage =
        Math.floor(selectedBannerIndex / avatarsPerPage) + 1;
      this.setState({
        logoData: logo_array,
        avatarData: avatar_array,
        avatarData2: avatar2_array,
        selected_logo: servicesResponce.data.logo,
        selected_avatar: servicesResponce.data.avatar,
        selected_avatar2:
          servicesResponce.data.avatar2 && servicesResponce.data.avatar2 !== ""
            ? servicesResponce.data.avatar2
            : "",
        bannerData: banner_array,
        selected_banner: servicesResponce.data.banner,
        tableLoaderBlockLogo: false,
        getLogoErrorMessage: "",
        currentPageAvatar:
          selectedAvatarIndex && selectedAvatarIndex !== -1
            ? selectedAvatarPage
            : 1,
        currentPageAvatar2:
          selectedAvatar2Index && selectedAvatar2Index !== -1
            ? selectedAvatar2Page
            : 1,
        currentPageLogo:
          selectedLogoIndex && selectedLogoIndex !== -1 ? selectedLogoPage : 1,
        currentPageBanner:
          selectedBannerIndex && selectedBannerIndex !== -1
            ? selectedBannerPage
            : 1,
      });
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "sharelink_not_found"
    ) {
      this.setState({
        tableLoaderBlockLogo: false,
        getLogoErrorMessage:
          "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
      });
    } else {
      this.setState({
        tableLoaderBlockLogo: false,
        getLogoErrorMessage: "There is some error while getting the logos.",
      });
    }
  };

  handlePageChange = (type, direction) => {
    const {
      avatarsPerPage,
      currentPageAvatar,
      currentPageAvatar2,
      currentPageLogo,
      currentPageBanner,
      avatarData,
      avatarData2,
      logoData,
      bannerData,
    } = this.state;

    let currentPage, data;

    switch (type) {
      case "avatar":
        currentPage = currentPageAvatar;
        data = avatarData;
        break;
      case "avatar2":
        currentPage = currentPageAvatar2;
        data = avatarData2;
        break;
      case "logo":
        currentPage = currentPageLogo;
        data = logoData;
        break;
      case "banner":
        currentPage = currentPageBanner;
        data = bannerData;
        break;
      default:
        return;
    }

    const totalPages = Math.ceil(data.length / avatarsPerPage);

    if (direction === "next") {
      if (currentPage < totalPages) {
        currentPage += 1;
      }
    } else if (direction === "prev") {
      if (currentPage > 1) {
        currentPage -= 1;
      }
    }

    this.setState({
      [`currentPage${type.charAt(0).toUpperCase() + type.slice(1)}`]:
        currentPage,
    });
  };
  /*getSharelinkLogos = async () => {
    const { theme, sharelink_id } = this.state;
    this.setState({
      tableLoaderBlockLogo: true,
      getLogoErrorMessage: "",
    });
    const servicesResponce = await getSharelinkLogos(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id
    );
    console.log("getSharelinkLogos: ", servicesResponce.data);
    let logo_array = [];
    let avatar_array = [];
    let banner_array = [];
    let avatar2_array = [];
    if (servicesResponce.data.data) {
      logo_array = this.groupBy(servicesResponce.data.data, "logo");
      avatar_array = this.groupBy(servicesResponce.data.data, "image");
      banner_array = this.groupBy(servicesResponce.data.data, "banner");
      avatar2_array = this.groupBy(servicesResponce.data.data, "image2");
    }

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        tableLoaderBlockLogo: false,
        getLogoErrorMessage: "Logos not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        logoData: logo_array,
        avatarData: avatar_array,
        selected_logo: servicesResponce.data.logo,
        selected_avatar: servicesResponce.data.avatar,
        avatarData2: avatar2_array,
        selected_avatar2: servicesResponce.data.avatar2,
        bannerData: banner_array,
        selected_banner: servicesResponce.data.banner,
        tableLoaderBlockLogo: false,
        getLogoErrorMessage: "",
      });
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "sharelink_not_found"
    ) {
      this.setState({
        tableLoaderBlockLogo: false,
        getLogoErrorMessage:
          "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
      });
    } else {
      this.setState({
        tableLoaderBlockLogo: false,
        getLogoErrorMessage: "There is some error while getting the logos.",
      });
    }
  };*/

  getSharelinkSettings = async (type) => {
    const { theme, sharelink_id } = this.state;
    this.setState({
      getAboutErrorMessage: "",
      getSeoErrorMessage: "",
      getContactErrorMessage: "",
      getSocialErrorMessage: "",
      about_me_short: "",
      about_me: "",
      instagram_link: "",
      facebook_link: "",
      twitter_link: "",
      linkedin_link: "",
      youtube_link: "",
      contact_address: "",
      contact_email: "",
      contact_phone: "",
      meta_description: "",
      meta_title: "",
    });
    //if (this.state.about_me === "") {
    if (type === "about") {
      this.setState({
        tableLoaderBlockAbout: true,
        getAboutErrorMessage: "",
      });
    }
    if (type === "social") {
      this.setState({
        tableLoaderBlockSocial: true,
        getSocialErrorMessage: "",
      });
    }
    if (type === "contact") {
      this.setState({
        tableLoaderBlockContact: true,
        getContactErrorMessage: "",
      });
    }
    if (type === "seo") {
      this.setState({
        tableLoaderBlockSEO: true,
        getSeoErrorMessage: "",
      });
    }

    if (type === "other_settings") {
      this.setState({
        tableLoaderBlockSEO: true,
        getSeoErrorMessage: "",
      });
    }

    if (type === "testimonials") {
      this.setState({
        tableLoaderBlockSEO: true,
        getSeoErrorMessage: "",
      });
    }

    const servicesResponce = await getSharelinkSettings(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id
    );
    console.log("getSharelinkSettings sharelink_id: ", sharelink_id);
    console.log("getSharelinkSettings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      if (type === "about") {
        this.setState({
          getSocialErrorMessage: "",
          getContactErrorMessage: "",
          getSeoErrorMessage: "",
          getAboutErrorMessage: "About me content not found.",
          isEditorReady: true,
        });
      }
      if (type === "social") {
        this.setState({
          getSocialErrorMessage: "Social media links not found.",
          getAboutErrorMessage: "",
          getContactErrorMessage: "",
          getSeoErrorMessage: "",
        });
      }
      if (type === "contact") {
        this.setState({
          getContactErrorMessage: "Contact details not found.",
          getAboutErrorMessage: "",
          getSocialErrorMessage: "",
          getSeoErrorMessage: "",
        });
      }
      if (type === "seo") {
        this.setState({
          getSeoErrorMessage: "SEO details not found.",
          getAboutErrorMessage: "",
          getSocialErrorMessage: "",
          getContactErrorMessage: "",
        });
      }
      if (type === "testimonials") {
        this.setState({
          getSeoErrorMessage: "Testimonial details not found.",
          getAboutErrorMessage: "",
          getSocialErrorMessage: "",
          getContactErrorMessage: "",
        });
      }
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let default_other_settings = JSON.parse(
        servicesResponce.data.default_theme_data[0]["data_json"]
      );
      let other_settings;
      try {
        const responseOtherSettings =
          servicesResponce.data.sharelink_settings[0]["other_settings"];
        other_settings = JSON.parse(
          responseOtherSettings || default_other_settings
        );
      } catch (error) {
        // If parsing fails, use default settings
        console.warn(
          "Failed to parse other_settings, using default settings:",
          error
        );
        other_settings = default_other_settings;
      }
      const selectedTestimonials =
        servicesResponce.data.sharelink_settings[0]["selected_testimonials"];
      const testimonialsArray =
        selectedTestimonials && selectedTestimonials.trim() !== ""
          ? selectedTestimonials.split(",").map(Number)
          : [];
      this.setState(
        {
          about_me_short:
            servicesResponce.data.sharelink_settings[0]["about_me_short"],
          about_me: servicesResponce.data.sharelink_settings[0]["about_me"],
          other_settings: other_settings,
          testimonials: servicesResponce.data.testimonials,
          selectedTestimonials: testimonialsArray,
          instagram_link:
            servicesResponce.data.sharelink_settings[0]["instagram_link"],
          facebook_link:
            servicesResponce.data.sharelink_settings[0]["facebook_link"],
          twitter_link:
            servicesResponce.data.sharelink_settings[0]["twitter_link"],
          linkedin_link:
            servicesResponce.data.sharelink_settings[0]["linkedin_link"],
          youtube_link:
            servicesResponce.data.sharelink_settings[0]["youtube_link"],
          settingData: servicesResponce.data.data,
          contact_address:
            servicesResponce.data.sharelink_settings[0]["contact_address"],
          contact_email:
            servicesResponce.data.sharelink_settings[0]["contact_email"],
          contact_phone:
            servicesResponce.data.sharelink_settings[0]["contact_phone"],
          meta_description: servicesResponce.data.meta_description,
          meta_title: servicesResponce.data.meta_title,
          isEditorReady: false,
        },
        () => {
          setTimeout(() => {
            this.setState({ isEditorReady: true });
          }, 500);
        }
      );
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "sharelink_not_found"
    ) {
      if (type === "about") {
        this.setState({
          getAboutErrorMessage:
            "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
          isEditorReady: true,
        });
      }
      if (type === "social") {
        this.setState({
          getSocialErrorMessage:
            "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
        });
      }
      if (type === "contact") {
        this.setState({
          getContactErrorMessage:
            "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
        });
      }
      if (type === "seo") {
        this.setState({
          getSeoErrorMessage:
            "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
        });
      }
    } else {
      if (type === "about") {
        this.setState({
          getAboutErrorMessage:
            "There is some error while getting about me content.",
          isEditorReady: true,
        });
      }
      if (type === "social") {
        this.setState({
          getSocialErrorMessage:
            "There is some error while getting social media links.",
        });
      }
      if (type === "contact") {
        this.setState({
          getContactErrorMessage:
            "There is some error while getting contact details.",
        });
      }
      if (type === "seo") {
        this.setState({
          getSeoErrorMessage: "There is some error while getting SEO data.",
        });
      }
      if (type === "testimonials") {
        this.setState({
          getSeoErrorMessage:
            "There is some error while getting testimonials data.",
        });
      }
    }
    if (type === "about") {
      this.setState({
        tableLoaderBlockAbout: false,
      });
    }
    if (type === "social") {
      this.setState({
        tableLoaderBlockSocial: false,
      });
    }
    if (type === "contact") {
      this.setState({
        tableLoaderBlockContact: false,
      });
    }
    if (type === "testimonials") {
      this.setState({
        tableLoaderBlockSEO: false,
      });
    }
    if (type === "seo") {
      this.setState({
        tableLoaderBlockSEO: false,
      });
    }
    /* } else {
      this.setState({
        tableLoaderBlockContact: false,
        tableLoaderBlockAbout: false,
        tableLoaderBlockSocial: false,
        tableLoaderBlockSEO: false,
      });
    }*/
  };

  addTestimonial = () => {
    let randomNumber = Math.floor(Math.random() * 1000000);
    this.setState({
      isAddingTestimonial: true,
      isEditing: false,
      editingIndex: null,
      testimonial_name: "",
      testimonial_title: "",
      testimonial_comment: "",
      testimonial_img: "",
      selectedImageName: "",
      testimonialErrors: {},
      testimonial_id: randomNumber,
    });
  };

  editTestimonial = (index) => {
    const testimonial = this.state.testimonials[index];
    this.setState({
      isAddingTestimonial: true,
      isEditing: true,
      editingIndex: index,
      testimonial_name: testimonial.name,
      testimonial_title: testimonial.title,
      testimonial_comment: testimonial.comment,
      testimonial_img: testimonial.image_name,
      testimonial_id: testimonial.id,
      testimonialErrors: {},
    });
  };

  saveTestimonial = async () => {
    const {
      isEditing,
      editingIndex,
      testimonial_name,
      testimonial_title,
      testimonial_comment,
      testimonials,
      testimonial_img,
      testimonial_id,
    } = this.state;
    let errors = {};
    if (!testimonial_name) errors.nameError = "Name is required";
    if (!testimonial_title) errors.titleError = "Title is required";
    if (!testimonial_comment) errors.commentError = "Comment is required";

    if (Object.keys(errors).length > 0) {
      this.setState({ testimonialErrors: errors });
      return;
    }
    if (isEditing) {
      const currentTestimonial = testimonials[editingIndex];
      const editedTestimonial = {
        id: currentTestimonial.id,
        name: testimonial_name,
        title: testimonial_title,
        comment: testimonial_comment,
        image_name: testimonial_img,
      };
      await this.updateTestimonial(
        currentTestimonial.id,
        testimonial_name,
        testimonial_title,
        testimonial_comment,
        testimonial_img
      );
      const updatedTestimonials = testimonials.map((item, index) =>
        index === editingIndex ? editedTestimonial : item
      );
      this.setState({
        testimonials: updatedTestimonials,
        isAddingTestimonial: false,
        isEditing: false,
        editingIndex: null,
        testimonial_name: "",
        testimonial_title: "",
        testimonial_comment: "",
        testimonial_img: "",
        selectedImageName: "",
        testimonialErrors: {},
      });
    } else {
      const newTestimonial = {
        name: testimonial_name,
        title: testimonial_title,
        comment: testimonial_comment,
        image_name: testimonial_img,
        id: testimonial_id,
      };
      await this.saveNewTestimonial(
        testimonial_id,
        testimonial_name,
        testimonial_title,
        testimonial_comment,
        testimonial_img
      );
      this.setState((prevState) => ({
        testimonials: [...prevState.testimonials, newTestimonial],
        isAddingTestimonial: false,
        testimonial_name: "",
        testimonial_title: "",
        testimonial_comment: "",
        testimonial_img: "",
        selectedImageName: "",
        testimonialErrors: {},
      }));
    }
  };

  saveNewTestimonial = async (
    testimonial_id,
    testimonial_name,
    testimonial_title,
    testimonial_comment,
    testimonial_img
  ) => {
    try {
      const saveResponse = await saveNewTestimonial(
        auth.getAccount(),
        auth.getToken(),
        testimonial_name,
        testimonial_title,
        testimonial_comment,
        testimonial_img,
        testimonial_id
      );
      if (
        saveResponse.data.status === 403 ||
        saveResponse.data.errors === "authentication missing" ||
        saveResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponse.data.status === 200 &&
        saveResponse.data.message === "success"
      ) {
        this.setState({
          uErrorMessage: "",
          uSuccessMessage: "Testimonial added successfully.",
          disabled: false,
        });
      } else {
        this.setState({
          uErrorMessage: "There is some error while adding the testimonial.",
          uSuccessMessage: "",
          disabled: false,
        });
      }
      setTimeout(() => {
        this.setState({ uSuccessMessage: "", uErrorMessage: "" });
      }, 3000);
    } catch (error) {
      console.error("Error while saving testimonial:", error);
      this.setState({
        uErrorMessage: "An unexpected error occurred.",
        uSuccessMessage: "",
        disabled: false,
      });
    }
  };

  updateTestimonial = async (
    testimonial_id,
    testimonial_name,
    testimonial_title,
    testimonial_comment,
    testimonial_img
  ) => {
    try {
      const saveResponse = await updateTestimonial(
        auth.getAccount(),
        auth.getToken(),
        testimonial_name,
        testimonial_title,
        testimonial_comment,
        testimonial_img,
        testimonial_id
      );
      if (
        saveResponse.data.status === 403 ||
        saveResponse.data.errors === "authentication missing" ||
        saveResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponse.data.status === 200 &&
        saveResponse.data.message === "success"
      ) {
        this.setState({
          uErrorMessage: "",
          uSuccessMessage: "Testimonials updated successfully.",
          disabled: false,
        });
      } else {
        this.setState({
          uErrorMessage: "There is some error while updating the testimonials.",
          uSuccessMessage: "",
          disabled: false,
        });
      }

      setTimeout(() => {
        this.setState({ uSuccessMessage: "", uErrorMessage: "" });
      }, 3000);
    } catch (error) {
      console.error("Error while saving testimonial:", error);
      this.setState({
        uErrorMessage: "An unexpected error occurred.",
        uSuccessMessage: "",
        disabled: false,
      });
    }
  };

  deleteTestimonial = async (index) => {
    const currentTestimonial = this.state.testimonials[index];
    try {
      const saveResponse = await deleteTestimonial(
        auth.getAccount(),
        auth.getToken(),
        currentTestimonial.id
      );
      if (
        saveResponse.data.status === 403 ||
        saveResponse.data.errors === "authentication missing" ||
        saveResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponse.data.status === 200 &&
        saveResponse.data.message === "success"
      ) {
        const updatedTestimonials = this.state.testimonials.filter(
          (_, i) => i !== index
        );
        this.setState({
          testimonials: updatedTestimonials,
          uErrorMessage: "",
          uSuccessMessage: "Testimonial Deleted Successfully.",
          disabled: false,
        });
      } else {
        this.setState({
          uErrorMessage: "There is some error while deleting the testimonial.",
          uSuccessMessage: "",
          disabled: false,
        });
      }
      setTimeout(() => {
        this.setState({ uSuccessMessage: "", uErrorMessage: "" });
      }, 3000);
    } catch (error) {
      console.error("Error while saving testimonial:", error);
      this.setState({
        uErrorMessage: "An unexpected error occurred.",
        uSuccessMessage: "",
        disabled: false,
      });
    }
  };

  handleTestimonialImageChange = (event) => {
    const file = event.target.files[0];
    this.setState({
      save_testimonial_btn_disable: true,
    });
    const formData = new FormData();
    formData.append("token", process.env.REACT_APP_API_TOKEN);
    formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
    formData.append("file", file);

    axios
      .post(
        process.env.REACT_APP_API_URL + "uploads/upload_testimonial_image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Credentials": true,
          },
        }
      )
      .then((res) => {
        if (
          res.data.status === 403 ||
          res.data.errors === "authentication missing" ||
          res.data.errors === "jwt expired"
        ) {
          auth.logout();
        } else if (res.data.status === 404 && res.data.error === "error") {
          this.setState({
            docSuccessMessage: "",
            docErrorMessage: res.data.message,
            save_testimonial_btn_disable: false,
          });
        } else if (res.data.status === 200 && res.data.message === "success") {
          this.setState({
            testimonial_img: res.data.img_url,
            selectedImageName: file.name,
            save_testimonial_btn_disable: false,
          });
        }
      })
      .catch((error) => {
        console.error("Error uploading image: ", error);
        this.setState({
          docErrorMessage: "Error uploading image. Please try again later.",
        });
      });
  };

  saveSharelinkDetail = async () => {
    const { title, status, sharelink_id } = this.state;

    const saveResponce = await saveSharelinkDetail(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      title,
      status
    );
    //console.log("saveSharelinkDetail: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not found.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "Share link detail updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage:
          "There is some error while updating the sharelink detail.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  saveSharelinkTheme = async () => {
    const { theme, sharelink_id } = this.state;
    const saveResponce = await saveSharelinkTheme(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      theme
    );
    //console.log("saveSharelinkTheme: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not found.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "Theme updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage: "There is some error while updating the theme.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  saveSharelinkServices = async () => {
    const { sharelink_id } = this.state;
    //console.log("this.state.selected_service: ", this.state.selected_service);
    const modalBody = document.getElementById("modalDetails");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }
    const saveResponce = await saveSharelinkServices(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      this.state.selected_service
    );
    //console.log("saveSharelinkServices: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not exists.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "Services updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage: "There is some error while updating the services.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  saveSharelinkBundles = async () => {
    const { sharelink_id } = this.state;
    //console.log("this.state.selected_service: ", this.state.selected_service);
    const saveResponce = await saveSharelinkBundles(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      this.state.selected_bundle
    );
    console.log("saveSharelinkBundles: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not exists.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "Bundles updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage: "There is some error while updating the bundles.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  saveSharelinkLogos = async () => {
    const {
      sharelink_id,
      selected_logo,
      selected_avatar,
      selected_banner,
      selected_avatar2,
    } = this.state;

    const modalBody = document.getElementById("modalDetails");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }

    const saveResponce = await saveSharelinkLogos(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      selected_logo,
      selected_avatar,
      selected_banner,
      selected_avatar2
    );

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not found.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "Images updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage: "There is some error while updating the images.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  saveSharelinkAbout = async () => {
    const { sharelink_id, about_me_short, about_me } = this.state;
    this.setState({
      uErrorMessage: "",
      uSuccessMessage: "",
      disabled: false,
    });
    const modalBody = document.getElementById("modalDetails");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }
    const saveResponce = await saveSharelinkAbout(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      about_me_short,
      about_me
    );
    //console.log("saveSharelinkAbout: ", saveResponce.data);

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not found.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "About me detail updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage:
          "There is some error while updating the about me detail.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  saveSharelinkContact = async () => {
    const {
      sharelink_id,
      contact_address,
      contact_email,
      contact_phone,
      emailError,
      phoneError,
      addressError,
    } = this.state;
    if (emailError === "" && phoneError === "" && addressError === "") {
      const saveResponce = await saveSharelinkContact(
        auth.getAccount(),
        auth.getToken(),
        sharelink_id,
        contact_address,
        contact_email,
        contact_phone
      );

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "not_found"
      ) {
        // window.location.replace("/error");
        this.setState({
          uErrorMessage: "Sharelink does not found.",
          uSuccessMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          uErrorMessage: "",
          uSuccessMessage: "Contact detail updated successfully.",
          disabled: false,
        });
      } else {
        this.setState({
          uErrorMessage:
            "There is some error while updating the contact detail.",
          uSuccessMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
      setTimeout(() => {
        this.setState({ uSuccessMessage: "", uErrorMessage: "" });
      }, 5000);
    } else {
      this.setState({
        uErrorMessage: "Please fill the fields correctly.",
        uSuccessMessage: "",
        disabled: false,
      });
      setTimeout(() => {
        this.setState({ uSuccessMessage: "", uErrorMessage: "" });
      }, 3000);
    }
  };

  saveSharelinkSocial = async () => {
    const {
      sharelink_id,
      instagram_link,
      facebook_link,
      twitter_link,
      linkedin_link,
      youtube_link,
      instagramError,
      facebookError,
      twitterError,
      linkedinError,
      youtubeError,
    } = this.state;
    if (
      instagramError === "" &&
      facebookError === "" &&
      twitterError === "" &&
      linkedinError === "" &&
      youtubeError === ""
    ) {
      const saveResponce = await saveSharelinkSocial(
        auth.getAccount(),
        auth.getToken(),
        sharelink_id,
        instagram_link,
        facebook_link,
        twitter_link,
        linkedin_link,
        youtube_link
      );

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "not_found"
      ) {
        // window.location.replace("/error");
        this.setState({
          uErrorMessage: "Sharelink does not found.",
          uSuccessMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          uErrorMessage: "",
          uSuccessMessage: "Social media links updated successfully.",
          disabled: false,
        });
      } else {
        this.setState({
          uErrorMessage:
            "There is some error while updating the social media links.",
          uSuccessMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
      setTimeout(() => {
        this.setState({ uSuccessMessage: "", uErrorMessage: "" });
      }, 5000);
    } else {
      this.setState({
        uErrorMessage: "Please fill the fields correctly.",
        uSuccessMessage: "",
        disabled: false,
      });
      setTimeout(() => {
        this.setState({ uSuccessMessage: "", uErrorMessage: "" });
      }, 3000);
    }
  };

  saveSharelinkSEO = async () => {
    const { sharelink_id, meta_description, meta_title } = this.state;

    const saveResponce = await saveSharelinkSEO(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      meta_description,
      meta_title
    );

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not found.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "SEO detail updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage: "There is some error while updating the SEO detail.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  show_regenerate = () => {
    this.setState({
      show_regenerate: "1",
      send_email: "",
    });
  };

  regenerate_link = async () => {
    const { sharelink2_id, custom_link } = this.state;

    this.setState({
      reg_button: true,
      linkErrorMessage: "",
      linkSuccessMessage: "",
    });

    if (custom_link === "") {
      this.setState({
        linkErrorMessage: "Please enter the custom link ID.",
        linkSuccessMessage: "",
        reg_button: false,
      });
    } else {
      const servicesResponce = await regenerateSharelink(
        auth.getAccount(),
        auth.getToken(),
        sharelink2_id,
        custom_link
      );
      //console.log("Regenerate.data: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        servicesResponce.data.status === 404 &&
        servicesResponce.data.message === "errors"
      ) {
        this.setState({
          linkErrorMessage: servicesResponce.data.data,
          linkSuccessMessage: "",
          reg_button: false,
        });
        //window.location.replace("/error");
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          signup_link: servicesResponce.data.data,
          linkErrorMessage: "",
          linkSuccessMessage: "Sharelink generated successfully.",
          reg_button: false,
          custom_link: "",
        });
      } else {
        this.setState({
          linkErrorMessage: "There is some error while updating the sharelink.",
          linkSuccessMessage: "",
          reg_button: false,
        });
        // window.location.replace("/error");
      }
    }
    setTimeout(() => {
      this.setState({ linkSuccessMessage: "", linkErrorMessage: "" });
    }, 5000);
  };

  connectDomain = async (value, tableMeta) => {
    //modalConfirm

    this.setState({
      confirm_status: "Are you sure you want to connect this domain. ",
      sharelink_id: tableMeta.rowData[10],
    });
    const sharelinkResponce = await getDomains(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("getDomains.data: ", sharelinkResponce.data);

    if (
      sharelinkResponce.data.status === 403 ||
      sharelinkResponce.data.errors === "authentication missing" ||
      sharelinkResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      sharelinkResponce.data.status === 404 &&
      sharelinkResponce.data.message === "no_record"
    ) {
      this.setState({
        errorMessage: "Domains are not available.",
        successMessage: "",
      });
    } else if (
      sharelinkResponce.data.status === 200 &&
      sharelinkResponce.data.message === "success"
    ) {
      this.setState({
        available_domains: sharelinkResponce.data.data,
        errorMessage: "",
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting available domains.",
        successMessage: "",
      });
    }

    window.$("#modalConfirm").modal("show");
  };

  connectDomainSubmit = async () => {
    let { domain_id, sharelink_id } = this.state;
    if (domain_id === "") {
      this.setState({
        errorMessage: "Please select a domain to connect.",
        successMessage: "",
      });
    } else {
      const disableResponce = await connectSharelinkSubmit(
        auth.getAccount(),
        auth.getToken(),
        domain_id,
        sharelink_id
      );
      console.log("connectSharelinkSubmit.data: ", disableResponce.data);
      this.setState({
        btn_loader: true,
      });
      if (
        disableResponce.data.status === 403 ||
        disableResponce.data.errors === "authentication missing" ||
        disableResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        disableResponce.data.status === 404 &&
        disableResponce.data.message === "config_error"
      ) {
        this.setState({
          errorMessage: "There is some error while connecting the domain.",
          successMessage: "",
          btn_loader: false,
        });
      } else if (
        disableResponce.data.status === 404 &&
        disableResponce.data.message !== "config_error"
      ) {
        this.setState({
          errorMessage:
            "There is some error while adding the domain configuration.",
          successMessage: "",
          btn_loader: false,
        });
      } else if (
        disableResponce.data.status === 404 &&
        disableResponce.data.message !== "verify_error"
      ) {
        this.setState({
          errorMessage: "DNS records of given domain are not verified.",
          successMessage: "",
        });
      } else if (
        disableResponce.data.status === 404 &&
        disableResponce.data.message !== "remove_config_error"
      ) {
        this.setState({
          errorMessage:
            "There is some error while removing the domain configuration.",
          successMessage: "",
          btn_loader: false,
        });
      } else if (
        disableResponce.data.status === 200 &&
        disableResponce.data.message === "success"
      ) {
        this.setState({
          successMessage:
            "Your request has been received. It will be processed in 5 to 10 minutes.",
          errorMessage: "",
          btn_loader: false,
        });
      } else {
        this.setState({
          errorMessage: "There is some error while connecting the domain.",
          successMessage: "",
          btn_loader: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        successMessage: "",
        errorMessage: "",
        btn_loader: false,
      });
    }, 4000);
  };

  getImageUpload = () => {
    this.setState({
      docErrorMessage: "",
      docSuccessMessage: "",
      edit_picture: "",
      edit_image_title: "",
      showImageLoader: true,
    });

    setTimeout(() => {
      this.setState({
        formLoader: false,
      });
    }, 2000);
  };

  handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation
    const allowedFileTypes = [".png", ".jpg", ".jpeg"];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    console.log("Selected file extension:", fileExtension);

    if (!allowedFileTypes.includes("." + fileExtension)) {
      console.log("Invalid file type:", fileExtension);
      this.setState({
        docErrorMessage:
          "Invalid file type. Please select a valid file format (e.g., .png, .jpg, .jpeg).",
      });
      return;
    }

    // File size validation (e.g., limit to 5MB)
    const maxSizeBytes = 5 * 1024 * 1024; // 5MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        docErrorMessage:
          "File size exceeds the limit (5MB). Please choose a smaller file.",
      });
      return;
    }

    const resizedImage = await this.resizeImage(selectedFile);

    // Clear any previous errors if file passes validations
    this.setState({
      edit_picture: URL.createObjectURL(resizedImage),
      docErrorMessage: "",
      edit_picture_name: selectedFile.name,
    });
    if (this.state.edit_images_type === "logo") {
      setTimeout(() => {
        if (this.cropperRef.current && this.cropperRef.current.cropper) {
          this.cropperRef.current.cropper.zoom(-2.3);
        }
      }, 100);
    }
  };

  resizeImage = async (file) => {
    const { edit_images_type } = this.state;
    const maxDimensions = {
      logo: { width: 325, height: 130 },
      image: { width: 1400, height: 980 },
      image2: { width: 549, height: 611 },
      banner: { width: 1640, height: 560 },
    };

    const maxW = maxDimensions[edit_images_type].width;
    const maxH = maxDimensions[edit_images_type].height;

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          let { width, height } = img;

          // Check if the image type is not "logo"
          if (edit_images_type !== "logo") {
            let resizePercent = 0;

            // Calculate the aspect ratio based on the original image dimensions
            const aspectRatio = width / height;

            // Determine the target aspect ratio based on the image type
            const targetAspectRatio =
              edit_images_type === "image"
                ? 1.43
                : edit_images_type === "image2"
                ? .89
                : edit_images_type === "banner"
                ? 2.93
                : 1; // Default aspect ratio if none matched

            // Adjust the dimensions to maintain the aspect ratio
            if (aspectRatio > targetAspectRatio) {
              width = maxW;
              height = Math.round(width / targetAspectRatio);
            } else {
              height = maxH;
              width = Math.round(height * targetAspectRatio);
            }

            // Draw the image onto the canvas at the adjusted dimensions
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            console.log(
              `Resized ${edit_images_type} image to ${width}x${height}`
            );
          } else {
            // Keep the original dimensions for the logo
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0);
            console.log(
              `Kept ${edit_images_type} image size: ${width}x${height}`
            );
          }

          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, { type: file.type }));
          }, file.type);
        };
      };
    });
  };

  saveNewImage = async () => {
    const {
      edit_picture,
      edit_picture_name,
      edit_image_title,
      edit_images_type,
    } = this.state;

    const modalBody = document.getElementById("modalDetails");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }

    if (edit_image_title === "") {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "Title is required.",
      });
    } else if (!edit_picture) {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "Please choose an image to upload.",
      });
    } else {
      const croppedBlob = await this.getCroppedBlob();
      const imageElement = this.cropperRef.current;
      const cropper = imageElement.cropper;
      const { width, height } = cropper.getCropBoxData();

      // Define maximum dimensions based on image type
      let maxWidth, maxHeight;

      if (edit_images_type === "logo") {
        maxWidth = 325;
        maxHeight = 130;
      } else if (edit_images_type === "image") {
        maxWidth = 1550;
        maxHeight = 840;
      } else if (edit_images_type === "image2") {
        maxWidth = 549;
        maxHeight = 611;
      } else if (edit_images_type === "banner") {
        maxWidth = 1640;
        maxHeight = 560;
      }

      // Check if dimensions exceed the allowed values
      if (width > maxWidth || height > maxHeight) {
        this.setState({
          docSuccessMessage: "",
          docErrorMessage: `Image dimensions exceed the allowed size for ${edit_images_type}. Maximum allowed size is W: ${maxWidth} x H: ${maxHeight}.`,
        });
        return;
      }

      console.log(
        "edit_picture_name: " +
          edit_picture_name +
          " edit_image_title: " +
          edit_image_title +
          " edit_images_type: " +
          edit_images_type
      );

      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);

      // Append the cropped blob instead of the original image
      formData.append("file", croppedBlob);

      formData.append("image_title", edit_image_title);
      formData.append("image_type", edit_images_type);
      formData.append("status", 1);
      formData.append("accountno", auth.getAccount());
      formData.append("fileName", edit_picture_name);

      axios
        .post(
          process.env.REACT_APP_API_URL + "uploads/upload_user_image",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
            },
          }
        )
        .then((res) => {
          console.log("File Upload RES ", res.data);
          if (
            res.data.status === 403 ||
            res.data.errors === "authentication missing" ||
            res.data.errors === "jwt expired"
          ) {
            auth.logout();
          } else if (res.data.status === 404 && res.data.error === "error") {
            this.setState({
              docSuccessMessage: "",
              docErrorMessage: res.data.message,
            });
          } else if (
            res.data.status === 200 &&
            res.data.message === "success"
          ) {
            this.setState({
              docSuccessMessage: "Image added successfully.",
              docErrorMessage: "",
              edit_picture_name: "",
              edit_picture: "",
              edit_image_title: "",
            });
            setTimeout(() => {
              this.setState({
                showImageLoader: false,
              });
              this.getSharelinkLogos();
            }, 3000);
          } else {
            this.setState({
              docSuccessMessage: "",
              docErrorMessage: "There is some error while adding the image.",
            });
          }
        })
        .catch((error) => {
          console.error("Error uploading image: ", error);
          this.setState({
            docErrorMessage: "Error uploading image. Please try again later.",
          });
        });
    }
    setTimeout(() => {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "",
      });
    }, 4000);
  };

  handleCloseModal = () => {
    window.$("#modalDetails").modal("hide");
    //window.location.reload();
  };

  onCrop = () => {
    const imageElement = this.cropperRef.current;
    const cropper = imageElement.cropper;
    const { width, height } = cropper.getCropBoxData();
    const { edit_images_type } = this.state;

    // Define dimensions based on image type
    let minWidth, minHeight;
    let aspectRatio;

    if (edit_images_type === "logo") {
      minWidth = 325;
      minHeight = 130;
      aspectRatio = 325 / 130;
    } else if (edit_images_type === "image") {
      minWidth = 1400;
      minHeight = 980;
      aspectRatio = 1400 / 900;
    } else if (edit_images_type === "image2") {
      minWidth = 549;
      minHeight = 611;
      aspectRatio = 549 / 611;
    } else if (edit_images_type === "banner") {
      minWidth = 1640;
      minHeight = 560;
      aspectRatio = 1640 / 540;
    }

    console.log(`Aspect ratio for ${edit_images_type}: ${aspectRatio}`);

    // Apply dimension restrictions
    if (width < minWidth || height < minHeight) {
      // Adjust cropping dimensions if they don't meet the minimum requirements
      cropper.setCropBoxData({
        width: Math.max(width, minWidth),
        height: Math.max(height, minHeight),
      });

      // Update dimensions directly in the DOM
      const cropSizeElement = document.getElementById("cropSize");
      if (cropSizeElement) {
        cropSizeElement.textContent = `Current Crop Size: W: ${Math.round(
          Math.max(width, minWidth)
        )} x H: ${Math.round(Math.max(height, minHeight))}`;
      }
    } else {
      // Update dimensions directly in the DOM
      const cropSizeElement = document.getElementById("cropSize");
      if (cropSizeElement) {
        cropSizeElement.textContent = `Current Crop Size: W: ${Math.round(
          width
        )} x H: ${Math.round(height)}`;
      }
    }
  };

  getCroppedBlob = () => {
    return new Promise((resolve, reject) => {
      const imageElement = this.cropperRef.current;

      if (imageElement && imageElement.cropper) {
        const cropper = imageElement.cropper;
        const croppedCanvas = cropper.getCroppedCanvas();

        if (croppedCanvas) {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          canvas.width = croppedCanvas.width;
          canvas.height = croppedCanvas.height;

          ctx.filter = `brightness(${this.state.brightness}%)`;
          ctx.drawImage(croppedCanvas, 0, 0, canvas.width, canvas.height);

          canvas.toBlob((blob) => {
            resolve(blob);
          }, "image/png");
        } else {
          reject(new Error("Failed to get cropped canvas."));
        }
      }
    });
  };

  rotateImage = (degree) => {
    const cropper = this.cropperRef.current.cropper;
    cropper.rotate(degree);
  };

  handleZoomIn = () => {
    const cropper = this.cropperRef.current.cropper;
    cropper.zoom(0.1); // Adjust zoom factor as needed
  };

  handleZoomOut = () => {
    const cropper = this.cropperRef.current.cropper;
    cropper.zoom(-0.1); // Adjust zoom factor as needed
  };

  flipBoth = () => {
    const cropper = this.cropperRef.current.cropper;
    cropper.scale(-1);
  };

  flipHorizontal = () => {
    const cropper = this.cropperRef.current.cropper;
    cropper.scale(-1, 1);
  };

  flipVertical = () => {
    const cropper = this.cropperRef.current.cropper;
    cropper.scale(1, -1);
  };

  handleResetCrop = () => {
    const imageElement = this.cropperRef.current;
    const cropper = imageElement.cropper;

    // Reset the crop box to its initial state
    cropper.reset();
    this.handleReady();
    this.setState({ brightness: 100 });

    // Apply zoom out if images_type is "logo"
    if (this.state.edit_images_type === "logo") {
      setTimeout(() => {
        if (this.cropperRef.current && this.cropperRef.current.cropper) {
          this.cropperRef.current.cropper.zoom(-2.3); // Zoom out by 2.3
        }
      }, 100);
    }
  };

  handleBrightnessChange = (e) => {
    this.setState({ brightness: parseInt(e.target.value) });
  };

  toggleBrightnessRange = () => {
    this.setState((prevState) => ({
      showBrightnessRange: !prevState.showBrightnessRange,
    }));
  };

  handleReady = () => {
    const cropper = this.cropperRef.current.cropper;
    const containerData = cropper.getContainerData();
    const imageData = cropper.getImageData();

    const aspectRatio = this.calculateAspectRatio();

    let newWidth = imageData.naturalWidth * 0.5;
    let newHeight = newWidth / aspectRatio;

    const maxWidth = containerData.width;
    const maxHeight = containerData.height;

    if (newWidth > maxWidth) {
      newWidth = maxWidth;
      newHeight = newWidth / aspectRatio;
    }
    if (newHeight > maxHeight) {
      newHeight = maxHeight;
      newWidth = newHeight * aspectRatio;
    }

    let offsetTop = 0;
    let offsetLeft = 0;
    if (this.state.edit_images_type === "logo") {
      offsetTop = 20;
      offsetLeft = 90;
    }

    const newLeft = (containerData.width - newWidth) / 2 - offsetLeft;
    const newTop = (containerData.height - newHeight) / 2 - offsetTop;

    cropper.setCropBoxData({
      left: newLeft,
      top: newTop,
      width: newWidth,
      height: newHeight,
    });
  };

  calculateAspectRatio = () => {
    const { edit_images_type } = this.state;
    switch (edit_images_type) {
      case "logo":
        return 325 / 130;
      case "image":
        return 1400 / 980;
      case "image2":
        return 549 / 611;
      default:
        return 1640 / 560;
    }
  };

  handleChangeOtherSettings = (event) => {
    const { name, value } = event.target;
    const keys = name.split(".");

    this.setState((prevState) => {
      let other_settings = { ...prevState.other_settings };
      let nestedObj = other_settings;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          nestedObj[key] = value;
        } else {
          nestedObj = nestedObj[key];
        }
      });

      return { other_settings };
    });
  };

  saveSharelinkOtherSettings = async () => {
    const { sharelink_id, other_settings } = this.state;
    const modalBody = document.getElementById("modalDetails");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }
    const saveResponce = await saveSharelinkOtherSettings(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      other_settings
    );
    console.log("saveSharelinkOtherSettings: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not found.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "Settings updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage:
          "There is some error while updating the sharelink settings.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  getTableData() {
    return this.state.testimonials.map((testimonial, index) => ({
      Id: testimonial.id,
      Name: testimonial.name,
      Title: testimonial.title,
      Comment: testimonial.comment,
      Image: testimonial.image_name,
      Actions: index,
    }));
  }

  handleTestimonialSelection = (id) => (event) => {
    const { selectedTestimonials } = this.state;
    if (event.target.checked) {
      this.setState({
        selectedTestimonials: [...selectedTestimonials, id],
      });
    } else {
      this.setState({
        selectedTestimonials: selectedTestimonials.filter(
          (selectedId) => selectedId !== id
        ),
      });
    }
  };

  getSelectedTestimonials = () => {
    return this.state.selectedTestimonials.join(",");
  };

  saveSharelinkTestimonials = async () => {
    const { sharelink_id } = this.state;
    const testimonials = this.getSelectedTestimonials();
    const saveResponce = await saveSharelinkTestimonials(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      testimonials
    );

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      this.setState({
        uErrorMessage: "Sharelink was not found.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "Testimonials updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage: "There is some error while updating the testimonials.",
        uSuccessMessage: "",
        disabled: false,
      });
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  render() {
    const { edit_picture, showBrightnessRange, brightness } = this.state;
    let filtered_services =
      this.state.service_type_id.length > 0
        ? this.state.serviceData.filter((service) =>
            this.state.service_type_id.includes(service.product_id)
          )
        : this.state.serviceData;
    const fetchToken = async () => {
      try {
        const response = await fetch(
          API_URL + "agents/editor_token_url_agents",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              request_type: "ckeditor-tokenUrl",
              user: auth.getAccount(),
            }),
          }
        );
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error("Failed to fetch token:", error);
        return null;
      }
    };
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Agent Sharelinks"
                        icon="icon ni ni-share"
                      />
                    </h3>
                    <div className="nk-block-des">
                      <p className="lead" style={{ paddingRight: "100px" }}>
                        {this.state.sharelink_limit > 0 ? (
                          <>
                            You are allowed to create up to{" "}
                            <b>{this.state.sharelink_limit}</b> share links.
                            With our platform, you have the freedom to generate
                            and share up to {this.state.sharelink_limit} unique
                            links. This limit ensures that you have a reasonable
                            number of shareable links while maintaining control
                            and manageability. Should you require more share
                            links, please contact our support team who will be
                            happy to assist you with any additional
                            requirements.
                          </>
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          {this.state.sharelink_limit > 0 &&
                          this.state.sharelink_added <
                            this.state.sharelink_limit ? (
                            <li className="nk-block-tools-opt">
                              <a
                                onClick={() =>
                                  this.addMenuHistory(
                                    "sharelinks/add_sharelink"
                                  )
                                }
                                style={{
                                  color: "#fff",
                                  cursor: "pointer",
                                }}
                                className="btn btn-primary"
                              >
                                <em className="icon ni ni-plus-round"></em>
                                <span>Add New Sharelink</span>
                              </a>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoaderListing === true ? (
                        tableLoaderListing()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title="All Sharelinks"
                        />
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
              {/* Sharelink MODAL START */}
              <div
                className="modal fade zoom"
                tabIndex="-1"
                id="modalSignupLink"
              >
                <div className="modal-dialog  modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-link-alt"></em> Agent
                          Sharelink
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body modal-body-lg">
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {this.state.tableLoaderLine === true ? (
                            tableLoaderLine()
                          ) : (
                            <>
                              {this.state.assigned_domain !== "" ? (
                                <div
                                  className="example-alert"
                                  style={{
                                    marginBottom: "15px",
                                  }}
                                >
                                  <div className="alert alert-info alert-icon">
                                    <em className="icon ni ni-link-alt"></em>{" "}
                                    <strong>Assigned Domain: </strong>{" "}
                                    {this.state.assigned_domain}{" "}
                                  </div>
                                </div>
                              ) : null}
                            </>
                          )}
                          <div className="example-alert">
                            <div className="alert alert-light alert-icon">
                              <em className="icon ni ni-alert-circle"></em>{" "}
                              <strong>NOTE: </strong> Share this link with your
                              clients to showcase your services and assist them
                              with signing up.{" "}
                            </div>
                          </div>
                          <div
                            className="caption-text"
                            style={{ marginBottom: "15px", marginTop: "20px" }}
                          >
                            <div className="flex-container">
                              <div
                                className="col-lg-8 col-xxl-8 col-md-8"
                                style={{ paddingLeft: "0px" }}
                              >
                                <div
                                  className="example-alert "
                                  style={{ marginBottom: "20px" }}
                                >
                                  <div className="alert alert-light column">
                                    {this.state.assigned_domain && this.state.assigned_domain !== "" ? this.state.assigned_domain : this.state.signup_link}
                                  </div>
                                </div>
                              </div>
                              <div
                                className="col-lg-4 col-xxl-4 col-md-4"
                                style={{ paddingRight: "0px" }}
                              >
                                {this.state.signup_link !== "" ? (
                                  <div
                                    className="example-alert "
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <div
                                      className="alert alert-light "
                                      style={{
                                        textAlign: "center",
                                        padding: "10px",
                                      }}
                                    >
                                      <QRCode
                                        value={this.state.assigned_domain && this.state.assigned_domain !== "" ? this.state.assigned_domain : this.state.signup_link}
                                        size={200}
                                      />
                                      {/*<img
                                        src={`https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${this.state.signup_link}`}
                                    />*/}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <button
                              className="btn btn-primary"
                              style={{ marginRight: "15px" }}
                              onClick={() => this.show_regenerate()}
                            >
                              <em className="icon ni ni-swap"></em> Regenerate
                            </button>

                            <CopyToClipboard
                              text={this.state.assigned_domain && this.state.assigned_domain !== "" ? this.state.assigned_domain : this.state.signup_link}
                              onCopy={() => this.setState({ copied: true })}
                            >
                              <button className="btn btn-success">
                                <em className="icon ni ni-copy"></em> Copy to
                                clipboard{" "}
                                {this.state.copied ? (
                                  <span style={{ color: "white" }}>
                                    <em className="icon ni ni-done"></em>
                                  </span>
                                ) : null}
                              </button>
                            </CopyToClipboard>
                            <button
                              className="btn btn-warning"
                              style={{ marginLeft: "15px" }}
                              onClick={() => this.show_email()}
                            >
                              <em className="icon ni ni-emails"></em> Email Link
                            </button>
                            <a
                              href={this.state.signup_link}
                              style={{ zIndex: "100", marginLeft: "15px" }}
                              target="_blank"
                              className="btn btn-secondary"
                            >
                              <em class="icon ni ni-external-alt"></em> Preview
                              Sharelink
                            </a>
                          </div>
                          {this.state.show_regenerate === "1" ? (
                            <div
                              className="row g-4 mt-15"
                              style={{ marginTop: "35px", clear: "both" }}
                            >
                              {this.state.linkErrorMessage !== "" ? (
                                <div
                                  className="example-alert col-md-12"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.linkErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.linkSuccessMessage !== "" ? (
                                <div
                                  className="example-alert col-md-12"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.linkSuccessMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-8">
                                <MutextField
                                  required
                                  id="custom_link"
                                  name="custom_link"
                                  type="text"
                                  label="Custom Link ID"
                                  defaultValue={this.state.custom_link}
                                  inputProps={{ maxLength: 50 }}
                                  onChange={this.handleChange}
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^a-z0-9]/gi,
                                      ""
                                    );
                                  }}
                                  helperText="Without spaces and special characters."
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                              <div className="col-md-4">
                                {this.state.reg_button === false ? (
                                  <button
                                    className="btn btn-xl btn-primary"
                                    style={{ marginLeft: "15px" }}
                                    onClick={() => this.regenerate_link()}
                                    disabled={this.state.disabled}
                                  >
                                    <em className="icon ni ni-swap"></em> Submit
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-xl btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Submit
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          ) : null}
                          {this.state.send_email === "1" ? (
                            <div
                              className="row g-4 mt-15"
                              style={{ marginTop: "25px" }}
                            >
                              {this.state.eErrorMessage !== "" ? (
                                <div
                                  className="example-alert col-md-12"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.eErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.eSuccessMessage !== "" ? (
                                <div
                                  className="example-alert col-md-12"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.eSuccessMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-8">
                                <MutextField
                                  required
                                  id="email_to"
                                  name="email_to"
                                  type="email"
                                  label="Enter Email Address"
                                  value={this.state.email_to}
                                  inputProps={{ maxLength: 100 }}
                                  onChange={this.handleChange}
                                  helperText="Enter your Email Address here."
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                              <div className="col-md-4">
                                {this.state.reg_email_button === false ? (
                                  <button
                                    className="btn btn-xl btn-primary"
                                    style={{ marginLeft: "15px" }}
                                    onClick={() => this.send_email()}
                                    disabled={this.state.disabled}
                                  >
                                    <em className="icon ni ni-emails"></em> Send
                                    Email Link
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-xl btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Send
                                    Email Link
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Sharelink MODAL End */}
              {/* STATS MODAL */}
              <div className="modal fade zoom" tabIndex="-1" id="modalStats">
                <div
                  className="modal-dialog modal-dialog-top  modal-lg"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em className="icon ni ni-share"></em> Sharelink Stats
                      </h5>
                    </div>
                    <div className="modal-body">
                      {this.state.statErrorMessage !== "" ? (
                        <div
                          className="example-alert col-md-12"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.statErrorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="card-inner">
                        <div className="row text-center">
                          <div className="col-6">
                            <div className="profile-stats">
                              <span className="amount">
                                {this.state.stat_hits}
                              </span>
                              <span className="sub-text">Total Hits</span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="profile-stats">
                              <span className="amount">
                                {this.state.last_visit}
                              </span>
                              <span className="sub-text">Last Visit</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* STATS MODAL */}
              {/* DETAIL MODAL START */}
              <div className="modal fade zoom" tabIndex="-1" id="modalDetails">
                <div
                  className="modal-dialog  modal-xl modal-dialog-top new_modal_width"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="col-md-8 col-lg-8"
                        style={{ paddingLeft: "0px" }}
                      >
                        <div
                          className="modal-title"
                          style={{
                            textAlign: "left",
                            fontSize: "20px",
                          }}
                        >
                          <strong>
                            <em className="icon ni ni-link-alt"></em> Sharelink
                            Detail
                          </strong>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <a
                          href={this.state.complete_link}
                          style={{ zIndex: "100" }}
                          target="_blank"
                          className="btn btn-outline-primary"
                        >
                          Preview Sharelink
                        </a>
                        <a
                          onClick={this.handleCloseModal}
                          style={{ cursor: "pointer" }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                      </div>
                    </div>

                    <div
                      className="modal-body modal-body-lg"
                      style={{ padding: ".2rem .2rem" }}
                    >
                      <div className="nk-modal" id="sharelinkdetail">
                        <div className="nk-modal-text">
                          <section className="tabs-section text-white">
                            <div className="container">
                              {this.state.uErrorMessage !== "" ? (
                                <div
                                  className="col-md-12 col-lg-12"
                                  style={{
                                    marginBottom: "30px",
                                    clear: "both",
                                    marginLeft: "0px",
                                    paddingLeft: "0px",
                                  }}
                                >
                                  <div
                                    className="example-alert"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h3 style={{ color: "#526484" }}>
                                          Error
                                        </h3>
                                        <p>{this.state.uErrorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.uSuccessMessage !== "" ? (
                                <div
                                  className="col-md-12 col-lg-12"
                                  style={{
                                    marginBottom: "30px",
                                    clear: "both",
                                    marginLeft: "0px",
                                    paddingLeft: "0px",
                                  }}
                                >
                                  <div
                                    className="example-alert"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h3 style={{ color: "#526484" }}>
                                          Success
                                        </h3>
                                        <p>{this.state.uSuccessMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="row">
                                <div
                                  className="col-sm-5 col-lg-3"
                                  id="detail_tabs"
                                >
                                  <ul className="nav nav-tabs mb-3">
                                    <li className="nav-item">
                                      <a
                                        className="nav-link active show"
                                        data-toggle="tab"
                                        href="#tab-1"
                                      >
                                        <em className="icon ni ni-share"></em>
                                        Sharelink Detail
                                      </a>
                                    </li>
                                    {this.state.landing_page === "Yes" ? (
                                      <>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-2"
                                            onClick={() =>
                                              this.getSharelinkTheme()
                                            }
                                          >
                                            <em className="icon ni ni-dashboard"></em>{" "}
                                            Theme
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-3"
                                            onClick={() =>
                                              this.getSharelinkServices()
                                            }
                                          >
                                            <em className="icon ni ni-list-round"></em>{" "}
                                            Services
                                          </a>
                                        </li>
                                        {/*<li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-33"
                                            onClick={() =>
                                              this.getSharelinkBundles()
                                            }
                                          >
                                            <em className="icon ni ni-list-round"></em>{" "}
                                            Bundles
                                          </a>
                                          </li>*/}
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-8"
                                            onClick={() =>
                                              this.getSharelinkLogos()
                                            }
                                          >
                                            <em className="icon ni ni-camera"></em>{" "}
                                            Image Library
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-4"
                                            onClick={() =>
                                              this.getSharelinkSettings("about")
                                            }
                                          >
                                            <em className="icon ni ni-align-justify"></em>{" "}
                                            About Me
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-5"
                                            onClick={() =>
                                              this.getSharelinkSettings(
                                                "social"
                                              )
                                            }
                                          >
                                            <em className="icon ni ni-link-group"></em>
                                            Social Media Links
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-6"
                                            onClick={() =>
                                              this.getSharelinkSettings(
                                                "contact"
                                              )
                                            }
                                          >
                                            <em className="icon ni ni-contact"></em>
                                            Contact Details
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-7"
                                            onClick={() =>
                                              this.getSharelinkSettings("seo")
                                            }
                                          >
                                            <em className="icon ni ni-list-round"></em>{" "}
                                            SEO Section
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-10"
                                            onClick={() =>
                                              this.getSharelinkSettings(
                                                "testimonials"
                                              )
                                            }
                                          >
                                            <em className="icon ni ni-star"></em>{" "}
                                            Testimonials
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-9"
                                            onClick={() =>
                                              this.getSharelinkSettings("seo")
                                            }
                                          >
                                            <em className="icon ni ni-list-round"></em>{" "}
                                            Other Settings
                                          </a>
                                        </li>
                                      </>
                                    ) : null}
                                  </ul>
                                </div>
                                <div className="col-sm-7 col-lg-9">
                                  <div className="tab-content">
                                    <div
                                      className="tab-pane active show"
                                      id="tab-1"
                                    >
                                      <div className="row">
                                        <div
                                          className="col-lg-12 details"
                                          style={{ paddingLeft: "0px" }}
                                        >
                                          <div
                                            className="col-md-8 col-lg-8"
                                            style={{
                                              paddingLeft: "0px",
                                              float: "left",
                                            }}
                                          >
                                            <h3>Initial Detail</h3>
                                          </div>

                                          <div
                                            className="col-md-4 col-lg-4"
                                            style={{
                                              paddingLeft: "0px",
                                              float: "right",
                                              textAlign: "right",
                                            }}
                                          >
                                            {/*<a
                                              href={this.state.complete_link}
                                              style={{ zIndex: "100" }}
                                              target="_blank"
                                              className="btn btn-outline-success"
                                            >
                                              Preview
                                          </a>*/}
                                          </div>

                                          <div
                                            className="form-group col-md-12 col-lg-12"
                                            style={{ paddingLeft: "0px" }}
                                          >
                                            <MutextField
                                              required
                                              id="title"
                                              name="title"
                                              type="text"
                                              label="Sharelink Title"
                                              inputProps={{ maxLength: 100 }}
                                              value={this.state.title}
                                              onChange={this.handleChange}
                                              helperText="Enter the title here."
                                              onInput={(e) => {
                                                e.target.value =
                                                  e.target.value.replace(
                                                    /[^a-z0-9 ]/gi,
                                                    ""
                                                  );
                                              }}
                                              variant="outlined"
                                              fullWidth
                                            />
                                          </div>

                                          <div
                                            className="form-group col-md-12 col-lg-12"
                                            style={{ paddingLeft: "0px" }}
                                          >
                                            <MutextField
                                              id="status"
                                              name="status"
                                              select
                                              label="Status"
                                              value={this.state.status}
                                              onChange={this.handleChange}
                                              SelectProps={{
                                                native: true,
                                              }}
                                              helperText="e.g Active or Inactive"
                                              variant="outlined"
                                              fullWidth
                                            >
                                              <option key="status1" value="1">
                                                Active
                                              </option>
                                              <option key="status0" value="0">
                                                Inactive
                                              </option>
                                            </MutextField>
                                          </div>

                                          <div
                                            className="form-group col-md-12 col-lg-12"
                                            style={{ paddingLeft: "0px" }}
                                          >
                                            <MutextField
                                              id="landing_page"
                                              name="landing_page"
                                              select
                                              label="Landing Page"
                                              value={this.state.landing_page}
                                              onChange={this.handleChange}
                                              SelectProps={{
                                                native: true,
                                              }}
                                              helperText="Yes: Displays a landing page where the client can select a service and register. No: Skips the landing page and redirects the client directly to the registration page."
                                              variant="outlined"
                                              fullWidth
                                            >
                                              <option
                                                key="landing_page1"
                                                value="Yes"
                                              >
                                                Yes
                                              </option>
                                              <option
                                                key="landing_page0"
                                                value="No"
                                              >
                                                No
                                              </option>
                                            </MutextField>
                                          </div>

                                          <div
                                            className="form-group col-md-12 col-lg-12"
                                            style={{
                                              paddingLeft: "0px",
                                              textAlign: "right",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-lg btn-primary"
                                              onClick={this.saveSharelinkDetail}
                                              disabled={this.state.disabled}
                                            >
                                              <em className="icon ni ni-note-add"></em>
                                              {"  "}Save Detail
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tab-pane" id="tab-2">
                                      <div className="row">
                                        <div
                                          className="col-lg-12 details"
                                          style={{ paddingLeft: "0px" }}
                                        >
                                          <h3>Update Theme</h3>
                                          <div
                                            className="col-md-12 col-lg-12"
                                            style={{ paddingLeft: "0px" }}
                                          >
                                            {this.state.getThemeErrorMessage !==
                                            "" ? (
                                              <div
                                                className="example-alert"
                                                style={{
                                                  marginBottom: "15px",
                                                  color: "#526484",
                                                }}
                                              >
                                                <div className="alert alert-pro alert-danger">
                                                  <div className="alert-text">
                                                    <h4
                                                      style={{
                                                        color: "#364a63",
                                                      }}
                                                    >
                                                      Error
                                                    </h4>
                                                    <p
                                                      style={{
                                                        color: "#364a63",
                                                      }}
                                                    >
                                                      {
                                                        this.state
                                                          .getThemeErrorMessage
                                                      }
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                            {this.state
                                              .tableLoaderBlockImage ===
                                            true ? (
                                              tableLoaderBlockImage()
                                            ) : (
                                              <>
                                                {this.state.themesList &&
                                                this.state.themesList.length >
                                                  0 ? (
                                                  <div
                                                    className="card card-bordered"
                                                    id="sharelink_div"
                                                  >
                                                    <div className="card-inner">
                                                      <div
                                                        className="row g-4"
                                                        style={{
                                                          padding: "0px",
                                                        }}
                                                      >
                                                        {this.state.themesList.map(
                                                          (themes) => (
                                                            <div
                                                              className="col-md-4 col-lg-4 col-sm-6"
                                                              key={`theme_key${themes.id}`}
                                                            >
                                                              <div
                                                                className={this.setThemeClassMain(
                                                                  themes.id
                                                                )}
                                                              >
                                                                <div className="pricingTable-header">
                                                                  <img
                                                                    src={`../../assets/images/${themes.theme_image}`}
                                                                  />
                                                                </div>

                                                                <a
                                                                  onClick={() =>
                                                                    this.setTheme(
                                                                      themes.id
                                                                    )
                                                                  }
                                                                  className={this.setThemeClass(
                                                                    themes.id
                                                                  )}
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    fontSize:
                                                                      "12px",
                                                                    paddingLeft:
                                                                      "25px",
                                                                    paddingRight:
                                                                      "25px",
                                                                  }}
                                                                >
                                                                  {this.state
                                                                    .theme ===
                                                                  themes.id ? (
                                                                    <em className="icon ni ni-done"></em>
                                                                  ) : null}
                                                                  {"  "}
                                                                  Choose Theme
                                                                  <i className="fa fa-angle-right"></i>
                                                                </a>
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </>
                                            )}
                                          </div>
                                          <div
                                            className="form-group col-md-12 col-lg-12"
                                            style={{
                                              marginTop: "20px",
                                              paddingLeft: "0px",
                                              textAlign: "right",
                                            }}
                                          >
                                            {this.state.themesList &&
                                            this.state.themesList.length > 0 ? (
                                              <button
                                                type="button"
                                                className="btn btn-lg btn-primary"
                                                onClick={
                                                  this.saveSharelinkTheme
                                                }
                                                disabled={this.state.disabled}
                                              >
                                                <em className="icon ni ni-note-add"></em>
                                                {"  "}Save Theme
                                              </button>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tab-pane" id="tab-3">
                                      <div className="row">
                                        <h3
                                          style={{
                                            color: "#526484",
                                            fontSize: "26px",
                                            fontWeight: "500",
                                            marginBottom: "20px",
                                          }}
                                        >
                                          Update Services
                                        </h3>
                                        <div
                                          className="col-lg-12 details"
                                          style={{ paddingLeft: "0px" }}
                                        >
                                          {this.state.getServiceErrorMessage !==
                                          "" ? (
                                            <div
                                              className="example-alert"
                                              style={{
                                                marginBottom: "15px",
                                                color: "#526484",
                                              }}
                                            >
                                              <div className="alert alert-pro alert-danger">
                                                <div className="alert-text">
                                                  <h4
                                                    style={{
                                                      color: "#364a63",
                                                    }}
                                                  >
                                                    Error
                                                  </h4>
                                                  <p
                                                    style={{
                                                      color: "#364a63",
                                                    }}
                                                  >
                                                    {
                                                      this.state
                                                        .getServiceErrorMessage
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                          {this.state
                                            .tableLoaderBlockService ===
                                          true ? (
                                            tableLoaderBlockImage()
                                          ) : (
                                            <>
                                              {this.state.serviceData &&
                                              this.state.serviceData.length >
                                                0 ? (
                                                <div className="card card-bordered">
                                                  <div className="card-inner">
                                                    {/** NEW SERVICE BLOCK **/}
                                                    <div
                                                      style={{
                                                        maxWidth: "60%",
                                                        marginBottom: "1.5rem",
                                                      }}
                                                    >
                                                      <Select
                                                        options={
                                                          this.state
                                                            .all_service_types
                                                        }
                                                        name="selected_service_types"
                                                        placeholder="Select service types"
                                                        value={
                                                          this.state
                                                            .selected_service_types
                                                        }
                                                        autoComplete="off"
                                                        onChange={
                                                          this
                                                            .handleChangeSearch
                                                        }
                                                        isClearable
                                                        isSearchable
                                                        isMulti
                                                        styles={{
                                                          control: (
                                                            baseStyles,
                                                            state
                                                          ) => ({
                                                            ...baseStyles,
                                                            lineHeight: "40px",
                                                          }),
                                                          option: (
                                                            baseStyles,
                                                            state
                                                          ) => ({
                                                            ...baseStyles,
                                                            color: "black",
                                                            backgroundColor:
                                                              state.isFocused
                                                                ? "#e0e0e0"
                                                                : "white",
                                                            "&:hover": {
                                                              backgroundColor:
                                                                "#f0f0f0",
                                                            },
                                                          }),
                                                          menu: (
                                                            baseStyles
                                                          ) => ({
                                                            ...baseStyles,
                                                            zIndex: 9999,
                                                          }),
                                                        }}
                                                      />
                                                    </div>
                                                    <div className="nk-block">
                                                      {filtered_services.map(
                                                        (services, sid) => (
                                                          <div
                                                            className={this.setServiceClassMain(
                                                              services.id
                                                            )}
                                                            key={`servKey${sid}`}
                                                          >
                                                            <div className="row no-gutters">
                                                              <div className="col-md-8">
                                                                <div className="sp-plan-info card-inner">
                                                                  <div className="row gx-0 gy-3">
                                                                    <div className="col-xl-9 col-sm-8">
                                                                      <div className="sp-plan-name">
                                                                        <h6 className="title">
                                                                          <a
                                                                            style={{
                                                                              cursor:
                                                                                "pointer",
                                                                              color:
                                                                                "#6576ff",
                                                                            }}
                                                                          >
                                                                            {
                                                                              services.service_title
                                                                            }{" "}
                                                                            {/*<span className="badge badge-outline-primary">
                                                                              {services.service_type ===
                                                                              "did"
                                                                                ? "DID Origination"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "tfn"
                                                                                ? "Toll Free Origination"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "term"
                                                                                ? "Voice Termination"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "teams"
                                                                                ? "Microsoft Teams UCAAS"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "ivr"
                                                                                ? "Voyce Admins"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "tfn_term"
                                                                                ? "Toll Free Termination"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "az_term"
                                                                                ? "International A-Z Termination"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "live_agent"
                                                                                ? "Live Agent"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "live_agent"
                                                                                ? "CNAM"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "sms"
                                                                                ? "SMS Inbound / Outbound"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "generic"
                                                                                ? "Other Services"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "cnam"
                                                                                ? "CNAM"
                                                                                : ""}
                                                                            </span>*/}
                                                                            <span className="badge badge-outline-primary">
                                                                            {services.product_title &&
                                                                            services.product_title !==
                                                                              ""
                                                                              ? `${services.product_title}`
                                                                              : ""}
                                                                          </span>
                                                                          </a>
                                                                        </h6>
                                                                        <p>
                                                                          Type:{" "}
                                                                          <span className="text-base">
                                                                            {services.per_min_type ===
                                                                            "unmetered"
                                                                              ? "Unmetered"
                                                                              : "Metered"}
                                                                          </span>
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                    <div className="col-xl-3 col-sm-4"></div>
                                                                  </div>
                                                                </div>
                                                                <div className="sp-plan-desc card-inner">
                                                                  {services.service_type ===
                                                                    "did" ||
                                                                  services.service_type ===
                                                                    "tfn" ||
                                                                  services.service_type ===
                                                                    "teams" ? (
                                                                    <ul className="row gx-1">
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            NRC{" "}
                                                                            <Tooltip
                                                                              title="Non-Recurring Charge – A one-time fee that is deducted on service setup."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.nrc &&
                                                                          services.nrc !==
                                                                            null &&
                                                                          services.nrc !==
                                                                            ""
                                                                            ? services.nrc.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            MRC{" "}
                                                                            <Tooltip
                                                                              title="Monthly Recurring Charge - what you pay each month for the service."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.mrc &&
                                                                          services.mrc !==
                                                                            null &&
                                                                          services.mrc !==
                                                                            ""
                                                                            ? services.mrc.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                      {services.main_type !==
                                                                        "ratedeck" &&
                                                                      services.per_min_type !==
                                                                        "unmetered" ? (
                                                                        <li className="col-6 col-lg-3">
                                                                          <p>
                                                                            <span className="text-soft">
                                                                              Rate
                                                                              Per
                                                                              Minute{" "}
                                                                              <Tooltip
                                                                                title="Rate assigned per minute."
                                                                                placement="top"
                                                                              >
                                                                                <em
                                                                                  className="icon ni ni-info"
                                                                                  style={{
                                                                                    color:
                                                                                      "#6576ff",
                                                                                  }}
                                                                                ></em>
                                                                              </Tooltip>
                                                                            </span>{" "}
                                                                            $
                                                                            {services.rate &&
                                                                            services.rate !==
                                                                              null &&
                                                                            services.rate !==
                                                                              ""
                                                                              ? services.rate.toFixed(
                                                                                  4
                                                                                )
                                                                              : "0.00"}
                                                                          </p>
                                                                        </li>
                                                                      ) : null}
                                                                      {services.per_min_type ===
                                                                      "unmetered" ? (
                                                                        <li className="col-6 col-lg-3">
                                                                          <p>
                                                                            <span className="text-soft">
                                                                              Rate
                                                                              Per
                                                                              Session{" "}
                                                                              <Tooltip
                                                                                title="Rate assigned per session."
                                                                                placement="top"
                                                                              >
                                                                                <em
                                                                                  className="icon ni ni-info"
                                                                                  style={{
                                                                                    color:
                                                                                      "#6576ff",
                                                                                  }}
                                                                                ></em>
                                                                              </Tooltip>
                                                                            </span>{" "}
                                                                            $
                                                                            {services.rate &&
                                                                            services.rate !==
                                                                              null &&
                                                                            services.rate !==
                                                                              ""
                                                                              ? services.rate.toFixed(
                                                                                  4
                                                                                )
                                                                              : "0.00"}
                                                                          </p>
                                                                        </li>
                                                                      ) : null}
                                                                      {services.main_type ===
                                                                      "ratedeck" ? (
                                                                        <>
                                                                          <li className="col-6 col-lg-3">
                                                                            <p>
                                                                              <span className="text-soft">
                                                                                Effective
                                                                                Date{" "}
                                                                                <Tooltip
                                                                                  title="Effective Date."
                                                                                  placement="top"
                                                                                >
                                                                                  <em
                                                                                    className="icon ni ni-info"
                                                                                    style={{
                                                                                      color:
                                                                                        "#6576ff",
                                                                                    }}
                                                                                  ></em>
                                                                                </Tooltip>
                                                                              </span>{" "}
                                                                              {services.effective_date &&
                                                                              services.effective_date !==
                                                                                null &&
                                                                              services.effective_date !==
                                                                                "" ? (
                                                                                <>
                                                                                  {this.format_date(
                                                                                    services.effective_date
                                                                                  )}
                                                                                </>
                                                                              ) : null}
                                                                            </p>
                                                                          </li>
                                                                        </>
                                                                      ) : null}
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Cancellation
                                                                            Fee{" "}
                                                                            <Tooltip
                                                                              title="A cancellation fee is a sum of money charged to a customer who has enlisted your services."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.cancellation_fee &&
                                                                          services.cancellation_fee !==
                                                                            null &&
                                                                          services.cancellation_fee !==
                                                                            ""
                                                                            ? services.cancellation_fee.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                    </ul>
                                                                  ) : null}
                                                                  {services.service_type ===
                                                                    "term" ||
                                                                  services.service_type ===
                                                                    "ivr" ||
                                                                  services.service_type ===
                                                                    "live_agent" ||
                                                                  services.service_type ===
                                                                    "tfn_term" ||
                                                                  services.service_type ===
                                                                    "az_term" ||
                                                                  services.service_type ===
                                                                    "generic" ? (
                                                                    <ul className="row gx-1">
                                                                      {services.main_type !==
                                                                        "ratedeck" &&
                                                                      services.per_min_type !==
                                                                        "unmetered" ? (
                                                                        <li className="col-6 col-lg-3">
                                                                          <p>
                                                                            <span className="text-soft">
                                                                              {services.service_type ===
                                                                              "generic"
                                                                                ? "Rate"
                                                                                : "Rate Per Minute"}{" "}
                                                                              <Tooltip
                                                                                title={
                                                                                  services.service_type ===
                                                                                  "generic"
                                                                                    ? "Rate assigned"
                                                                                    : "Rate assigned per minute."
                                                                                }
                                                                                placement="top"
                                                                              >
                                                                                <em
                                                                                  className="icon ni ni-info"
                                                                                  style={{
                                                                                    color:
                                                                                      "#6576ff",
                                                                                  }}
                                                                                ></em>
                                                                              </Tooltip>
                                                                            </span>{" "}
                                                                            $
                                                                            {services.rate &&
                                                                            services.rate !==
                                                                              null &&
                                                                            services.rate !==
                                                                              ""
                                                                              ? services.rate.toFixed(
                                                                                  4
                                                                                )
                                                                              : "0.00"}
                                                                          </p>
                                                                        </li>
                                                                      ) : null}
                                                                      {services.per_min_type ===
                                                                      "unmetered" ? (
                                                                        <li className="col-6 col-lg-3">
                                                                          <p>
                                                                            <span className="text-soft">
                                                                              {services.service_type ===
                                                                              "generic"
                                                                                ? "Session Rate"
                                                                                : "Rate Per Session"}{" "}
                                                                              <Tooltip
                                                                                title={
                                                                                  services.service_type ===
                                                                                  "generic"
                                                                                    ? "Session rate assigned"
                                                                                    : "Rate assigned per session"
                                                                                }
                                                                                placement="top"
                                                                              >
                                                                                <em
                                                                                  className="icon ni ni-info"
                                                                                  style={{
                                                                                    color:
                                                                                      "#6576ff",
                                                                                  }}
                                                                                ></em>
                                                                              </Tooltip>
                                                                            </span>{" "}
                                                                            $
                                                                            {services.rate &&
                                                                            services.rate !==
                                                                              null &&
                                                                            services.rate !==
                                                                              ""
                                                                              ? services.rate.toFixed(
                                                                                  4
                                                                                )
                                                                              : "0.00"}
                                                                          </p>
                                                                        </li>
                                                                      ) : null}
                                                                      {services.main_type ===
                                                                      "ratedeck" ? (
                                                                        <>
                                                                          <li className="col-6 col-lg-3">
                                                                            <p>
                                                                              <span className="text-soft">
                                                                                Effective
                                                                                Date{" "}
                                                                                <Tooltip
                                                                                  title="Effective Date."
                                                                                  placement="top"
                                                                                >
                                                                                  <em
                                                                                    className="icon ni ni-info"
                                                                                    style={{
                                                                                      color:
                                                                                        "#6576ff",
                                                                                    }}
                                                                                  ></em>
                                                                                </Tooltip>
                                                                              </span>{" "}
                                                                              {services.effective_date &&
                                                                              services.effective_date !==
                                                                                null &&
                                                                              services.effective_date !==
                                                                                "" ? (
                                                                                <>
                                                                                  {this.format_date(
                                                                                    services.effective_date
                                                                                  )}
                                                                                </>
                                                                              ) : null}
                                                                            </p>
                                                                          </li>
                                                                        </>
                                                                      ) : null}
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Cancellation
                                                                            Fee{" "}
                                                                            <Tooltip
                                                                              title="A cancellation fee is a sum of money charged to a customer who has enlisted your services."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.cancellation_fee &&
                                                                          services.cancellation_fee !==
                                                                            null &&
                                                                          services.cancellation_fee !==
                                                                            ""
                                                                            ? services.cancellation_fee.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                    </ul>
                                                                  ) : null}
                                                                  {services.service_type ===
                                                                  "sms" ? (
                                                                    <ul className="row gx-1">
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            SMS
                                                                            IN{" "}
                                                                            <Tooltip
                                                                              title="Price applied to SMS In Services."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.sms_in &&
                                                                          services.sms_in !==
                                                                            null &&
                                                                          services.sms_in !==
                                                                            ""
                                                                            ? services.sms_in.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            SMS
                                                                            OUT{" "}
                                                                            <Tooltip
                                                                              title="Price applied to SMS Out Services."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.sms_out &&
                                                                          services.sms_out !==
                                                                            null &&
                                                                          services.sms_out !==
                                                                            ""
                                                                            ? services.sms_out.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Cancellation
                                                                            Fee{" "}
                                                                            <Tooltip
                                                                              title="A cancellation fee is a sum of money charged to a customer who has enlisted your services."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.cancellation_fee &&
                                                                          services.cancellation_fee !==
                                                                            null &&
                                                                          services.cancellation_fee !==
                                                                            ""
                                                                            ? services.cancellation_fee.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                    </ul>
                                                                  ) : null}
                                                                  {services.service_type ===
                                                                  "cnam" ? (
                                                                    <ul className="row gx-1">
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Update
                                                                            CNAM
                                                                            (MRC){" "}
                                                                            <Tooltip
                                                                              title="Price applied to Update CNAM (MRC)."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.cnam_mrc &&
                                                                          services.cnam_mrc !==
                                                                            null &&
                                                                          services.cnam_mrc !==
                                                                            ""
                                                                            ? services.cnam_mrc.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            CNAM
                                                                            DIP{" "}
                                                                            <Tooltip
                                                                              title="Price applied to CNAM DIP."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.cnam_dip &&
                                                                          services.cnam_dip !==
                                                                            null &&
                                                                          services.cnam_dip !==
                                                                            ""
                                                                            ? services.cnam_dip.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>

                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Storage
                                                                            DIP{" "}
                                                                            <Tooltip
                                                                              title="Price applied to Storage DIP."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.cnam_dip_local &&
                                                                          services.cnam_dip_local !==
                                                                            null &&
                                                                          services.cnam_dip_local !==
                                                                            ""
                                                                            ? services.cnam_dip_local.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>

                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Cancellation
                                                                            Fee{" "}
                                                                            <Tooltip
                                                                              title="A cancellation fee is a sum of money charged to a customer who has enlisted your services."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.cancellation_fee &&
                                                                          services.cancellation_fee !==
                                                                            null &&
                                                                          services.cancellation_fee !==
                                                                            ""
                                                                            ? services.cancellation_fee.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                    </ul>
                                                                  ) : null}
                                                                </div>
                                                              </div>
                                                              <div className="col-md-4">
                                                                <div className="sp-plan-action card-inner">
                                                                  <div className="sp-plan-btn">
                                                                    <a
                                                                      onClick={() =>
                                                                        this.setService(
                                                                          services.id
                                                                        )
                                                                      }
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      className={this.setServiceClass(
                                                                        services.id
                                                                      )}
                                                                    >
                                                                      <span>
                                                                        {this.state.selected_service.includes(
                                                                          services.id
                                                                        ) ? (
                                                                          <em className="icon ni ni-done"></em>
                                                                        ) : null}
                                                                        {"  "}
                                                                        CHOOSE
                                                                        SERVICE
                                                                      </span>
                                                                    </a>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                    {/** NEW SERVICE BLOCK **/}
                                                  </div>
                                                </div>
                                              ) : null}
                                              <div
                                                className="form-group col-md-12 col-lg-12"
                                                style={{
                                                  marginTop: "20px",
                                                  paddingLeft: "0px",
                                                  textAlign: "right",
                                                }}
                                              >
                                                {this.state.serviceData &&
                                                this.state.serviceData.length >
                                                  0 ? (
                                                  <button
                                                    type="button"
                                                    className="btn btn-lg btn-primary"
                                                    onClick={
                                                      this.saveSharelinkServices
                                                    }
                                                    disabled={
                                                      this.state.disabled
                                                    }
                                                  >
                                                    <em className="icon ni ni-note-add"></em>
                                                    {"  "}Save Services
                                                  </button>
                                                ) : null}
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tab-pane" id="tab-33">
                                      <div className="row">
                                        <h3
                                          style={{
                                            color: "#526484",
                                            fontSize: "26px",
                                            fontWeight: "500",
                                            marginBottom: "20px",
                                          }}
                                        >
                                          Update Bundles
                                        </h3>
                                        <div
                                          className="col-lg-12 details"
                                          style={{ paddingLeft: "0px" }}
                                        >
                                          {this.state.getBundleErrorMessage !==
                                          "" ? (
                                            <div
                                              className="example-alert"
                                              style={{
                                                marginBottom: "15px",
                                                color: "#526484",
                                              }}
                                            >
                                              <div className="alert alert-pro alert-danger">
                                                <div className="alert-text">
                                                  <h4
                                                    style={{
                                                      color: "#364a63",
                                                    }}
                                                  >
                                                    Error
                                                  </h4>
                                                  <p
                                                    style={{
                                                      color: "#364a63",
                                                    }}
                                                  >
                                                    {
                                                      this.state
                                                        .getBundleErrorMessage
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                          {this.state.tableLoaderBlockBundle ===
                                          true ? (
                                            tableLoaderBlockImage()
                                          ) : (
                                            <>
                                              {this.state.bundlesData &&
                                              this.state.bundlesData.length >
                                                0 ? (
                                                <>
                                                  <div className="card card-bordered">
                                                    <div className="card-inner">
                                                      {/** NEW SERVICE BLOCK **/}
                                                      <div className="nk-block">
                                                        {this.state.bundlesData.map(
                                                          (bundles, sid) => (
                                                            <div
                                                              className={this.setBundleClassMainNew(
                                                                bundles.id
                                                              )}
                                                              key={`servKey${sid}`}
                                                            >
                                                              <div className="row no-gutters">
                                                                <div className="col-md-8">
                                                                  <div className="sp-plan-info card-inner">
                                                                    <div className="row gx-0 gy-3">
                                                                      <div className="col-xl-9 col-sm-8">
                                                                        <div className="sp-plan-name">
                                                                          <h6 className="title">
                                                                            <a
                                                                              style={{
                                                                                cursor:
                                                                                  "pointer",
                                                                                color:
                                                                                  "#6576ff",
                                                                              }}
                                                                            >
                                                                              {
                                                                                bundles.bundle_title
                                                                              }{" "}
                                                                              <span className="badge badge-outline-primary"></span>
                                                                            </a>
                                                                          </h6>
                                                                          <p>
                                                                            Assigned
                                                                            Services:{" "}
                                                                            <span className="text-base">
                                                                              <span className="badge badge-outline-primary">
                                                                                VIEW
                                                                              </span>
                                                                            </span>
                                                                          </p>
                                                                        </div>
                                                                      </div>
                                                                      <div className="col-xl-3 col-sm-4"></div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="sp-plan-desc card-inner">
                                                                    <ul className="row gx-1">
                                                                      <li className="col-6 col-lg-4">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Per
                                                                            Month
                                                                            Rate
                                                                            (Charge
                                                                            Monthly){" "}
                                                                            <Tooltip
                                                                              title="Per Month Rate (Charge Monthly)"
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {bundles.per_month_rate.toFixed(
                                                                            4
                                                                          )}
                                                                        </p>
                                                                      </li>
                                                                      <li className="col-6 col-lg-4">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Per
                                                                            Month
                                                                            Rate
                                                                            (Charge
                                                                            Yearly)
                                                                            <Tooltip
                                                                              title="Per Month Rate (Charge Yearly)"
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {bundles.per_year_rate.toFixed(
                                                                            4
                                                                          )}
                                                                        </p>
                                                                      </li>

                                                                      <li className="col-6 col-lg-4">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Date
                                                                            Added
                                                                            <Tooltip
                                                                              title="Date Added"
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          {bundles.date_added !==
                                                                          ""
                                                                            ? this.format_date(
                                                                                bundles.date_added
                                                                              )
                                                                            : null}
                                                                        </p>
                                                                      </li>
                                                                    </ul>
                                                                  </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                  <div className="sp-plan-action card-inner">
                                                                    <div className="sp-plan-btn">
                                                                      <a
                                                                        onClick={() =>
                                                                          this.setBundles(
                                                                            bundles.id
                                                                          )
                                                                        }
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                        className={this.setBundleClassNew(
                                                                          bundles.id
                                                                        )}
                                                                      >
                                                                        <span>
                                                                          {this.state.selected_bundle.includes(
                                                                            bundles.id
                                                                          ) ? (
                                                                            <em className="icon ni ni-done"></em>
                                                                          ) : null}
                                                                          {"  "}
                                                                          CHOOSE
                                                                          BUNDLE
                                                                        </span>
                                                                      </a>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                      {/** NEW SERVICE BLOCK **/}
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="form-group col-md-12 col-lg-12"
                                                    style={{
                                                      marginTop: "20px",
                                                      paddingLeft: "0px",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    {this.state.bundlesData &&
                                                    this.state.bundlesData
                                                      .length > 0 ? (
                                                      <button
                                                        type="button"
                                                        className="btn btn-lg btn-primary"
                                                        onClick={
                                                          this
                                                            .saveSharelinkBundles
                                                        }
                                                        disabled={
                                                          this.state.disabled
                                                        }
                                                      >
                                                        <em className="icon ni ni-note-add"></em>
                                                        {"  "}Save Bundles
                                                      </button>
                                                    ) : null}
                                                  </div>
                                                </>
                                              ) : null}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tab-pane" id="tab-4">
                                      <div className="row">
                                        <div
                                          className="col-lg-12 col-md-12 col-xl-12 details"
                                          style={{
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <h3>About Me Details</h3>
                                          {this.state.tableLoaderBlockAbout ===
                                          true ? (
                                            tableLoaderSimple()
                                          ) : (
                                            <>
                                              {this.state
                                                .getAboutErrorMessage !== "" ? (
                                                <div
                                                  className="example-alert"
                                                  style={{
                                                    marginBottom: "15px",
                                                    color: "#526484",
                                                  }}
                                                >
                                                  <div className="alert alert-pro alert-danger">
                                                    <div className="alert-text">
                                                      <h4
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        Error
                                                      </h4>
                                                      <p
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        {
                                                          this.state
                                                            .getAboutErrorMessage
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                              {this.state
                                                .getAboutErrorMessage === "" ? (
                                                <div className="card card-bordered">
                                                  <div className="card-inner">
                                                    <FormControlLabel
                                                      style={{
                                                        marginBottom: "0px",
                                                        color: "#526484",
                                                      }}
                                                      control={
                                                        <Checkbox
                                                          checked={
                                                            this.state
                                                              .about_me_settings
                                                          }
                                                          onChange={
                                                            this.handleChange
                                                          }
                                                          name="about_me_settings"
                                                          id="about_me_settings"
                                                          color="primary"
                                                        />
                                                      }
                                                      label="About Me Selection"
                                                    />
                                                    {this.state
                                                      .about_me_settings ? (
                                                      <div
                                                        className="row g-4"
                                                        style={{
                                                          padding: "30px",
                                                        }}
                                                      >
                                                        <div className="col-lg-12">
                                                          <h6
                                                            style={{
                                                              color: "#526484",
                                                            }}
                                                          >
                                                            Banner Description
                                                          </h6>
                                                        </div>
                                                        <div className="col-lg-12">
                                                          {/*<TextareaAutosize
                                                            maxrows={4}
                                                            name="about_me_short"
                                                            id="about_me_short"
                                                            aria-label=""
                                                            placeholder=""
                                                            defaultValue={
                                                              this.state
                                                                .about_me_short
                                                            }
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                            style={{
                                                              width: "100%",
                                                              height: "100px",
                                                              borderColor:
                                                                "rgba(0, 0, 0, 0.125)",
                                                              borderRadius:
                                                                "4px",
                                                              padding: "5px",
                                                            }}
                                                            maxLength={50}
                                                            variant="outlined"
                                                          />*/}
                                                          {this.state
                                                            .isEditorReady ? (
                                                            <>
                                                              <RichTextEditorComponent
                                                                agreementContent={
                                                                  this.state
                                                                    .about_me_short
                                                                }
                                                                onContentChange={
                                                                  this
                                                                    .handleEditorChangeShort
                                                                }
                                                              />
                                                            </>
                                                          ) : null}
                                                        </div>
                                                        <div className="col-lg-12">
                                                          <h6
                                                            style={{
                                                              color: "#526484",
                                                            }}
                                                          >
                                                            About Me
                                                          </h6>
                                                        </div>

                                                        <div className="col-lg-12">
                                                          <div className="form-group">
                                                            {this.state
                                                              .isEditorReady ? (
                                                              <>
                                                                <RichTextEditorComponent
                                                                  agreementContent={
                                                                    this.state
                                                                      .about_me
                                                                  }
                                                                  onContentChange={
                                                                    this
                                                                      .handleEditorChange
                                                                  }
                                                                />
                                                              </>
                                                            ) : null}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : null}
                                                    <div
                                                      className="form-group col-md-12 col-lg-12"
                                                      style={{
                                                        marginTop: "20px",
                                                        paddingLeft: "0px",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      <button
                                                        type="button"
                                                        className="btn btn-lg btn-primary"
                                                        onClick={
                                                          this
                                                            .saveSharelinkAbout
                                                        }
                                                        disabled={
                                                          this.state.disabled
                                                        }
                                                      >
                                                        <em className="icon ni ni-note-add"></em>
                                                        {"  "}Save About Me
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tab-pane" id="tab-5">
                                      <div className="row">
                                        <div
                                          className="col-lg-12 col-md-12 col-xl-12 details"
                                          style={{
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <h3>Social Media Links</h3>
                                          {this.state.tableLoaderBlockSocial ===
                                          true ? (
                                            tableLoaderSimple()
                                          ) : (
                                            <>
                                              {this.state
                                                .getSocialErrorMessage !==
                                              "" ? (
                                                <div
                                                  className="example-alert"
                                                  style={{
                                                    marginBottom: "15px",
                                                    color: "#526484",
                                                  }}
                                                >
                                                  <div className="alert alert-pro alert-danger">
                                                    <div className="alert-text">
                                                      <h4
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        Error
                                                      </h4>
                                                      <p
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        {
                                                          this.state
                                                            .getSocialErrorMessage
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                              {this.state
                                                .getSocialErrorMessage ===
                                              "" ? (
                                                <>
                                                  <div className="card card-bordered">
                                                    <div className="card-inner">
                                                      <FormControlLabel
                                                        style={{
                                                          marginBottom: "0px",
                                                          color: "#526484",
                                                        }}
                                                        control={
                                                          <Checkbox
                                                            checked={
                                                              this.state
                                                                .social_settings
                                                            }
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                            name="social_settings"
                                                            id="social_settings"
                                                            color="primary"
                                                          />
                                                        }
                                                        label="Social Media Links"
                                                      />
                                                      {this.state
                                                        .social_settings ? (
                                                        <div className="row g-4">
                                                          <div className="col-lg-12">
                                                            <FormControl
                                                              fullWidth
                                                              variant="outlined"
                                                            >
                                                              <InputLabel htmlFor="instagram_link">
                                                                Instagram Link
                                                              </InputLabel>
                                                              <OutlinedInput
                                                                id="instagram_link"
                                                                name="instagram_link"
                                                                value={
                                                                  this.state
                                                                    .instagram_link
                                                                }
                                                                onChange={
                                                                  this
                                                                    .handleChange
                                                                }
                                                                startAdornment={
                                                                  <InputAdornment position="start">
                                                                    <em className="icon ni ni-instagram"></em>
                                                                  </InputAdornment>
                                                                }
                                                                labelWidth={110}
                                                              />
                                                              {this.state
                                                                .instagramError && (
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "#e85347",
                                                                  }}
                                                                >
                                                                  {
                                                                    this.state
                                                                      .instagramError
                                                                  }
                                                                </span>
                                                              )}
                                                            </FormControl>
                                                          </div>

                                                          <div className="col-lg-12">
                                                            <FormControl
                                                              fullWidth
                                                              variant="outlined"
                                                            >
                                                              <InputLabel htmlFor="facebook_link">
                                                                Facebook Link
                                                              </InputLabel>
                                                              <OutlinedInput
                                                                id="facebook_link"
                                                                name="facebook_link"
                                                                value={
                                                                  this.state
                                                                    .facebook_link
                                                                }
                                                                onChange={
                                                                  this
                                                                    .handleChange
                                                                }
                                                                startAdornment={
                                                                  <InputAdornment position="start">
                                                                    <em className="icon ni ni-facebook-fill"></em>
                                                                  </InputAdornment>
                                                                }
                                                                labelWidth={110}
                                                              />
                                                              {this.state
                                                                .facebookError && (
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "#e85347",
                                                                  }}
                                                                >
                                                                  {
                                                                    this.state
                                                                      .facebookError
                                                                  }
                                                                </span>
                                                              )}
                                                            </FormControl>
                                                          </div>

                                                          <div className="col-lg-12">
                                                            <FormControl
                                                              fullWidth
                                                              variant="outlined"
                                                            >
                                                              <InputLabel htmlFor="twitter_link">
                                                                Twitter Link
                                                              </InputLabel>
                                                              <OutlinedInput
                                                                id="twitter_link"
                                                                name="twitter_link"
                                                                value={
                                                                  this.state
                                                                    .twitter_link
                                                                }
                                                                onChange={
                                                                  this
                                                                    .handleChange
                                                                }
                                                                startAdornment={
                                                                  <InputAdornment position="start">
                                                                    <em className="icon ni ni-twitter-round"></em>
                                                                  </InputAdornment>
                                                                }
                                                                labelWidth={90}
                                                              />
                                                              {this.state
                                                                .twitterError && (
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "#e85347",
                                                                  }}
                                                                >
                                                                  {
                                                                    this.state
                                                                      .twitterError
                                                                  }
                                                                </span>
                                                              )}
                                                            </FormControl>
                                                          </div>

                                                          <div className="col-lg-12">
                                                            <FormControl
                                                              fullWidth
                                                              variant="outlined"
                                                            >
                                                              <InputLabel htmlFor="linkedin_link">
                                                                Linkedin Link
                                                              </InputLabel>
                                                              <OutlinedInput
                                                                id="linkedin_link"
                                                                name="linkedin_link"
                                                                value={
                                                                  this.state
                                                                    .linkedin_link
                                                                }
                                                                onChange={
                                                                  this
                                                                    .handleChange
                                                                }
                                                                startAdornment={
                                                                  <InputAdornment position="start">
                                                                    <em className="icon ni ni-linkedin-round"></em>
                                                                  </InputAdornment>
                                                                }
                                                                labelWidth={100}
                                                              />
                                                              {this.state
                                                                .linkedinError && (
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "#e85347",
                                                                  }}
                                                                >
                                                                  {
                                                                    this.state
                                                                      .linkedinError
                                                                  }
                                                                </span>
                                                              )}
                                                            </FormControl>
                                                          </div>

                                                          <div className="col-lg-12">
                                                            <FormControl
                                                              fullWidth
                                                              variant="outlined"
                                                            >
                                                              <InputLabel htmlFor="youtube_link">
                                                                Youtube Link
                                                              </InputLabel>
                                                              <OutlinedInput
                                                                id="youtube_link"
                                                                name="youtube_link"
                                                                value={
                                                                  this.state
                                                                    .youtube_link
                                                                }
                                                                onChange={
                                                                  this
                                                                    .handleChange
                                                                }
                                                                startAdornment={
                                                                  <InputAdornment position="start">
                                                                    <em className="icon ni ni-youtube"></em>
                                                                  </InputAdornment>
                                                                }
                                                                labelWidth={110}
                                                              />
                                                              {this.state
                                                                .youtubeError && (
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "#e85347",
                                                                  }}
                                                                >
                                                                  {
                                                                    this.state
                                                                      .youtubeError
                                                                  }
                                                                </span>
                                                              )}
                                                            </FormControl>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="form-group col-md-12 col-lg-12"
                                                    style={{
                                                      marginTop: "20px",
                                                      paddingLeft: "0px",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    <button
                                                      type="button"
                                                      className="btn btn-lg btn-primary"
                                                      onClick={
                                                        this.saveSharelinkSocial
                                                      }
                                                      disabled={
                                                        this.state.disabled
                                                      }
                                                    >
                                                      <em className="icon ni ni-note-add"></em>
                                                      {"  "}Save Social Media
                                                      Links
                                                    </button>
                                                  </div>
                                                </>
                                              ) : null}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tab-pane" id="tab-6">
                                      <div className="row">
                                        <div
                                          className="col-lg-12 col-md-12 col-xl-12 details"
                                          style={{
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <h3>Contact Details</h3>
                                          {this.state
                                            .tableLoaderBlockContact ===
                                          true ? (
                                            tableLoaderSimple()
                                          ) : (
                                            <>
                                              {this.state
                                                .getContactErrorMessage !==
                                              "" ? (
                                                <div
                                                  className="example-alert"
                                                  style={{
                                                    marginBottom: "15px",
                                                    color: "#526484",
                                                  }}
                                                >
                                                  <div className="alert alert-pro alert-danger">
                                                    <div className="alert-text">
                                                      <h4
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        Error
                                                      </h4>
                                                      <p
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        {
                                                          this.state
                                                            .getContactErrorMessage
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                              {this.state
                                                .getContactErrorMessage ===
                                              "" ? (
                                                <>
                                                  <div className="card card-bordered">
                                                    <div className="card-inner">
                                                      <FormControlLabel
                                                        style={{
                                                          marginBottom: "0px",
                                                          color: "#526484",
                                                        }}
                                                        control={
                                                          <Checkbox
                                                            checked={
                                                              this.state
                                                                .contact_settings
                                                            }
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                            name="contact_settings"
                                                            id="contact_settings"
                                                            color="primary"
                                                          />
                                                        }
                                                        label="Contact Settings"
                                                      />
                                                      {this.state
                                                        .contact_settings ? (
                                                        <div className="row g-4">
                                                          <div className="col-lg-12">
                                                            <FormControl
                                                              fullWidth
                                                              variant="outlined"
                                                            >
                                                              <InputLabel htmlFor="contact_email">
                                                                Email Address
                                                              </InputLabel>
                                                              <OutlinedInput
                                                                id="contact_email"
                                                                name="contact_email"
                                                                value={
                                                                  this.state
                                                                    .contact_email
                                                                }
                                                                onChange={
                                                                  this
                                                                    .handleChange
                                                                }
                                                                startAdornment={
                                                                  <InputAdornment position="start">
                                                                    <em className="icon ni ni-emails"></em>
                                                                  </InputAdornment>
                                                                }
                                                                labelWidth={110}
                                                              />
                                                              {this.state
                                                                .emailError && (
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "#e85347",
                                                                  }}
                                                                >
                                                                  {
                                                                    this.state
                                                                      .emailError
                                                                  }
                                                                </span>
                                                              )}
                                                            </FormControl>
                                                          </div>

                                                          <div className="col-lg-12">
                                                            <PhoneInput
                                                              className="customStyle-phone_input-add_sharelink"
                                                              required
                                                              defaultCountry={
                                                                "us"
                                                              }
                                                              error={
                                                                this.state
                                                                  .phoneError
                                                              }
                                                              inputProps={{
                                                                name: "contact_phone",
                                                                id: "contact_phone",
                                                                maxLength: 20,
                                                                className: this
                                                                  .state
                                                                  .phoneError
                                                                  ? "error-border"
                                                                  : "default-border",
                                                              }}
                                                              dialCodePreviewStyleProps={{
                                                                className: this
                                                                  .state
                                                                  .phoneError
                                                                  ? "error-border"
                                                                  : "default-border",
                                                              }}
                                                              value={
                                                                this.state
                                                                  .contact_phone
                                                              }
                                                              onChange={(
                                                                value
                                                              ) =>
                                                                this.handleChange(
                                                                  {
                                                                    target: {
                                                                      name: "contact_phone",
                                                                      value,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                              helperText="Enter the phone number."
                                                              label="Phone Number"
                                                              ref={
                                                                this
                                                                  .phoneInputRef
                                                              }
                                                              onFocus={
                                                                this.handleFocus
                                                              }
                                                            />
                                                          </div>

                                                          <div className="col-lg-12">
                                                            <FormControl
                                                              fullWidth
                                                              variant="outlined"
                                                            >
                                                              <InputLabel htmlFor="contact_address">
                                                                Address
                                                              </InputLabel>
                                                              <OutlinedInput
                                                                id="contact_address"
                                                                name="contact_address"
                                                                value={
                                                                  this.state
                                                                    .contact_address ||
                                                                  ""
                                                                }
                                                                onChange={
                                                                  this
                                                                    .handleChange
                                                                }
                                                                startAdornment={
                                                                  <InputAdornment position="start">
                                                                    <em className="icon ni ni-home"></em>
                                                                  </InputAdornment>
                                                                }
                                                                labelWidth={70}
                                                              />
                                                              {this.state
                                                                .addressError && (
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "#e85347",
                                                                  }}
                                                                >
                                                                  {
                                                                    this.state
                                                                      .addressError
                                                                  }
                                                                </span>
                                                              )}
                                                            </FormControl>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="form-group col-md-12 col-lg-12"
                                                    style={{
                                                      marginTop: "20px",
                                                      paddingLeft: "0px",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    <button
                                                      type="button"
                                                      className="btn btn-lg btn-primary"
                                                      onClick={
                                                        this
                                                          .saveSharelinkContact
                                                      }
                                                      disabled={
                                                        this.state.disabled
                                                      }
                                                    >
                                                      <em className="icon ni ni-note-add"></em>
                                                      {"  "}Save Social Media
                                                      Links
                                                    </button>
                                                  </div>
                                                </>
                                              ) : null}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="tab-pane" id="tab-7">
                                      <div className="row">
                                        <div
                                          className="col-lg-12 col-md-12 col-xl-12 details"
                                          style={{
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <h3>SEO Details</h3>
                                          {this.state.tableLoaderBlockSEO ===
                                          true ? (
                                            tableLoaderSimple()
                                          ) : (
                                            <>
                                              {this.state.getSeoErrorMessage !==
                                              "" ? (
                                                <div
                                                  className="example-alert"
                                                  style={{
                                                    marginBottom: "15px",
                                                    color: "#526484",
                                                  }}
                                                >
                                                  <div className="alert alert-pro alert-danger">
                                                    <div className="alert-text">
                                                      <h4
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        Error
                                                      </h4>
                                                      <p
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        {
                                                          this.state
                                                            .getSeoErrorMessage
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                              {this.state.getSeoErrorMessage ===
                                              "" ? (
                                                <div className="card card-bordered">
                                                  <div className="card-inner">
                                                    <div
                                                      className="row g-4"
                                                      style={{
                                                        padding: "30px",
                                                      }}
                                                    >
                                                      <div className="form-group col-md-12 col-lg-12 col-xxl-12">
                                                        <MutextField
                                                          required
                                                          id="meta_title"
                                                          name="meta_title"
                                                          type="text"
                                                          label="Sharelink SEO Title"
                                                          inputProps={{
                                                            maxLength: 100,
                                                          }}
                                                          value={
                                                            this.state
                                                              .meta_title
                                                          }
                                                          onChange={
                                                            this.handleChange
                                                          }
                                                          helperText="Enter the SEO title here."
                                                          variant="outlined"
                                                          fullWidth
                                                        />
                                                      </div>
                                                      <div className="col-lg-12">
                                                        <h6
                                                          style={{
                                                            color: "#526484",
                                                          }}
                                                        >
                                                          Meta Description
                                                        </h6>
                                                      </div>
                                                      <div className="col-lg-12">
                                                        <TextareaAutosize
                                                          maxrows={4}
                                                          name="meta_description"
                                                          id="meta_description"
                                                          aria-label=""
                                                          placeholder=""
                                                          value={
                                                            this.state
                                                              .meta_description
                                                          }
                                                          onChange={
                                                            this.handleChange
                                                          }
                                                          style={{
                                                            width: "100%",
                                                            height: "100px",
                                                            borderColor:
                                                              "rgba(0, 0, 0, 0.125)",
                                                            borderRadius: "4px",
                                                            padding: "15px",
                                                          }}
                                                          variant="outlined"
                                                        />
                                                      </div>
                                                      <div
                                                        className="form-group col-md-12 col-lg-12"
                                                        style={{
                                                          marginTop: "20px",
                                                          paddingLeft: "0px",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        <button
                                                          type="button"
                                                          className="btn btn-lg btn-primary"
                                                          onClick={
                                                            this
                                                              .saveSharelinkSEO
                                                          }
                                                          disabled={
                                                            this.state.disabled
                                                          }
                                                        >
                                                          <em className="icon ni ni-note-add"></em>
                                                          {"  "}Save SEO Details
                                                          Links
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="tab-pane" id="tab-8">
                                      <div className="row">
                                        <div
                                          className="col-lg-12 details"
                                          style={{ paddingLeft: "0px" }}
                                        >
                                          <div className="col-md-12 col-lg-12 col-xxl-12 col-sm-12 pl-0">
                                            <div className="row g-gs">
                                              <div className="col-md-6 col-lg-6 col-xxl-6 col-sm-12">
                                                <h3>Update Image Library</h3>
                                              </div>
                                              <div className="col-md-6 col-lg-6 col-xxl-6 col-sm-12">
                                                <a
                                                  onClick={() =>
                                                    this.getImageUpload()
                                                  }
                                                  class="btn btn-dim btn-outline-primary text-right"
                                                  style={{
                                                    cursor: "pointer",
                                                    float: "right",
                                                  }}
                                                >
                                                  <em className="icon ni ni-upload"></em>{" "}
                                                  Upload Logo / Avatar / Banner
                                                </a>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-md-12 pl-0">
                                            {this.state.showImageLoader ===
                                            true ? (
                                              <div class="card card-bordered mb-4">
                                                <div class="card-inner">
                                                  {this.state.formLoader ===
                                                  true ? (
                                                    FormLoader()
                                                  ) : (
                                                    <div className="row g-4">
                                                      <div className="col-md-12 pl-0">
                                                        <h5
                                                          style={{
                                                            color: "#526484",
                                                          }}
                                                        >
                                                          Upload New Image
                                                        </h5>
                                                      </div>
                                                      {this.state
                                                        .docErrorMessage !==
                                                      "" ? (
                                                        <div className="col-md-12 mb-3">
                                                          <div
                                                            className="example-alert example-alert-revoke"
                                                            style={{
                                                              marginBottom:
                                                                "15px",
                                                            }}
                                                          >
                                                            <div className="alert alert-pro alert-danger">
                                                              <div className="alert-text">
                                                                <h4>Error</h4>
                                                                <p>
                                                                  {
                                                                    this.state
                                                                      .docErrorMessage
                                                                  }
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                      {this.state
                                                        .docSuccessMessage !==
                                                      "" ? (
                                                        <div className="col-md-12 mb-3">
                                                          <div
                                                            className="example-alert example-alert-revoke"
                                                            style={{
                                                              marginBottom:
                                                                "15px",
                                                            }}
                                                          >
                                                            <div className="alert alert-pro alert-success">
                                                              <div className="alert-text">
                                                                <h4 className="text-dark">Success</h4>
                                                                <p>
                                                                  {
                                                                    this.state
                                                                      .docSuccessMessage
                                                                  }
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                      <div className="col-md-6">
                                                        <div className="form-group">
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              required
                                                              id="edit_image_title"
                                                              name="edit_image_title"
                                                              type="text"
                                                              label="Title"
                                                              value={
                                                                this.state
                                                                  .edit_image_title
                                                              }
                                                              inputProps={{
                                                                maxLength: 64,
                                                              }}
                                                              onInput={(e) => {
                                                                e.target.value =
                                                                  e.target.value.replace(
                                                                    /[^a-zA-Z0-9 -_]/gi,
                                                                    ""
                                                                  );
                                                              }}
                                                              helperText="Title of the image."
                                                              autoFocus
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-md-6">
                                                        <div className="form-group">
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="edit_images_type"
                                                              name="edit_images_type"
                                                              select
                                                              label="Image Type"
                                                              defaultValue={
                                                                this.state
                                                                  .edit_images_type
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              SelectProps={{
                                                                native: true,
                                                              }}
                                                              helperText=""
                                                              variant="outlined"
                                                              fullWidth
                                                            >
                                                              <option
                                                                key="sets100"
                                                                value="logo"
                                                              >
                                                                Logo
                                                              </option>
                                                              <option
                                                                key="sets101"
                                                                value="image"
                                                              >
                                                                Avatar
                                                              </option>
                                                              <option
                                                                key="sets103"
                                                                value="image2"
                                                              >
                                                                Avatar 2
                                                              </option>
                                                              <option
                                                                key="sets102"
                                                                value="banner"
                                                              >
                                                                {" "}
                                                                Banner{" "}
                                                              </option>
                                                            </MutextField>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-md-12">
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              Upload File
                                                              <sup> *</sup>
                                                            </label>
                                                          </div>
                                                          <div
                                                            className="form-control-group"
                                                            id="fileContainer"
                                                          >
                                                            <input
                                                              type="file"
                                                              id="edit_picture"
                                                              className="file-block"
                                                              name="edit_picture"
                                                              defaultValue={
                                                                this.state
                                                                  .edit_picture
                                                              }
                                                              accept=".png, .jpg, .jpeg"
                                                              onChange={
                                                                this
                                                                  .handleFileChange
                                                              }
                                                              style={{
                                                                color:
                                                                  "#526484",
                                                              }}
                                                              required
                                                            />
                                                            {edit_picture && (
                                                              <div
                                                                style={{
                                                                  margin:
                                                                    "15px 0 0 0",
                                                                }}
                                                              >
                                                                <Cropper
                                                                  src={
                                                                    this.state
                                                                      .edit_picture
                                                                  }
                                                                  style={{
                                                                    height: 400,
                                                                    width:
                                                                      "100%",
                                                                    filter: `brightness(${this.state.brightness}%)`,
                                                                  }}
                                                                  aspectRatio={this.calculateAspectRatio()}
                                                                  guides={true}
                                                                  zoomable={
                                                                    true
                                                                  }
                                                                  crop={
                                                                    this.onCrop
                                                                  }
                                                                  ref={
                                                                    this
                                                                      .cropperRef
                                                                  }
                                                                  rotatable={
                                                                    true
                                                                  }
                                                                  ready={
                                                                    this
                                                                      .handleReady
                                                                  }
                                                                />
                                                                <div
                                                                  style={{
                                                                    margin:
                                                                      "10px 0 10px 0",
                                                                  }}
                                                                >
                                                                  <p id="cropSize">
                                                                    No crop
                                                                    selected
                                                                  </p>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    alignItems:
                                                                      "center",
                                                                    gap: "5px",
                                                                  }}
                                                                >
                                                                  <Tooltip
                                                                    title="Zoom In"
                                                                    placement="top"
                                                                  >
                                                                    <a
                                                                      className="btn btn-icon btn-sm btn-primary"
                                                                      onClick={() =>
                                                                        this.handleZoomIn()
                                                                      }
                                                                    >
                                                                      <em
                                                                        className="icon ni ni-zoom-in"
                                                                        style={{
                                                                          color:
                                                                            "white",
                                                                        }}
                                                                      ></em>
                                                                    </a>
                                                                  </Tooltip>
                                                                  <Tooltip
                                                                    title="Zoom Out"
                                                                    placement="top"
                                                                  >
                                                                    <a
                                                                      className="btn btn-icon btn-sm btn-primary"
                                                                      onClick={() =>
                                                                        this.handleZoomOut()
                                                                      }
                                                                    >
                                                                      <em
                                                                        className="icon ni ni-zoom-out"
                                                                        style={{
                                                                          color:
                                                                            "white",
                                                                        }}
                                                                      ></em>
                                                                    </a>
                                                                  </Tooltip>
                                                                  <Tooltip
                                                                    title="Rotate Left"
                                                                    placement="top"
                                                                  >
                                                                    <a
                                                                      className="btn btn-icon btn-sm btn-primary"
                                                                      onClick={() =>
                                                                        this.rotateImage(
                                                                          -15
                                                                        )
                                                                      }
                                                                    >
                                                                      <em
                                                                        className="icon ni ni-curve-up-left"
                                                                        style={{
                                                                          color:
                                                                            "white",
                                                                        }}
                                                                      ></em>
                                                                    </a>
                                                                  </Tooltip>
                                                                  <Tooltip
                                                                    title="Rotate Right"
                                                                    placement="top"
                                                                  >
                                                                    <a
                                                                      className="btn btn-icon btn-sm btn-primary"
                                                                      onClick={() =>
                                                                        this.rotateImage(
                                                                          15
                                                                        )
                                                                      }
                                                                    >
                                                                      <em
                                                                        className="icon ni ni-curve-up-right"
                                                                        style={{
                                                                          color:
                                                                            "white",
                                                                        }}
                                                                      ></em>
                                                                    </a>
                                                                  </Tooltip>

                                                                  <Tooltip
                                                                    title="Flip Horizontal"
                                                                    placement="top"
                                                                  >
                                                                    <a
                                                                      className="btn btn-icon btn-sm btn-primary"
                                                                      onClick={() =>
                                                                        this.flipHorizontal()
                                                                      }
                                                                    >
                                                                      <em
                                                                        class="icon ni ni-swap"
                                                                        style={{
                                                                          color:
                                                                            "white",
                                                                        }}
                                                                      ></em>{" "}
                                                                    </a>
                                                                  </Tooltip>

                                                                  <Tooltip
                                                                    title="Flip Vertical"
                                                                    placement="top"
                                                                  >
                                                                    <a
                                                                      className="btn btn-icon btn-sm btn-primary"
                                                                      onClick={() =>
                                                                        this.flipVertical()
                                                                      }
                                                                    >
                                                                      <em
                                                                        class="icon ni ni-swap-v"
                                                                        style={{
                                                                          color:
                                                                            "white",
                                                                        }}
                                                                      ></em>{" "}
                                                                    </a>
                                                                  </Tooltip>
                                                                  <Tooltip
                                                                    title="Adjust Brightness"
                                                                    placement="top"
                                                                  >
                                                                    <a
                                                                      className="btn btn-icon btn-sm btn-primary"
                                                                      onClick={
                                                                        this
                                                                          .toggleBrightnessRange
                                                                      }
                                                                    >
                                                                      <em
                                                                        className="icon ni ni-sun-fill"
                                                                        style={{
                                                                          color:
                                                                            "white",
                                                                        }}
                                                                      ></em>
                                                                    </a>
                                                                  </Tooltip>
                                                                  {showBrightnessRange && (
                                                                    <input
                                                                      type="range"
                                                                      min="0"
                                                                      max="200"
                                                                      value={
                                                                        brightness
                                                                      }
                                                                      onChange={
                                                                        this
                                                                          .handleBrightnessChange
                                                                      }
                                                                      style={{
                                                                        width:
                                                                          "15%",
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                    />
                                                                  )}
                                                                  <Tooltip
                                                                    title="Reset Crop"
                                                                    placement="top"
                                                                  >
                                                                    <a
                                                                      className="btn btn-icon btn-sm btn-danger"
                                                                      onClick={() =>
                                                                        this.handleResetCrop(
                                                                          15
                                                                        )
                                                                      }
                                                                    >
                                                                      <em
                                                                        class="icon ni ni-reload-alt"
                                                                        style={{
                                                                          color:
                                                                            "white",
                                                                        }}
                                                                      ></em>
                                                                    </a>
                                                                  </Tooltip>
                                                                </div>
                                                                <div className="col-md-12 mt-3 p-0">
                                                                  <div className="example-alert">
                                                                    <div className="alert alert-light">
                                                                      <ul className="list">
                                                                        <li>
                                                                          Double-click
                                                                          on the
                                                                          image
                                                                          to
                                                                          drag
                                                                          it.
                                                                        </li>
                                                                        <li>
                                                                          Use
                                                                          the{" "}
                                                                          <b>
                                                                            <em class="icon ni ni-zoom-in"></em>
                                                                          </b>{" "}
                                                                          button
                                                                          to
                                                                          zoom
                                                                          in and
                                                                          the{" "}
                                                                          <b>
                                                                            <em class="icon ni ni-zoom-out"></em>
                                                                          </b>{" "}
                                                                          button
                                                                          to
                                                                          zoom
                                                                          out.
                                                                        </li>
                                                                        <li>
                                                                          Use
                                                                          the{" "}
                                                                          <b>
                                                                            <em className="icon ni ni-curve-up-left"></em>
                                                                          </b>{" "}
                                                                          icon
                                                                          to
                                                                          rotate
                                                                          left
                                                                          and
                                                                          the{" "}
                                                                          <b>
                                                                            <em className="icon ni ni-curve-up-right"></em>{" "}
                                                                          </b>
                                                                          icon
                                                                          to
                                                                          rotate
                                                                          right.
                                                                        </li>
                                                                        <li>
                                                                          Use
                                                                          the{" "}
                                                                          <b>
                                                                            <em className="icon ni ni-swap-v"></em>
                                                                          </b>{" "}
                                                                          icon
                                                                          to
                                                                          flip
                                                                          vertical
                                                                          and
                                                                          the{" "}
                                                                          <b>
                                                                            <em className="icon ni ni-swap"></em>{" "}
                                                                          </b>
                                                                          icon
                                                                          to
                                                                          flip
                                                                          horizontal.
                                                                        </li>
                                                                        <li>
                                                                          Use
                                                                          the{" "}
                                                                          <b>
                                                                            <em class="icon ni ni-reload-alt"></em>
                                                                          </b>{" "}
                                                                          button
                                                                          to
                                                                          reset
                                                                          the
                                                                          image
                                                                          and
                                                                          crop
                                                                          box.
                                                                        </li>
                                                                      </ul>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-md-12 d-flex justify-content-end">
                                                        <button
                                                          type="button"
                                                          onClick={() =>
                                                            this.setState({
                                                              showImageLoader: false,
                                                            })
                                                          }
                                                          className="btn btn-light mr-2"
                                                        >
                                                          <em className="icon ni ni-na"></em>
                                                          <span>Cancel</span>{" "}
                                                        </button>
                                                        <button
                                                          type="button"
                                                          onClick={() =>
                                                            this.saveNewImage()
                                                          }
                                                          className="btn btn-primary"
                                                          disabled={
                                                            this.state.disabled
                                                          }
                                                        >
                                                          <em className="icon ni ni-emails"></em>
                                                          <span>Submit</span>{" "}
                                                        </button>
                                                      </div>
                                                      <div className="col-md-12">
                                                        <div className="example-alert">
                                                          <div className="alert alert-light">
                                                            <ul className="list">
                                                              <li>
                                                                Accepted
                                                                Formats: PNG,
                                                                JPEG, JPG
                                                              </li>
                                                              <li>
                                                                Recommended
                                                                Size: 5MB
                                                              </li>
                                                              <li>
                                                                Recommended
                                                                Dimensions:
                                                                <b>
                                                                  {" "}
                                                                  Logo (W:325 x
                                                                  H:130) -
                                                                  Avatar (W:1400
                                                                  x H:980) -
                                                                  Avatar2 (W:549
                                                                  x H:611) -
                                                                  Banner (W:1640
                                                                  x H:560)
                                                                </b>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>

                                          <div
                                            className="col-md-12 col-lg-12"
                                            style={{ paddingLeft: "0px" }}
                                          >
                                            {this.state.getLogoErrorMessage !==
                                            "" ? (
                                              <div
                                                className="example-alert"
                                                style={{
                                                  marginBottom: "15px",
                                                  color: "#526484",
                                                }}
                                              >
                                                <div className="alert alert-pro alert-danger">
                                                  <div className="alert-text">
                                                    <h4
                                                      style={{
                                                        color: "#364a63",
                                                      }}
                                                    >
                                                      Error
                                                    </h4>
                                                    <p
                                                      style={{
                                                        color: "#364a63",
                                                      }}
                                                    >
                                                      {
                                                        this.state
                                                          .getLogoErrorMessage
                                                      }
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                            {this.state.tableLoaderBlockLogo ===
                                            true ? (
                                              tableLoaderBlockImage()
                                            ) : (
                                              <>
                                                {this.state.logoData &&
                                                this.state.logoData.length >
                                                  0 ? (
                                                  <div className="card card-bordered">
                                                    <div className="card-inner">
                                                      <div
                                                        className="row g-4"
                                                        style={{
                                                          padding: "0px",
                                                        }}
                                                      >
                                                        <div className="col-md-12 col-lg-12 col-xxl-12 col-sm-12">
                                                          <h5
                                                            style={{
                                                              marginBottom:
                                                                "10px",
                                                              color: "#526484",
                                                            }}
                                                          >
                                                            Select Logo
                                                          </h5>
                                                        </div>
                                                        {this.state.logoData
                                                          .slice(
                                                            (this.state
                                                              .currentPageLogo -
                                                              1) *
                                                              this.state
                                                                .avatarsPerPage,
                                                            this.state
                                                              .currentPageLogo *
                                                              this.state
                                                                .avatarsPerPage
                                                          )
                                                          .map((image) => (
                                                            <div
                                                              className="col-md-4 col-lg-4 col-xxl-4 col-sm-6"
                                                              style={{
                                                                float: "left",
                                                              }}
                                                              key={`logo${image.id}`}
                                                            >
                                                              <div
                                                                className={this.setLogoClassMain(
                                                                  image.id
                                                                )}
                                                              >
                                                                <div
                                                                  className="pricingTable-header"
                                                                  style={{
                                                                    padding:
                                                                      "15px",
                                                                      minHeight: "110px"
                                                                  }}
                                                                >
                                                                  <img
                                                                    style={{
                                                                      verticalAlign:
                                                                        "middle",
                                                                      display:
                                                                        "inline-block",
                                                                      height:
                                                                        "100%",
                                                                    }}
                                                                    src={`${APP_LIVE_URL}files_data/sharelinks/${image.picture}`}
                                                                    alt={`Logo ${image.id}`}
                                                                  />
                                                                  
                                                                </div>
                                                                <p class="text-dark">{image.image_title}</p>
                                                                <a
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    paddingLeft:
                                                                      "15px",
                                                                    paddingRight:
                                                                      "15px",
                                                                  }}
                                                                  className={this.setLogoClass(
                                                                    image.id
                                                                  )}
                                                                  onClick={() =>
                                                                    this.setLogo(
                                                                      image.id
                                                                    )
                                                                  }
                                                                >
                                                                  {this.state
                                                                    .selected_logo ===
                                                                  image.id ? (
                                                                    <em className="icon ni ni-done"></em>
                                                                  ) : null}{" "}
                                                                  Choose Logo
                                                                  <i className="fa fa-angle-right"></i>
                                                                </a>
                                                              </div>
                                                            </div>
                                                          ))}
                                                        {this.state.logoData
                                                          .length >
                                                        this.state
                                                          .avatarsPerPage ? (
                                                          <div
                                                            className="col-md-12 text-center mt-4"
                                                            style={{
                                                              color: "black",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  this.state
                                                                    .currentPageLogo ===
                                                                  1
                                                                    ? "not-allowed"
                                                                    : "pointer",
                                                              }}
                                                              onClick={() =>
                                                                this.handlePageChange(
                                                                  "logo",
                                                                  "prev"
                                                                )
                                                              }
                                                              disabled={
                                                                this.state
                                                                  .currentPageLogo ===
                                                                1
                                                              }
                                                            >
                                                              <em
                                                                className="icon ni ni-back-ios"
                                                                style={{
                                                                  fontSize:
                                                                    "24px",
                                                                  marginRight:
                                                                    "20px",
                                                                }}
                                                              ></em>
                                                            </span>
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  this.state
                                                                    .currentPageLogo ===
                                                                  Math.ceil(
                                                                    this.state
                                                                      .logoData
                                                                      .length /
                                                                      this.state
                                                                        .avatarsPerPage
                                                                  )
                                                                    ? "not-allowed"
                                                                    : "pointer",
                                                              }}
                                                              onClick={() =>
                                                                this.handlePageChange(
                                                                  "logo",
                                                                  "next"
                                                                )
                                                              }
                                                              disabled={
                                                                this.state
                                                                  .currentPageLogo ===
                                                                Math.ceil(
                                                                  this.state
                                                                    .logoData
                                                                    .length /
                                                                    this.state
                                                                      .avatarsPerPage
                                                                )
                                                              }
                                                            >
                                                              <em
                                                                className="icon ni ni-forward-ios"
                                                                style={{
                                                                  fontSize:
                                                                    "24px",
                                                                  marginLeft:
                                                                    "20px",
                                                                }}
                                                              ></em>
                                                            </span>
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : null}

                                                {this.state.avatarData &&
                                                this.state.avatarData.length >
                                                  0 ? (
                                                  <div className="card card-bordered">
                                                    <div className="card-inner">
                                                      <div
                                                        className="row g-4"
                                                        style={{
                                                          padding: "0px",
                                                        }}
                                                      >
                                                        <div className="col-md-12 col-lg-12 col-xxl-12 col-sm-12">
                                                          <h5
                                                            style={{
                                                              marginBottom:
                                                                "10px",
                                                              color: "#526484",
                                                            }}
                                                          >
                                                            Select Avatar
                                                          </h5>
                                                        </div>
                                                        {this.state.avatarData
                                                          .slice(
                                                            (this.state
                                                              .currentPageAvatar -
                                                              1) *
                                                              this.state
                                                                .avatarsPerPage,
                                                            this.state
                                                              .currentPageAvatar *
                                                              this.state
                                                                .avatarsPerPage
                                                          )
                                                          .map((avatar) => (
                                                            <div
                                                              className="col-md-4 col-lg-4 col-xxl-4 col-sm-6"
                                                              style={{
                                                                float: "left",
                                                              }}
                                                              key={`avatar${avatar.id}`}
                                                            >
                                                              <div
                                                                className={this.setAvatarClassMain(
                                                                  avatar.id
                                                                )}
                                                              >
                                                                <div
                                                                  className="pricingTable-header"
                                                                  style={{
                                                                    minHeight:
                                                                      "150px",
                                                                    padding:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <img
                                                                    style={{
                                                                      height:
                                                                        "150px",
                                                                    }}
                                                                    src={`${APP_LIVE_URL}files_data/sharelinks/${avatar.picture}`}
                                                                    alt={`Avatar ${avatar.id}`}
                                                                  />
                                                                </div>
                                                                <p class="text-dark">{avatar.image_title}</p>
                                                                <a
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    paddingLeft:
                                                                      "15px",
                                                                    paddingRight:
                                                                      "15px",
                                                                  }}
                                                                  className={this.setAvatarClass(
                                                                    avatar.id
                                                                  )}
                                                                  onClick={() =>
                                                                    this.setAvatar(
                                                                      avatar.id
                                                                    )
                                                                  }
                                                                >
                                                                  {this.state
                                                                    .selected_avatar ===
                                                                  avatar.id ? (
                                                                    <em className="icon ni ni-done"></em>
                                                                  ) : null}
                                                                  Choose Avatar{" "}
                                                                  <i className="fa fa-angle-right"></i>
                                                                </a>
                                                              </div>
                                                            </div>
                                                          ))}
                                                        {this.state.avatarData
                                                          .length >
                                                        this.state
                                                          .avatarsPerPage ? (
                                                          <div
                                                            className="col-md-12 text-center mt-4"
                                                            style={{
                                                              color: "black",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  this.state
                                                                    .currentPageAvatar ===
                                                                  1
                                                                    ? "not-allowed"
                                                                    : "pointer",
                                                              }}
                                                              onClick={() =>
                                                                this.handlePageChange(
                                                                  "avatar",
                                                                  "prev"
                                                                )
                                                              }
                                                              disabled={
                                                                this.state
                                                                  .currentPageAvatar ===
                                                                1
                                                              }
                                                            >
                                                              <em
                                                                className="icon ni ni-back-ios"
                                                                style={{
                                                                  fontSize:
                                                                    "24px",
                                                                  marginRight:
                                                                    "20px",
                                                                }}
                                                              ></em>
                                                            </span>
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  this.state
                                                                    .currentPageAvatar ===
                                                                  Math.ceil(
                                                                    this.state
                                                                      .avatarData
                                                                      .length /
                                                                      this.state
                                                                        .avatarsPerPage
                                                                  )
                                                                    ? "not-allowed"
                                                                    : "pointer",
                                                              }}
                                                              onClick={() =>
                                                                this.handlePageChange(
                                                                  "avatar",
                                                                  "next"
                                                                )
                                                              }
                                                              disabled={
                                                                this.state
                                                                  .currentPageAvatar ===
                                                                Math.ceil(
                                                                  this.state
                                                                    .avatarData
                                                                    .length /
                                                                    this.state
                                                                      .avatarsPerPage
                                                                )
                                                              }
                                                            >
                                                              <em
                                                                className="icon ni ni-forward-ios"
                                                                style={{
                                                                  fontSize:
                                                                    "24px",
                                                                  marginLeft:
                                                                    "20px",
                                                                }}
                                                              ></em>
                                                            </span>
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : null}

                                                {this.state.avatarData2 &&
                                                this.state.avatarData2.length >
                                                  0 ? (
                                                  <div className="card card-bordered">
                                                    <div className="card-inner">
                                                      <div
                                                        className="row g-4"
                                                        style={{
                                                          padding: "0px",
                                                        }}
                                                      >
                                                        <div className="col-md-12 col-lg-12 col-xxl-12 col-sm-12">
                                                          <h5
                                                            style={{
                                                              marginBottom:
                                                                "10px",
                                                              color: "#526484",
                                                            }}
                                                          >
                                                            Select Avatar 2
                                                          </h5>
                                                        </div>
                                                        {this.state.avatarData2
                                                          .slice(
                                                            (this.state
                                                              .currentPageAvatar2 -
                                                              1) *
                                                              this.state
                                                                .avatarsPerPage,
                                                            this.state
                                                              .currentPageAvatar2 *
                                                              this.state
                                                                .avatarsPerPage
                                                          )
                                                          .map((avatar) => (
                                                            <div
                                                              className="col-md-4 col-lg-4 col-xxl-4 col-sm-6"
                                                              style={{
                                                                float: "left",
                                                              }}
                                                              key={`avatar2${avatar.id}`}
                                                            >
                                                              <div
                                                                className={this.setAvatar2ClassMain(
                                                                  avatar.id
                                                                )}
                                                              >
                                                                <div
                                                                  className="pricingTable-header"
                                                                  style={{
                                                                    minHeight:
                                                                      "150px",
                                                                    padding:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <img
                                                                    style={{
                                                                      height:
                                                                        "150px",
                                                                    }}
                                                                    src={`${APP_LIVE_URL}files_data/sharelinks/${avatar.picture}`}
                                                                    alt={`Avatar 2 ${avatar.id}`}
                                                                  />
                                                                </div>
                                                                <p class="text-dark">{avatar.image_title}</p>
                                                                <a
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    paddingLeft:
                                                                      "15px",
                                                                    paddingRight:
                                                                      "15px",
                                                                  }}
                                                                  className={this.setAvatar2Class(
                                                                    avatar.id
                                                                  )}
                                                                  onClick={() =>
                                                                    this.setAvatar2(
                                                                      avatar.id
                                                                    )
                                                                  }
                                                                >
                                                                  {this.state
                                                                    .selected_avatar2 ===
                                                                  avatar.id ? (
                                                                    <em className="icon ni ni-done"></em>
                                                                  ) : null}
                                                                  Choose Avatar
                                                                  <i className="fa fa-angle-right"></i>
                                                                </a>
                                                              </div>
                                                            </div>
                                                          ))}
                                                        {this.state.avatarData2
                                                          .length >
                                                        this.state
                                                          .avatarsPerPage ? (
                                                          <div
                                                            className="col-md-12 text-center mt-4"
                                                            style={{
                                                              color: "black",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  this.state
                                                                    .currentPageAvatar2 ===
                                                                  1
                                                                    ? "not-allowed"
                                                                    : "pointer",
                                                              }}
                                                              onClick={() =>
                                                                this.handlePageChange(
                                                                  "avatar2",
                                                                  "prev"
                                                                )
                                                              }
                                                              disabled={
                                                                this.state
                                                                  .currentPageAvatar2 ===
                                                                1
                                                              }
                                                            >
                                                              <em
                                                                className="icon ni ni-back-ios"
                                                                style={{
                                                                  fontSize:
                                                                    "24px",
                                                                  marginRight:
                                                                    "20px",
                                                                }}
                                                              ></em>
                                                            </span>
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  this.state
                                                                    .currentPageAvatar2 ===
                                                                  Math.ceil(
                                                                    this.state
                                                                      .avatarData2
                                                                      .length /
                                                                      this.state
                                                                        .avatarsPerPage
                                                                  )
                                                                    ? "not-allowed"
                                                                    : "pointer",
                                                              }}
                                                              onClick={() =>
                                                                this.handlePageChange(
                                                                  "avatar2",
                                                                  "next"
                                                                )
                                                              }
                                                              disabled={
                                                                this.state
                                                                  .currentPageAvatar2 ===
                                                                Math.ceil(
                                                                  this.state
                                                                    .avatarData2
                                                                    .length /
                                                                    this.state
                                                                      .avatarsPerPage
                                                                )
                                                              }
                                                            >
                                                              <em
                                                                className="icon ni ni-forward-ios"
                                                                style={{
                                                                  fontSize:
                                                                    "24px",
                                                                  marginLeft:
                                                                    "20px",
                                                                }}
                                                              ></em>
                                                            </span>
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : null}

                                                {this.state.bannerData &&
                                                this.state.bannerData.length >
                                                  0 ? (
                                                  <div className="card card-bordered">
                                                    <div className="card-inner">
                                                      <div
                                                        className="row g-4"
                                                        style={{
                                                          padding: "0px",
                                                        }}
                                                      >
                                                        <div className="col-md-12 col-lg-12 col-xxl-12 col-sm-12">
                                                          <h5
                                                            style={{
                                                              marginBottom:
                                                                "10px",
                                                              color: "#526484",
                                                            }}
                                                          >
                                                            Select Banner
                                                          </h5>
                                                        </div>
                                                        {this.state.bannerData
                                                          .slice(
                                                            (this.state
                                                              .currentPageBanner -
                                                              1) *
                                                              this.state
                                                                .avatarsPerPage,
                                                            this.state
                                                              .currentPageBanner *
                                                              this.state
                                                                .avatarsPerPage
                                                          )
                                                          .map((banner) => (
                                                            <div
                                                              className="col-md-4 col-lg-4 col-xxl-4 col-sm-6"
                                                              style={{
                                                                float: "left",
                                                              }}
                                                              key={`banner${banner.id}`}
                                                            >
                                                              <div
                                                                className={this.setBannerClassMain(
                                                                  banner.id
                                                                )}
                                                              >
                                                                <div
                                                                  className="pricingTable-header"
                                                                  style={{
                                                                    minHeight:
                                                                      "150px",
                                                                    padding:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <img
                                                                    style={{
                                                                      height:
                                                                        "150px",
                                                                    }}
                                                                    src={`${APP_LIVE_URL}files_data/sharelinks/${banner.picture}`}
                                                                    alt={`Banner ${banner.id}`}
                                                                  />
                                                                </div>
                                                                <p class="text-dark">{banner.image_title}</p>
                                                                <a
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    paddingLeft:
                                                                      "15px",
                                                                    paddingRight:
                                                                      "15px",
                                                                  }}
                                                                  className={this.setBannerClass(
                                                                    banner.id
                                                                  )}
                                                                  onClick={() =>
                                                                    this.setBanner(
                                                                      banner.id
                                                                    )
                                                                  }
                                                                >
                                                                  {this.state
                                                                    .selected_banner ===
                                                                  banner.id ? (
                                                                    <em className="icon ni ni-done"></em>
                                                                  ) : null}
                                                                  Choose Banner
                                                                  <i className="fa fa-angle-right"></i>
                                                                </a>
                                                              </div>
                                                            </div>
                                                          ))}
                                                        {this.state.bannerData
                                                          .length >
                                                        this.state
                                                          .avatarsPerPage ? (
                                                          <div
                                                            className="col-md-12 text-center mt-4"
                                                            style={{
                                                              color: "black",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  this.state
                                                                    .currentPageBanner ===
                                                                  1
                                                                    ? "not-allowed"
                                                                    : "pointer",
                                                              }}
                                                              onClick={() =>
                                                                this.handlePageChange(
                                                                  "banner",
                                                                  "prev"
                                                                )
                                                              }
                                                              disabled={
                                                                this.state
                                                                  .currentPageBanner ===
                                                                1
                                                              }
                                                            >
                                                              <em
                                                                className="icon ni ni-back-ios"
                                                                style={{
                                                                  fontSize:
                                                                    "24px",
                                                                  marginRight:
                                                                    "20px",
                                                                }}
                                                              ></em>
                                                            </span>
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  this.state
                                                                    .currentPageBanner ===
                                                                  Math.ceil(
                                                                    this.state
                                                                      .bannerData
                                                                      .length /
                                                                      this.state
                                                                        .avatarsPerPage
                                                                  )
                                                                    ? "not-allowed"
                                                                    : "pointer",
                                                              }}
                                                              onClick={() =>
                                                                this.handlePageChange(
                                                                  "banner",
                                                                  "next"
                                                                )
                                                              }
                                                              disabled={
                                                                this.state
                                                                  .currentPageBanner ===
                                                                Math.ceil(
                                                                  this.state
                                                                    .bannerData
                                                                    .length /
                                                                    this.state
                                                                      .avatarsPerPage
                                                                )
                                                              }
                                                            >
                                                              <em
                                                                className="icon ni ni-forward-ios"
                                                                style={{
                                                                  fontSize:
                                                                    "24px",
                                                                  marginLeft:
                                                                    "20px",
                                                                }}
                                                              ></em>
                                                            </span>
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : null}
                                                <div
                                                  className="form-group col-md-12 col-lg-12"
                                                  style={{
                                                    marginTop: "20px",
                                                    paddingLeft: "0px",
                                                    textAlign: "right",
                                                  }}
                                                >
                                                  {this.state.logoData &&
                                                  this.state.logoData.length >
                                                    0 &&
                                                  this.state.avatarData &&
                                                  this.state.avatarData.length >
                                                    0 &&
                                                  this.state.bannerData &&
                                                  this.state.bannerData.length >
                                                    0 ? (
                                                    <button
                                                      type="button"
                                                      className="btn btn-lg btn-primary"
                                                      onClick={
                                                        this.saveSharelinkLogos
                                                      }
                                                      disabled={
                                                        this.state.disabled
                                                      }
                                                    >
                                                      <em className="icon ni ni-note-add"></em>
                                                      {"  "}Save Images
                                                    </button>
                                                  ) : null}
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tab-pane" id="tab-9">
                                      <div className="row">
                                        <div
                                          className="col-lg-12 col-md-12 col-xl-12 details"
                                          style={{
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <h3>Other Settings</h3>
                                          {this.state.tableLoaderBlockSEO ===
                                          true ? (
                                            tableLoaderSimple()
                                          ) : (
                                            <>
                                              {this.state.getSeoErrorMessage !==
                                              "" ? (
                                                <div
                                                  className="example-alert"
                                                  style={{
                                                    marginBottom: "15px",
                                                    color: "#526484",
                                                  }}
                                                >
                                                  <div className="alert alert-pro alert-danger">
                                                    <div className="alert-text">
                                                      <h4
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        Error
                                                      </h4>
                                                      <p
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        {
                                                          this.state
                                                            .getSeoErrorMessage
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                              {this.state.getSeoErrorMessage ===
                                              "" ? (
                                                <div className="card card-bordered">
                                                  <div className="card-inner">
                                                    <div className="row g-4">
                                                      <div className="col-lg-12">
                                                        <pre
                                                          style={{
                                                            background:
                                                              "#f5f5f5",
                                                            padding: "10px",
                                                            borderRadius: "4px",
                                                            whiteSpace:
                                                              "pre-wrap",
                                                          }}
                                                        >
                                                          {"{"}
                                                          {Object.keys(
                                                            this.state
                                                              .other_settings
                                                          ).map(
                                                            (
                                                              key,
                                                              index,
                                                              arr
                                                            ) => {
                                                              const value =
                                                                this.state
                                                                  .other_settings[
                                                                  key
                                                                ];

                                                              if (
                                                                typeof value ===
                                                                  "object" &&
                                                                value !== null
                                                              ) {
                                                                return (
                                                                  <div
                                                                    key={key}
                                                                    style={{
                                                                      marginLeft:
                                                                        "20px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      "{key}":{" "}
                                                                      {"{"}
                                                                    </span>
                                                                    {Object.keys(
                                                                      value
                                                                    ).map(
                                                                      (
                                                                        subKey,
                                                                        subIndex,
                                                                        subArr
                                                                      ) => (
                                                                        <div
                                                                          key={`${key}.${subKey}`}
                                                                          style={{
                                                                            marginBottom:
                                                                              "10px",
                                                                            display:
                                                                              "flex",
                                                                            alignItems:
                                                                              "center",
                                                                            marginLeft:
                                                                              "20px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              marginRight:
                                                                                "5px",
                                                                            }}
                                                                          >
                                                                            "
                                                                            {subKey.toUpperCase()}
                                                                            ": "
                                                                          </span>
                                                                          <MutextField
                                                                            required
                                                                            name={`${key}.${subKey}`}
                                                                            id={`${key}.${subKey}`}
                                                                            type="text"
                                                                            inputProps={{
                                                                              maxLength: 100,
                                                                            }}
                                                                            value={
                                                                              this
                                                                                .state
                                                                                .other_settings[
                                                                                key
                                                                              ][
                                                                                subKey
                                                                              ]
                                                                            }
                                                                            onChange={
                                                                              this
                                                                                .handleChangeOtherSettings
                                                                            }
                                                                            style={{
                                                                              flexGrow: 1,
                                                                            }}
                                                                          />
                                                                          <span>
                                                                            {subIndex <
                                                                            subArr.length -
                                                                              1
                                                                              ? ","
                                                                              : ""}
                                                                          </span>
                                                                        </div>
                                                                      )
                                                                    )}
                                                                    <span
                                                                      style={{
                                                                        marginLeft:
                                                                          "20px",
                                                                      }}
                                                                    >
                                                                      {"}"}
                                                                    </span>
                                                                    {index <
                                                                      arr.length -
                                                                        1 && (
                                                                      <span>
                                                                        ,
                                                                      </span>
                                                                    )}
                                                                  </div>
                                                                );
                                                              } else {
                                                                return (
                                                                  <div
                                                                    key={key}
                                                                    style={{
                                                                      marginBottom:
                                                                        "10px",
                                                                      display:
                                                                        "flex",
                                                                      alignItems:
                                                                        "center",
                                                                      marginLeft:
                                                                        "20px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        marginRight:
                                                                          "5px",
                                                                      }}
                                                                    >
                                                                      "{key}": "
                                                                    </span>
                                                                    <MutextField
                                                                      required
                                                                      name={key}
                                                                      id={key}
                                                                      type="text"
                                                                      inputProps={{
                                                                        maxLength: 100,
                                                                      }}
                                                                      value={
                                                                        value
                                                                      }
                                                                      onChange={
                                                                        this
                                                                          .handleChangeOtherSettings
                                                                      }
                                                                      style={{
                                                                        flexGrow: 1,
                                                                      }}
                                                                    />
                                                                    <span>
                                                                      {index <
                                                                      arr.length -
                                                                        1
                                                                        ? ","
                                                                        : ""}
                                                                    </span>
                                                                  </div>
                                                                );
                                                              }
                                                            }
                                                          )}
                                                          {"}"}
                                                        </pre>
                                                      </div>

                                                      <div
                                                        className="form-group col-md-12 col-lg-12"
                                                        style={{
                                                          marginTop: "20px",
                                                          paddingLeft: "0px",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        <button
                                                          type="button"
                                                          className="btn btn-lg btn-primary"
                                                          onClick={
                                                            this
                                                              .saveSharelinkOtherSettings
                                                          }
                                                          disabled={
                                                            this.state.disabled
                                                          }
                                                        >
                                                          <em className="icon ni ni-note-add"></em>
                                                          {"  "}Save Settings
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="tab-pane" id="tab-10">
                                      <div className="row">
                                        <div
                                          className="col-lg-12 col-md-12 col-xl-12 details"
                                          style={{
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <h3>Testimonials</h3>
                                          {this.state.tableLoaderBlockSEO ===
                                          true ? (
                                            tableLoaderSimple()
                                          ) : (
                                            <>
                                              {this.state.getSeoErrorMessage !==
                                              "" ? (
                                                <div
                                                  className="example-alert"
                                                  style={{
                                                    marginBottom: "15px",
                                                    color: "#526484",
                                                  }}
                                                >
                                                  <div className="alert alert-pro alert-danger">
                                                    <div className="alert-text">
                                                      <h4
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        Error
                                                      </h4>
                                                      <p
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        {
                                                          this.state
                                                            .getSeoErrorMessage
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                              {this.state.getSeoErrorMessage ===
                                              "" ? (
                                                <div className="card card-bordered">
                                                  <div className="card-inner">
                                                    <div className="row g-4">
                                                      <div className="col-lg-12">
                                                        {!this.state
                                                          .isAddingTestimonial && (
                                                          <div className="col-lg-12 text-right">
                                                            <button
                                                              type="button"
                                                              className="btn btn-primary"
                                                              onClick={
                                                                this
                                                                  .addTestimonial
                                                              }
                                                            >
                                                              Add Testimonial
                                                            </button>
                                                          </div>
                                                        )}
                                                        {this.state
                                                          .isAddingTestimonial && (
                                                          <div className="testimonial-form">
                                                            <div className="form-group col-md-12 col-lg-12 col-xxl-12">
                                                              <MutextField
                                                                id="testimonial_name"
                                                                name="testimonial_name"
                                                                type="text"
                                                                label="Name"
                                                                inputProps={{
                                                                  maxLength: 100,
                                                                }}
                                                                value={
                                                                  this.state
                                                                    .testimonial_name
                                                                }
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      testimonial_name:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                                helperText="Enter the name here"
                                                                variant="outlined"
                                                                fullWidth
                                                                maxLength={100}
                                                                error={
                                                                  !!this.state
                                                                    .testimonialErrors
                                                                    .nameError
                                                                }
                                                              />
                                                            </div>
                                                            <div className="form-group col-md-12 col-lg-12 col-xxl-12">
                                                              <MutextField
                                                                id="testimonial_title"
                                                                name="testimonial_title"
                                                                type="text"
                                                                label="Title"
                                                                inputProps={{
                                                                  maxLength: 100,
                                                                }}
                                                                value={
                                                                  this.state
                                                                    .testimonial_title
                                                                }
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      testimonial_title:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                                helperText="Enter the title here"
                                                                variant="outlined"
                                                                fullWidth
                                                                maxLength={50}
                                                                error={
                                                                  !!this.state
                                                                    .testimonialErrors
                                                                    .titleError
                                                                }
                                                              />
                                                            </div>
                                                            <div className="col-lg-12">
                                                              <h6 style={{color: "black"}}>Comment</h6>
                                                            </div>
                                                            <div className="col-lg-12">
                                                              <TextareaAutosize
                                                                maxRows={4}
                                                                name="testimonial_comment"
                                                                id="testimonial_comment"
                                                                value={
                                                                  this.state
                                                                    .testimonial_comment
                                                                }
                                                                onChange={(e) =>
                                                                  this.setState(
                                                                    {
                                                                      testimonial_comment:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                                style={{
                                                                  width: "100%",
                                                                  height:
                                                                    "100px",
                                                                  borderColor:
                                                                    "rgba(0, 0, 0, 0.125)",
                                                                  borderRadius:
                                                                    "4px",
                                                                  padding:
                                                                    "15px",
                                                                }}
                                                                error={
                                                                  !!this.state
                                                                    .testimonialErrors
                                                                    .commentError
                                                                }
                                                                maxLength={200}
                                                              />
                                                              {this.state
                                                                .testimonialErrors
                                                                .commentError && (
                                                                <div
                                                                  style={{
                                                                    color:
                                                                      "red",
                                                                  }}
                                                                >
                                                                  {
                                                                    this.state
                                                                      .testimonialErrors
                                                                      .commentError
                                                                  }
                                                                </div>
                                                              )}
                                                            </div>
                                                            <div className="form-group col-md-12 col-lg-12 col-xxl-12 d-flex justify-content-end mt-1 mb-2">
                                                              <input
                                                                id="testimonial_image"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={
                                                                  this
                                                                    .handleTestimonialImageChange
                                                                }
                                                                style={{
                                                                  display:
                                                                    "none",
                                                                }}
                                                              />
                                                              {this.state
                                                                .selectedImageName && (
                                                                <span
                                                                  className="mr-2"
                                                                  style={{
                                                                    alignContent:
                                                                      "center",
                                                                    color: "black"
                                                                  }}
                                                                >
                                                                  <strong>
                                                                    Selected
                                                                    File:
                                                                  </strong>{" "}
                                                                  {
                                                                    this.state
                                                                      .selectedImageName
                                                                  }
                                                                </span>
                                                              )}
                                                              <button
                                                                type="button"
                                                                onClick={() =>
                                                                  document
                                                                    .querySelector(
                                                                      'input[id="testimonial_image"]'
                                                                    )
                                                                    .click()
                                                                }
                                                                className="btn btn-dim btn-outline-primary customStyle_add_sharelink_testimonial_btns"
                                                                style={{
                                                                  minWidth: "",
                                                                }}
                                                              >
                                                                <em className="icon ni ni-upload"></em>{" "}
                                                                Upload Image
                                                              </button>
                                                            </div>
                                                            <div className="col-lg-12 d-flex justify-content-end">
                                                              <button
                                                                type="button"
                                                                className="btn btn-success customStyle_add_sharelink_testimonial_btns"
                                                                onClick={
                                                                  this
                                                                    .saveTestimonial
                                                                }
                                                                disabled={
                                                                  this.state
                                                                    .save_testimonial_btn_disable
                                                                }
                                                              >
                                                                Save Testimonial
                                                              </button>
                                                            </div>
                                                          </div>
                                                        )}

                                                        {this.state.testimonials
                                                          .length > 0 && (
                                                          <div
                                                            className="col-lg-12"
                                                            style={{
                                                              marginTop: "20px",
                                                            }}
                                                          >
                                                            <DataTable
                                                              columns={
                                                                this.state
                                                                  .columnTestimonials
                                                              }
                                                              tableData={this.getTableData()}
                                                              title="Testimonials"
                                                            />
                                                            {/* <h4 className="mb-3">
                                              Saved Testimonials
                                            </h4>
                                            <ul>
                                              {this.state.testimonials.map(
                                                (testimonial, index) => (
                                                  <li
                                                    key={index}
                                                    className="mb-3"
                                                  >
                                                    <div>
                                                      <strong>
                                                        Testimonial #{index + 1}
                                                      </strong>
                                                      <em
                                                        className="ml-1 icon ni ni-edit"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          this.editTestimonial(
                                                            index
                                                          )
                                                        }
                                                      ></em>
                                                      <em
                                                                      className="ml-1 icon ni ni-trash-empty"
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        this.deleteTestimonial(
                                                                          index
                                                                        )
                                                                      }
                                                                    ></em>
                                                    </div>
                                                    <br />
                                                    <strong>Name:</strong>{" "}
                                                    {
                                                      testimonial.testimonial_name
                                                    }
                                                    <br />
                                                    <strong>
                                                      Address:
                                                    </strong>{" "}
                                                    {
                                                      testimonial.testimonial_title
                                                    }
                                                    <br />
                                                    <strong>
                                                      Comment:
                                                    </strong>{" "}
                                                    {
                                                      testimonial.testimonial_comment
                                                    }
                                                    <br />
                                                  </li>
                                                )
                                              )}
                                            </ul> */}
                                                          </div>
                                                        )}
                                                      </div>

                                                      <div
                                                        className="form-group col-md-12 col-lg-12"
                                                        style={{
                                                          marginTop: "20px",
                                                          paddingLeft: "0px",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        {this.state.testimonials
                                                          .length > 0 && (
                                                          <button
                                                            type="button"
                                                            className="btn btn-lg btn-primary"
                                                            onClick={
                                                              this
                                                                .saveSharelinkTestimonials
                                                            }
                                                            disabled={
                                                              this.state
                                                                .disabled
                                                            }
                                                          >
                                                            <em className="icon ni ni-note-add"></em>
                                                            {"  "}Save
                                                            Testimonial Settings
                                                          </button>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}

              {/* Contact Logs MODAL Start */}
              <div className="modal fade" tabIndex="-1" id="modalContactLogs">
                <div
                  className="modal-dialog  modal-xl modal-dialog-top new_modal_width"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">Contact Logs</h5>
                    </div>
                    <div className="modal-body">
                      <DataTableSmall
                        columns={this.state.columnContactLogs}
                        tableData={this.state.tableDataContactLogs}
                        title="Sharelink Contact Logs"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Contact Logs MODAL End */}
              <div className="modal fade" tabIndex="-1" id="modalConfirm">
                <div
                  className="modal-dialog modal-dialog-top  modal-lg"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">Connect Domain</h5>
                    </div>
                    <div className="modal-body">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="form-group">
                        {this.state.available_domains.length > 0 ? (
                          <MutextField
                            id="domain_id"
                            name="domain_id"
                            select
                            label="Select Domain"
                            value={this.state.domain_id}
                            onChange={this.handleChange}
                            SelectProps={{
                              native: true,
                            }}
                            helperText="Please select the domain to connect the sharelink."
                            variant="outlined"
                            fullWidth
                          >
                            <option key="" value=""></option>
                            {this.state.available_domains.map((domains) => (
                              <option value={domains[0]}>{domains[2]}</option>
                            ))}
                          </MutextField>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="modal-footer bg-light"
                      style={{
                        float: "right",
                        textAlign: "right",
                        justifyContent: "flex-end",
                      }}
                    >
                      <a
                        href="#"
                        className="btn btn-danger"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </a>
                      {this.state.confirm_status !==
                      "This domain is already connected." ? (
                        <>
                          {this.state.btn_loader === true ? (
                            <button
                              className="btn btn-dim btn-primary"
                              disabled
                            >
                              <em className="icon ni ni-swap"></em> Connect
                              <div
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </button>
                          ) : (
                            <a
                              onClick={() => {
                                this.connectDomainSubmit();
                              }}
                              className="btn btn-primary"
                              style={{ color: "#fff" }}
                            >
                              Connect
                            </a>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sharelink);
