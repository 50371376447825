import { React } from "react";
import { Component } from "react";

import { connect } from "react-redux";

import { addSettings } from "../../../actions";

import $ from "jquery";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { AmazonLoader } from "../../../components/ContentLoaders/newLoaders/AmazonLoader";
import { Article } from "../../../components/ContentLoaders/newLoaders/Article";
import { ArticleLoader } from "../../../components/ContentLoaders/newLoaders/ArticleLoader";
import { AuthorsList } from "../../../components/ContentLoaders/newLoaders/AuthorsList";
import { AvatarWithText } from "../../../components/ContentLoaders/newLoaders/AvatarWithText";
import { BarChart } from "../../../components/ContentLoaders/newLoaders/BarChart";
import { BlogItem } from "../../../components/ContentLoaders/newLoaders/BlogItem";
import { BootstrapCardDataTable } from "../../../components/ContentLoaders/newLoaders/BootstrapCardDataTable";
import { BulletList } from "../../../components/ContentLoaders/newLoaders/BulletList";
import { CalloutStripLoader } from "../../../components/ContentLoaders/newLoaders/CalloutStripLoader";
import { Card } from "../../../components/ContentLoaders/newLoaders/Card";
import { CardList } from "../../../components/ContentLoaders/newLoaders/CardList";
import { Catalog } from "../../../components/ContentLoaders/newLoaders/Catalog";
//import { CatalogMagic } from "../../../components/ContentLoaders/newLoaders/CatalogMagic";
import { CategoryLoader } from "../../../components/ContentLoaders/newLoaders/CategoryLoader";
import { Chat } from "../../../components/ContentLoaders/newLoaders/Chat";
import { CheckboxList } from "../../../components/ContentLoaders/newLoaders/CheckboxList";
import { ClassicPostLoader } from "../../../components/ContentLoaders/newLoaders/ClassicPostLoader";
import { CleanChat } from "../../../components/ContentLoaders/newLoaders/CleanChat";
//import { CleoOne } from "../../../components/ContentLoaders/newLoaders/CleoOne";
import { Code } from "../../../components/ContentLoaders/newLoaders/Code";
import { CustomerTestimonial } from "../../../components/ContentLoaders/newLoaders/CustomerTestimonial";
import { DashboardLoader } from "../../../components/ContentLoaders/newLoaders/DashboardLoader";
import { DataList } from "../../../components/ContentLoaders/newLoaders/DataList";
import { DataTable } from "../../../components/ContentLoaders/newLoaders/DataTable";
import { DevtoCard } from "../../../components/ContentLoaders/newLoaders/DevtoCard";
import { DiscordLoader } from "../../../components/ContentLoaders/newLoaders/DiscordLoader";
import { DoorDashFavorite } from "../../../components/ContentLoaders/newLoaders/DoorDashFavorite";
import { EcommerceProduct } from "../../../components/ContentLoaders/newLoaders/EcommerceProduct";
import { EventsLoader } from "../../../components/ContentLoaders/newLoaders/EventsLoader";
import { Facebook } from "../../../components/ContentLoaders/newLoaders/Facebook";
import { FadingLoader } from "../../../components/ContentLoaders/newLoaders/FadingLoader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/FormLoader";
import { ForumPost } from "../../../components/ContentLoaders/newLoaders/ForumPost";
import { GithubProfile } from "../../../components/ContentLoaders/newLoaders/GithubProfile";
import { GlobalSidebar } from "../../../components/ContentLoaders/newLoaders/GlobalSidebar";
import { GmailLoader } from "../../../components/ContentLoaders/newLoaders/Gmail";
import { GoogleMap } from "../../../components/ContentLoaders/newLoaders/GoogleMap";
import { Grid } from "../../../components/ContentLoaders/newLoaders/Grid";
import { HackerNewsLoader } from "../../../components/ContentLoaders/newLoaders/HackerNewsLoader";
import { HeadBodyGrid } from "../../../components/ContentLoaders/newLoaders/HeadBodyGrid";
import { HeaderLoader } from "../../../components/ContentLoaders/newLoaders/HeaderLoader";
import { HelpLinksLoader } from "../../../components/ContentLoaders/newLoaders/HelpLinksLoader";
import { HistoriesLoader } from "../../../components/ContentLoaders/newLoaders/HistoriesLoader";
import { HomePage } from "../../../components/ContentLoaders/newLoaders/HomePage";
import { ImageGrid } from "../../../components/ContentLoaders/newLoaders/ImageGrid";
import { ImageList } from "../../../components/ContentLoaders/newLoaders/ImageList";
import { ImageUpload } from "../../../components/ContentLoaders/newLoaders/ImageUpload";
import { Instagram } from "../../../components/ContentLoaders/newLoaders/Instagram";
import { InstaStories } from "../../../components/ContentLoaders/newLoaders/InstaStories";
import { Invoice } from "../../../components/ContentLoaders/newLoaders/Invoice";
import { LinkedIn } from "../../../components/ContentLoaders/newLoaders/LinkedIn";
import { ListingWithThumbnail } from "../../../components/ContentLoaders/newLoaders/ListingWithThumbnail";
import { Medium } from "../../../components/ContentLoaders/newLoaders/Medium";
import { NestedList } from "../../../components/ContentLoaders/newLoaders/NestedList";
import { Netflix } from "../../../components/ContentLoaders/newLoaders/Netflix";
import { NewFacebook } from "../../../components/ContentLoaders/newLoaders/NewFacebook";
import { News } from "../../../components/ContentLoaders/newLoaders/News";
import { NotificationLoader } from "../../../components/ContentLoaders/newLoaders/NotificationLoader";
import { PieChart } from "../../../components/ContentLoaders/newLoaders/PieChart";
import { ProductDetails } from "../../../components/ContentLoaders/newLoaders/ProductDetails";
import { ProfileCard } from "../../../components/ContentLoaders/newLoaders/ProfileCard";
import { ProfileShow } from "../../../components/ContentLoaders/newLoaders/ProfileShow";
import { ReactTable } from "../../../components/ContentLoaders/newLoaders/ReactTable";
import { Reddit } from "../../../components/ContentLoaders/newLoaders/Reddit";
//import { RepeatableTableRows } from "../../../components/ContentLoaders/newLoaders/RepeatableTableRows";
//import { ResponsiveArticle } from "../../../components/ContentLoaders/newLoaders/ResponsiveArticle";
import { ResponsiveProduct } from "../../../components/ContentLoaders/newLoaders/ResponsiveProduct";
import { RotatingGallery } from "../../../components/ContentLoaders/newLoaders/RotatingGallery";
import { ShoppingLoader } from "../../../components/ContentLoaders/newLoaders/ShoppingLoader";
import { Sidebar } from "../../../components/ContentLoaders/newLoaders/Sidebar";
import { SnapchatThread } from "../../../components/ContentLoaders/newLoaders/SnapchatThread";
import { Table } from "../../../components/ContentLoaders/newLoaders/Table";
import { TableLoader } from "../../../components/ContentLoaders/newLoaders/TableLoader";
import { TaskList } from "../../../components/ContentLoaders/newLoaders/TaskList";
import { ThreeDots } from "../../../components/ContentLoaders/newLoaders/ThreeDots";
import { Twitter } from "../../../components/ContentLoaders/newLoaders/Twitter";
import { UpworkJobLoader } from "../../../components/ContentLoaders/newLoaders/UpworkJobLoader";
import { UserReviewSkype } from "../../../components/ContentLoaders/newLoaders/UserReviewSkype";
import { XYChart } from "../../../components/ContentLoaders/newLoaders/XYChart";
import { Youtube } from "../../../components/ContentLoaders/newLoaders/Youtube";
import { YoutubeFresh } from "../../../components/ContentLoaders/newLoaders/YoutubeFresh";
import { McqLoader } from "../../../components/ContentLoaders/newLoaders/Mcq";

import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();

class Loaders extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
    };
  }

  async componentDidMount() {}

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="Support_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Loaders Listing"
                        icon="icon ni ni-help"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          <div className="row g-gs">
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  EcommerceProduct
                                </div>
                                <div class="card-inner">
                                  {EcommerceProduct()}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  EventsLoader
                                </div>
                                <div class="card-inner">{EventsLoader()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Facebook
                                </div>
                                <div class="card-inner">{Facebook()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  FadingLoader
                                </div>
                                <div class="card-inner">{FadingLoader()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Form
                                </div>
                                <div class="card-inner">{FormLoader()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  ForumPost
                                </div>
                                <div class="card-inner">{ForumPost()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  GithubProfile
                                </div>
                                <div class="card-inner">{GithubProfile()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  GlobalSidebar
                                </div>
                                <div class="card-inner">{GlobalSidebar()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Gmail
                                </div>
                                <div class="card-inner">{GmailLoader()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  GoogleMap
                                </div>
                                <div class="card-inner">{GoogleMap()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Grid
                                </div>
                                <div class="card-inner">{Grid()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  HackerNewsLoader
                                </div>
                                <div class="card-inner">
                                  {HackerNewsLoader()}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  HeadBodyGrid
                                </div>
                                <div class="card-inner">{HeadBodyGrid()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  HeaderLoader
                                </div>
                                <div class="card-inner">{HeaderLoader()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  HelpLinksLoader
                                </div>
                                <div class="card-inner">
                                  {HelpLinksLoader()}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  HistoriesLoader
                                </div>
                                <div class="card-inner">
                                  {HistoriesLoader()}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  HomePage
                                </div>
                                <div class="card-inner">{HomePage()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  ImageGrid
                                </div>
                                <div class="card-inner">{ImageGrid()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  ImageList
                                </div>
                                <div class="card-inner">{ImageList()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  ImageUpload
                                </div>
                                <div class="card-inner">{ImageUpload()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Instagram
                                </div>
                                <div class="card-inner">{Instagram()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  InstaStories
                                </div>
                                <div class="card-inner">{InstaStories()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Invoice
                                </div>
                                <div class="card-inner">{Invoice()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  LinkedIn
                                </div>
                                <div class="card-inner">{LinkedIn()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  ListingWithThumbnail
                                </div>
                                <div class="card-inner">
                                  {ListingWithThumbnail()}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Medium
                                </div>
                                <div class="card-inner">{Medium()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  NestedList
                                </div>
                                <div class="card-inner">{NestedList()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Netflix
                                </div>
                                <div class="card-inner">{Netflix()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  NewFacebook
                                </div>
                                <div class="card-inner">{NewFacebook()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  News
                                </div>
                                <div class="card-inner">{News()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  NotificationLoader
                                </div>
                                <div class="card-inner">
                                  {NotificationLoader()}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  PieChart
                                </div>
                                <div class="card-inner">{PieChart()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  ProductDetails
                                </div>
                                <div class="card-inner">{ProductDetails()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  ProfileCard
                                </div>
                                <div class="card-inner">{ProfileCard()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  ProfileShow
                                </div>
                                <div class="card-inner">{ProfileShow()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  ReactTable
                                </div>
                                <div class="card-inner">{ReactTable()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Reddit
                                </div>
                                <div class="card-inner">{Reddit()}</div>
                              </div>
                            </div>
                            {/*<div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  RepeatableTableRows
                                </div>
                                <div class="card-inner">
                                  {RepeatableTableRows()}
                                </div>
                              </div>
          </div>*/}

                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  ResponsiveProduct
                                </div>
                                <div class="card-inner">
                                  {ResponsiveProduct()}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  RotatingGallery
                                </div>
                                <div class="card-inner">
                                  {RotatingGallery()}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  ShoppingLoader
                                </div>
                                <div class="card-inner">{ShoppingLoader()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Sidebar
                                </div>
                                <div class="card-inner">{Sidebar()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  SnapchatThread
                                </div>
                                <div class="card-inner">{SnapchatThread()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Table
                                </div>
                                <div class="card-inner">{Table()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  TaskList
                                </div>
                                <div class="card-inner">{TaskList()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Twitter
                                </div>
                                <div class="card-inner">{Twitter()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  ThreeDots
                                </div>
                                <div class="card-inner">{ThreeDots()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  UpworkJobLoader
                                </div>
                                <div class="card-inner">
                                  {UpworkJobLoader()}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  UserReviewSkype
                                </div>
                                <div class="card-inner">
                                  {UserReviewSkype()}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  XYChart
                                </div>
                                <div class="card-inner">{XYChart()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Youtube
                                </div>
                                <div class="card-inner">{Youtube()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  YoutubeFresh
                                </div>
                                <div class="card-inner">{YoutubeFresh()}</div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  TableLoader
                                </div>
                                <div class="card-inner">{TableLoader()}</div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  tableLoader
                                </div>
                                <div class="card-inner">{tableLoader()}</div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  tableLoaderListing
                                </div>
                                <div class="card-inner">
                                  {tableLoaderListing()}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  AmazonLoader
                                </div>
                                <div class="card-inner">{AmazonLoader()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Article
                                </div>
                                <div class="card-inner">{Article()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  ArticleLoader
                                </div>
                                <div class="card-inner">{ArticleLoader()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  AuthorsList
                                </div>
                                <div class="card-inner">{AuthorsList()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  AvatarWithText
                                </div>
                                <div class="card-inner">{AvatarWithText()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  BarChart
                                </div>
                                <div class="card-inner">{BarChart()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  BlogItem
                                </div>
                                <div class="card-inner">{BlogItem()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  BootstrapCardDataTable
                                </div>
                                <div class="card-inner">
                                  {BootstrapCardDataTable()}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  BulletList
                                </div>
                                <div class="card-inner">{BulletList()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  CalloutStripLoader
                                </div>
                                <div class="card-inner">
                                  {CalloutStripLoader()}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Card
                                </div>
                                <div class="card-inner">{Card()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  CardList
                                </div>
                                <div class="card-inner">{CardList()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Catalog
                                </div>
                                <div class="card-inner">{Catalog()}</div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  CategoryLoader
                                </div>
                                <div class="card-inner">{CategoryLoader()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Chat
                                </div>
                                <div class="card-inner">{Chat()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  CheckboxList
                                </div>
                                <div class="card-inner">{CheckboxList()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  ClassicPostLoader
                                </div>
                                <div class="card-inner">
                                  {ClassicPostLoader()}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  CleanChat
                                </div>
                                <div class="card-inner">{CleanChat()}</div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  Code
                                </div>
                                <div class="card-inner">{Code()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  CustomerTestimonial
                                </div>
                                <div class="card-inner">
                                  {CustomerTestimonial()}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  DashboardLoader
                                </div>
                                <div class="card-inner">
                                  {DashboardLoader()}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  DataList
                                </div>
                                <div class="card-inner">{DataList()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  DataTable
                                </div>
                                <div class="card-inner">{DataTable()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  DevtoCard
                                </div>
                                <div class="card-inner">{DevtoCard()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  tableLoader
                                </div>
                                <div class="card-inner">{tableLoader()}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="card card-bordered">
                                <div class="card-header border-bottom">
                                  MCQ's
                                </div>
                                <div class="card-inner">{McqLoader()}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Loaders);
