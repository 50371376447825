import ImgError from "../../assets/images/error-404.svg";
import { THEME_COLOR } from "./../../config/config";

export default function error() {
  return (
    <div className="nk-body bg-white npc-general pg-error">
      <div className="nk-app-root">
        <div className="nk-main ">
          <div className="nk-wrap justify-center">
            <div className="nk-content ">
              <div className="nk-block nk-block-middle wide-md mx-auto">
                <div className="nk-block-content nk-error-ld text-center">
                  <img className="nk-error-gfx" src={ImgError} alt="" />
                  <div className="wide-xs mx-auto">
                    <h3 className="nk-error-title">Oops! Why you’re here?</h3>
                    <p className="nk-error-text">
                      We are very sorry for inconvenience. It looks like you’re
                      try to access a page that either has been deleted or never
                      existed.
                    </p>
                    <a
                      href="/"
                      className="btn btn-lg mt-2"
                      style={{ backgroundColor: THEME_COLOR, color: "white" }}
                    >
                      Back To Home
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
