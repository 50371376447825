import { React } from "react";
import { Component } from "react";
import styles from "./../../../styles.module.css";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { connect } from "react-redux";
import { addSettings } from "../../../actions";
import SignatureCanvas from "react-signature-canvas";
import { Row, Col, Container } from "react-bootstrap";
import {
  unsignedAgreements,
  saveGeneralAgreement,
  sendToSignee,
  saveChooseSignatureAgreement,
} from "./../../../config/client_api_calls";
import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderBlockImage } from "../../../components/ContentLoaders/table_loader_imageblock";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  ADMIN_URL,
} from "./../../../config/config";
import { REPLACECONTENTCLIENT, ValidateEmail } from "./../../../config/utility";
import MutextField from "@material-ui/core/TextField";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import html2canvas from "html2canvas";
const auth = new HelperClass();

class Agreements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      errorMessage: "",
      successMessage: "",
      tableLoader: true,
      tableLoaderBlockImage: true,
      pdfurl: "",
      unsigned_agreements: "1",
      replacable_content: "",
      final_content: "",
      agreement_id: "",
      vendor_signature: "",
      agreementData: [],
      signatoryData: [],
      clientData: [],
      resellerData: [],
      errorMessageSignee: "",
      successMessageSignee: "",
      agreement_type: "esign",
      is_checked: "",
      signee_name: "",
      signee_email: "",
      signee_title: "",
      signee_comment: "",
      charLimit: 1000,
      characterExceededMessage: "",
      agreement_service_type: "",
      agreement_accountno: "",
      agreement_title: "",
      signature_type: "draw",
      signatureText: "",
      signatureClass: "'Amita', cursive",
      button_submit_loader: false,
    };
    this.cursiveFonts = [
      { name: "Your Signature", value: "'Amita', cursive" },
      { name: "Your Signature", value: "'Bad Script', cursive" },
      { name: "Your Signature", value: "'Damion', cursive" },
      { name: "Your Signature", value: "'Dancing Script', cursive" },
      { name: "Your Signature", value: "'Felipa', cursive" },
      { name: "Your Signature", value: "'Julee', cursive" },
      { name: "Your Signature", value: "'Kaushan Script', cursive" },
      { name: "Your Signature", value: "'Lugrasimo', cursive" },
      { name: "Your Signature", value: "'Pacifico', cursive" },
      { name: "Your Signature", value: "'Sofia', cursive" },
      { name: "Your Signature", value: "'Yellowtail', cursive" },
      // Add more cursive fonts here
    ];
  }

  viewDownloadBtns = (tableMeta) => {
    return (
      <div>
        <a
          onClick={() =>
            this.addMenuHistory(
              "/agreements/pdfdownload/" +
                btoa(tableMeta.rowData[0] + "###" + tableMeta.rowData[4])
            )
          }
          disabled={tableMeta.rowData[2] === "No" ? true : false}
          target="_blank"
          style={{ cursor: "pointer", color: "#006393" }}
        >
          <GetAppOutlinedIcon
            style={{
              cursor: "pointer",
            }}
          />
        </a>{" "}
      </div>
    );
  };

  formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberObj) {
      if (phoneNumberObj.country === "US") {
        const nationalNumber = phoneNumberObj
          .formatNational()
          .replace(/\D/g, ""); // e.g. "2123727200"
        const areaCode = nationalNumber.slice(0, 3);
        const firstPart = nationalNumber.slice(3, 6);
        const secondPart = nationalNumber.slice(6);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }
      return phoneNumberObj.formatInternational();
    }
    return phoneNumber; // Return original number if parsing fails
  };

  async componentDidMount() {
    let agreement_no = this.props.props.match.params.agreement_no;
    let agreements_required = "not_required";
    let apiData = await unsignedAgreements(
      auth.getClientAccount(),
      auth.getClientToken(),
      agreements_required
    );
    console.log("UnSignedAgreement DATA: ", apiData.data);
    this.setState({
      tableLoaderBlockImage: true,
    });
    if (
      apiData.data.status === 403 ||
      apiData.data.errors === "authentication missing" ||
      apiData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (apiData.data.status === 404 || apiData.data.data.length === 0) {
      console.log("First 404 Error");
      this.setState({
        errorMessage: "There is some error.",
        successMessage: "",
        tableLoaderBlockImage: false,
      });
      //this.props.history.push("/error");
    } else if (apiData.data.status === 200 && apiData.data.data.length > 0) {
      this.setState({});
      let get_first_agreement = apiData.data.data[0][6];
      let companyProfileSetting = this.findMatchingSignatories(
        get_first_agreement,
        apiData.data.company_profile
      );
      let objReplace = {
        resellerCompName: auth.getCompName(),
        client_name: apiData.data.user_data[0]["name"],
        client_title:
          apiData.data.user_data[0]["title"] &&
          apiData.data.user_data[0]["title"] !== ""
            ? apiData.data.user_data[0]["title"]
            : "N/A",
        client_comp_name: apiData.data.user_data[0]["comp_name"],
        client_email: apiData.data.user_data[0]["email"],
        client_address: apiData.data.user_data[0]["address"]
          ? apiData.data.user_data[0]["address"]
          : "N/A",
        client_city: apiData.data.user_data[0]["city"]
          ? apiData.data.user_data[0]["city"]
          : "N/A",
        client_phone_no: apiData.data.user_data[0]["phone"]
          ? apiData.data.user_data[0]["phone"]
          : "N/A",
        client_state: apiData.data.user_data[0]["state"]
          ? apiData.data.user_data[0]["state"]
          : "N/A",
        client_zip: apiData.data.user_data[0]["zip"]
          ? apiData.data.user_data[0]["zip"]
          : "N/A",
        client_country: apiData.data.user_data[0]["country"]
          ? apiData.data.user_data[0]["country"]
          : "N/A",
        client_phone_no: this.formatPhoneNumber(
          apiData.data.user_data[0]["phone"]
        ),
        vendor_signor_name: apiData.data.reseller_data[0].reseller_name,
        vendor_signor_comp_name: apiData.data.reseller_data[0].reseller_comp,
        vendor_signor_title: apiData.data.reseller_data[0].reseller_title,
        vendor_signor_address: apiData.data.reseller_data[0].reseller_address,
        vendor_signor_email: "N/A",
        vendor_signor_phone_no: apiData.data.reseller_data[0].reseller_phone,
        vendor_signor_fax: "N/A",
        vendor_signor_city: apiData.data.reseller_data[0].reseller_city,
        vendor_signor_state: apiData.data.reseller_data[0].reseller_state,
        vendor_signor_zip: apiData.data.reseller_data[0].reseller_zip,
        vendor_signor_comp_title: apiData.data.reseller_data[0].reseller_comp,

        company_profile_name: companyProfileSetting.name || "N/A",
        company_profile_title: companyProfileSetting.title || "N/A",
        company_profile_address: companyProfileSetting.address
          ? `${companyProfileSetting.address}`
          : "N/A",
        company_profile_full_address: companyProfileSetting.address
          ? `${companyProfileSetting.address}, ${companyProfileSetting.city}, ${companyProfileSetting.state}, ${companyProfileSetting.zip}, ${companyProfileSetting.country}`
          : "N/A",
        company_profile_city: companyProfileSetting.city || "N/A",
        company_profile_phone: companyProfileSetting.phone || "N/A",
        company_profile_email: companyProfileSetting.email || "N/A",
        company_profile_state: companyProfileSetting.state || "N/A",
        company_profile_country: companyProfileSetting.country || "N/A",
        company_profile_zip: companyProfileSetting.zip || "N/A",
        company_profile_sign: companyProfileSetting.sign || "N/A",
        company_profile_company_name: companyProfileSetting.comp_name || "N/A",
      };

      /*newContent = newContent.replace(
        "VENDOR SIGNATURE DATE SHOWN HERE",
        apiData.data.agreement[0]["published_date"]
      );*/
      let final_content = apiData.data.data[0][1];
      final_content = REPLACECONTENTCLIENT(
        final_content,
        objReplace,
        ""
      ).replace(/\[\[IMG_VENDOR_SIGN\]\]/g, this.state.vendor_signature);
      this.setState({
        replacable_content: objReplace,
        agreementData: apiData.data.data,
        signatoryData: apiData.data.company_profile,
        clientData: apiData.data.user_data,
        resellerData: apiData.data.reseller_data,
        errorMessage: "",
        final_content: final_content,
        bgColor: "white",
        defaultLoader: false,
        agreement_id: apiData.data.data[0][0],
        agreement_type: apiData.data.data[0][8],
        vendor_signature: apiData.data.reseller_data[0].reseller_signature,
        agreement_accountno: apiData.data.data[0][6],
        agreement_title: apiData.data.data[0][9],
        agreement_service_type: apiData.data.data[0][10],
        tableLoaderBlockImage: false,
      });

      //this.show_signature(0);
    } else if (apiData.data.status === 406) {
      this.setState({
        errorMessage: "",
        successMessage: "",
        agreementData: [],
        tableLoaderBlockImage: false,
      });
    } else if (apiData.data.status === 407) {
      this.setState({
        errorMessage: "",
        successMessage: "",
        agreementData: [],
        tableLoaderBlockImage: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error.",
        successMessage: "",
        tableLoaderBlockImage: false,
      });
      console.log("Last Error");
      //this.props.history.push("/error");
    }
  }

  findMatchingSignatories = (agreement_accountno, signatoryArray) => {
    // Find the record with a matching agreement_accountno
    const matchingRecord = signatoryArray.find(
      (record) => record.agreement_accountno === agreement_accountno
    );

    return matchingRecord || null; // Return the found record or null if no match
  };

  manage_content_with_replacable_data = (
    agreement_accountno,
    agreement_content
  ) => {
    const { signatoryData, clientData, resellerData } = this.state;
    let get_first_agreement = agreement_accountno;
    let companyProfileSetting = this.findMatchingSignatories(
      get_first_agreement,
      signatoryData
    );

    let objReplace = {
      resellerCompName: auth.getCompName(),
      client_name: clientData[0]["name"],
      client_title:
        clientData[0]["title"] && clientData[0]["title"] !== ""
          ? clientData[0]["title"]
          : "N/A",
      client_comp_name: clientData[0]["comp_name"],
      client_email: clientData[0]["email"],
      client_address: clientData[0]["address"]
        ? clientData[0]["address"]
        : "N/A",
      client_city: clientData[0]["city"] ? clientData[0]["city"] : "N/A",
      client_phone_no: clientData[0]["phone"] ? clientData[0]["phone"] : "N/A",
      client_state: clientData[0]["state"] ? clientData[0]["state"] : "N/A",
      client_zip: clientData[0]["zip"] ? clientData[0]["zip"] : "N/A",
      client_country: clientData[0]["country"]
        ? clientData[0]["country"]
        : "N/A",
      client_phone_no: this.formatPhoneNumber(clientData[0]["phone"]),
      vendor_signor_name: resellerData[0].reseller_name,
      vendor_signor_comp_name: resellerData[0].reseller_comp,
      vendor_signor_title: resellerData[0].reseller_title,
      vendor_signor_address: resellerData[0].reseller_address
        ? resellerData[0].reseller_address +
          ", " +
          resellerData[0].reseller_city +
          ", " +
          resellerData[0].reseller_state +
          ", " +
          resellerData[0].reseller_zip
        : " ",
      vendor_signor_email: "N/A",
      vendor_signor_phone_no: resellerData[0].reseller_phone,
      vendor_signor_fax: "N/A",
      vendor_signor_city: resellerData[0].reseller_city,
      vendor_signor_state: resellerData[0].reseller_state,
      vendor_signor_zip: resellerData[0].reseller_zip,
      vendor_signor_comp_title: resellerData[0].reseller_comp,

      company_profile_name: companyProfileSetting.name || "N/A",
      company_profile_title: companyProfileSetting.title || "N/A",
      company_profile_address: companyProfileSetting.address
        ? `${companyProfileSetting.address}`
        : "N/A",
      company_profile_full_address: companyProfileSetting.address
        ? `${companyProfileSetting.address}, ${companyProfileSetting.city}, ${companyProfileSetting.state}, ${companyProfileSetting.zip}, ${companyProfileSetting.country}`
        : "N/A",
      company_profile_city: companyProfileSetting.city || "N/A",
      company_profile_phone: companyProfileSetting.phone || "N/A",
      company_profile_email: companyProfileSetting.email || "N/A",
      company_profile_state: companyProfileSetting.state || "N/A",
      company_profile_country: companyProfileSetting.country || "N/A",
      company_profile_zip: companyProfileSetting.zip || "N/A",
      company_profile_sign: companyProfileSetting.sign || "N/A",
      company_profile_company_name: companyProfileSetting.comp_name || "N/A",
    };
    let final_content = agreement_content;
    final_content = REPLACECONTENTCLIENT(final_content, objReplace, "").replace(
      /\[\[IMG_VENDOR_SIGN\]\]/g,
      ADMIN_URL + "files_data/logos/" + resellerData[0].signature_image
    );
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: final_content,
        }}
      />
    );
  };

  open_signee = async () => {
    console.log("agreement_id: " + this.state.agreement_id);
    this.setState({
      errorMessageSignee: "",
      successMessageSignee: "",
      characterExceededMessage: "",
      signee_comment: "",
    });
    window.$("#modalSendSignee").modal("show");
  };

  close_signee = async () => {
    window.$("#modalSendSignee").modal("hide");
  };

  send_to_signee = async () => {
    console.log("agreement_id: " + this.state.agreement_id);
    this.setState({
      successMessageSignee: "",
      errorMessageSignee: "",
      disabled: true,
    });
    const {
      agreement_id,
      signee_name,
      signee_email,
      signee_title,
      signee_comment,
      agreement_service_type,
    } = this.state;

    var validated = true;
    if (signee_name === "") {
      validated = false;
    } else if (signee_email === "") {
      validated = false;
    } else if (ValidateEmail(signee_email) === false) {
      validated = false;
      this.setState({
        errorEmail: "error",
        errorMessageSignee: "Invalid email format.",
      });
    } else if (signee_comment === "") {
      validated = false;
    }

    if (validated === false) {
      this.setState({
        errorMessageSignee: "Please enter the required fields.",
        disabled: false,
      });
    } else {
      const apiData = await sendToSignee(
        auth.getClientAccount(),
        auth.getClientToken(),
        agreement_id,
        signee_name,
        signee_email,
        signee_title,
        signee_comment,
        agreement_service_type
      );
      console.log("sendToSignee: ", apiData.data);
      if (
        apiData.data.status === 403 ||
        apiData.data.errors === "authentication missing" ||
        apiData.data.errors === "jwt expired"
      ) {
        auth.clientLogout();
      } else if (apiData.data.status === 404) {
        this.setState({
          errorMessageSignee: "We are sorry, something went wrong try later.",
          disabled: false,
        });
        // this.props.mainHistory.history.push("/error");
        // console.log("errors: ", apiData.data);
      } else if (
        apiData.data.status === 200 &&
        apiData.data.message === "success"
      ) {
        this.setState({
          successMessageSignee:
            "Agreement has been sent successfully to the signee.",
          disabled: false,
          signee_name: "",
          signee_email: "",
          signee_title: "",
          signee_comment: "",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
  };

  save = async () => {
    console.log("agreement_id: " + this.state.agreement_id);

    if (
      this.state.agreement_type === "accept" &&
      this.state.is_checked !== "yes"
    ) {
      window.$("#modalAlertError").modal("show");
      return this.setState({
        errorMessage:
          "For account activation and further processing. You must accept the agreement.",
      });
    } else if (
      this.state.agreement_type === "esign" &&
      this.signaturePad.isEmpty() &&
      this.state.signature_type === "draw"
    ) {
      window.$("#modalAlertError").modal("show");
      return this.setState({
        errorMessage:
          "For account activation and further processing. You must sign the mandatory agreements.",
      });
    } else if (
      this.state.agreement_type === "esign" &&
      this.state.signature_type === "choose" &&
      this.state.signatureText == ""
    ) {
      window.$("#modalAlertError").modal("show");
      return this.setState({
        errorMessage: "Please enter the authorized person name.",
      });
    } else {
      this.setState({
        button_submit_loader: true,
        disabled: true,
      });
      if (this.state.signature_type === "draw") {
        $("#loading_btn").show();
        $("#save_btn").hide();

        this.setState({
          errorMessage: "",
        });
        var data = "";
        if (this.state.agreement_type === "esign") {
          data = this.signaturePad
            .getTrimmedCanvas()
            .toDataURL("image/svg+xml");
        }

        let content_div_id = "agreement" + this.state.agreement_accountno;
        var final_content = document.getElementById(content_div_id).innerHTML;

        const apiData = await saveGeneralAgreement(
          auth.getClientAccount(),
          auth.getClientToken(),
          data,
          final_content.replace(
            "CLIENT SIGNATURE DATE SHOWN HERE",
            this.props.published_date
          ),
          this.state.agreement_id,
          this.state.agreement_accountno,
          this.state.vendor_signature,
          this.state.agreement_type
        );
        //console.log("MSA: ", apiData.data);
        if (
          apiData.data.status === 403 ||
          apiData.data.errors === "authentication missing" ||
          apiData.data.errors === "jwt expired"
        ) {
          auth.clientLogout();
        } else if (apiData.data.status === 404) {
          window.$("#modalAlertError").modal("show");
          return this.setState({
            errorMessage: "We are sorry, something went wrong try later.",
            button_submit_loader: false,
            disabled: false,
          });
          // this.props.mainHistory.history.push("/error");
          // console.log("errors: ", apiData.data);
        } else if (
          apiData.data.status === 200 &&
          apiData.data.data === "success"
        ) {
          $("#loading_btn").hide();
          $("#save_btn").show();

          window.location.replace(
            "/" + auth.getResellerIdentifier() + "/client/dashboard"
          );
        }

        $("#divSig").show();
        $("#divImgSig").show();
        $(".clsimgSig").show();
        $(".clsimgSig").attr("src", data);
      } else if (this.state.signature_type === "choose") {
        this.submitSignatoryDetails();
      }
    }
  };

  set_data = (agreement_content) => {
    this.setState({
      final_content: agreement_content,
    });
  };

  updateSignatureType = (type) => {
    if (type === "choose") {
      this.clear();
    } else {
      this.setState({ signatureText: "" });
    }
    this.setState({
      signature_type: type,
    });
  };

  handleSignature = (family) => {
    this.setState({ signatureClass: family });
  };

  submitSignatoryDetails = async () => {
    let { signatureText, signature_type } = this.state;
    this.setState({
      verifyDetailsButton: true,
    });
    let is_validated = true;
    if (signatureText === "") {
      is_validated = false;
      this.setState({
        button_submit_loader: false,
      });
    }
    if (signature_type === "choose") {
      await this.saveAsImage();
    }
    if (signature_type === "draw") {
      //await this.saveSignature();
    }
  };

  saveAsImage = async () => {
    let { signatureText, signature_type } = this.state;
    const element = document.getElementById("styled-text-container");
    //console.log("element: ", element);
    if (element) {
      html2canvas(element, { scale: 2, backgroundColor: null }).then(
        async (canvas) => {
          const imgData = canvas.toDataURL("image/png");
          let content_div_id = "agreement" + this.state.agreement_accountno;
          var final_content = document.getElementById(content_div_id).innerHTML;
          const apiData = await saveChooseSignatureAgreement(
            auth.getClientAccount(),
            auth.getClientToken(),
            imgData,
            signatureText,
            signature_type,
            final_content.replace(
              "VENDOR SIGNATURE DATE SHOWN HERE",
              this.props.published_date
            ),
            this.state.agreement_id,
            this.state.vendor_signature,
            this.state.agreement_type,
            this.state.agreement_accountno
          );
          //console.log("saveSignature: ", apiData.data);
          if (
            apiData.data.status === 403 ||
            apiData.data.errors === "authentication missing" ||
            apiData.data.errors === "jwt expired"
          ) {
            auth.clientLogout();
          } else if (apiData.data.status === 404) {
            $("#signee_button").show();
            $("#loading_btn").hide();
            $("#save_btn").show();
            this.setState({
              errorMessageSignatory:
                "There is some error while processing your request.",
              successMessageSignatory: "",
              verifyDetailsButton: false,
              disabled: false,
              button_submit_loader: false,
            });
          } else if (
            apiData.data.status === 200 &&
            apiData.data.data === "success"
          ) {
            window.location.replace(
              "/" + auth.getResellerRegToken() + "/client/dashboard"
            );
          } else {
            //console.log("there was some error while signing the agreement!");
            this.setState({
              errorMessageSignatory:
                "There is some error while processing your request.",
              successMessageSignatory: "",
              verifyDetailsButton: false,
              disabled: false,
              button_submit_loader: false,
            });
          }
        }
      );
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.value : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeTextArea = (event) => {
    let { value } = event.target;
    const { charLimit } = this.state;

    // Update state only if the character limit is not exceeded

    if (value.length <= charLimit) {
      this.setState({ signee_comment: value, characterExceededMessage: "" });
    } else {
      //value = value.slice(0, charLimit);
      this.setState({
        characterExceededMessage: `Character limit exceeded. Maximum 500 characters are allowed. you have entered ${value.length} characters.`,
      });
    }
  };

  preview = () => {
    if (!this.signaturePad.isEmpty()) {
      var data = this.signaturePad
        .getTrimmedCanvas()
        .toDataURL("image/svg+xml");
      $("#divSig").show();
      //$("#divImgSig").show();
      $(".clsimgSig").show();
      $(".clsimgSig").attr("src", data);
    }
  };

  class_manage_link = (ids) => {
    if (ids === 0) {
      return "nav-link active";
    } else {
      return "nav-link";
    }
  };

  class_manage_content = (ids) => {
    if (ids === 0) {
      return "tab-pane active";
    } else {
      return "tab-pane";
    }
  };

  class_required = (ids) => {
    if (ids === 1) {
      return (
        <sup style={{ fontSize: "100%" }}>
          <small className="" style={{ color: "#e85347", fontSize: "100%" }}>
            *
          </small>
        </sup>
      );
    } else {
      return "";
    }
  };

  set_tabs = (ids) => {
    if (ids !== "") {
      return "#agreement" + ids;
    } else {
      return "tab-pane";
    }
  };

  set_tabs_content = (ids) => {
    if (ids !== "") {
      return "agreement" + ids;
    } else {
      return "tab-pane";
    }
  };

  set_tabs_accountno = (ids) => {
    if (ids !== "") {
      this.setState({ agreement_accountno: ids });
    }
  };

  show_agreement_content = async (
    ids,
    agreement_content,
    agreement_accountno
  ) => {
    //console.log("replacable_content: ", this.state.replacable_content);
    let final_content = agreement_content;
    if (final_content !== "") {
      final_content = REPLACECONTENTCLIENT(
        agreement_content,
        this.state.replacable_content,
        ""
      ).replace(/\[\[IMG_VENDOR_SIGN\]\]/g, this.state.vendor_signature);
    }
    this.setState({
      final_content: final_content,
      agreement_id: ids,
      agreement_accountno: agreement_accountno,
    });
  };

  addMenuHistory = (link) => {
    this.props.history.push(link);
  };

  setMarkUp = (value, tableMeta) => {
    let pdf_path = tableMeta.rowData[2];
    //console.log("pdf_path: ", pdf_path);
    if (tableMeta.rowData[1] === "Yes") {
      this.setState({
        pdfurl:
          process.env.REACT_APP_API_URL + "public/msa_agreements/" + pdf_path,
      });
    } else {
      this.setState({
        pdfurl: "",
      });
    }
    //console.log(this.state.pdfurl);
    return this.state.pdfurl;
  };

  clear = () => {
    this.signaturePad.clear();
    $("#divImgSig").hide();
    $(".clsimgSig").attr("src", "");
    $(".clsimgSig").hide();
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    this.props.agreementSigned();
  };

  showModal = () => {
    window.$("#modalForm").modal("show");
  };

  downloadPdf = (value, tableMeta) => {
    fetch(process.env.REACT_APP_API_URL + "agreements/get_agreement_data")
      .then((x) => x.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = "a.pdf";
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  handlePrint = () => {
    // Target the div element by ID
    const activeTabPane = document.querySelector(".tab-pane.active");
    const activeTabId = activeTabPane.id;
    const printableElement = document.getElementById(activeTabId);

    // Check if the element exists
    if (printableElement) {
      // Open a new window for printing
      const printWindow = window.open("", "_blank");

      // Append the content of the div to the new window for printing
      printWindow.document.write(printableElement.innerHTML);

      // Trigger the print dialog for the new window
      printWindow.print();

      // Close the new window after printing
      printWindow.close();
    } else {
      console.error("Element not found: contentToPrint");
    }
  };

  render() {
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Unsigned Agreements" />
                    </h3>
                  </div>
                </div>
              </div>

              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    {this.state.tableLoaderBlockImage === true ? (
                      tableLoaderBlockImage()
                    ) : (
                      <>
                        {this.state.agreementData.length > 0 ? (
                          <>
                            <div className="nk-block nk-block-lg">
                              <div
                                className="card card-bordered"
                                style={{
                                  backgroundColor: this.props.backgroundColor,
                                  border: "none",
                                }}
                              >
                                <div className="card-inner card-inner-xl">
                                  <ul className="nav nav-tabs">
                                    {this.state.agreementData.map(
                                      (agreements, i) => (
                                        <li className="nav-item">
                                          <a
                                            className={this.class_manage_link(
                                              i
                                            )}
                                            data-toggle="tab"
                                            href={this.set_tabs(agreements[6])}
                                            onClick={() => {
                                              this.show_agreement_content(
                                                agreements[0],
                                                agreements[1],
                                                agreements[6]
                                              );
                                            }}
                                          >
                                            <em className="icon ni ni-list-round"></em>
                                            <span>
                                              {agreements[9]}{" "}
                                              {this.class_required(
                                                agreements[3]
                                              )}
                                            </span>
                                          </a>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                  <div className="tab-content">
                                    {this.state.agreementData.map(
                                      (agreementscontent, i) => (
                                        <div
                                          className={this.class_manage_content(
                                            i
                                          )}
                                          id={this.set_tabs_content(
                                            agreementscontent[6]
                                          )}
                                        >
                                          {this.manage_content_with_replacable_data(
                                            agreementscontent[6],
                                            agreementscontent[1]
                                          )}
                                          {/*<div
                                            dangerouslySetInnerHTML={{
                                              __html: REPLACECONTENTCLIENT(
                                                agreementscontent[1],
                                                this.state.replacable_content,
                                                ""
                                              ).replace(
                                                /\[\[IMG_VENDOR_SIGN\]\]/g,
                                                this.state.vendor_signature
                                              ),
                                            }}
                                          />*/}
                                        </div>
                                      )
                                    )}
                                    {/* ACTION BUTTONS DIV  START*/}
                                    {this.state.agreement_type === "esign" ? (
                                      <>
                                        <div id="signature_div">
                                          <Row
                                            style={{ padding: "0px 0px 10px" }}
                                          >
                                            <Col sm={12}>
                                              <div>
                                                <ul className="nav nav-tabs">
                                                  <li className="nav-item">
                                                    <a
                                                      className={
                                                        this.state
                                                          .signature_type ===
                                                          "draw" ||
                                                        this.state
                                                          .signature_type === ""
                                                          ? "nav-link active"
                                                          : "nav-link"
                                                      }
                                                      data-toggle="tab"
                                                      onClick={() =>
                                                        this.updateSignatureType(
                                                          "draw"
                                                        )
                                                      }
                                                      href="#tabItem6"
                                                    >
                                                      <em className="icon ni ni-edit-alt"></em>
                                                      <span>Draw</span>
                                                    </a>
                                                  </li>
                                                  <li className="nav-item">
                                                    <a
                                                      className={
                                                        this.state
                                                          .signature_type ===
                                                        "choose"
                                                          ? "nav-link active"
                                                          : "nav-link"
                                                      }
                                                      data-toggle="tab"
                                                      onClick={() =>
                                                        this.updateSignatureType(
                                                          "choose"
                                                        )
                                                      }
                                                      href="#tabItem5"
                                                    >
                                                      <em className="icon ni ni-list-check"></em>
                                                      <span>Choose</span>
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </Col>
                                          </Row>
                                          <div className="tab-content">
                                            <div
                                              className={
                                                this.state.signature_type ===
                                                "choose"
                                                  ? "tab-pane active"
                                                  : "tab-pane"
                                              }
                                              id="tabItem5"
                                            >
                                              <div class="example-alert">
                                                <div class="alert alert-light">
                                                  Pick a style for your
                                                  signature (Authorized Person
                                                  Name).
                                                </div>
                                              </div>
                                              <div className="form-group mt-4">
                                                <div className="form-group">
                                                  <MutextField
                                                    required
                                                    id="signatureText"
                                                    name="signatureText"
                                                    type="text"
                                                    label="Authorized Person Name"
                                                    inputProps={{
                                                      maxLength: 30,
                                                    }}
                                                    onInput={(e) => {
                                                      e.target.value =
                                                        e.target.value.replace(
                                                          /[^a-zA-Z0-9 ]/gi,
                                                          ""
                                                        );
                                                    }}
                                                    defaultValue={
                                                      this.state.signatureText
                                                    }
                                                    onChange={this.handleChange}
                                                    helperText=""
                                                    variant="outlined"
                                                    fullWidth
                                                  />
                                                </div>
                                                <h6 className="title">
                                                  Choose Style
                                                </h6>

                                                <ul className="cc-pay-method">
                                                  <li className="cc-pay-dd dropdown">
                                                    <a
                                                      href="#"
                                                      className="btn btn-white btn-outline-light dropdown-toggle dropdown-indicator"
                                                      data-toggle="dropdown"
                                                    >
                                                      <span
                                                        style={{
                                                          fontFamily:
                                                            this.state
                                                              .signatureClass,
                                                        }}
                                                      >
                                                        {this.state
                                                          .signatureText ||
                                                          "Your Signature"}
                                                      </span>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-auto">
                                                      <ul className="link-list-plain">
                                                        {this.cursiveFonts.map(
                                                          (font, index) => (
                                                            <li key={index}>
                                                              <a
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={() =>
                                                                  this.handleSignature(
                                                                    font.value
                                                                  )
                                                                }
                                                                className="cc-pay-item"
                                                              >
                                                                <span className="cc-pay-item-name">
                                                                  <span
                                                                    className="cc-pay-item-method"
                                                                    style={{
                                                                      fontFamily:
                                                                        font.value,
                                                                    }}
                                                                  >
                                                                    {this.state
                                                                      .signatureText !==
                                                                    ""
                                                                      ? this
                                                                          .state
                                                                          .signatureText
                                                                      : font.name}
                                                                  </span>
                                                                </span>
                                                              </a>
                                                            </li>
                                                          )
                                                        )}
                                                      </ul>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                              <div
                                                className="signature-preview mt-3"
                                                id="styled-text-container"
                                                style={{
                                                  fontFamily:
                                                    this.state.signatureClass,
                                                  fontSize: "40px",
                                                  width: "480px",
                                                  height: "80px",
                                                  background: "trasnparent",
                                                  backgroundColor:
                                                    "transparent",
                                                  border: "none",
                                                  lineHeight: "80px",
                                                  color: "#000",
                                                }}
                                              >
                                                {this.state.signatureText ||
                                                  "Your Signature"}
                                              </div>
                                            </div>
                                            <div
                                              className={
                                                this.state.signature_type ===
                                                  "draw" ||
                                                this.state.signature_type === ""
                                                  ? "tab-pane active"
                                                  : "tab-pane"
                                              }
                                              id="tabItem6"
                                            >
                                              <Row>
                                                <Col sm={12}>
                                                  <div class="example-alert mb-4">
                                                    <div class="alert alert-light">
                                                      Please Save the signature
                                                      to proceed.
                                                    </div>
                                                  </div>
                                                  <div
                                                    id="divSig"
                                                    style={{ display: "none" }}
                                                  >
                                                    <div class="mb-4">
                                                      <img
                                                        src=""
                                                        class="clsimgSig"
                                                        height="100"
                                                        width="200"
                                                        onerror="this.style.display='none'"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <strong>Signature</strong>
                                                  </div>
                                                </Col>
                                              </Row>
                                              <Row style={{ marginTop: "1%" }}>
                                                <Col
                                                  sm={12}
                                                  style={{ height: "120px" }}
                                                >
                                                  <SignatureCanvas
                                                    clearOnResize={true}
                                                    canvasProps={{
                                                      className: styles.sigPad,
                                                    }}
                                                    velocityFilterWeight={0.7}
                                                    throttle={50}
                                                    ref={(ref) => {
                                                      this.signaturePad = ref;
                                                    }}
                                                  />
                                                </Col>
                                              </Row>
                                            </div>
                                          </div>

                                          <Row style={{ marginTop: "1%" }}>
                                            <Col sm={12}>
                                              <div style={{ float: "right" }}>
                                                <button
                                                  className="btn btn-info"
                                                  style={{
                                                    margin:
                                                      "13px 10px 12px 10px",
                                                  }}
                                                  onClick={() =>
                                                    this.handlePrint()
                                                  }
                                                >
                                                  <em class="icon ni ni-download"></em>{" "}
                                                  <span>
                                                    Download PDF / Print
                                                  </span>
                                                </button>
                                                {this.state.signature_type ===
                                                "draw" ? (
                                                  <>
                                                    {this.state
                                                      .button_submit_loader ===
                                                    false ? (
                                                      <>
                                                        <button
                                                          className="btn btn-warning"
                                                          style={{
                                                            margin:
                                                              "13px 0px 12px 0px",
                                                          }}
                                                          onClick={() =>
                                                            this.clear()
                                                          }
                                                        >
                                                          <em class="icon ni ni-delete"></em>{" "}
                                                          <span>Clear</span>
                                                        </button>
                                                        <button
                                                          className="btn btn-success"
                                                          style={{
                                                            margin:
                                                              "13px 0px 12px 10px",
                                                          }}
                                                          onClick={() =>
                                                            this.preview()
                                                          }
                                                        >
                                                          <em class="icon ni ni-eye"></em>{" "}
                                                          <span>
                                                            Preview Signature
                                                          </span>
                                                        </button>
                                                      </>
                                                    ) : null}
                                                  </>
                                                ) : null}
                                                {this.state
                                                  .button_submit_loader ===
                                                false ? (
                                                  <button
                                                    className="btn btn-primary  "
                                                    onClick={() =>
                                                      this.open_signee()
                                                    }
                                                    id="signee_button"
                                                    style={{
                                                      margin:
                                                        "13px 12px 12px 10px",
                                                    }}
                                                  >
                                                    <em class="icon ni ni-send"></em>{" "}
                                                    <span>Send To Signee</span>
                                                  </button>
                                                ) : null}
                                                <button
                                                  className="btn"
                                                  type="button"
                                                  id="loading_btn"
                                                  style={{
                                                    display: "none",
                                                    backgroundColor:
                                                      THEME_COLOR,
                                                    color: THEME_TEXT_COLOR,
                                                  }}
                                                  disabled
                                                >
                                                  <span
                                                    className="spinner-grow spinner-grow-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                  ></span>
                                                  <span> Wait... </span>
                                                </button>
                                                <button
                                                  className="btn "
                                                  type="button"
                                                  id="loading_btn"
                                                  style={{
                                                    display: "none",
                                                    backgroundColor:
                                                      THEME_COLOR,
                                                    color: THEME_TEXT_COLOR,
                                                    marginLeft: "12px",
                                                  }}
                                                  disabled
                                                >
                                                  <span
                                                    className="spinner-grow spinner-grow-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                  ></span>
                                                  <span> Wait... </span>
                                                </button>
                                                <button
                                                  className="btn"
                                                  style={{
                                                    backgroundColor:
                                                      THEME_COLOR,
                                                    color: THEME_TEXT_COLOR,
                                                    margin: "13px 0px 12px 0px",
                                                  }}
                                                  onClick={() => this.save()}
                                                  disabled={this.state.disabled}
                                                  id="save_btn"
                                                >
                                                  <em class="icon ni ni-folder-check"></em>{" "}
                                                  <span>Save</span>
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      </>
                                    ) : null}
                                    {this.state.agreement_type === "accept" ? (
                                      <div
                                        id="signature_div"
                                        className="signature_width"
                                      >
                                        <div className="nk-block">
                                          <div className="row g-gs">
                                            <div className="col-md-12">
                                              <div className="card card-bordered card-full">
                                                <div className="nk-wg1">
                                                  <div className="nk-wg1-block">
                                                    <div className="nk-wg1-text">
                                                      <div className="form-group">
                                                        <div className="custom-control custom-control-xs custom-checkbox">
                                                          <input
                                                            type="checkbox"
                                                            name="is_checked"
                                                            className="custom-control-input"
                                                            id="checkbox"
                                                            defaultChecked={
                                                              this.state
                                                                .is_checked
                                                            }
                                                            value="yes"
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                          />
                                                          <label
                                                            className="custom-control-label"
                                                            htmlFor="checkbox"
                                                          >
                                                            I accept the{" "}
                                                            {
                                                              this.state
                                                                .agreement_title
                                                            }
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="nk-wg1-action"
                                                    style={{
                                                      padding: "1.25rem",
                                                    }}
                                                  >
                                                    <div className="form-group ">
                                                      <button
                                                        className="btn btn-lg"
                                                        type="button"
                                                        id="loading_btn"
                                                        style={{
                                                          display: "none",
                                                          backgroundColor:
                                                            THEME_COLOR,
                                                          color:
                                                            THEME_TEXT_COLOR,
                                                        }}
                                                        disabled
                                                      >
                                                        <span
                                                          className="spinner-grow spinner-grow-sm"
                                                          role="status"
                                                          aria-hidden="true"
                                                        ></span>
                                                        <span> Wait... </span>
                                                      </button>
                                                      <button
                                                        className="btn btn-lg"
                                                        type="button"
                                                        id="loading_btn"
                                                        style={{
                                                          display: "none",
                                                          backgroundColor:
                                                            THEME_COLOR,
                                                          color:
                                                            THEME_TEXT_COLOR,
                                                        }}
                                                        disabled
                                                      >
                                                        <span
                                                          className="spinner-grow spinner-grow-sm"
                                                          role="status"
                                                          aria-hidden="true"
                                                        ></span>
                                                        <span> Wait... </span>
                                                      </button>

                                                      <button
                                                        className="btn btn-primary"
                                                        onClick={() =>
                                                          this.save()
                                                        }
                                                        id="save_btn"
                                                      >
                                                        <em class="icon ni ni-folder-check"></em>{" "}
                                                        <span>
                                                          Accept and Continue
                                                        </span>
                                                      </button>
                                                      <button
                                                        className="btn btn-warning"
                                                        onClick={() =>
                                                          this.open_signee()
                                                        }
                                                        style={{
                                                          margin:
                                                            "0px 12px 0px 10px",
                                                        }}
                                                        id="signee_button"
                                                      >
                                                        <em class="icon ni ni-send"></em>{" "}
                                                        <span>
                                                          Send to Signee
                                                        </span>
                                                      </button>
                                                      <button
                                                        className="btn btn-info"
                                                        style={{
                                                          margin:
                                                            "13px 0px 12px 10px",
                                                        }}
                                                        onClick={() =>
                                                          this.handlePrint()
                                                        }
                                                      >
                                                        <em class="icon ni ni-download"></em>{" "}
                                                        <span>
                                                          Download PDF / Print
                                                        </span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {/* ACTIONS BUTTONS DIV END */}
                                  </div>
                                </div>
                                <div class="col-lg-12 mb-4">
                                  <div class="alert alert-light alert-icon">
                                    <em class="icon ni ni-alert-circle"></em>{" "}
                                    <b>Full Legal Name:</b> This field requires
                                    the full legal name of the signing
                                    authority. If you are not the authorized
                                    signer, please select the 'Send to Signee'
                                    option below. The designated signee will
                                    receive an email directly from our system
                                    with the document attached for signature.
                                    The recipient does not need to register an
                                    account to sign the document.{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="example-alert">
                              <div className="alert alert-pro alert-warning">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>You have no unsigned agreements.</p>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  {/* Table 5th Col End */}
                  {/* SUCCESS MODAL START */}
                  <div className="modal fade" tabIndex="-1" id="modalAlert">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHide();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4 className="nk-modal-title">
                              {this.state.successMessage}
                            </h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                You’ve successfully signed{" "}
                                <strong>Agreement</strong>
                              </div>
                            </div>
                            <div className="nk-modal-action">
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHide();
                                }}
                              >
                                OK
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* SUCCESS MODAL End */}
                  {/* ERROR MODAL START */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalAlertError"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                            <h4 className="nk-modal-title">
                              Unable to Process!
                            </h4>
                            <div className="nk-modal-text">
                              <p className="lead">{this.state.errorMessage}</p>
                            </div>
                            <div className="nk-modal-action mt-5">
                              <a
                                href="#"
                                className="btn btn-lg btn-mw btn-light"
                                data-dismiss="modal"
                              >
                                Cancel
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ERROR MODAL END */}
                  {/* Signee Model */}

                  <div
                    className="modal fade"
                    tabindex="-1"
                    id="modalSendSignee"
                  >
                    <div
                      className="modal-dialog modal-dialog-top modal-xl"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">Send To Signee</h5>
                        </div>
                        <div className="modal-body">
                          {this.state.errorMessageSignee !== "" ? (
                            <div className="example-alert">
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessageSignee}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessageSignee !== "" ? (
                            <div className="example-alert">
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessageSignee}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <br />

                          <div className="row g-4">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-group">
                                  <MutextField
                                    required
                                    id="signee_name"
                                    name="signee_name"
                                    type="text"
                                    label="Full Name"
                                    value={this.state.signee_name}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <MutextField
                                  id="signee_title"
                                  name="signee_title"
                                  type="text"
                                  label="Title"
                                  value={this.state.signee_title}
                                  onChange={this.handleChange}
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <MutextField
                                  required
                                  id="signee_email"
                                  name="signee_email"
                                  type="text"
                                  label="Email Address"
                                  value={this.state.signee_email}
                                  onChange={this.handleChange}
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group">
                                <textarea
                                  class="form-control"
                                  id="signee_comment"
                                  name="signee_comment"
                                  onChange={this.handleChangeTextArea}
                                  placeholder="Comments *"
                                  maxLength={1000}
                                >
                                  {this.state.signee_comment}
                                </textarea>
                                <div class="form-note">
                                  {this.state.signee_comment.length}/
                                  {this.state.charLimit} characters
                                </div>
                                <div class="form-note">
                                  {this.state.characterExceededMessage &&
                                  this.state.characterExceededMessage !== "" ? (
                                    <code>
                                      {this.state.characterExceededMessage}
                                    </code>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div class="example-alert">
                                <div class="alert alert-warning alert-icon">
                                  <em class="icon ni ni-alert-circle"></em>{" "}
                                  <strong>NOTE</strong>. This will send new
                                  agreement sign request. Your previous request
                                  will be discarded if you have sent any.
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 text-right">
                              <button
                                type="button"
                                className="btn btn-light"
                                style={{ marginRight: "10px" }}
                                onClick={() => this.close_signee()}
                              >
                                <em class="icon ni ni-step-back"></em> Cancel
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.send_to_signee()}
                                disabled={this.state.disabled}
                              >
                                <em className="icon ni ni-done"></em> Send
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Signee Model */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Agreements);
