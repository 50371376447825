import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addSettings } from "../../../actions";
import {
  listSalesOrders,
  singleCreditApplication,
} from "./../../../config/client_api_calls";

import $ from "jquery";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { TableLoader } from "../../../components/ContentLoaders/newLoaders/TableLoader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  APP_LIVE_URL,
} from "./../../../config/config";

import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();

class SalesOrders extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      tableDataSingle: [],
      serviceData: [],
      tableLoaderListing: true,
      tableLoader: true,
      errorMessage: "",
      successMessage: "",
      columnServices: [
        {
          name: "orderno",
          label: "Order #",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.create_link(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "quote_accountno",
          label: "Quotation #",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.create_link_quotation(value, tableMeta)}</div>;
            },
          },
        },

        {
          name: "dateCreated",
          label: "Date Created",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatDate(value)}</div>;
            },
          },
        },
        {
          name: "quote_attachement",
          label: "Attachement",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.viewPdf(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "quote_status",
          label: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setStatus(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "payment_status",
          label: "Payment Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setPaymentStatus(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.addMenuHistory(
                                      "/" +
                                        auth.getResellerRegToken() +
                                        "/client/ViewSalesOrder/" +
                                        tableMeta.rowData[0]
                                    )
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="View Quotation"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-list"></em>
                                  <span>View</span>
                                </a>
                              </li>
                              {tableMeta.rowData[4] === "pending" ||
                              tableMeta.rowData[4] === "in_review" ? (
                                <li>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.addMenuHistory(
                                        "/" +
                                          auth.getResellerRegToken() +
                                          "/client/EditSalesOrder/" +
                                          tableMeta.rowData[0]
                                      )
                                    }
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=" integration "
                                  >
                                    <em class="icon ni ni-edit"></em>
                                    <span> Edit </span>
                                  </a>
                                </li>
                              ) : (
                                ""
                              )}
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    const servicesResponce = await listSalesOrders(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("Available SalesOrders: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    }
  }

  setStatus = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === "pending") {
      return (
        <div>
          <span className="badge badge-outline-warning">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> Pending
          </span>
        </div>
      );
    } else if (domain_str === "approved") {
      return (
        <div>
          <span className="badge badge-outline-success">
            {" "}
            <em className="icon ni ni-done "></em> Approved
          </span>
        </div>
      );
    } else if (domain_str === "cancelled") {
      return (
        <div>
          <span className="badge badge-outline-danger">
            {" "}
            <em className="icon ni ni-alert-c"></em> Cancelled
          </span>
        </div>
      );
    } else if (domain_str === "in_review") {
      return (
        <div>
          <span className="badge badge-outline-secondary">
            {" "}
            <em className="icon ni ni-alert-c"></em> In Review
          </span>
        </div>
      );
    } else if (domain_str === "converted") {
      return (
        <div>
          <span className="badge badge-outline-primary">
            {" "}
            <em className="icon ni ni-alert-c"></em> Converted
          </span>
        </div>
      );
    } else if (domain_str === "completed") {
      return (
        <div>
          <span className="badge badge-success">
            {" "}
            <em class="icon ni ni-done"></em> Completed
          </span>
        </div>
      );
    }
  };

  setPaymentStatus = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === "unpaid") {
      return (
        <div>
          <span className="badge badge-outline-danger">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> Unpaid
          </span>
        </div>
      );
    } else if (domain_str === "paid") {
      return (
        <div>
          <span className="badge badge-outline-success">
            {" "}
            <em className="icon ni ni-done "></em> Paid
          </span>
        </div>
      );
    }
  };

  setComplete = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === "incomplete") {
      return (
        <div>
          <span className="badge badge-outline-warning">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> In Progress
          </span>
        </div>
      );
    } else if (domain_str === "complete") {
      return (
        <div>
          <span className="badge badge-outline-secondary">
            {" "}
            <em className="icon ni ni-done "></em> Submitted
          </span>
        </div>
      );
    }
  };

  viewPdf = (value, tableMeta) => {
    let domain_str = value;
    const rowIndex = tableMeta.rowIndex;
    //console.log("domain_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      return (
        <div>
          {this.state.downloadingIndex === rowIndex ? (
            <span className="badge badge-outline-light">
              {" "}
              <em class="icon ni ni-download"></em> Downloading...
            </span>
          ) : (
            <a
              onClick={() => {
                this.setState({ downloadingIndex: rowIndex });
                setTimeout(() => {
                  //this.setState({ downloadingIndex: null });
                  this.downloadPdf(value, tableMeta);
                }, 2000);
                //this.downloadPdf(value, tableMeta)
              }}
              style={{ cursor: "pointer" }}
              title="Download PDF"
            >
              <span className="badge badge-outline-secondary">
                {" "}
                <em class="icon ni ni-file-pdf"></em> View Attachement
              </span>
            </a>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-outline-light">
            {" "}
            <em class="icon ni ni-file-pdf"></em> No Attachement
          </span>
        </div>
      );
    }
  };

  create_link = (value, tableMeta) => {
    let quote_accountno = tableMeta.rowData[0];
    if (quote_accountno !== "") {
      return (
        <div>
          <a
            style={{ cursor: "pointer", textDecoration: "underline" }}
            className="text-primary"
            onClick={() =>
              this.addMenuHistory(
                "/" +
                  auth.getResellerRegToken() +
                  "/client/ViewSalesOrder/" +
                  tableMeta.rowData[0]
              )
            }
            data-toggle="tooltip"
            data-placement="top"
            title="View Quotation"
            disabled={
              this.props.is_msa_signed === "No" ||
              this.props.defaultSettings === "missing"
                ? true
                : false
            }
          >
            {quote_accountno}
          </a>
        </div>
      );
    }
  };

  create_link_quotation = (value, tableMeta) => {
    let quote_accountno = tableMeta.rowData[1];
    if (quote_accountno !== "") {
      return (
        <div>
          <a
            style={{ cursor: "pointer", textDecoration: "underline" }}
            className="text-primary"
            onClick={() =>
              this.addMenuHistory(
                "/" +
                  auth.getResellerRegToken() +
                  "/client/ViewQuotation/" +
                  tableMeta.rowData[1]
              )
            }
            data-toggle="tooltip"
            data-placement="top"
            title="View Quotation"
            disabled={
              this.props.is_msa_signed === "No" ||
              this.props.defaultSettings === "missing"
                ? true
                : false
            }
          >
            {quote_accountno}
          </a>
        </div>
      );
    }
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  serviceSigning = async (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = tableMeta.rowData[5];
    if (is_default === "No") {
      return (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => this.setDefaultService(value, tableMeta)}
          disabled={
            this.props.is_msa_signed === "No" ||
            this.props.defaultSettings === "missing"
              ? true
              : false
          }
        >
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-signin"></em> Make Default
          </span>
        </a>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    }
  };

  setMarkUp = async (value, tableMeta) => {
    let service_id = tableMeta.rowData[0];

    window.$("#modalForm").modal("show");
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  downloadPdf = async (d_f_name, tableMeta) => {
    var url = d_f_name;
    let file_name = "";
    let check_url = this.isStringURL(url);
    console.log("check_url: " + check_url + " " + url);
    if (check_url === true) {
      file_name = this.getFileNameFromUrl(url);
    } else {
      file_name = url;
      url = APP_LIVE_URL + "files_data/rfq/" + url;
    }
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        this.setState({ errorMessage: "", downloadingIndex: null });
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the attachment.",
          downloadingIndex: null
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  getFileNameFromUrl = (url) => {
    try {
      const urlObject = new URL(url);
      // Using the pathname for simplicity; adjust as needed based on your URL structure
      const fileName = urlObject.pathname.split("/").pop();
      return fileName;
    } catch (error) {
      console.error("Error extracting file name:", error);
      return "attachement";
    }
  };

  isStringURL = (str) => {
    try {
      new URL(str);
      return true;
    } catch (error) {
      return false;
    }
  };

  handleChanges = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeCheckbox = (input) => (e) => {
    this.setState({
      [input]:
        e.target.type === "checkbox" ? e.target.checked : e.target.checked,
    });
    //console.log("handleChangeCheckbox: ", input);
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  generateRandomNumber = (link) => {
    const min = 1000000; // Minimum 10-digit number (10^9)
    const max = 9999999; // Maximum 10-digit number (10^10 - 1)
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    this.props.history.push(link + "/" + randomNumber);
  };

  addMenuHistory = (link) => {
    this.props.history.push(link);
  };

  add_sales_order = async () => {
    
      window.$("#modalFormClients").modal("hide");
      this.addMenuHistory(
        "EditSalesOrder/" +
          this.generateRandomNumber() +
          ""
      );
    
  };

  render() {
    const { step } = this.state;
    const { email, VerifyUsernameButton } = this.state;
    const values = {
      email,
      VerifyUsernameButton,
    };
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div
        className={
          this.props.from === "dashboard"
            ? "nk-content newcontent_padding"
            : "nk-content"
        }
        id="Support_Block"
      >
        <div
          className={
            this.props.from === "dashboard"
              ? "container-fluid newcontainerfluid_padding"
              : "container-fluid"
          }
        >
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              {/* RIGHTS MODAL START */}

              {/* RIGHTS MODAL End */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Orders" icon="icon ni ni-file-docs" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                  <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        datatarget="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        datacontent="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <a
                              style={{ cursor: "pointer", color: "#fff" }}
                              onClick={() =>
                                this.generateRandomNumber("EditSalesOrder")
                              }
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;Add New Order
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert example-alert-revoke mt-3 mb-3"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.tableLoader === true ? (
                            TableLoader()
                          ) : (
                            <DataTableExtended
                              columns={this.state.columnServices}
                              tableData={this.state.tableData}
                              title=""
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SalesOrders));
