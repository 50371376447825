import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { addSettings } from "../../../actions";
import {
  listClientDocuments,
  getSubmittedDocuments,
} from "./../../../config/client_api_calls";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { ADMIN_URL, APP_LIVE_URL } from "./../../../config/config";
import dateFormat, { masks } from "dateformat";
import MutextField from "@material-ui/core/TextField";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import axios from "axios";
import { reject } from "lodash";

const auth = new HelperClass();

class Agreements extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      errorMessage: "",
      successMessage: "",
      tableLoader: false,
      pdfurl: "",
      unsigned_agreements: "1",
      document_id: "",
      document_title: "",
      document_file: [],
      document_file_name: "",
      submission_submitted: null,
      submitted_file: null,
      submission_data: [],
      rejected_data: [],
      downloadingIndex: null,
      isDownloading: false,
      selectedTab: "approved",
      columnServices: [
        { name: "ID" },
        { name: "Title" },
        {
          name: "Date Added",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "View / Download",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.viewDownloadBtns(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Submission",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.uploadSubmission(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Approved",
          options: {
            display: false,
          },
        },
        {
          name: "Submitted",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.assignedTo(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "File",
          options: {
            display: false,
          },
        },
        {
          name: "Document ID",
          options: {
            display: false,
          },
        },
      ],
    };
  }

  async componentDidMount() {
    const servicesResponce = await listClientDocuments(
      auth.getClientAccount(),
      auth.getClientToken(),
      auth.getUserTypeClient()
    );

    console.log("servicesResponce.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      const clientDetails = servicesResponce.data.data || [];

      // Calculate total, approved, and rejected records
      const totalRecordsClients = clientDetails.length;
      console.log("totalRecordsClients", totalRecordsClients);
      const totalApprovedClients = clientDetails.filter(
        (client) => client.is_approved === 1
      ).length;
      const totalRejectedClients = clientDetails.filter(
        (client) => client.is_approved === 0
      ).length;
      const totalPendingClients = clientDetails.filter(
        (client) => client.is_approved === null
      ).length;

      this.setState({
        tableData: clientDetails,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  viewDownloadBtns = (value, tableMeta) => {
    const rowIndex = tableMeta.rowIndex;
    const is_approved = tableMeta.rowData[5];
    const submitted_file = tableMeta.rowData[7];
    let domain_str;

    domain_str = value;

    const filename = domain_str ? domain_str.split("/").pop() : "";

    if (domain_str && domain_str !== "") {
      return (
        <div>
          <a
            onClick={() => {
              this.setState({ downloadingIndex: rowIndex });

              // Start loader and stop after 1 second
              setTimeout(() => {
                this.setState({ downloadingIndex: null });
                // Start download after loader stops
                this.downloadCreditAppPdf(filename, is_approved);
              }, 2000);
            }}
            style={{ cursor: "pointer" }}
            title="Download Document"
          >
            {this.state.downloadingIndex === rowIndex ? (
              <div className="text-left">
                <div
                  className="spinner-border spinner-border-sm text-primary"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <span className="badge badge-outline-secondary">
                {" "}
                <em className="icon ni ni-file-pdf"></em> View
              </span>
            )}
          </a>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-outline-light">
            {" "}
            <em class="icon ni ni-file-pdf"></em> View
          </span>
        </div>
      );
    }
  };

  assignedTo = (value, tableMeta) => {
    return (
      <span
        className="badge badge-outline-secondary"
        onClick={() => this.openAttachedUsers(value, tableMeta)}
        style={{ cursor: "pointer" }}
      >
        View Submissions
      </span>
    );
  };

  openAttachedUsers = async (value, tableMeta) => {
    let document_id = tableMeta.rowData[8];
    this.setState({
      isProcessing: true,
      document_id: document_id,
      total_submissions_clients: 0,
      total_approved_clients: 0,
      total_rejected_clients: 0,
      total_pending_clients: 0,
    });

    const servicesResponce = await getSubmittedDocuments(
      auth.getClientAccount(),
      auth.getClientToken(),
      auth.getUserTypeClient(),
      document_id
    );

    console.log("getSubmittedDocuments.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      // Handle not found scenario if necessary
      // window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      const submissionData = servicesResponce.data.data || [];
      const rejectedData = servicesResponce.data.rejectedDocuments || [];

      // Calculate total submissions, approved, and rejected for clients
      const totalSubmissionsClients = submissionData.length;
      const totalApprovedClients = submissionData.filter(
        (doc) => doc.is_approved === 1
      ).length;
      const totalRejectedClients = submissionData.filter(
        (doc) => doc.is_approved === 0
      ).length;
      const totalPendingClients = submissionData.filter(
        (doc) => doc.is_approved === null
      ).length;

      this.setState({
        submission_data: submissionData,
        rejected_data: rejectedData,
        tableLoader: false,
        total_submissions_clients: totalSubmissionsClients,
        total_approved_clients: totalApprovedClients,
        total_rejected_clients: totalRejectedClients,
        total_pending_clients: totalPendingClients,
      });
    } else {
      // Handle other response cases if necessary
    }

    window.$("#modalSubmissionDocument").modal("show");
  };

  uploadSubmission = (value, tableMeta) => {
    let submission_value = value;

    if (submission_value && submission_value === "Yes") {
      return (
        <a
          onClick={() => this.uploadSubmissionDoc(tableMeta)}
          style={{ cursor: "pointer" }}
        >
          <span class="badge badge-outline-primary">
            <em className="icon ni ni-upload"></em>
            Upload
          </span>
        </a>
      );
    } else {
      return (
        <div>
          <span>---</span>
        </div>
      );
    }
  };

  approvedSubmission = (value, tableMeta) => {
    let submission_value = tableMeta.rowData[4];
    let is_approved = tableMeta.rowData[5];
    let submission_submitted = tableMeta.rowData[6];

    if (
      submission_value &&
      submission_value === "Yes" &&
      submission_submitted === "Yes"
    ) {
      return (
        <div>
          <span
            className={`badge ${
              is_approved && is_approved === 1
                ? "badge-outline-success"
                : "badge-outline-danger"
            }`}
          >
            <em className="icon ni ni-file-pdf"></em>
            {is_approved && is_approved === 1 ? "Yes" : "No"}
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span>---</span>
        </div>
      );
    }
  };

  submitSubmission = (value, tableMeta) => {
    let submission_value = tableMeta.rowData[4];
    let submission_submitted = tableMeta.rowData[6];

    if (submission_value && submission_value === "Yes") {
      return (
        <div>
          <span
            className={`badge ${
              submission_submitted && submission_submitted === "Yes"
                ? "badge-outline-success"
                : "badge-outline-danger"
            }`}
          >
            <em className="icon ni ni-file-pdf"></em>
            {submission_submitted && submission_submitted === "Yes"
              ? "Yes"
              : "No"}
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span>---</span>
        </div>
      );
    }
  };

  uploadSubmissionDoc = async (tableMeta) => {
    const document_title = tableMeta.rowData[1];
    const document_id = tableMeta.rowData[8];
    this.setState({
      errorMessage: "",
      successMessage: "",
      document_title: document_title,
      document_id: document_id,
    });
    window.$("#modalNewCollateral").modal("show");
    setTimeout(() => {
      this.setState({
        formLoader: false,
      });
    }, 2000);
  };

  reSubmissionDoc = async (title, id) => {
    const document_title = title;
    const document_id = id;
    this.setState({
      errorMessage: "",
      successMessage: "",
      document_title: document_title,
      document_id: document_id,
    });
    window.$("#modalSubmissionDocument").modal("hide");
    window.$("#modalNewCollateral").modal("show");
    setTimeout(() => {
      this.setState({
        formLoader: false,
      });
    }, 2000);
  };

  downloadCreditAppPdf = async (d_f_name, is_approved) => {
    //this.setState({ processing_request: true });
    console.log("d_f_name", d_f_name);

    const baseURL = ADMIN_URL;
    const url = `${baseURL}files_data/collaterals/${d_f_name}`;
    console.log("url: ", url);
    let file_name = d_f_name;
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the document.",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  downloadCreditAppPdfSubmission = (file_path, index) => {
    // Set the downloading state for the specific document
    this.setState((prevState) => ({
      isDownloading: {
        ...prevState.isDownloading,
        [index]: true,
      },
    }));

    // Log the file_path for debugging
    console.log("file_path", file_path);

    // Use a timeout to add a 500ms delay before starting the download
    setTimeout(async () => {
      try {
        const response = await fetch(file_path);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.href = url;
        a.download = file_path.split("/").pop(); // Extract file name from the path
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Fetch error:", error);
        this.setState({
          errorMessage: "There is an error while downloading the document.",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
      } finally {
        // Reset the downloading state for the specific document
        this.setState((prevState) => ({
          isDownloading: {
            ...prevState.isDownloading,
            [index]: false,
          },
        }));
      }
    }, 500); // 500ms delay
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };

  format_date_inner = (value) => {
    if (value && value !== "") {
      let date = value;
      return dateFormat(date, "mmmm dS, yyyy");
    }
    return value;
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation
    const allowedFileTypes = [
      ".png",
      ".jpg",
      ".jpeg",
      ".doc",
      ".docx",
      ".pdf",
      ".csv",
      ".xlsx",
      ".xls",
    ];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        errorMessage: "Invalid file type. Please select a valid file format.",
      });
      return;
    }

    // File size validation (e.g., limit to 10MB)
    const maxSizeBytes = 10 * 1024 * 1024; // 5MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        errorMessage:
          "File size exceeds the limit (10MB). Please choose a smaller file.",
      });
      return;
    }

    // Clear any previous errors
    this.setState({
      document_file: selectedFile,
      errorMessage: "",
      document_file_name: selectedFile.name,
    });
    //return true;
  };

  uploadSubmissionFun = async () => {
    this.setState({ isProcessing: true });

    const { document_file, document_file_name, document_title, document_id } =
      this.state;

    if (document_title === "") {
      this.setState({
        successMessage: "",
        errorMessage: "Title field is required.",
        isProcessing: false,
      });
    } else {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("file", document_file);
      formData.append("document_title", document_title);
      formData.append("document_id", document_id);
      formData.append("accountno", auth.getClientAccount());
      formData.append("fileName", document_file_name);

      try {
        const res = await axios.post(
          process.env.REACT_APP_API_URL +
            "clients/upload_submission_document_client",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
            },
          }
        );

        console.log("File Upload RES ", res.data);

        if (
          res.data.status === 403 ||
          res.data.errors === "authentication missing" ||
          res.data.errors === "jwt expired"
        ) {
          auth.logout();
        } else if (res.data.status === 404) {
          this.setState({
            successMessage: "",
            errorMessage: res.data.message || "Failed to upload the document.",
          });
          // Optionally redirect to an error page
          // window.location.replace("/error");
        } else if (res.data.status === 409) {
          this.setState({
            successMessage: "",
            errorMessage:
              res.data.message ||
              "Uploaded document does not match the original document.",
          });
          // Optionally redirect to an error page
          // window.location.replace("/error");
        } else if (res.data.status === 200 && res.data.message === "success") {
          this.setState({
            successMessage: "Document uploaded successfully.",
            errorMessage: "",
            document_file_name: "",
            document_file: "",
            isProcessing: false,
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          this.setState({
            successMessage: "",
            errorMessage: "There is some error while adding the document.",
            isProcessing: false,
          });
        }
      } catch (error) {
        this.setState({
          successMessage: "",
          errorMessage: "An error occurred during the upload process.",
          isProcessing: false,
        });
      }
    }

    setTimeout(() => {
      this.setState({
        successMessage: "",
        errorMessage: "",
      });
    }, 3000);
  };

  handleAccordionToggle(id, index) {
    const element = document.getElementById(id);
    const isActive = this.state.activeAccordion === index;

    // Close all accordions first
    const allAccordionItems = document.querySelectorAll(".accordion-row");
    allAccordionItems.forEach((item) => {
      item.classList.remove("show");
    });

    // Toggle the clicked accordion
    if (!isActive) {
      element.classList.add("show");
      this.setState({ activeAccordion: index });
    } else {
      this.setState({ activeAccordion: null });
    }
  }

  render() {
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Documents" icon="icon ni ni-file-docs" />
                    </h3>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title=""
                        />
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/* Table Start */}
                  <div
                    className="modal fade"
                    tabindex="-1"
                    id="modalNewCollateral"
                  >
                    <div
                      className="modal-dialog modal-dialog-top modal-xl"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">
                            <em
                              className="icon ni ni-users"
                              style={{ marginRight: "15px" }}
                            ></em>{" "}
                            Upload Submission Document
                          </h5>
                        </div>
                        <div className="modal-body">
                          {this.state.formLoader === true ? (
                            FormLoader()
                          ) : (
                            <div className="row g-4">
                              {this.state.errorMessage !== "" ? (
                                <div className="col-md-12 mb-3">
                                  <div
                                    className="example-alert example-alert-revoke"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessage !== "" ? (
                                <div className="col-md-12 mb-3">
                                  <div
                                    className="example-alert example-alert-revoke"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <MutextField
                                      required
                                      id="document_title"
                                      name="document_title"
                                      type="text"
                                      label="Title"
                                      value={this.state.document_title}
                                      inputProps={{ maxLength: 64 }}
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^a-zA-Z0-9 -_]/gi,
                                          ""
                                        );
                                      }}
                                      helperText="Title of the document."
                                      autoFocus
                                      onChange={this.handleChange}
                                      variant="outlined"
                                      fullWidth
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <label className="form-label">
                                      Upload File
                                      <sup> *</sup>
                                    </label>
                                  </div>
                                  <div
                                    className="form-control-group"
                                    id="fileContainer"
                                  >
                                    <input
                                      type="file"
                                      id="document_file"
                                      className="file-block"
                                      name="document_file"
                                      defaultValue={this.state.document_file}
                                      accept=".png, .jpg, .jpeg, .doc, .docx, .pdf, .csv, .xlsx, .xls"
                                      onChange={this.handleFileChange}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 d-flex justify-content-end">
                                <button
                                  type="button"
                                  onClick={() => this.uploadSubmissionFun()}
                                  className="btn btn-primary"
                                  disabled={this.state.disabled} // disable button when processing
                                >
                                  <span>Submit</span>

                                  <em className="icon ni ni-emails"></em>
                                </button>
                              </div>
                              <div className="col-md-12">
                                <div className="example-alert">
                                  <div className="alert alert-light">
                                    <ul className="list">
                                      <li>
                                        Supported file formats include: PNG,
                                        JPG, JPEG, DOC, DOCX, PDF, CSV, XLSX,
                                        XLS.
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table Start */}
                  {/**** Open Submission Modal ***/}
                  <div
                    class="modal fade"
                    tabindex="-1"
                    id="modalSubmissionDocument"
                  >
                    <div
                      class="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div class="modal-content">
                        <a
                          href="#"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em class="icon ni ni-cross"></em>
                        </a>
                        <div class="modal-header">
                          <h5 class="modal-title">Submitted Documents</h5>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="form-control-group">
                              {/* <ul class="nav nav-tabs">
                                <li class="nav-item">
                                  <a
                                    className={`nav-link ${
                                      this.state.selectedTab === "approved"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      this.setState({
                                        selectedTab: "approved",
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <em className="icon ni ni-done"></em>
                                    <span>Approved</span>
                                  </a>
                                </li>
                                <li class="nav-item">
                                  <a
                                    className={`nav-link ${
                                      this.state.selectedTab === "rejected"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      this.setState({
                                        selectedTab: "rejected",
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <em className="icon ni ni-cross-circle"></em>
                                    <span>Rejected</span>
                                  </a>
                                </li>
                              </ul> */}
                              <div class="tab-content">
                                <div>
                                  <div class="modal-body">
                                    <div class="g-4">
                                      <div
                                        style={{
                                          textAlign: "end",
                                        }}
                                      >
                                        <a
                                          className="nav-link"
                                          style={{
                                            color: "#526484",
                                            fontWeight: 600,
                                            color: "#526484",
                                          }}
                                        >
                                          {/* <>
                                            Total Submissions:{" "}
                                            {
                                              this.state
                                                .total_submissions_clients
                                            }{" "}
                                            | Total Approved:{" "}
                                            {this.state.total_approved_clients}{" "}
                                            | Total Rejected:{" "}
                                            {this.state.total_rejected_clients}{" "}
                                            | Total Pending:{" "}
                                            {this.state.total_pending_clients}
                                          </> */}
                                          <>
                                            Total Submissions:&nbsp;
                                            <span>
                                              {
                                                this.state
                                                  .total_submissions_clients
                                              }
                                            </span>
                                            &nbsp;| Total Approved:&nbsp;
                                            <span style={{ color: "#1ee0ac" }}>
                                              {
                                                this.state
                                                  .total_approved_clients
                                              }
                                            </span>
                                            &nbsp;| Total Rejected:&nbsp;
                                            <span style={{ color: "#e85347" }}>
                                              {
                                                this.state
                                                  .total_rejected_clients
                                              }
                                            </span>
                                            &nbsp;| Total Pending:&nbsp;
                                            <span style={{ color: "orange" }}>
                                              {this.state.total_pending_clients}
                                            </span>
                                          </>
                                        </a>
                                      </div>
                                      <div id="no-more-tables-new">
                                        <table class="table table-bordered">
                                          <thead class="thead-light">
                                            <tr>
                                              <th class="tb-col-ip">
                                                <span class="overline-title">
                                                  Info
                                                </span>
                                              </th>
                                              <th class="tb-col-ip">
                                                <span class="overline-title">
                                                  Document Title
                                                </span>
                                              </th>
                                              {/* <th class="tb-col-ip">
                                                <span class="overline-title">
                                                  Submitted File
                                                </span>
                                              </th> */}
                                              <th class="tb-col-ip">
                                                <span class="overline-title">
                                                  Download
                                                </span>
                                              </th>
                                              <th class="tb-col-ip">
                                                <span class="overline-title">
                                                  Status
                                                </span>
                                              </th>
                                              <th class="tb-col-ip">
                                                <span class="overline-title">
                                                  Date Submission
                                                </span>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.submission_data &&
                                            this.state.submission_data.length >
                                              0 ? (
                                              <>
                                                {this.state.submission_data.map(
                                                  (details, index) => (
                                                    <>
                                                      <tr key={`idx${index}`}>
                                                        <td className="tb-col-ip text-center">
                                                          <span
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              this.handleAccordionToggle(
                                                                `accordion-item-${index}`,
                                                                index
                                                              )
                                                            }
                                                          >
                                                            <em
                                                              className={`icon ni ${
                                                                this.state
                                                                  .activeAccordion ===
                                                                index
                                                                  ? "ni-minus"
                                                                  : "ni-plus"
                                                              }`}
                                                            ></em>
                                                          </span>
                                                        </td>
                                                        <td className="tb-col-os">
                                                          {
                                                            details.document_title
                                                          }
                                                        </td>
                                                        {/* <td className="tb-col-os">
                                                          {
                                                            details.document_file
                                                          }
                                                        </td> */}
                                                        <td className="tb-col-os text-center">
                                                          <span
                                                            style={{
                                                              cursor: "pointer",
                                                              marginRight:
                                                                "20px",
                                                              fontSize: "18px",
                                                            }}
                                                            onClick={() => {
                                                              this.downloadCreditAppPdfSubmission(
                                                                details.file_path,
                                                                index
                                                              );
                                                            }}
                                                          >
                                                            {this.state
                                                              .isDownloading[
                                                              index
                                                            ] ? (
                                                              <div className="text-left">
                                                                <div
                                                                  className="spinner-border spinner-border-sm text-primary"
                                                                  role="status"
                                                                >
                                                                  <span className="sr-only">
                                                                    Loading...
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              <em className="icon ni ni-download"></em>
                                                            )}
                                                          </span>
                                                        </td>
                                                        <td className="tb-col-os">
                                                          {details.is_approved ===
                                                          1 ? (
                                                            <span className="badge badge-outline-success">
                                                              Approved
                                                            </span>
                                                          ) : details.is_approved ===
                                                            0 ? (
                                                            <span className="badge badge-outline-danger">
                                                              Rejected
                                                            </span>
                                                          ) : details.is_approved ===
                                                            null ? (
                                                            <span className="badge badge-outline-warning">
                                                              Pending
                                                            </span>
                                                          ) : null}
                                                        </td>
                                                        <td className="tb-col-os">
                                                          {details.addedOn &&
                                                          details.addedOn !== ""
                                                            ? this.format_date_inner(
                                                                details.addedOn
                                                              )
                                                            : ""}
                                                        </td>
                                                      </tr>
                                                      <tr
                                                        id={`accordion-item-${index}`}
                                                        className="accordion-row collapse"
                                                      >
                                                        <td colSpan="7">
                                                          <div
                                                            className="accordion-inner"
                                                            style={{
                                                              borderRadius:
                                                                "8px",
                                                              padding: "15px",
                                                            }}
                                                          >
                                                            <h4
                                                              style={{
                                                                fontSize:
                                                                  "18px",
                                                                marginBottom:
                                                                  "15px",
                                                                color: "#333",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                            >
                                                              Document Info
                                                            </h4>
                                                            <div
                                                              style={{
                                                                lineHeight:
                                                                  "1.6",
                                                                color: "#555",
                                                              }}
                                                            >
                                                              <div>
                                                                <strong>
                                                                  Submitted
                                                                  File:
                                                                </strong>{" "}
                                                                {
                                                                  details.document_file
                                                                }
                                                              </div>
                                                              <div>
                                                                <strong>
                                                                  Status:{" "}
                                                                </strong>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      details.is_approved ===
                                                                      1
                                                                        ? "green"
                                                                        : details.is_approved ===
                                                                          0
                                                                        ? "red"
                                                                        : "orange",
                                                                  }}
                                                                >
                                                                  {details.is_approved ===
                                                                  1
                                                                    ? "Approved"
                                                                    : details.is_approved ===
                                                                      0
                                                                    ? "Rejected"
                                                                    : "Pending"}
                                                                </span>
                                                              </div>
                                                              {details.is_approved ===
                                                                0 && (
                                                                <div>
                                                                  <strong>
                                                                    Reason:
                                                                  </strong>{" "}
                                                                  {
                                                                    details.reason
                                                                  }
                                                                </div>
                                                              )}
                                                              <div>
                                                                <strong>
                                                                  Date Added:
                                                                </strong>{" "}
                                                                {this.format_date(
                                                                  details.addedOn
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </>
                                                  )
                                                )}
                                              </>
                                            ) : (
                                              <tr>
                                                <td
                                                  className="tb-col-os"
                                                  colSpan={4}
                                                >
                                                  <strong className="text-danger">
                                                    Currently, there are no
                                                    submissions uploaded for
                                                    this document.
                                                  </strong>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**** Open Submission Modal ***/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Agreements);
