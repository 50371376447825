import { React } from "react";
import { Component } from "react";
import { createRef } from "react";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  defaultShortlinkSettings,
  addAboutme,
  addSocialLinks,
  getUserImageLibrary,
  deleteUserImage,
  getSharelinkTestimonials,
  saveNewTestimonial,
  updateTestimonial,
  deleteTestimonial,
} from "./../../../config/shortlink_api_calls";

import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";

import HelperClass from "./../../../config/helperClass";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import DataTable from "../../../components/Tables/DataTable";
import { API_URL, APP_LIVE_URL } from "./../../../config/config";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import PageTitle from "../ExtraComponents/PageTitle";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MutextField from "@material-ui/core/TextField";
//import { Editor } from "@tinymce/tinymce-react";
//import { CKEditor } from "@ckeditor/ckeditor5-react";
//import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import RichTextEditorComponent from "../../../components/Reseller/RichTextEditorComponent";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Tooltip from "@material-ui/core/Tooltip";

const auth = new HelperClass();

class SharelinkSettings extends Component {
  constructor() {
    super();
    this.state = {
      serviceData: [],
      about_me_short: "",
      about_me: "",
      errorMessage: "",
      instagram_link: "https://www.instagram.com/",
      facebook_link: "https://www.facebook.com/",
      twitter_link: "https://twitter.com/",
      linkedin_link: "https://www.linkedin.com/",
      youtube_link: "https://www.youtube.com/",
      successMessage: "",
      tableLoader: true,
      tableLoaderListing: true,
      logo: "",
      setting_id: "",
      disabled: false,
      errorMessageEdit: "",
      successMessageEdit: "",
      images_status: "1",
      images_type: "logo",
      picture: "",
      pictureName: "",
      image_title: "",
      logo_view: "",
      imageData: [],
      isEditorReady: false,
      brightness: 100,
      showBrightnessRange: false,
      tableData: [],
      testimonials: [],
      isAddingTestimonial: false,
      isEditing: false,
      editingIndex: null,
      testimonial_name: "",
      testimonial_title: "",
      testimonial_id: null,
      testimonial_comment: "",
      testimonial_img: "",
      testimonialErrors: {},
      selectedImageName: "",
      uErrorMessage: "",
      uSuccessMessage: "",
      columnServices: [
        {
          name: "picture",
          label: "Image",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div className="nk-help-img">
                  <img
                    src={`${APP_LIVE_URL}files_data/sharelinks/${tableMeta.rowData[0]}`}
                    alt={tableMeta.rowData[1]}
                  />
                </div>
              );
            },
          },
        },
        {
          name: "image_title",
          label: "image_title",
        },
        {
          name: "images_type",
          label: "Type",
        },
        {
          name: "id",
          label: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div
                  className="nk-tb-col nk-tb-col-tools"
                  style={{ padding: "0px" }}
                >
                  <ul className="nk-tb-actions gx-1">
                    <li>
                      <div className="drodown">
                        <a
                          href="#"
                          title="Other Actions"
                          className="dropdown-toggle btn btn-icon btn-outline-light"
                          data-toggle="dropdown"
                        >
                          <em className="icon ni ni-more-h"></em>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <a
                                style={{ cursor: "pointer" }}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete Image"
                                onClick={() =>
                                  this.deleteImage(tableMeta.rowData[3])
                                }
                              >
                                <em className="icon ni ni-trash"></em>
                                <span>Delete Image</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              );
            },
          },
        },
      ],
      columnTestimonials: [
        { name: "Id", options: { display: false } },
        { name: "Name" },
        { name: "Title" },
        { name: "Comment" },
        {
          name: "Image",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  {tableMeta.rowData[4] && tableMeta.rowData[4] !== "" ? (
                    <img
                      style={{
                        height: "50px",
                        width: "auto",
                      }}
                      src={`${APP_LIVE_URL}files_data/sharelinks/${tableMeta.rowData[4]}`}
                      alt="Testimonial Image"
                    />
                  ) : (
                    <span>-</span>
                  )}
                </div>
              );
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() => this.editTestimonial(value)}
                                  title="Edit Testimonial"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit Testimonial</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() => this.deleteTestimonial(value)}
                                  title="Delete Testimonial"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-trash-empty"></em>
                                  <span>Delete Testimonial</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
    this.cropperRef = createRef();
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };
  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    const servicesResponce = await defaultShortlinkSettings(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Settings Data: ", servicesResponce.data.data[0]);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        about_me_short: servicesResponce.data.data[0]["about_me_short"],
        about_me: servicesResponce.data.data[0]["about_me"],
        instagram_link: servicesResponce.data.data[0]["instagram_link"],
        facebook_link: servicesResponce.data.data[0]["facebook_link"],
        twitter_link: servicesResponce.data.data[0]["twitter_link"],
        linkedin_link: servicesResponce.data.data[0]["linkedin_link"],
        youtube_link: servicesResponce.data.data[0]["youtube_link"],
        setting_id: servicesResponce.data.data[0]["id"],
        isEditorReady: true,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  getImageLibrary = async () => {
    this.setState({
      tableLoaderListing: true,
    });
    const servicesResponce = await getUserImageLibrary(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("getImageLibrary: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableLoaderListing: false,
        tableData: [],
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        imageData: servicesResponce.data.data,
        tableData: servicesResponce.data.data,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        tableLoaderListing: false,
        tableData: [],
      });
      //window.location.replace("/error");
    }
  };

  formSave = async () => {
    window.scrollTo(0, 0);
    const { about_me, about_me_short } = this.state;

    const saveResponce = await addAboutme(
      auth.getAccount(),
      auth.getToken(),
      about_me,
      about_me_short
    );
    console.log("ADD RES: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "About me details added successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        errorMessage: saveResponce.data.message,
        successMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  formSaveSocial = async () => {
    window.scrollTo(0, 0);
    const {
      instagram_link,
      facebook_link,
      twitter_link,
      linkedin_link,
      youtube_link,
    } = this.state;

    const saveResponce = await addSocialLinks(
      auth.getAccount(),
      auth.getToken(),
      instagram_link,
      facebook_link,
      twitter_link,
      linkedin_link,
      youtube_link
    );
    console.log("ADD Social: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Social media links added successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        errorMessage: saveResponce.data.message,
        successMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  /*formAddImage = async (e) => {
    e.preventDefault();
    const { images_type, images_status } = this.state;
    if (this.state.fileName !== "") {
      console.log("image: ", this.state.file);
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("file", this.state.picture);
      formData.append("image_type", this.state.images_type);
      formData.append("accountno", auth.getAccount());
      formData.append("fileName", this.state.pictureName);
      formData.append("status", this.state.images_status);
      formData.append("image_title", this.state.image_title);

      axios
        .post(
          process.env.REACT_APP_API_URL + "uploads/upload_user_image",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
            },
          },
          {}
        )
        .then((res) => {
          console.log(
            "File Upload RES " + this.state.images_type + ": ",
            res.data
          );
          if (
            res.data.status === 403 ||
            res.data.errors === "authentication missing" ||
            res.data.errors === "jwt expired"
          ) {
            auth.logout();
          } else if (res.data.status === 404) {
            this.setState({
              successMessageEdit: "",
              errorMessageEdit: "There is some error while adding the image.",
            });
            //window.location.replace("/error");
          } else if (
            res.data.status === 200 &&
            res.data.message === "success"
          ) {
            this.setState({
              successMessageEdit: "Image added successfully.",
              errorMessageEdit: "",
              pictureName: "",
              picture: "",
              logo_view: res.data.data,
              signature_view: "",
            });
            setTimeout(() => {
              window.$("#modalAddImage").modal("hide");
              this.getImageLibrary();
            }, 3000);
          } else {
            this.setState({
              successMessageEdit: "",
              errorMessageEdit: "There is some error while adding the image.",
            });
          }
        });
    } else {
      this.setState({
        successMessageEdit: "",
        errorMessageEdit: "Please select an image to upload.",
      });
    }
    setTimeout(() => {
      this.setState({
        errorMessageEdit: "",
        successMessageEdit: "",
        logo_view: "",
        image_title: "",
      });
    }, 4000);
  };*/
  formAddImage = async (e) => {
    e.preventDefault();
    const { images_type, images_status, pictureName, picture, disabled } =
      this.state;

    const modalBody = document.getElementById("modalAddImage");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }

    if (!picture) {
      this.setState({
        errorMessageEdit: "Please select an image to upload.",
        successMessageEdit: "",
      });
      return;
    }

    try {
      this.setState({ disabled: true });
      const croppedBlob = await this.getCroppedBlob();
      const imageElement = this.cropperRef.current;
      const cropper = imageElement.cropper;
      const { width, height } = cropper.getCropBoxData();

      let maxWidth, maxHeight;

      if (images_type === "logo") {
        maxWidth = 325;
        maxHeight = 130;
      } else if (images_type === "image") {
        maxWidth = 1550;
        maxHeight = 840;
      } else if (images_type === "image2") {
        maxWidth = 549;
        maxHeight = 611;
      } else if (images_type === "banner") {
        maxWidth = 1640;
        maxHeight = 560;
      }

      if (width > maxWidth || height > maxHeight) {
        this.setState({
          successMessageEdit: "",
          errorMessageEdit: `Image dimensions exceed the allowed size for ${images_type}. Maximum allowed size is W: ${maxWidth} x H: ${maxHeight}.`,
          disabled: false,
        });
        return;
      }

      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("file", croppedBlob);
      formData.append("image_type", images_type);
      formData.append("accountno", auth.getAccount());
      formData.append("fileName", pictureName);
      formData.append("status", images_status);
      formData.append("image_title", this.state.image_title);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}uploads/upload_user_image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Credentials": true,
          },
        }
      );

      console.log("File Upload RES " + images_type + ": ", response.data);

      if (
        response.data.status === 403 ||
        response.data.errors === "authentication missing" ||
        response.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (response.data.status === 404) {
        this.setState({
          successMessageEdit: "",
          errorMessageEdit: "There was an error while adding the image.",
          disabled: false,
        });
      } else if (
        response.data.status === 200 &&
        response.data.message === "success"
      ) {
        this.setState({
          successMessageEdit: "Image added successfully.",
          errorMessageEdit: "",
          pictureName: "",
          picture: "",
          logo_view: response.data.data,
          signature_view: "",
          image_title: "",
          disabled: false,
        });

        setTimeout(() => {
          this.setState({
            successMessageEdit: "",
            errorMessageEdit: "",
            pictureName: "",
            picture: "",
            logo_view: "",
            image_title: "",
            signature_view: "",
            brightness: "",
          });
          window.$("#modalAddImage").modal("hide");
          this.getImageLibrary();
        }, 3000);
      } else {
        this.setState({
          successMessageEdit: "",
          errorMessageEdit: "There was an error while adding the image.",
          disabled: false,
        });
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      this.setState({
        successMessageEdit: "",
        errorMessageEdit: "There was an error while uploading the image.",
        disabled: false,
      });
    }
  };

  deleteImage = async (id) => {
    const saveResponce = await deleteUserImage(
      auth.getAccount(),
      auth.getToken(),
      id
    );
    console.log("ADD RES: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage: "There is some error while deleting the image.",
        successMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "image_type"
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage:
          "Cannot delete the image of this type. At least one image of this type should exists.",
        successMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "assigned_to_sharelink"
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage:
          "Cannot delete the image. This image is assigned to the sharelink. Please change the image in sharelink and try again.",
        successMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Image deleted successfully.",
        disabled: false,
      });

      this.getImageLibrary();
    } else {
      this.setState({
        errorMessage: "There is some error while deleting the image.",
        successMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  hide_messages = () => {
    this.setState({
      errorMessage: "",
      successMessage: "",
      errorMessageEdit: "",
      successMessageEdit: "",
      disabled: false,
    });
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    if (name === "images_type") {
      const inputElement = document.getElementById("picture");
      if (inputElement) {
        const selectedFile = inputElement.files[0];
        if (selectedFile) {
          inputElement.value = null;
          this.setState({
            errorMessageEdit: "",
            picture: "",
            pictureName: "",
          });
        }
      }
    }
    this.setState({
      [name]: value,
    });
    console.log("handleChange:", name + " " + value);
  };

  handleEditorChange = (updatedContent) => {
    this.setState({
      about_me: updatedContent,
    });
  };

  handleEditorChangeShort = (updatedContent) => {
    this.setState({
      about_me_short: updatedContent,
    });
  };

  /*handleFile = async (e) => {
    const selectedFile = e.target.files[0];
    const image_type = this.state.images_type;
    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation
    const allowedFileTypes = [".png", ".jpg", ".jpeg"];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        errorMessageEdit:
          "Invalid file type. Please select a valid file format.",
      });
      return;
    }

    // File size validation (e.g., limit to 5MB)
    const maxSizeBytes = 5 * 1024 * 1024; // 5MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        errorMessageEdit:
          "File size exceeds the limit (5MB). Please choose a smaller file.",
      });
      return;
    }

    // Image dimension validation
    const imageReader = new FileReader();

    imageReader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;
      let maxWidth = 0; 
      let maxHeight = 0;
      let minWidth = 0; 
      let minHeight = 0;
      image.onload = () => {
        if(image_type === 'logo'){
          maxWidth = 320; 
          maxHeight = 110;
          minWidth = 300; 
          minHeight = 90;
        } else if (image_type === 'image'){
          maxWidth = 1560; 
          maxHeight = 850;
          minWidth = 1540; 
          minHeight = 830;
        } else if (image_type === 'banner'){
          maxWidth = 1930; 
          maxHeight = 1010;
          minWidth = 1910; 
          minHeight = 990;
        }

        if (image.width > maxWidth || image.height > maxHeight) {
          this.setState({
            errorMessageEdit: `Image dimensions exceed the limit (${maxWidth}x${maxHeight}). Please choose a smaller image.`,
          });
        } else if (image.width < minWidth || image.height < minHeight) {
          this.setState({
            errorMessageEdit: `Image dimensions should be at least (${minWidth}x${minHeight}). Please choose a larger image.`,
          });
        } else {
          // Clear any previous errors
          this.setState({
            picture: selectedFile,
            errorMessageEdit: "",
            pictureName: selectedFile.name,
          });
        }
      };
    };

    imageReader.readAsDataURL(selectedFile);
  };*/
  handleFile = async (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation
    const allowedFileTypes = [".png", ".jpg", ".jpeg"];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    console.log("Selected file extension:", fileExtension);

    if (!allowedFileTypes.includes("." + fileExtension)) {
      console.log("Invalid file type:", fileExtension);
      this.setState({
        errorMessageEdit:
          "Invalid file type. Please select a valid file format (e.g., .png, .jpg, .jpeg).",
      });
      return;
    }

    // File size validation (e.g., limit to 5MB)
    const maxSizeBytes = 5 * 1024 * 1024; // 5MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        errorMessageEdit:
          "File size exceeds the limit (5MB). Please choose a smaller file.",
      });
      return;
    }

    const resizedImage = await this.resizeImage(selectedFile);

    // Clear any previous errors if file passes validations
    this.setState({
      picture: URL.createObjectURL(resizedImage),
      errorMessageEdit: "",
      pictureName: selectedFile.name,
    });
    if (this.state.images_type === "logo") {
      setTimeout(() => {
        if (this.cropperRef.current && this.cropperRef.current.cropper) {
          this.cropperRef.current.cropper.zoom(-2.3);
        }
      }, 100);
    }
  };

  resizeImage = async (file) => {
    const { images_type } = this.state;
    const maxDimensions = {
      logo: { width: 325, height: 130 },
      image: { width: 1400, height: 980 },
      image2: { width: 549, height: 611 },
      banner: { width: 1640, height: 560 },
    };

    const maxW = maxDimensions[images_type].width;
    const maxH = maxDimensions[images_type].height;

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          let { width, height } = img;

          // Check if the image type is not "logo"
          if (images_type !== "logo") {
            let resizePercent = 0;

            // Calculate the aspect ratio based on the original image dimensions
            const aspectRatio = width / height;

            // Determine the target aspect ratio based on the image type
            const targetAspectRatio =
              images_type === "image"
                ? 1.43
                : images_type === "image2"
                ? 0.89
                : images_type === "banner"
                ? 2.93
                : 1; // Default aspect ratio if none matched

            // Adjust the dimensions to maintain the aspect ratio
            if (aspectRatio > targetAspectRatio) {
              width = maxW;
              height = Math.round(width / targetAspectRatio);
            } else {
              height = maxH;
              width = Math.round(height * targetAspectRatio);
            }

            // Draw the image onto the canvas at the adjusted dimensions
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            console.log(`Resized ${images_type} image to ${width}x${height}`);
          } else {
            // Keep the original dimensions for the logo
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0);
            console.log(`Kept ${images_type} image size: ${width}x${height}`);
          }

          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, { type: file.type }));
          }, file.type);
        };
      };
    });
  };

  calculatePercent = (percent, num) => {
    return (percent * (num / 100)).toFixed(4);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  open_add_image = () => {
    window.$("#modalAddImage").modal("show");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  onCrop = () => {
    const imageElement = this.cropperRef.current;
    const cropper = imageElement.cropper;
    const { width, height } = cropper.getCropBoxData();
    const { images_type } = this.state;

    // Define dimensions based on image type
    let minWidth, minHeight;
    let aspectRatio;

    if (images_type === "logo") {
      minWidth = 325;
      minHeight = 130;
      aspectRatio = 325 / 130;
    } else if (images_type === "image") {
      minWidth = 1400;
      minHeight = 980;
      aspectRatio = 1400 / 900;
    } else if (images_type === "image2") {
      minWidth = 549;
      minHeight = 611;
      aspectRatio = 549 / 611;
    } else if (images_type === "banner") {
      minWidth = 1640;
      minHeight = 560;
      aspectRatio = 1640 / 560;
    }

    console.log(`Aspect ratio for ${images_type}: ${aspectRatio}`);

    // Apply dimension restrictions
    if (width < minWidth || height < minHeight) {
      // Adjust cropping dimensions if they don't meet the minimum requirements
      cropper.setCropBoxData({
        width: Math.max(width, minWidth),
        height: Math.max(height, minHeight),
      });

      // Update dimensions directly in the DOM
      const cropSizeElement = document.getElementById("cropSize");
      if (cropSizeElement) {
        cropSizeElement.textContent = `Current Crop Size: W: ${Math.round(
          Math.max(width, minWidth)
        )} x H: ${Math.round(Math.max(height, minHeight))}`;
      }
    } else {
      // Update dimensions directly in the DOM
      const cropSizeElement = document.getElementById("cropSize");
      if (cropSizeElement) {
        cropSizeElement.textContent = `Current Crop Size: W: ${Math.round(
          width
        )} x H: ${Math.round(height)}`;
      }
    }
  };

  rotateImage = (degree) => {
    const cropper = this.cropperRef.current.cropper;
    cropper.rotate(degree);
  };

  handleZoomIn = () => {
    const cropper = this.cropperRef.current.cropper;
    cropper.zoom(0.1); // Adjust zoom factor as needed
  };

  handleZoomOut = () => {
    const cropper = this.cropperRef.current.cropper;
    cropper.zoom(-0.1); // Adjust zoom factor as needed
  };

  flipBoth = () => {
    const cropper = this.cropperRef.current.cropper;
    cropper.scale(-1);
  };

  flipHorizontal = () => {
    const cropper = this.cropperRef.current.cropper;
    cropper.scale(-1, 1);
  };

  flipVertical = () => {
    const cropper = this.cropperRef.current.cropper;
    cropper.scale(1, -1);
  };

  handleBrightnessChange = (e) => {
    this.setState({ brightness: parseInt(e.target.value) });
  };

  toggleBrightnessRange = () => {
    this.setState((prevState) => ({
      showBrightnessRange: !prevState.showBrightnessRange,
    }));
  };

  getCroppedBlob = () => {
    return new Promise((resolve, reject) => {
      const imageElement = this.cropperRef.current;
      const cropper = imageElement.cropper;
      const croppedCanvas = cropper.getCroppedCanvas();

      if (croppedCanvas) {
        // Create a new canvas to apply brightness
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set canvas dimensions
        canvas.width = croppedCanvas.width;
        canvas.height = croppedCanvas.height;

        // Apply brightness filter
        ctx.filter = `brightness(${this.state.brightness}%)`;
        ctx.drawImage(croppedCanvas, 0, 0, canvas.width, canvas.height);

        // Get blob from the adjusted canvas
        canvas.toBlob((blob) => {
          resolve(blob);
        }, "image/png"); // Adjust mime type as needed (e.g., "image/jpeg", "image/png")
      } else {
        reject(new Error("Failed to get cropped blob."));
      }
    });
  };

  handleResetCrop = () => {
    const imageElement = this.cropperRef.current;
    const cropper = imageElement.cropper;

    // Reset the crop box to its initial state
    cropper.reset();
    this.handleReady();
    this.setState({ brightness: 100 });

    // Apply zoom out if images_type is "logo"
    if (this.state.images_type === "logo") {
      setTimeout(() => {
        if (this.cropperRef.current && this.cropperRef.current.cropper) {
          this.cropperRef.current.cropper.zoom(-2.3); // Zoom out by 2.3
        }
      }, 100);
    }
  };

  handleReady = () => {
    const cropper = this.cropperRef.current.cropper;
    const containerData = cropper.getContainerData();
    const imageData = cropper.getImageData();

    const aspectRatio = this.calculateAspectRatio();

    let newWidth = imageData.naturalWidth * 0.5;
    let newHeight = newWidth / aspectRatio;

    const maxWidth = containerData.width;
    const maxHeight = containerData.height;

    if (newWidth > maxWidth) {
      newWidth = maxWidth;
      newHeight = newWidth / aspectRatio;
    }
    if (newHeight > maxHeight) {
      newHeight = maxHeight;
      newWidth = newHeight * aspectRatio;
    }

    let offsetTop = 0;
    let offsetLeft = 0;
    if (this.state.images_type === "logo") {
      offsetTop = 20;
      offsetLeft = 90;
    }

    const newLeft = (containerData.width - newWidth) / 2 - offsetLeft;
    const newTop = (containerData.height - newHeight) / 2 - offsetTop;

    cropper.setCropBoxData({
      left: newLeft,
      top: newTop,
      width: newWidth,
      height: newHeight,
    });
  };

  calculateAspectRatio = () => {
    const { images_type } = this.state;
    switch (images_type) {
      case "logo":
        return 325 / 130;
      case "image":
        return 1400 / 980;
      case "image2":
        return 549 / 611;
      default:
        return 1640 / 560;
    }
  };

  addTestimonial = () => {
    let randomNumber = Math.floor(Math.random() * 1000000);
    this.setState({
      isAddingTestimonial: true,
      isEditing: false,
      editingIndex: null,
      testimonial_name: "",
      testimonial_title: "",
      testimonial_comment: "",
      testimonial_img: "",
      selectedImageName: "",
      testimonialErrors: {},
      testimonial_id: randomNumber,
    });
  };

  editTestimonial = (index) => {
    const testimonial = this.state.testimonials[index];
    this.setState({
      isAddingTestimonial: true,
      isEditing: true,
      editingIndex: index,
      testimonial_name: testimonial.name,
      testimonial_title: testimonial.title,
      testimonial_comment: testimonial.comment,
      testimonial_img: testimonial.image_name,
      testimonial_id: testimonial.id,
      testimonialErrors: {},
    });
  };

  saveTestimonial = async () => {
    const {
      isEditing,
      editingIndex,
      testimonial_name,
      testimonial_title,
      testimonial_comment,
      testimonials,
      testimonial_img,
      testimonial_id,
    } = this.state;
    let errors = {};
    if (!testimonial_name) errors.nameError = "Name is required";
    if (!testimonial_title) errors.titleError = "Title is required";
    if (!testimonial_comment) errors.commentError = "Comment is required";

    if (Object.keys(errors).length > 0) {
      this.setState({ testimonialErrors: errors });
      return;
    }
    if (isEditing) {
      const currentTestimonial = testimonials[editingIndex];
      const editedTestimonial = {
        id: currentTestimonial.id,
        name: testimonial_name,
        title: testimonial_title,
        comment: testimonial_comment,
        image_name: testimonial_img,
      };
      await this.updateTestimonial(
        currentTestimonial.id,
        testimonial_name,
        testimonial_title,
        testimonial_comment,
        testimonial_img
      );
      const updatedTestimonials = testimonials.map((item, index) =>
        index === editingIndex ? editedTestimonial : item
      );
      this.setState({
        testimonials: updatedTestimonials,
        isAddingTestimonial: false,
        isEditing: false,
        editingIndex: null,
        testimonial_name: "",
        testimonial_title: "",
        testimonial_comment: "",
        testimonial_img: "",
        selectedImageName: "",
        testimonialErrors: {},
      });
    } else {
      const newTestimonial = {
        name: testimonial_name,
        title: testimonial_title,
        comment: testimonial_comment,
        image_name: testimonial_img,
        id: testimonial_id,
      };
      await this.saveNewTestimonial(
        testimonial_id,
        testimonial_name,
        testimonial_title,
        testimonial_comment,
        testimonial_img
      );
      this.setState((prevState) => ({
        testimonials: [...prevState.testimonials, newTestimonial],
        isAddingTestimonial: false,
        testimonial_name: "",
        testimonial_title: "",
        testimonial_comment: "",
        testimonial_img: "",
        selectedImageName: "",
        testimonialErrors: {},
      }));
    }
  };

  saveNewTestimonial = async (
    testimonial_id,
    testimonial_name,
    testimonial_title,
    testimonial_comment,
    testimonial_img
  ) => {
    try {
      const saveResponse = await saveNewTestimonial(
        auth.getAccount(),
        auth.getToken(),
        testimonial_name,
        testimonial_title,
        testimonial_comment,
        testimonial_img,
        testimonial_id
      );
      if (
        saveResponse.data.status === 403 ||
        saveResponse.data.errors === "authentication missing" ||
        saveResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponse.data.status === 200 &&
        saveResponse.data.message === "success"
      ) {
        this.setState({
          uErrorMessage: "",
          uSuccessMessage: "Testimonial added successfully.",
          disabled: false,
        });
      } else {
        this.setState({
          uErrorMessage: "There is some error while adding the testimonial.",
          uSuccessMessage: "",
          disabled: false,
        });
      }
      setTimeout(() => {
        this.setState({ uSuccessMessage: "", uErrorMessage: "" });
      }, 3000);
    } catch (error) {
      console.error("Error while saving testimonial:", error);
      this.setState({
        uErrorMessage: "An unexpected error occurred.",
        uSuccessMessage: "",
        disabled: false,
      });
    }
  };

  updateTestimonial = async (
    testimonial_id,
    testimonial_name,
    testimonial_title,
    testimonial_comment,
    testimonial_img
  ) => {
    try {
      const saveResponse = await updateTestimonial(
        auth.getAccount(),
        auth.getToken(),
        testimonial_name,
        testimonial_title,
        testimonial_comment,
        testimonial_img,
        testimonial_id
      );
      if (
        saveResponse.data.status === 403 ||
        saveResponse.data.errors === "authentication missing" ||
        saveResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponse.data.status === 200 &&
        saveResponse.data.message === "success"
      ) {
        this.setState({
          uErrorMessage: "",
          uSuccessMessage: "Testimonials updated successfully.",
          disabled: false,
        });
      } else {
        this.setState({
          uErrorMessage: "There is some error while updating the testimonials.",
          uSuccessMessage: "",
          disabled: false,
        });
      }

      setTimeout(() => {
        this.setState({ uSuccessMessage: "", uErrorMessage: "" });
      }, 3000);
    } catch (error) {
      console.error("Error while saving testimonial:", error);
      this.setState({
        uErrorMessage: "An unexpected error occurred.",
        uSuccessMessage: "",
        disabled: false,
      });
    }
  };

  deleteTestimonial = async (index) => {
    const currentTestimonial = this.state.testimonials[index];
    try {
      const saveResponse = await deleteTestimonial(
        auth.getAccount(),
        auth.getToken(),
        currentTestimonial.id
      );
      if (
        saveResponse.data.status === 403 ||
        saveResponse.data.errors === "authentication missing" ||
        saveResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponse.data.status === 200 &&
        saveResponse.data.message === "success"
      ) {
        const updatedTestimonials = this.state.testimonials.filter(
          (_, i) => i !== index
        );
        this.setState({
          testimonials: updatedTestimonials,
          uErrorMessage: "",
          uSuccessMessage: "Testimonial Deleted Successfully.",
          disabled: false,
        });
      } else {
        this.setState({
          uErrorMessage: "There is some error while deleting the testimonial.",
          uSuccessMessage: "",
          disabled: false,
        });
      }
      setTimeout(() => {
        this.setState({ uSuccessMessage: "", uErrorMessage: "" });
      }, 3000);
    } catch (error) {
      console.error("Error while saving testimonial:", error);
      this.setState({
        uErrorMessage: "An unexpected error occurred.",
        uSuccessMessage: "",
        disabled: false,
      });
    }
  };
  handleTestimonialImageChange = (event) => {
    const file = event.target.files[0];
    this.setState({
      disabled: true,
    });
    const formData = new FormData();
    formData.append("token", process.env.REACT_APP_API_TOKEN);
    formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
    formData.append("file", file);
    axios
      .post(
        process.env.REACT_APP_API_URL + "uploads/upload_testimonial_image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Credentials": true,
          },
        }
      )
      .then((res) => {
        if (
          res.data.status === 403 ||
          res.data.errors === "authentication missing" ||
          res.data.errors === "jwt expired"
        ) {
          auth.logout();
        } else if (res.data.status === 404 && res.data.error === "error") {
          this.setState({
            docSuccessMessage: "",
            docErrorMessage: res.data.message,
            disabled: false,
          });
        } else if (res.data.status === 200 && res.data.message === "success") {
          this.setState({
            testimonial_img: res.data.img_url,
            disabled: false,
            selectedImageName: file.name,
          });
        } else {
          this.setState({
            disabled: false,
            docSuccessMessage: "",
            docErrorMessage: "There is some error while adding the image.",
          });
        }
      })
      .catch((error) => {
        console.error("Error uploading image: ", error);
        this.setState({
          docErrorMessage: "Error uploading image. Please try again later.",
        });
      });
  };

  getTableData() {
    return this.state.testimonials.map((testimonial, index) => ({
      Id: testimonial.id,
      Name: testimonial.name,
      Title: testimonial.title,
      Comment: testimonial.comment,
      Image: testimonial.image_name,
      Actions: index,
    }));
  }

  getTestimonialsLibrary = async () => {
    const response = await getSharelinkTestimonials(
      auth.getAccount(),
      auth.getToken()
    );
    if (
      response.data.status === 403 ||
      response.data.errors === "authentication missing" ||
      response.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      response.data.status === 200 &&
      response.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        testimonials: response.data.data,
        disabled: false,
        tableLoaderListing: false,
      });
    } else if (
      response.data.status === 200 &&
      response.data.message === "no_data"
    ) {
      this.setState({
        errorMessage: "",
        testimonials: [],
        disabled: false,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while fetching the testimonials.",
        successMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
  };

  render() {
    const { images_type, showBrightnessRange, brightness } = this.state;
    const fetchToken = async () => {
      try {
        const response = await fetch(
          API_URL + "agents/editor_token_url_agents",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              request_type: "ckeditor-tokenUrl",
              user: auth.getAccount(),
            }),
          }
        );
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error("Failed to fetch token:", error);
        return null;
      }
    };
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  <PageTitle
                    name="Sharelink Settings"
                    icon="icon ni ni-setting"
                  />
                </h3>

                {/*<h3 className="nk-block-title page-title">
                  <PageTitle name="Add New Services" />
                </h3>*/}
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-12">
              <div className="card card-bordered">
                <div className="card-inner">
                  <div className="card-head">
                    <h5 className="card-title">
                      Add your sharelink settings below.
                    </h5>
                  </div>
                  {this.state.errorMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <br />
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#tabItem5"
                      >
                        <em className="icon ni ni-user"></em>
                        <span>About Me</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabItem6"
                      >
                        <em className="icon ni ni-lock-alt"></em>
                        <span>Social Media Links</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabItem7"
                        onClick={() => this.getImageLibrary()}
                      >
                        <em className="icon ni ni-camera"></em>
                        <span>Image Library</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabItem9"
                        onClick={() => this.getTestimonialsLibrary()}
                      >
                        <em className="icon ni ni-star"></em>
                        <span>
                           Testimonials
                        </span>
                      </a>
                    </li>
                    {/*<li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabItem8"
                      >
                        <em className="icon ni ni-link"></em>
                        <span>Domains</span>
                      </a>
                  </li>*/}
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane active" id="tabItem5">
                      <form
                        method="post"
                        className="serviceForm"
                        id="serviceForm"
                      >
                        <div className="row g-4">
                          <div className="col-lg-12">
                            <h4>Abut Me Details</h4>
                          </div>
                          <div className="col-lg-12">
                            <h6>Banner Description</h6>
                          </div>
                          <div className="col-lg-12">
                            {this.state.isEditorReady ? (
                              <>
                                <RichTextEditorComponent
                                  agreementContent={this.state.about_me_short}
                                  onContentChange={this.handleEditorChangeShort}
                                />
                              </>
                            ) : null}
                          </div>
                          <div className="col-lg-12">
                            <h6>About Me</h6>
                          </div>

                          <div className="col-lg-12">
                            <div className="form-group">
                              {this.state.isEditorReady ? (
                                <>
                                  <RichTextEditorComponent
                                    agreementContent={this.state.about_me}
                                    onContentChange={this.handleEditorChange}
                                  />
                                </>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group text-right">
                              <button
                                type="button"
                                className="btn btn-lg btn-primary"
                                onClick={() => this.formSave()}
                                disabled={this.state.disabled}
                              >
                                <em className="icon ni ni-note-add"></em>
                                {"  "}Save About Me
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="tab-pane" id="tabItem6">
                      <form
                        method="post"
                        className="serviceForm"
                        id="serviceForm2"
                      >
                        <div className="row g-4">
                          <div className="col-lg-12">
                            <h4>Social Media Links</h4>
                          </div>

                          <div className="col-lg-12">
                            <FormControl fullWidth variant="outlined">
                              <InputLabel htmlFor="instagram_link">
                                Instagram Link
                              </InputLabel>
                              <OutlinedInput
                                id="instagram_link"
                                name="instagram_link"
                                value={this.state.instagram_link}
                                onChange={this.handleChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <em className="icon ni ni-instagram"></em>
                                  </InputAdornment>
                                }
                                labelWidth={110}
                              />
                            </FormControl>
                          </div>

                          <div className="col-lg-12">
                            <FormControl fullWidth variant="outlined">
                              <InputLabel htmlFor="facebook_link">
                                Facebook Link
                              </InputLabel>
                              <OutlinedInput
                                id="facebook_link"
                                name="facebook_link"
                                value={this.state.facebook_link}
                                onChange={this.handleChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <em className="icon ni ni-facebook-fill"></em>
                                  </InputAdornment>
                                }
                                labelWidth={110}
                              />
                            </FormControl>
                          </div>

                          <div className="col-lg-12">
                            <FormControl fullWidth variant="outlined">
                              <InputLabel htmlFor="twitter_link">
                                Twitter Link
                              </InputLabel>
                              <OutlinedInput
                                id="twitter_link"
                                name="twitter_link"
                                value={this.state.twitter_link}
                                onChange={this.handleChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <em className="icon ni ni-twitter-round"></em>
                                  </InputAdornment>
                                }
                                labelWidth={90}
                              />
                            </FormControl>
                          </div>

                          <div className="col-lg-12">
                            <FormControl fullWidth variant="outlined">
                              <InputLabel htmlFor="linkedin_link">
                                Linkedin Link
                              </InputLabel>
                              <OutlinedInput
                                id="linkedin_link"
                                name="linkedin_link"
                                value={this.state.linkedin_link}
                                onChange={this.handleChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <em className="icon ni ni-linkedin-round"></em>
                                  </InputAdornment>
                                }
                                labelWidth={100}
                              />
                            </FormControl>
                          </div>

                          <div className="col-lg-12">
                            <FormControl fullWidth variant="outlined">
                              <InputLabel htmlFor="youtube_link">
                                Youtube Link
                              </InputLabel>
                              <OutlinedInput
                                id="youtube_link"
                                name="youtube_link"
                                value={this.state.youtube_link}
                                onChange={this.handleChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <em className="icon ni ni-youtube"></em>
                                  </InputAdornment>
                                }
                                labelWidth={110}
                              />
                            </FormControl>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group text-right">
                              <button
                                type="button"
                                className="btn btn-lg btn-primary"
                                onClick={this.formSaveSocial}
                                disabled={this.state.disabled}
                              >
                                <em className="icon ni ni-note-add"></em>
                                {"  "}Save Social Media Links
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="tab-pane" id="tabItem7">
                      {/* PAGE TITLE START */}
                      {this.state.tableLoaderListing === true ? (
                        tableLoaderListing()
                      ) : (
                        <>
                          <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between">
                              <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">
                                  Image Library
                                </h3>
                              </div>
                              <div
                                className="nk-block-head-content"
                                id="HeadContent"
                              >
                                <div className="toggle-wrap nk-block-tools-toggle">
                                  <a
                                    href="#"
                                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                    data-target="pageMenu"
                                  >
                                    <em className="icon ni ni-more-v"></em>
                                  </a>
                                  <div
                                    className="toggle-expand-content"
                                    data-content="pageMenu"
                                  >
                                    <ul className="nk-block-tools g-3">
                                      <li className="nk-block-tools-opt">
                                        <Link
                                          color="inherit"
                                          onClick={() => this.open_add_image()}
                                          className="btn btn-success"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            color: "#fff",
                                          }}
                                        >
                                          Add New Image
                                          <em
                                            className="icon ni ni-upload ml-20 "
                                            style={{ marginLeft: "15px" }}
                                          ></em>
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.state.imageData ? (
                            <DataTable
                              columns={this.state.columnServices}
                              tableData={this.state.tableData}
                              title=""
                            />
                          ) : null}

                          {/*<div className="col-xxl-12">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Image</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.imageData.map((images) => (
                                    <tr key={images.id}>
                                      <td>
                                        <div className="nk-help-img">
                                          <img
                                            src={`${APP_LIVE_URL}files_data/sharelinks/${images.picture}`}
                                            alt={images.image_title}
                                          />
                                        </div>
                                      </td>
                                      <td>{images.image_title}</td>
                                      <td>{images.images_type}</td>
                                      <td>
                                        <div
                                          className="nk-tb-col nk-tb-col-tools"
                                          style={{ padding: "0px" }}
                                        >
                                          <ul className="nk-tb-actions gx-1">
                                            <li>
                                              <div className="drodown">
                                                <a
                                                  href="#"
                                                  title="Other Actions"
                                                  className="dropdown-toggle btn btn-icon  btn-outline-light"
                                                  data-toggle="dropdown"
                                                >
                                                  <em className="icon ni ni-more-h"></em>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                  <ul className="link-list-opt no-bdr">
                                                    <li>
                                                      <a
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Delete Image"
                                                        onClick={() =>
                                                          this.deleteImage(
                                                            images.id
                                                          )
                                                        }
                                                      >
                                                        <em className="icon ni ni-trash"></em>
                                                        <span>
                                                          Delete Image
                                                        </span>
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>*/}
                        </>
                      )}
                    </div>
                    <div className="tab-pane" id="tabItem8">
                      <p>Coming Soon</p>
                    </div>
                    <div className="tab-pane" id="tabItem9">
                      {/* PAGE TITLE START */}
                      {this.state.tableLoaderListing === true ? (
                        tableLoaderListing()
                      ) : (
                        <>
                          {this.state.uErrorMessage !== "" ? (
                            <div className="example-alert">
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.uErrorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.uSuccessMessage !== "" ? (
                            <div className="example-alert">
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.uSuccessMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {!this.state.isAddingTestimonial && (
                            <div className="col-lg-12 pl-0 my-3 text-right">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.addTestimonial}
                              >
                                Add Testimonial
                              </button>
                            </div>
                          )}
                          {this.state.isAddingTestimonial && (
                            <div className="testimonial-form">
                              <div className="form-group col-md-12 col-lg-12 col-xxl-12">
                                <MutextField
                                  id="testimonial_name"
                                  name="testimonial_name"
                                  type="text"
                                  label="Name"
                                  inputProps={{
                                    maxLength: 100,
                                  }}
                                  value={this.state.testimonial_name}
                                  onChange={(e) =>
                                    this.setState({
                                      testimonial_name: e.target.value,
                                    })
                                  }
                                  maxLength={100}
                                  helperText="Enter the name here"
                                  variant="outlined"
                                  fullWidth
                                  error={
                                    !!this.state.testimonialErrors.nameError
                                  }
                                />
                              </div>
                              <div className="form-group col-md-12 col-lg-12 col-xxl-12">
                                <MutextField
                                  id="testimonial_title"
                                  name="testimonial_title"
                                  type="text"
                                  label="Title"
                                  inputProps={{
                                    maxLength: 100,
                                  }}
                                  value={this.state.testimonial_title}
                                  onChange={(e) =>
                                    this.setState({
                                      testimonial_title: e.target.value,
                                    })
                                  }
                                  helperText="Enter the title here"
                                  variant="outlined"
                                  maxLength={50}
                                  fullWidth
                                  error={
                                    !!this.state.testimonialErrors.titleError
                                  }
                                />
                              </div>
                              <div className="col-lg-12">
                                <h6>Comment</h6>
                              </div>
                              <div className="col-lg-12">
                                <TextareaAutosize
                                  maxRows={4}
                                  name="testimonial_comment"
                                  id="testimonial_comment"
                                  value={this.state.testimonial_comment}
                                  onChange={(e) =>
                                    this.setState({
                                      testimonial_comment: e.target.value,
                                    })
                                  }
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    borderColor: "rgba(0, 0, 0, 0.125)",
                                    borderRadius: "4px",
                                    padding: "15px",
                                  }}
                                  error={
                                    !!this.state.testimonialErrors.commentError
                                  }
                                  maxLength={200}
                                />
                                {this.state.testimonialErrors.commentError && (
                                  <div
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    {this.state.testimonialErrors.commentError}
                                  </div>
                                )}
                              </div>
                              <div className="form-group col-md-12 col-lg-12 col-xxl-12 d-flex justify-content-end mt-1 mb-2">
                                <input
                                  id="testimonial_image"
                                  type="file"
                                  accept="image/*"
                                  onChange={this.handleTestimonialImageChange}
                                  style={{
                                    display: "none",
                                  }}
                                />
                                {this.state.selectedImageName && (
                                  <span
                                    className="mr-2"
                                    style={{
                                      alignContent: "center",
                                    }}
                                  >
                                    <strong>Selected File:</strong>{" "}
                                    {this.state.selectedImageName}
                                  </span>
                                )}
                                <button
                                  type="button"
                                  onClick={() =>
                                    document
                                      .querySelector(
                                        'input[id="testimonial_image"]'
                                      )
                                      .click()
                                  }
                                  className="btn btn-dim btn-outline-primary customStyle_add_sharelink_testimonial_btns"
                                  disabled={this.state.disabled}
                                >
                                  Upload Image
                                </button>
                              </div>
                              <div className="col-lg-12 pl-0 mb-3 d-flex justify-content-end">
                                <button
                                  type="button"
                                  className="btn btn-success customStyle_add_sharelink_testimonial_btns"
                                  onClick={this.saveTestimonial}
                                  disabled={this.state.disabled}
                                >
                                  Save Testimonial
                                </button>
                              </div>
                            </div>
                          )}
                          <DataTable
                            columns={this.state.columnTestimonials}
                            tableData={this.getTableData()}
                            title="Testimonials"
                          />
                          {/* {this.state.testimonials.length > 0 && (
                            <div
                              className="form-group col-md-12 col-lg-12"
                              style={{
                                marginTop: "20px",
                                paddingLeft: "0px",
                                textAlign: "right",
                              }}
                            >
                              <button
                                type="button"
                                className="btn btn-lg btn-primary"
                                onClick={this.saveSharelinkTestimonials}
                                disabled={this.state.disabled}
                              >
                                <em className="icon ni ni-note-add"></em>
                                {"  "}Save Testimonials
                              </button>
                            </div>
                          )} */}
                        </>
                      )}
                    </div>
                  </div>
                  <br />
                </div>
              </div>
              {/* DETAIL MODAL End */}
              <div className="modal fade" tabIndex="-1" id="modalAddImage">
                <div className="modal-dialog modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {" "}
                        <em className="icon ni ni-plus-round"></em> Add New
                        Image
                      </h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      <div className="row g-4">
                        <div className="col-xxl-12">
                          <form
                            method="post"
                            encType="multipart/form-data"
                            onSubmit={this.formAddImage}
                          >
                            <div className="row g-4">
                              <div className="form-group col-md-12">
                                <MutextField
                                  required
                                  id="image_title"
                                  name="image_title"
                                  type="text"
                                  label="Image Title"
                                  inputProps={{ maxLength: 100 }}
                                  value={this.state.image_title}
                                  onChange={this.handleChange}
                                  helperText="Enter the title here."
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>

                              <div className="form-group col-md-6">
                                <MutextField
                                  id="images_type"
                                  name="images_type"
                                  select
                                  label="Image Type"
                                  defaultValue={this.state.images_type}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="e.g Logo, Avatar or Banner"
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option key="logo1" value="logo">
                                    Logo
                                  </option>
                                  <option key="image1" value="image">
                                    Avatar
                                  </option>
                                  <option key="image2" value="image2">
                                    Avatar 2
                                  </option>
                                  <option key="Banner1" value="banner">
                                    Banner
                                  </option>
                                </MutextField>
                              </div>

                              <div className="form-group col-md-6">
                                <MutextField
                                  id="images_status"
                                  name="images_status"
                                  select
                                  label="Status"
                                  defaultValue={this.state.images_status}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="e.g Active or Inactive"
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option key="status1" value="1">
                                    Active
                                  </option>
                                  <option key="status0" value="0">
                                    Inactive
                                  </option>
                                </MutextField>
                              </div>

                              <div className="form-group col-md-6">
                                <div
                                  className="upload-btn-wrapper"
                                  style={{
                                    position: "relative",
                                    overflow: "hidden",
                                    display: "inline-block",
                                  }}
                                >
                                  <button
                                    style={{
                                      border: "1px solid gray",
                                      cursor: "pointer",
                                      color: "#364a63",
                                      backgroundColor: "white",
                                      padding: "6px 20px",
                                      borderRadius: "4px",
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <em className="icon ni ni-upload"></em>{" "}
                                    Choose a file to upload{" "}
                                    <small>(.jpg or .png only)</small>
                                  </button>
                                  <input
                                    type="file"
                                    name="picture"
                                    id="picture"
                                    accept=".png, .jpg"
                                    onChange={this.handleFile}
                                    defaultValue={this.state.picture}
                                    style={{
                                      border: "1px solid gray",
                                      position: "absolute",
                                      left: "0",
                                      top: "0",
                                      opacity: "0",
                                      fontSize: "100px",
                                    }}
                                  />
                                </div>
                                <div>
                                  <span className="badge badge-outline-primary">
                                    {this.state.pictureName}
                                  </span>
                                </div>
                              </div>
                              <div className="form-group col-md-12">
                                {this.state.picture && (
                                  <div style={{ margin: "15px 0 0 0" }}>
                                    <Cropper
                                      src={this.state.picture}
                                      style={{
                                        height: 400,
                                        width: "100%",
                                        filter: `brightness(${this.state.brightness}%)`,
                                      }}
                                      aspectRatio={this.calculateAspectRatio()}
                                      guides={true}
                                      zoomable={true}
                                      crop={this.onCrop}
                                      ref={this.cropperRef}
                                      rotatable={true}
                                      ready={this.handleReady}
                                    />
                                    <div style={{ margin: "10px 0 10px 0" }}>
                                      <p id="cropSize">No crop selected</p>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "5px",
                                      }}
                                    >
                                      <Tooltip title="Zoom In" placement="top">
                                        <a
                                          className="btn btn-icon btn-sm btn-primary"
                                          onClick={() => this.handleZoomIn()}
                                        >
                                          <em
                                            className="icon ni ni-zoom-in"
                                            style={{ color: "white" }}
                                          ></em>
                                        </a>
                                      </Tooltip>
                                      <Tooltip title="Zoom Out" placement="top">
                                        <a
                                          className="btn btn-icon btn-sm btn-primary"
                                          onClick={() => this.handleZoomOut()}
                                        >
                                          <em
                                            className="icon ni ni-zoom-out"
                                            style={{ color: "white" }}
                                          ></em>
                                        </a>
                                      </Tooltip>
                                      <Tooltip
                                        title="Rotate Left"
                                        placement="top"
                                      >
                                        <a
                                          className="btn btn-icon btn-sm btn-primary"
                                          onClick={() => this.rotateImage(-15)}
                                        >
                                          <em
                                            className="icon ni ni-curve-up-left"
                                            style={{ color: "white" }}
                                          ></em>
                                        </a>
                                      </Tooltip>
                                      <Tooltip
                                        title="Rotate Right"
                                        placement="top"
                                      >
                                        <a
                                          className="btn btn-icon btn-sm btn-primary"
                                          onClick={() => this.rotateImage(15)}
                                        >
                                          <em
                                            className="icon ni ni-curve-up-right"
                                            style={{ color: "white" }}
                                          ></em>
                                        </a>
                                      </Tooltip>

                                      <Tooltip
                                        title="Flip Horizontal"
                                        placement="top"
                                      >
                                        <a
                                          className="btn btn-icon btn-sm btn-primary"
                                          onClick={() => this.flipHorizontal()}
                                        >
                                          <em
                                            class="icon ni ni-swap"
                                            style={{ color: "white" }}
                                          ></em>{" "}
                                        </a>
                                      </Tooltip>

                                      <Tooltip
                                        title="Flip Vertical"
                                        placement="top"
                                      >
                                        <a
                                          className="btn btn-icon btn-sm btn-primary"
                                          onClick={() => this.flipVertical()}
                                        >
                                          <em
                                            class="icon ni ni-swap-v"
                                            style={{ color: "white" }}
                                          ></em>{" "}
                                        </a>
                                      </Tooltip>
                                      <Tooltip
                                        title="Adjust Brightness"
                                        placement="top"
                                      >
                                        <a
                                          className="btn btn-icon btn-sm btn-primary"
                                          onClick={this.toggleBrightnessRange}
                                        >
                                          <em
                                            className="icon ni ni-sun-fill"
                                            style={{ color: "white" }}
                                          ></em>
                                        </a>
                                      </Tooltip>
                                      {showBrightnessRange && (
                                        <input
                                          type="range"
                                          min="0"
                                          max="200"
                                          value={brightness}
                                          onChange={this.handleBrightnessChange}
                                          style={{
                                            width: "15%",
                                            cursor: "pointer",
                                          }}
                                        />
                                      )}
                                      <Tooltip
                                        title="Reset Crop"
                                        placement="top"
                                      >
                                        <a
                                          className="btn btn-icon btn-sm btn-danger"
                                          onClick={() =>
                                            this.handleResetCrop(15)
                                          }
                                        >
                                          <em
                                            class="icon ni ni-reload-alt"
                                            style={{ color: "white" }}
                                          ></em>
                                        </a>
                                      </Tooltip>
                                    </div>
                                    <div className="col-md-12 mt-3 p-0">
                                      <div className="example-alert">
                                        <div className="alert alert-light">
                                          <ul className="list">
                                            <li>
                                              Double-click on the image to drag
                                              it.
                                            </li>
                                            <li>
                                              Use the{" "}
                                              <b>
                                                <em class="icon ni ni-zoom-in"></em>
                                              </b>{" "}
                                              button to zoom in and the{" "}
                                              <b>
                                                <em class="icon ni ni-zoom-out"></em>
                                              </b>{" "}
                                              button to zoom out.
                                            </li>
                                            <li>
                                              Use the{" "}
                                              <b>
                                                <em className="icon ni ni-curve-up-left"></em>
                                              </b>{" "}
                                              icon to rotate left and the{" "}
                                              <b>
                                                <em className="icon ni ni-curve-up-right"></em>{" "}
                                              </b>
                                              icon to rotate right.
                                            </li>
                                            <li>
                                              Use the{" "}
                                              <b>
                                                <em className="icon ni ni-swap-v"></em>
                                              </b>{" "}
                                              icon to flip vertical and the{" "}
                                              <b>
                                                <em className="icon ni ni-swap"></em>{" "}
                                              </b>
                                              icon to flip horizontal.
                                            </li>
                                            <li>
                                              Use the{" "}
                                              <b>
                                                <em class="icon ni ni-reload-alt"></em>
                                              </b>{" "}
                                              button to reset the image and crop
                                              box.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="col-xxl-6">
                                {this.state.logo_view !== "" ? (
                                  <img
                                    style={{ height: "100px" }}
                                    src={this.state.logo_view}
                                  ></img>
                                ) : null}
                              </div>

                              <div className="col-md-12">
                                <div className="example-alert">
                                  <div className="alert alert-light">
                                    <ul className="list">
                                      <li>Accepted Formats: PNG, JPEG, JPG</li>
                                      <li>Recommended Size: 5MB</li>
                                      <li>
                                        Recommended Dimensions:
                                        <b>
                                          {" "}
                                          Logo (W:325 x H:130) - Avatar (W:1400
                                          x H:980) - Avatar2 (W:549 x H:611) -
                                          Banner (W:1640 x H:560)
                                        </b>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>

                              <div style={{ clear: "both" }}></div>
                              <hr />
                              <div className="form-group text-right col-md-12">
                                <button
                                  className="btn btn-lg btn-primary"
                                  type="submit"
                                  disabled={this.state.disabled}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SharelinkSettings);
