import React, { Component, Fragment } from "react";
import axios from "axios";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { HTML5_FMT } from "moment";
import {
  ValidateEmail,
  normalize,
  ValidateDomain,
  axiosConfig,
} from "../../../config/utility";
import HelperClass from "../../../config/helperClass";
import { Helmet, HelmetProvider } from "react-helmet-async";
const auth = new HelperClass();
export class Step1 extends Component {
  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };

  constructor() {
    super();
    this.state = {
      errorMessage: "",
      successMessage: "",
      dnsList: [],
      select_499: false,
      disabled: false,
    };
  }

  SubmitFirst = () => {
    const { values } = this.props;
    console.log(
      "this.state.company_name: ",
      values.company_name + " " + values.company_name
    );
    if (values.company_name === "") {
      this.setState({
        errorMessage: "Please enter the company name.",
      });
    } else if (this.state.select_499 === true) {
      if (values.filer_id === "") {
        this.setState({
          errorMessage: "Please enter the 499 filer ID.",
        });
      } else {
        this.setState({
          disabled: true,
        });
        this.verifyFileId();
      }
    } else {
      this.continue();
    }
  };

  verifyFileId = async () => {
    const { values } = this.props;
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "client_portal/verify_filer_id",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            filer_id: values.filer_id,
          }),
          axiosConfig
        )
        .then((response) => {
          console.log("Filer ID RES: ", response.data);
          if (
            response.data.status === 404 ||
            response.data.status === 422 ||
            response.data.errors !== ""
          ) {
            this.setState({
              errorMessage: "Cannot validate the Filer ID.",
              disabled: false,
            });
          }

          if (
            response.data.status === 200 &&
            response.data.message === "Cannot validate the Filer ID."
          ) {
            this.setState({
              errorMessage: response.data.message,
              disabled: false,
            });
          } else if (
            response.data.status === 200 &&
            response.data.message === "success"
          ) {
            this.setState({
              successMessage: "Your Filer ID is verified.",
              errorMessage: "",
              disabled: false,
            });
            setTimeout(() => {
              this.continue();
            }, 3000);
          }
        });
    } catch (error) {
      console.log("catch : ", error);
    }
    setTimeout(() => {
      this.setState({
        successMessage: "",
        errorMessage: "",
      });
    }, 5000);
  };

  handleChangeCheck = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log(name + " " + value);
  };

  render() {
    const { values, handleChange, verifyFileId } = this.props;

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/csstheme.css?ver=3.0.3"
            />
          </Helmet>
        </HelmetProvider>
        <div className="nk-block nk-block-lg" style={{ width: "100%" }}>
          <div
            className="card card-bordered"
            style={{
              border: "none",
              paddingTop: "5%",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <form
              className="nk-stepper stepper-init is-alter nk-stepper-s1"
              action="#"
              id="stepper-create-profile"
            >
              <div className="row g-0 col-sep col-sep-md col-sep-xl">
                <div className="col-md-4 col-xl-4">
                  <div className="card-inner">
                    <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Company Info</div>
                            <div className="sub-text">
                              Company name and 499 Filer ID
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Contact Info</div>
                            <div className="sub-text">
                              Other contact information
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Other Info</div>
                            <div className="sub-text">
                              Number of employes in the company
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Account Password</div>
                            <div className="sub-text">
                              Account password to access the portal
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Preview Details</div>
                            <div className="sub-text">Review and Submit</div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Completed</div>
                            <div className="sub-text">You are done.</div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="card-inner">
                    <div className="nk-stepper-content">
                      <div className="nk-stepper-steps stepper-steps">
                        {this.state.errorMessage !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.errorMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.successMessage !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="alert alert-pro alert-success">
                              <div className="alert-text">
                                <h4>Success</h4>
                                <p>{this.state.successMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="nk-stepper-step">
                          <h5 className="title mb-3">Company Information</h5>
                          <p>
                            {values.res_title} client SignUp. Add your company
                            name and 499 filer ID.
                          </p>
                          <div className="row g-3">
                            <div className="col-12">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="cp1-first-name"
                                >
                                  Company Name
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="company_name"
                                    name="company_name"
                                    placeholder="Company Name"
                                    defaultValue={values.company_name}
                                    onChange={handleChange}
                                    inputprops={{ maxLength: 100 }}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                  <li>
                                    <div className="custom-control custom-checkbox custom-control-pro">
                                      <input
                                        checked={this.state.select_499}
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="select_499"
                                        name="select_499"
                                        onChange={this.handleChangeCheck}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="select_499"
                                      >
                                        <span className="d-flex align-center">
                                          <span className="user-avatar sq bg-success-dim">
                                            <span className="icon ni ni-briefcase"></span>
                                          </span>
                                          <span className="ms-2">
                                            <span className="lead-text">
                                              499 FilerID
                                            </span>
                                            <span className="sub-text">
                                              I have a 499 FilerID{" "}
                                              <a
                                                href="https://www.usac.org/service-providers/contributing-to-the-usf/register-for-a-499-id/"
                                                target="_blank"
                                              >
                                                FCC / USAC
                                              </a>
                                            </span>
                                          </span>
                                        </span>
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {this.state.select_499 ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    htmlFor="cp1-first-name"
                                  >
                                    499 Filer ID
                                  </label>
                                  <div className="form-control-wrap">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="filer_id"
                                      name="filer_id"
                                      placeholder="499 Filer ID"
                                      inputprops={{ maxLength: 100 }}
                                      defaultValue={values.filer_id}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step text-center"></div>
                      </div>
                      <ul className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination">
                        {/*<li className="step-prev">
                          <button className="btn btn-dim btn-primary">
                            Back
                          </button>
                        </li>*/}
                        <li className="step-next">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.SubmitFirst}
                            disabled={this.state.disabled}
                          >
                            Continue
                          </button>
                        </li>
                        {/*<li className="step-submit">
                          <button className="btn btn-primary">Submit</button>
          </li>*/}
                      </ul>
                      <div
                        className="text-center"
                        style={{ marginTop: "25px" }}
                      >
                        Already have an account ?{" "}
                        <a
                          href={`${auth.getResellerIdentifier()}/client/login`}
                        >
                          Sign in instead
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Step1;
