import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";

import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";

import { checkLogin } from "../../../config/client_api_calls";

import { COMPANY_NAME, LOGO, THEME_COLOR, APP_URL } from "../../../config/config";

import { ValidateEmail, axiosConfig } from "../../../config/utility";
import { tableLoaderSimple } from "../../../components/ContentLoaders/table_loader_simple";
import helpherClass from "../../../config/helperClass";
import { GoogleLogin } from "@react-oauth/google";
const auth = new helpherClass();

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      errorEmail: "",
      errorPass: "",
      email: "",
      password: "",
      errorMessage: "",
      disabled: false,
      loader: "",
      successMessage: "",
      resellerData: [],
      tableLoader: true,
      tableLoaderSimple: true,
      res_accountno: "",
      res_url: "",
      res_hash: "",
      is_agent_selected: true,
      res_title: "",
      pageError: "",
      passwordShown: false,
      url_type: "Simple",
      signup_hash: "",
    };
    this.site_key = process.env.REACT_APP_CAPTCHA_SITE_KEY;
    this.captcha_token = null;
  }

  async componentDidMount() {
    let reseller_url = this.props.match.params.reseller_url;
    localStorage.removeItem('userName');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('authProvider');
    localStorage.removeItem('userSub');
    this.setState({
      tableLoaderSimple: true,
    });

    let urlElements = window.location.href.split("/");

    console.log("CUrrent URL: ", reseller_url);
    let host_url = urlElements[2];
    //if (reseller_url !== "") {
    //Errors
    //404 and no_reseller
    //404 and no_agent
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/check_reseller_login",
          JSON.stringify({
            reseller_url: reseller_url,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            host_url: host_url,
          }),
          axiosConfig
        )
        .then((response) => {
          console.log("response.data start : ", response.data);
          if (
            response.data.status === 404 ||
            response.data.status === 422 ||
            response.data.errors !== ""
          ) {
            this.setState({
              errorMessage: "",
              successMessage: "",
              show_form: false,
              pageError:
                "Sorry we are unable to process your request. Please contact admin for more details.",
              tableLoaderSimple: false,
            });
          }

          if (
            response.data.status === 404 &&
            response.data.message === "no_reseller"
          ) {
            this.setState({
              errorMessage: "",
              pageError:
                "Reseller does not exists. Please contact support or try again with correct URL.",
              successMessage: "",
              show_form: false,
              tableLoaderSimple: false,
            });
          } else if (response.data.status === 403) {
            this.setState({
              errorMessage: "There is some error.",
              successMessage: "",
              show_form: false,
              tableLoaderSimple: false,
              pageError: "",
            });
          } else if (
            response.data.status === 200 &&
            response.data.message === "success"
          ) {
            console.log("response.data.url_type: ", response.data.url_type);
            Cookies.set(
              "residentifier__",
              CryptoJS.AES.encrypt(
                response.data.reseller_url,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "resacct__",
              CryptoJS.AES.encrypt(
                response.data.res_accountno,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "restitle__",
              CryptoJS.AES.encrypt(
                response.data.reseller_title,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "reslogo__",
              CryptoJS.AES.encrypt(
                response.data.reseller_logo,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "ressig__",
              CryptoJS.AES.encrypt(
                response.data.reseller_signature,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "urltype__",
              CryptoJS.AES.encrypt(
                response.data.url_type,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "client_country__",
              CryptoJS.AES.encrypt(
                response.data.country,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            this.setState({
              errorMessage: "",
              res_accountno: response.data.res_accountno,
              res_title: response.data.reseller_title,
              resellerData: response.data,
              tableLoader: false,
              show_form: true,
              pageError: "",
              res_url: reseller_url,
              res_hash: response.data.reseller_hash,
              tableLoaderSimple: false,
              url_type: response.data.url_type,
              signup_hash: response.data.signup_hash,
            });
          }
        });
    } catch (error) {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        tableLoaderSimple: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
      console.log("catch : ", error);
    }
    /*} else {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        tableLoaderSimple: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
    }*/
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCaptchaChange = (value) => {
    this.captcha_token = value;
  };

  togglePassword = () => {
    this.setState({
      passwordShown: !this.state.passwordShown,
    });
  };

  formSubmitClient = async (e) => {
    e.preventDefault();
    let { email, password } = this.state;

    if (email === "") {
      return this.setState({
        errorEmail: "error",
        errorMessage: "Email is required.",
      });
    } else if (ValidateEmail(email) === false) {
      return this.setState({
        errorEmail: "error",
        errorMessage: "Invalid email format.",
      });
    } else if (password === "") {
      return this.setState({
        errorPass: "error",
        errorEmail: "",
        errorMessage: "Password is required.",
      });
    } else if (this.captcha_token === "" || this.captcha_token === null) {
      return this.setState({
        errorPass: "",
        errorEmail: "",
        errorMessage: "Invalid captcha, please retry.",
      });
    } else {
      this.setState({
        errorPass: "",
        errorEmail: "",
        errorMessage: "",
        disabled: true,
        loader: "show",
      });

      try {
        const response = await checkLogin(email, password);
        console.log("clientlogin", response.data);
        if (
          response.data.status === 404 &&
          response.data.message === "invalid_credentials"
        ) {
          this.setState({
            errorMessage: response.data.errors,
            disabled: false,
          });
        } else if (
          response.data.status === 404 ||
          response.data.status === 422 ||
          response.data.errors !== ""
        ) {
          this.setState({
            errorMessage: response.data.errors,
            disabled: false,
          });
        }

        if (
          response.data.status === 200 &&
          response.data.message === "success"
        ) {
          this.setState({
            successMessage: "Please wait, redirecting you to the dashboard.",
            errorMessage: "",
          });
          Cookies.set(
            "client_tkn__",
            CryptoJS.AES.encrypt(
              response.data.token,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_acn__",
            CryptoJS.AES.encrypt(
              response.data.accountno,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_eml__",
            CryptoJS.AES.encrypt(
              response.data.email,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_comp_nme__",
            CryptoJS.AES.encrypt(
              response.data.comp_name,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_user_type__",
            CryptoJS.AES.encrypt(
              response.data.user_type,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_parent_accountno__",
            CryptoJS.AES.encrypt(
              response.data.parent_accountno,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_name__",
            CryptoJS.AES.encrypt(
              response.data.name,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_phone__",
            CryptoJS.AES.encrypt(
              response.data.phone,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_adress__",
            CryptoJS.AES.encrypt(
              response.data.address,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_title__",
            CryptoJS.AES.encrypt(
              response.data.title,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          setTimeout(function () {
            //this.props.history.push("/dashboard");
            //  window.location = "/dashboard";
            window.location.replace(
              auth.getResellerRegToken() + "/client/dashboard"
            );
          }, 1000);
        }
      } catch (error) {
        console.log("catch : ", error);
      }
    }
  };

  handleGoogleSuccess = (credentialResponse) => {
    if (
      process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID ===
      credentialResponse.clientId
    ) {
      this.authLogin(credentialResponse.credential);
    }
  };

  authLogin = (authToken) => {
    let reseller_url = this.props.match.params.reseller_url;
    this.setState({
      disabled: true,
    });
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/login_Oauth",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            authToken: authToken,
            reseller_url: reseller_url,
          }),
          axiosConfig
        )
        .then((response) => {
          if (
            response.data.status === 200 &&
            response.data.message === "success" &&
            response.data.requestType === "user_register"
          ) {
            const userEmail = response.data.userEmail;
            const userName = response.data.userName;
            const userSub = response.data.userSub;
            const requestType = response.data.requestType;
            const signup_hash = response.data.signup_hash;
            console.log(
              "userEmail: ",
              userEmail,
              ", userName: ",
              userName,
              ", userSub: ",
              userSub,
              ", requestType: ",
              requestType
            );
            localStorage.setItem("userEmail", userEmail);
            localStorage.setItem("userName", userName);
            localStorage.setItem("userSub", userSub);
            localStorage.setItem("authProvider", "google");
            this.setState({
              successMessage:
                "Please wait, while we set up your account. You will shortly be redirected to complete the setup process.",
              errorMessage: "",
            });
            setTimeout(() => {
              const redirectUrl = `client/register/${signup_hash}`;
              window.location.replace(redirectUrl);
            }, 2000);
          } else if (
            response.data.status === 200 &&
            response.data.message === "success" &&
            response.data.requestType === "user_login"
          ) {
            this.setState({
              successMessage: "Please wait, redirecting you to the dashboard.",
              errorMessage: "",
            });
            Cookies.set(
              "client_tkn__",
              CryptoJS.AES.encrypt(
                response.data.token,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "client_acn__",
              CryptoJS.AES.encrypt(
                response.data.accountno,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "client_eml__",
              CryptoJS.AES.encrypt(
                response.data.email,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "client_comp_nme__",
              CryptoJS.AES.encrypt(
                response.data.comp_name,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "client_user_type__",
              CryptoJS.AES.encrypt(
                response.data.user_type,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "client_parent_accountno__",
              CryptoJS.AES.encrypt(
                response.data.parent_accountno,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "client_name__",
              CryptoJS.AES.encrypt(
                response.data.name,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "client_phone__",
              CryptoJS.AES.encrypt(
                response.data.phone,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "client_adress__",
              CryptoJS.AES.encrypt(
                response.data.address,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "client_title__",
              CryptoJS.AES.encrypt(
                response.data.title,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            setTimeout(function () {
              //this.props.history.push("/dashboard");
              //  window.location = "/dashboard";
              window.location.replace("/client/dashboard");
            }, 1000);
          } else {
            this.setState({
              successMessage: "",
              errorMessage:
                "There was some error while processing the request. Please try again!",
            });
          }
        });
    } catch (error) {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        disabled: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
      console.log("catch : ", error);
    }
  };

  render() {
    if (auth.isLogin() === true) {
      return <Redirect to="/dashboard" />;
    }
    if (auth.isAdminLogin() === true) {
      return <Redirect to="/admin_dashboard" />;
    }
    if (auth.isClientLogin() === true) {
      return (
        <Redirect to={"/" + auth.getResellerRegToken() + "/client/dashboard"} />
      );
    }
    return (
      <div className="nk-body npc-crypto ui-clean pg-auth">
        <div className="nk-app-root">
          <div className="nk-main ">
            <div className="nk-wrap nk-wrap-nosidebar">
              <div className="nk-content ">
                <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                  <div className="brand-logo pb-5 text-center">
                    {auth.getResellerLogo() && auth.getResellerLogo() !== "" ? (
                      <a href="/" className="logo-link">
                        <img
                          className="logo-light logo-img logo-img-lg"
                          src={auth.getResellerLogo()}
                          alt="logo"
                        />
                        <img
                          className="logo-dark logo-img logo-img-lg"
                          src={auth.getResellerLogo()}
                          alt="logo-dark"
                        />
                      </a>
                    ) : (
                      <a href="/" className="logo-link">
                        <img
                          className="logo-light logo-img logo-img-lg"
                          src={LOGO}
                          alt="logo"
                        />
                        <img
                          className="logo-dark logo-img logo-img-lg"
                          src={LOGO}
                          alt="logo-dark"
                        />
                      </a>
                    )}
                  </div>
                  <div className="card card-bordered">
                    <div className="card-inner card-inner-lg">
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h4 className="nk-block-title">Sign-In</h4>
                          <div className="nk-block-des">
                            <p>Use your verified email and password.</p>
                            {this.state.errorMessage !== "" ? (
                              <div className="example-alert">
                                <div className="alert alert-pro alert-danger">
                                  <div className="alert-text">
                                    <h4>Error</h4>
                                    <p>{this.state.errorMessage}</p>
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {this.state.successMessage !== "" ? (
                              <div className="example-alert">
                                <div className="alert alert-pro alert-success">
                                  <div className="alert-text">
                                    <h4>Success</h4>
                                    <p>{this.state.successMessage}</p>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {this.state.pageError !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.pageError}</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          {this.state.tableLoaderSimple === true ? (
                            tableLoaderSimple()
                          ) : (
                            <form
                              method="post"
                              className="loginForm"
                              onSubmit={this.formSubmitClient}
                            >
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label
                                    className="form-label"
                                    htmlFor="default-01"
                                  >
                                    Email
                                  </label>
                                </div>
                                <div className="form-control-wrap">
                                  <input
                                    type="email"
                                    className={
                                      "form-control form-control-lg " +
                                      this.state.errorEmail
                                    }
                                    id="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    placeholder="Enter your email address"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label
                                    className="form-label"
                                    htmlFor="password"
                                  >
                                    Password
                                  </label>
                                  <a
                                    className="link link-primary link-sm"
                                    tabIndex="-1"
                                    href={`/${this.state.res_url}/client/forgot`}
                                    style={{ color: "#006393" }}
                                  >
                                    Forgot Password?
                                  </a>
                                </div>
                                <div className="form-control-wrap">
                                  <a
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    tabIndex="-1"
                                    onClick={() => {
                                      this.togglePassword();
                                    }}
                                    className="form-icon form-icon-right passcode-switch"
                                    data-target="password"
                                  >
                                    {this.state.passwordShown ? (
                                      <em className="icon ni ni-eye-off"></em>
                                    ) : (
                                      <em className="icon ni ni-eye"></em>
                                    )}
                                  </a>
                                  <input
                                    type={
                                      this.state.passwordShown
                                        ? "text"
                                        : "password"
                                    }
                                    className={
                                      "form-control form-control-lg " +
                                      this.state.errorPass
                                    }
                                    id="password"
                                    name="password"
                                    onChange={this.handleChange}
                                    value={this.state.password}
                                    placeholder="Enter your password"
                                  />
                                </div>
                              </div>

                              <ReCAPTCHA
                                sitekey={this.site_key}
                                onChange={this.handleCaptchaChange}
                                style={{ padding: "0px 0px 15px" }}
                              />

                              <div className="form-group">
                                <button
                                  className="btn btn-lg btn-block"
                                  disabled={this.state.disabled}
                                  type="submit"
                                  style={{
                                    backgroundColor: THEME_COLOR,
                                    color: "white",
                                  }}
                                >
                                  Sign in
                                </button>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <hr
                                  style={{
                                    flex: 1,
                                    border: "none",
                                    borderBottom: "1px solid #ccc",
                                  }}
                                />
                                <span style={{ padding: "0 10px" }}>OR</span>
                                <hr
                                  style={{
                                    flex: 1,
                                    border: "none",
                                    borderBottom: "1px solid #ccc",
                                  }}
                                />
                              </div>
                              <div className="my-3">
                                <div class="googleLoginBtn-large">
                                  <GoogleLogin
                                    onSuccess={this.handleGoogleSuccess}
                                    onError={() => {
                                      console.log("Login Failed");
                                    }}
                                    size="large"
                                    theme="outline"
                                    text="signup_with"
                                    width="397px"
                                    logo_alignment="center"
                                  />
                                </div>
                                <div class="googleLoginBtn-small">
                                  <GoogleLogin
                                    onSuccess={this.handleGoogleSuccess}
                                    onError={() => {
                                      console.log("Login Failed");
                                    }}
                                    size="large"
                                    theme="outline"
                                    text="signup_with"
                                    width="333px"
                                    logo_alignment="center"
                                  />
                                </div>
                              </div>
                              <div className="form-group text-center">
                                New on our platform?
                                <a
                                  href={`${APP_URL}client/register/${this.state.signup_hash}`}
                                >
                                  &nbsp;Create an account{" "}
                                </a>
                              </div>
                              {this.state.res_url &&
                              this.state.res_url !== "" ? (
                                <div className="form-group text-center">
                                  <a href={`${this.state.res_url}`}>
                                    Channel Partner Login{" "}
                                    <em class="icon ni ni-signin"></em>
                                  </a>
                                </div>
                              ) : null}
                            </form>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {/*<div className="form-note-s2 pt-4">
                    {" "}
                    New on our platform?{" "}
                    {auth.getUrlType() === "Simple" ? (
                      <a href={`client/register/${this.state.res_hash}`}>
                        Create an account
                      </a>
                    ) : (
                      <a href={`client/register/${this.state.res_hash}`}>
                        Create an account
                      </a>
                    )}
                    </div>*/}
                </div>
                <div className="nk-footer nk-auth-footer-full">
                  <div className="container wide-lg">
                    <div className="row g-3">
                      <div className="col-lg-12">
                        <div className="nk-block-content text-center ">
                          <p>
                            &copy; {new Date().getFullYear()}{" "}
                            <a
                              href="https://onboardsoft.com/"
                              style={{ color: "#526484" }}
                            >
                              OnBoardsoft LLC
                            </a>
                            . All Rights Reserved.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
