import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";

import { forgotEmailSend } from "../../../config/client_api_calls";
import { ValidateEmail, axiosConfig } from "../../../config/utility";
import {
  LOGO,
  THEME_COLOR,
  THEME_TEXT_COLOR,
  COMPANY_NAME,
} from "../../../config/config";

import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";
import helpherClass from "../../../config/helperClass";
const auth = new helpherClass();

export default class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errorEmail: "",
      errorMessage: "",
      successMessage: "",
      btnDisable: false,
      res_url: "",
    };
  }

  async componentDidMount() {
    this.setState({
      res_url: auth.getResellerRegToken(),
    });
  }

  formPost = async (e) => {
    e.preventDefault();
    let { email } = this.state;

    if (email === "") {
      return this.setState({
        errorEmail: "error",
        errorMessage: "Email is required.",
      });
    } else if (ValidateEmail(email) === false) {
      return this.setState({
        errorEmail: "error",
        errorMessage: "Invalid email format.",
      });
    } else {
      this.setState({
        errorEmail: "",
        errorMessage: "",
        btnDisable: true,
      });
      let apiCall = await forgotEmailSend(email);
      console.log("status: ", apiCall.data.status);
      console.log("errors: ", apiCall.data.errors);
      console.log("message: ", apiCall.data.message);

      if (apiCall.data.status === 404 || apiCall.data.errors !== "") {
        this.setState({
          errorEmail: "error",
          errorMessage: apiCall.data.errors,
          btnDisable: false,
        });
      } else if (
        apiCall.data.status === 200 &&
        apiCall.data.message === "Success"
      ) {
        this.setState({
          successMessage:
            "Email sent to your account, Please check inbox to complete this process.",
        });
      } else {
        window.location.replace("/error");
      }
    }
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <div className="nk-body npc-crypto ui-clean pg-auth">
        <div class="nk-app-root">
          <div class="nk-main ">
            <div class="nk-wrap nk-wrap-nosidebar">
              <div class="nk-content ">
                <div class="nk-block nk-block-middle nk-auth-body  wide-xs">
                  <div class="brand-logo pb-4 text-center">
                    {auth.getResellerLogo() !== "" ? (
                      <a href="/" className="logo-link">
                        <img
                          className="logo-light logo-img logo-img-lg"
                          src={auth.getResellerLogo()}
                        />
                        <img
                          className="logo-dark logo-img logo-img-lg"
                          src={auth.getResellerLogo()}
                        />
                      </a>
                    ) : (
                      <a href="/" className="logo-link">
                        <img
                          className="logo-light logo-img logo-img-lg"
                          src={LOGO}
                        />
                        <img
                          className="logo-dark logo-img logo-img-lg"
                          src={LOGO}
                        />
                      </a>
                    )}
                  </div>
                  <div class="card card-bordered">
                    <div class="card-inner card-inner-lg">
                      <div class="nk-block-head">
                        <div class="nk-block-head-content">
                          <h5 className="nk-block-title">Reset password</h5>
                          {this.state.errorMessage !== "" ? (
                            <div className="example-alert">
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {this.state.successMessage !== "" ? (
                            <div className="example-alert">
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <br />
                          <div class="nk-block-des">
                            <p>
                              If you forgot your password, well, then we’ll
                              email you instructions to reset your password.
                            </p>
                          </div>
                        </div>
                      </div>
                      <form
                        method="post"
                        className="forgotForm"
                        onSubmit={this.formPost}
                      >
                        <div className="form-group">
                          <div className="form-label-group">
                            <label className="form-label" htmlFor="default-01">
                              Email
                            </label>
                          </div>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            placeholder="Enter your email address"
                          />
                        </div>
                        <div className="form-group">
                          <button
                            className="btn btn-lg btn-block"
                            style={{
                              backgroundColor: THEME_COLOR,
                              color: THEME_TEXT_COLOR,
                            }}
                            type="submit"
                            disabled={this.state.btnDisable}
                          >
                            Send Reset Link
                          </button>
                        </div>
                      </form>
                      <div class="form-note-s2 text-center pt-4">
                        <a href={`${this.state.res_url}/client/login`}>
                          <strong>Back to login</strong>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="nk-footer nk-auth-footer-full">
                  <div class="container wide-lg">
                    <div class="row g-3">
                      <div class="col-lg-12">
                        <div class="nk-block-content text-center">
                          <p>
                            &copy; {new Date().getFullYear()}{" "}
                            <a
                              href="https://onboardsoft.com/"
                              style={{ color: "#526484" }}
                            >
                              OnBoardsoft LLC
                            </a>
                            . All Rights Reserved.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
