import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";

import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";

import { COMPANY_NAME, LOGO, THEME_COLOR } from "../../../config/config";

import { ValidateEmail, axiosConfig } from "../../../config/utility";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";

import helpherClass from "../../../config/helperClass";
const auth = new helpherClass();

export default class Resolver extends Component {
  constructor() {
    super();
    this.state = {
      errorEmail: "",
      errorPass: "",
      email: "",
      password: "",
      errorMessage: "",
      disabled: false,
      loader: "",
      successMessage: "",
      tableLoader: true,
    };
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  async componentDidMount() {
    let reseller_url = this.props.match.params.reseller_url;
    let reseller_hash = this.props.match.params.reseller_hash;
    let action_type = "agent";
    if (this.props.match.params.action_type) {
      action_type = "sub";
    }

    console.log(
      "URL Details: ",
      reseller_url + " " + reseller_hash + " " + action_type
    );

    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/check_reseller",
          JSON.stringify({
            reseller_url: reseller_url,
            reseller_hash: reseller_hash,
            action_type: action_type,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          }),
          axiosConfig
        )
        .then((response) => {
          console.log("response.data : ", response.data);
          if (
            response.data.status === 404 ||
            response.data.status === 422 ||
            response.data.errors !== ""
          ) {
            this.setState({
              errorMessage: "There is some error.",
              successMessage: "",
              show_form: false,
            });
          }

          if (response.data.status === 403) {
            this.setState({
              errorMessage: "There is some error.",
              successMessage: "",
              show_form: false,
            });
          } else if (
            response.data.status === 200 &&
            response.data.message === "success"
          ) {
            Cookies.set(
              "residentifier__",
              CryptoJS.AES.encrypt(
                reseller_url,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "restkn__",
              CryptoJS.AES.encrypt(
                reseller_hash,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "resacct__",
              CryptoJS.AES.encrypt(
                response.data.data,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "resregtype__",
              CryptoJS.AES.encrypt(
                response.data.action_type,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            setTimeout(function () {
              window.location.replace("/register");
            }, 1000);
            this.setState({
              errorMessage: "",
              resellerData: response.data,
              tableLoader: false,
              show_form: true,
            });
          }
        });
    } catch (error) {
      this.setState({
        errorMessage: "There is some error.",
        successMessage: "",
        show_form: false,
      });
      console.log("catch : ", error);
    }
  }

  formSubmit = (e) => {
    e.preventDefault();
    let { email, password } = this.state;

    if (email === "") {
      return this.setState({
        errorEmail: "error",
        errorMessage: "Email is required.",
      });
    } else if (ValidateEmail(email) === false) {
      return this.setState({
        errorEmail: "error",
        errorMessage: "Invalid email format.",
      });
    } else if (password === "") {
      return this.setState({
        errorPass: "error",
        errorEmail: "",
        errorMessage: "Password is required.",
      });
    } else {
      this.setState({
        errorPass: "",
        errorEmail: "",
        errorMessage: "",
        disabled: true,
        loader: "show",
      });

      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "login",
            JSON.stringify({
              email: email,
              password: password,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            }),
            axiosConfig
          )
          .then((response) => {
            if (
              response.data.status === 404 ||
              response.data.status === 422 ||
              response.data.errors !== ""
            ) {
              this.setState({
                errorMessage: response.data.errors,
                disabled: false,
              });
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessage:
                  "Please wait, redirecting you to the dashboard.",
                errorMessage: "",
              });
              Cookies.set(
                "tkn__",
                CryptoJS.AES.encrypt(
                  response.data.token,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "acn__",
                CryptoJS.AES.encrypt(
                  response.data.accountno,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "eml__",
                CryptoJS.AES.encrypt(
                  response.data.email,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "comp_nme__",
                CryptoJS.AES.encrypt(
                  response.data.comp_name,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "name__",
                CryptoJS.AES.encrypt(
                  response.data.name,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "phone__",
                CryptoJS.AES.encrypt(
                  response.data.phone,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "title__",
                CryptoJS.AES.encrypt(
                  response.data.title,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );

              setTimeout(function () {
                //this.props.history.push("/dashboard");
                //  window.location = "/dashboard";
                window.location.replace("/dashboard");
              }, 1000);
            }
          });
      } catch (error) {
        console.log("catch : ", error);
      }
    }
  };

  render() {
    if (auth.isLogin() === true) {
      return <Redirect to="/dashboard" />;
    }
    if (auth.isAdminLogin() === true) {
      return <Redirect to="/admin_dashboard" />;
    }
    return (
      <div className="nk-content-body">
        <div className="content-page " style={{ padding: "30px" }}>
          {/*<div className="nk-block-head nk-block-head-lg wide-sm mx-auto">
            <div className="nk-block-head-content text-center">
              <h2 className="nk-block-title fw-normal">&nbsp;</h2>
            </div>
    </div>*/}
          <div className="nk-block">
            <div className="card card-bordered">
              <div className="card-inner card-inner-xl">
                <div className="entry">
                  {this.state.tableLoader === true ? tableLoader() : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
