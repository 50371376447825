import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listDomains,
  deletePage,
  disableDomainSubmit,
  deleteDomainSubmit,
  connectDomainSubmit,
  getSharelinks,
  verfiyDomainSharelink,
} from "./../../../config/domain_api_calls";
import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();

class Domains extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      delete_id: "",
      defaultNrc: "",
      defaultMrc: "",
      new_nrc: "",
      new_mrc: "",
      rate_type: "",
      rate: "",
      update_nrc: "",
      update_mrc: "",
      update_rate: "",
      product_id: "",
      product_desc: "",
      mrc_rate_type: "Flat",
      service_title: "",
      service_type_edit: "",
      confirm_status: "",
      disable_status: "",
      delete_status: "",
      domain_id: "",
      domain_name: "",
      is_verified: "",
      btn_loader: false,
      sharelinks: [],
      sharelink_id: "",
      dnsList: [],
      columnServices: [
        { name: "Domain Title" },
        { name: "Domain Name" },
        { name: "Assigned To Sharelink" },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Verified",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.verifiedText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Date Added",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div
                            className="dropdown-menu dropdown-menu-right"
                            style={{ minWidth: "220px" }}
                          >
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.connectDomain(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-grid-add-c"></em>
                                  <span>Connect Sharelink</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.disableDomain(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-na"></em>
                                  <span>Disconnect Sharelink</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.deleteDomain(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-cross-round"></em>
                                  <span>Delete Domain</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  defaultText = (value, tableMeta) => {
    let is_default = tableMeta.rowData[3];
    if (is_default === "Connected") {
      return (
        <Tooltip title="Domain is connected and assigned" placement="top">
          <span className="badge badge-outline-success">
            <em className="icon ni ni-done"></em> Connected
          </span>
        </Tooltip>
      );
    } else if (is_default === "Not Connected") {
      return (
        <Tooltip title="Domain is added but not assigned" placement="top">
          <span className="badge badge-outline-warning">
            <em className="icon ni ni-na"></em> Not Connected
          </span>
        </Tooltip>
      );
    } else if (is_default === "Pending") {
      return (
        <Tooltip
          title="Domain is added but pending configuration"
          placement="top"
        >
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-na"></em> Pending
          </span>
        </Tooltip>
      );
    } else if (is_default === "Cancelled") {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-cross-circle"></em> Cancelled
        </span>
      );
    } else {
      return "Inactive";
    }
  };

  verifiedText = (value, tableMeta) => {
    let is_default = tableMeta.rowData[4];
    let domain_name = tableMeta.rowData[1];
    if (is_default === "Yes") {
      return (
        <Tooltip title="DNS verified" placement="top">
          <span className="badge badge-outline-success">
            <em className="icon ni ni-done"></em> Yes
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="DNS not verified" placement="top">
          <a
            style={{ cursor: "pointer" }}
            onClick={() => this.VerifyConnection(domain_name)}
          >
            <span className="badge badge-outline-danger">
              <em className="icon ni ni-na"></em> Verify Domain
            </span>
          </a>
        </Tooltip>
      );
    }
  };

  format_date = (value, tableMeta) => {
    let date = tableMeta.rowData[5];
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  VerifyConnection = async (domain_name) => {
    this.setState({
      dnsList: [],
      errorMessage: "",
      successMessage: "",
    });
    const { domain_title } = this.state;
    console.log("domain_name: ", domain_name);
    window.$("#modalVerify").modal("show");
    if (domain_name) {
      const servicesResponce = await verfiyDomainSharelink(
        auth.getAccount(),
        auth.getToken(),
        domain_name
      );
      console.log("DNS RESPONSE: ", servicesResponce.data.dnsList);
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage: "There is some error while verifying the domain.",
          tableLoader: false,
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        if (servicesResponce.data.dnsList.ErrorMessage) {
          console.log(
            "DNS RESPONSE ErrorMessage: ",
            servicesResponce.data.dnsList.ErrorMessage
          );
          this.setState({
            dnsList: [],
            errorMessage:
              "Unable to retrieve DNS records. Try some other domain or add without verification.",
            tableLoader: false,
            disabled: false,
          });
        } else {
          this.setState({
            dnsList: servicesResponce.data.dnsList,
            errorMessage: "",
            tableLoader: false,
            disabled: false,
          });
          setTimeout(() => {
            window.$("#modalVerify").modal("hide");
            this.componentDidMount();
          }, 4000);
        }
      } else {
        this.setState({
          errorMessage: "There is some error while verifying the domain.",
          tableLoader: false,
          disabled: false,
        });
      }
    } else {
      this.setState({
        errorMessage: "Domain name cannot be empty.",
        tableLoader: false,
        disabled: false,
      });
    }
  };

  connectDomain = async (value, tableMeta) => {
    //modalConfirm
    let status = tableMeta.rowData[3];
    if (status === "Connected") {
      this.setState({
        confirm_status: "This domain is already connected.",
      });
    } else {
      this.setState({
        confirm_status: "Are you sure you want to connect this domain. ",
        domain_id: tableMeta.rowData[6],
        domain_name: tableMeta.rowData[1],
        is_verified: tableMeta.rowData[4],
      });
      const sharelinkResponce = await getSharelinks(
        auth.getAccount(),
        auth.getToken()
      );

      console.log("getSharelinks.data: ", sharelinkResponce.data);

      if (
        sharelinkResponce.data.status === 403 ||
        sharelinkResponce.data.errors === "authentication missing" ||
        sharelinkResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (sharelinkResponce.data.status === 404) {
        this.setState({
          errorMessage:
            "There is some error while getting available sharelinks.",
          successMessage: "",
        });
      } else if (
        sharelinkResponce.data.status === 404 &&
        sharelinkResponce.data.message === "no_record"
      ) {
        this.setState({
          errorMessage: "Sharelink does not available.",
          successMessage: "",
        });
      } else if (
        sharelinkResponce.data.status === 200 &&
        sharelinkResponce.data.message === "success"
      ) {
        this.setState({
          sharelinks: sharelinkResponce.data.data,
          errorMessage: "",
        });
      } else {
        this.setState({
          errorMessage:
            "There is some error while getting available sharelinks.",
          successMessage: "",
        });
      }
    }
    //this.componentDidMount();
    window.$("#modalConfirm").modal("show");
  };

  connectDomainSubmit = async () => {
    let { domain_id, domain_name, is_verified, sharelink_id } = this.state;
    if (sharelink_id === "") {
      this.setState({
        errorMessage: "Please select a sharelink to connect.",
        successMessage: "",
      });
    } else {
      const disableResponce = await connectDomainSubmit(
        auth.getAccount(),
        auth.getToken(),
        domain_id,
        domain_name,
        is_verified,
        sharelink_id
      );
      console.log("connectDomainSubmit.data: ", disableResponce.data);
      this.setState({
        btn_loader: true,
      });
      if (
        disableResponce.data.status === 403 ||
        disableResponce.data.errors === "authentication missing" ||
        disableResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        disableResponce.data.status === 404 &&
        disableResponce.data.message === "config_error"
      ) {
        this.setState({
          errorMessage: "There is some error while connecting the domain.",
          successMessage: "",
          btn_loader: false,
        });
      } else if (
        disableResponce.data.status === 404 &&
        disableResponce.data.message !== "config_error"
      ) {
        this.setState({
          errorMessage:
            "There is some error while adding the domain configuration.",
          successMessage: "",
          btn_loader: false,
        });
      } else if (
        disableResponce.data.status === 404 &&
        disableResponce.data.message !== "verify_error"
      ) {
        this.setState({
          errorMessage: "DNS records of given domain are not verified.",
          successMessage: "",
        });
      } else if (
        disableResponce.data.status === 404 &&
        disableResponce.data.message !== "remove_config_error"
      ) {
        this.setState({
          errorMessage:
            "There is some error while removing the domain configuration.",
          successMessage: "",
          btn_loader: false,
        });
      } else if (
        disableResponce.data.status === 200 &&
        disableResponce.data.message === "success"
      ) {
        this.setState({
          successMessage:
            "Your request has been received. It will be processed in 5 to 10 minutes.",
          errorMessage: "",
          btn_loader: false,
        });
      } else {
        this.setState({
          errorMessage: "There is some error while connecting the domain.",
          successMessage: "",
          btn_loader: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        successMessage: "",
        errorMessage: "",
        btn_loader: false,
      });
      window.$("#modalConfirm").modal("hide");
    }, 4000);
  };

  disableDomain = async (value, tableMeta) => {
    //modalConfirm
    let status = tableMeta.rowData[3];

    if (status !== "Connected") {
      this.setState({
        disable_status: "This domain is already disconnected.",
      });
    } else {
      this.setState({
        disable_status:
          "Are you sure you want to disconnect this domain. You will not be able to access the portal after disconnecting it.",
        domain_id: tableMeta.rowData[6],
        domain_name: tableMeta.rowData[1],
        is_verified: tableMeta.rowData[4],
      });
    }
    window.$("#modalDisable").modal("show");
  };

  disableDomainSubmit = async () => {
    let { domain_id, domain_name, is_verified } = this.state;
    const disableResponce = await disableDomainSubmit(
      auth.getAccount(),
      auth.getToken(),
      domain_id,
      domain_name,
      is_verified
    );
    console.log("disableResponce.data: ", disableResponce.data);
    this.setState({
      btn_loader: true,
    });
    if (
      disableResponce.data.status === 403 ||
      disableResponce.data.errors === "authentication missing" ||
      disableResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      disableResponce.data.status === 404 &&
      disableResponce.data.message === "config_error"
    ) {
      this.setState({
        errorMessage:
          "There is some error while removing the domain configuration.",
        successMessage: "",
        btn_loader: false,
      });
    } else if (
      disableResponce.data.status === 404 &&
      disableResponce.data.message !== "config_error"
    ) {
      this.setState({
        errorMessage: "There is some error while disconnecting the domain.",
        successMessage: "",
        btn_loader: false,
      });
    } else if (
      disableResponce.data.status === 200 &&
      disableResponce.data.message === "success"
    ) {
      this.setState({
        successMessage:
          "Your request has been received. It will be processed in 5 to 10 minutes.",
        errorMessage: "",
        btn_loader: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while disconnecting the domain.",
        successMessage: "",
        btn_loader: false,
      });
    }
    setTimeout(() => {
      this.setState({
        successMessage: "",
        errorMessage: "",
        btn_loader: false,
      });
    }, 4000);
  };

  deleteDomain = async (value, tableMeta) => {
    //modalConfirm
    let status = tableMeta.rowData[3];

    this.setState({
      delete_status: "Are you sure you want to delete this domain. ",
      domain_id: tableMeta.rowData[6],
      domain_name: tableMeta.rowData[1],
      is_verified: tableMeta.rowData[4],
    });

    window.$("#modalDelete").modal("show");
  };

  deleteDomainSubmit = async () => {
    let { domain_id, domain_name, is_verified } = this.state;
    const disableResponce = await deleteDomainSubmit(
      auth.getAccount(),
      auth.getToken(),
      domain_id,
      domain_name,
      is_verified
    );
    this.setState({
      btn_loader: true,
    });
    console.log("disableResponce.data: ", disableResponce.data);

    if (
      disableResponce.data.status === 403 ||
      disableResponce.data.errors === "authentication missing" ||
      disableResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (disableResponce.data.status === 404) {
      this.setState({
        errorMessage:
          "There is some error while removing the domain configuration.",
        successMessage: "",
        btn_loader: false,
      });
    } else if (
      disableResponce.data.status === 200 &&
      disableResponce.data.message === "config_error"
    ) {
      this.setState({
        errorMessage: "",
        successMessage:
          "Your request has been received. It will be processed in 5 to 10 minutes.",
        btn_loader: false,
      });
    } else if (
      disableResponce.data.status === 200 &&
      disableResponce.data.message === "success"
    ) {
      this.setState({
        successMessage: "Domain deleted successfully.",
        errorMessage: "",
        btn_loader: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while deleting the domain.",
        successMessage: "",
        btn_loader: false,
      });
    }
    setTimeout(() => {
      this.setState({
        successMessage: "",
        errorMessage: "",
        btn_loader: false,
      });
    }, 4000);
  };

  publishText = (value, tableMeta) => {
    let is_default = tableMeta.rowData[4];
    if (is_default === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  async componentDidMount() {
    const servicesResponce = await listDomains(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("servicesResponce.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting the domains.",
        successMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting the domains.",
        successMessage: "",
      });
    }
  }

  setMarkUp = async (value, tableMeta) => {
    await this.handleStatesDynamic(
      tableMeta.rowData[0],
      tableMeta.rowData[3].replace("$", ""),
      tableMeta.rowData[4].replace("$", ""),
      tableMeta.rowData[6].replace("$", ""),
      tableMeta.rowData[1],
      tableMeta.rowData[5]
    );
    console.log("setMarkupData: ", this.state.service_title);
    window.$("#modalForm").modal("show");
  };

  handleStatesDynamic = async (
    serviceId,
    update_nrc,
    update_mrc,
    update_rate,
    service_title,
    service_type_edit
  ) => {
    this.setState({
      serviceId: serviceId,
      update_nrc: update_nrc,
      update_mrc: update_mrc,
      update_rate: update_rate,
      service_title: service_title,
      service_type_edit: service_type_edit,
    });
  };

  showDetails = (value, tableMeta) => {
    this.setState({
      product_desc: tableMeta.rowData[7],
    });
    window.$("#modalFormDetail").modal("show");
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  calculatePercent = (percent, num) => {
    return (percent / 100) * num;
  };
  modalHide = () => {
    window.$("#modalFormDetail").modal("hide");
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");

    return (
      <div className="nk-content " id="Cms_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Manage Domains" icon="icon ni ni-link" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <a
                              style={{ color: "#ffffff" }}
                              onClick={() =>
                                this.addMenuHistory("/domains/Adddomains")
                              }
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-plus-round"></em>
                              <span>Add Domain</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title="All Domains"
                        />
                      )}
                      {/* END TABLE */}
                    </div>
                  </div>
                  <br />
                  <div className="modal fade" tabIndex="-1" id="modalConfirm">
                    <div
                      className="modal-dialog modal-dialog-top  modal-lg"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">Connect Domain</h5>
                        </div>
                        <div className="modal-body">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "20px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "20px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="form-group">
                            <MutextField
                              id="sharelink_id"
                              name="sharelink_id"
                              select
                              label="Select Share Link"
                              value={this.state.sharelink_id}
                              onChange={this.handleChange}
                              SelectProps={{
                                native: true,
                              }}
                              helperText="Please select the sharelink to connect the domain."
                              variant="outlined"
                              fullWidth
                            >
                              <option key="" value=""></option>
                              {this.state.sharelinks.map((services) => (
                                <option value={services[0]}>
                                  {services[1]} ({services[2]})
                                </option>
                              ))}
                            </MutextField>
                          </div>
                        </div>
                        <div
                          className="modal-footer bg-light"
                          style={{
                            float: "right",
                            textAlign: "right",
                            justifyContent: "flex-end",
                          }}
                        >
                          <a
                            href="#"
                            className="btn btn-danger"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            Cancel
                          </a>
                          {this.state.confirm_status !==
                          "This domain is already connected." ? (
                            <>
                              {this.state.btn_loader === true ? (
                                <button
                                  className="btn btn-dim btn-primary"
                                  disabled
                                >
                                  <em className="icon ni ni-swap"></em> Connect
                                  <div
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </button>
                              ) : (
                                <a
                                  onClick={() => {
                                    this.connectDomainSubmit();
                                  }}
                                  className="btn btn-primary"
                                  style={{ color: "#fff" }}
                                >
                                  Connect
                                </a>
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal fade" tabIndex="-1" id="modalVerify">
                    <div
                      className="modal-dialog modal-dialog-top  modal-lg"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">Verifying Domain DNS</h5>
                        </div>
                        <div className="modal-body">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "20px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "20px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="col-lg-12 col-md-12 col-xl-12">
                            {this.state.dnsList.DNSData ? (
                              <>
                                {this.state.dnsList.DNSData.dnsRecords.map(
                                  (dns, index) => (
                                    <>
                                      {dns.type == 1 ? (
                                        <>
                                          {dns.address ==
                                          process.env
                                            .REACT_APP_AGENT_PORTAL_DNS ? (
                                            <>
                                              <div className="callout callout-success">
                                                <h4>Domain Verified</h4>
                                                Your domain is verified. Now you
                                                can connect the domain to the
                                                sharelink.
                                                <br />
                                                <br />
                                              </div>
                                              <p>
                                                <strong
                                                  style={{
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  A RECORD
                                                </strong>{" "}
                                                (@)
                                                <br />
                                                <br />
                                                Current IP address:{" "}
                                                {dns.address} <br />
                                                Required IP address:{" "}
                                                {
                                                  process.env
                                                    .REACT_APP_AGENT_PORTAL_DNS
                                                }
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              <p>
                                                <strong
                                                  style={{
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  A RECORD entered incorrectly
                                                </strong>{" "}
                                                <br />
                                                Check that you've entered the
                                                required values, and verify your
                                                connection again.
                                              </p>
                                              <p>
                                                <strong
                                                  style={{
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  A RECORD
                                                </strong>{" "}
                                                (@)
                                                <br />
                                                Current IP address:{" "}
                                                <span style={{ color: "red" }}>
                                                  {dns.address}
                                                </span>{" "}
                                                <br />
                                                Required IP address:{" "}
                                                {
                                                  process.env
                                                    .REACT_APP_AGENT_PORTAL_DNS
                                                }
                                              </p>
                                              <div className="callout callout-warning">
                                                <h4>Connection Incomplete</h4>
                                                Some providers take few minutes
                                                to update their settings. If you
                                                want to verify again, return one
                                                step back and click "Verify
                                                Connection" button.
                                                <br />
                                                <br />
                                              </div>
                                            </>
                                          )}
                                        </>
                                      ) : null}
                                    </>
                                  )
                                )}
                              </>
                            ) : (
                              <div className="callout callout-warning">
                                <h4>Domain verification in process</h4>
                                Please wait while we are verifying your domain.
                                <br />
                                <br />
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="modal-footer bg-light"
                          style={{
                            float: "right",
                            textAlign: "right",
                            justifyContent: "flex-end",
                          }}
                        >
                          <a
                            href="#"
                            className="btn btn-danger"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal fade" tabIndex="-1" id="modalDisable">
                    <div
                      className="modal-dialog modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">Disconnect Domain</h5>
                        </div>
                        <div className="modal-body">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "20px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "20px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <p>{this.state.disable_status}</p>
                        </div>
                        <div
                          className="modal-footer bg-light"
                          style={{
                            float: "right",
                            textAlign: "right",
                            justifyContent: "flex-end",
                          }}
                        >
                          {this.state.disable_status !==
                          "This domain is already disconnected." ? (
                            <>
                              {this.state.btn_loader === true ? (
                                <button
                                  className="btn btn-dim btn-primary"
                                  disabled
                                >
                                  <em className="icon ni ni-swap"></em>{" "}
                                  Disconnect
                                  <div
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </button>
                              ) : (
                                <a
                                  onClick={() => {
                                    this.disableDomainSubmit();
                                  }}
                                  className="btn btn-dim btn-primary"
                                >
                                  Disconnect
                                </a>
                              )}
                            </>
                          ) : null}
                          <a
                            href="#"
                            className="btn btn-dim btn-danger"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal fade" tabIndex="-1" id="modalDelete">
                    <div
                      className="modal-dialog modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">Delete Domain</h5>
                        </div>
                        <div className="modal-body">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "20px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "20px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <p>{this.state.delete_status}</p>
                        </div>
                        <div
                          className="modal-footer bg-light"
                          style={{
                            float: "right",
                            textAlign: "right",
                            justifyContent: "flex-end",
                          }}
                        >
                          {this.state.delete_status !==
                          "This domain is already connected." ? (
                            <>
                              {this.state.btn_loader === true ? (
                                <button
                                  className="btn btn-dim btn-primary"
                                  disabled
                                >
                                  <em className="icon ni ni-swap"></em> Delete
                                  <div
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </button>
                              ) : (
                                <a
                                  onClick={() => {
                                    this.deleteDomainSubmit();
                                  }}
                                  className="btn btn-dim btn-primary"
                                >
                                  Delete
                                </a>
                              )}
                            </>
                          ) : null}
                          <a
                            href="#"
                            className="btn btn-dim btn-danger"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Domains);
