import ContentLoader from "react-content-loader";
export const tableLoaderSimple = () => {
  return (
    <ContentLoader
      width="100%"
      viewBox="0 0 400 500"
      speed={4}
      primarycolor="#f3f3f3"
      secondarycolor="#ecebeb"
    >
      <rect x="0" y="0" rx="4" width="80" height="10" />
      <rect x="90" y="0" rx="4" width="320" height="10" />

      <rect x="0" y="30" rx="4" width="80" height="10" />
      <rect x="90" y="30" rx="4" width="320" height="10" />

      <rect x="0" y="60" rx="4" width="80" height="10" />
      <rect x="90" y="60" rx="4" width="320" height="10" />

      <rect x="0" y="90" rx="4" width="80" height="10" />
      <rect x="90" y="90" rx="4" width="320" height="10" />
    </ContentLoader>
  );
};
