import React, { Component } from "react";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import HelperClass from "./../../../config/helperClass";
import PageTitle from "../ExtraComponents/PageTitle";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import { addSettings } from "../../../actions";
import {
  getSpecificTimeRangeChartData,
  getSpecificTimeRangeMostViewedData,
} from "./../../../config/shortlink_api_calls";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { tableLoaderSimple } from "../../../components/ContentLoaders/table_loader_simple";
//
const auth = new HelperClass();

class SharelinkAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Clicks",
            data: [],
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
          },
        ],
      },
      chartOptions: {
        scales: {
          y: {
            min: 0,
            ticks: {
              precision: 0,
            },
          },
        },
        plugins: {
          tooltip: {
            mode: "index",
            intersect: false,
          },
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
        },
      },
      mostViewedData: [],
      selectedOption: "7_days",
      tableLoaderListing: true,
      tableLoaderChart: true,
    };
  }

  async componentDidMount() {
    let currentDate = new Date();
    let formattedCurrentDate = this.formatDate(currentDate);
    let sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    let formattedSevenDaysAgo = this.formatDate(sevenDaysAgo);
    this.getChartData(
      formattedSevenDaysAgo,
      formattedCurrentDate,
      this.state.selectedOption
    );
    this.getMostViewedData(
      formattedSevenDaysAgo,
      formattedCurrentDate,
      this.state.selectedOption
    );
  }

  async getChartData(date_from, date_to, range) {
    const analyticsResponce = await getSpecificTimeRangeChartData(
      auth.getAccount(),
      auth.getToken(),
      date_from,
      date_to,
      range
    );
    console.log("sharelink chart Data: ", analyticsResponce.data);
    if (
      analyticsResponce.data.status === 403 ||
      analyticsResponce.data.errors === "authentication missing" ||
      analyticsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (analyticsResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      analyticsResponce.data.status === 200 &&
      analyticsResponce.data.message === "success"
    ) {
      const responseData = analyticsResponce.data.data.data;
      let newLabels = [];
      if (this.state.selectedOption === "this_year") {
        newLabels = Object.keys(responseData).map((month) => month);
      } else {
        newLabels = Object.keys(responseData).map((date) => {
          const dateObj = new Date(date);
          return `${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
        });
      }
      const newData = Object.values(responseData).map((count) =>
        parseInt(count)
      );
      console.log("newLabels: ", newLabels);
      console.log("newData: ", newData);
      const updatedChartData = {
        ...this.state.chartData,
        labels: newLabels,
        datasets: [
          {
            ...this.state.chartData.datasets[0],
            data: newData,
          },
        ],
      };

      this.setState({ chartData: updatedChartData, tableLoaderChart: false });
    } else {
      //window.location.replace("/error");
    }
  }

  async getMostViewedData(date_from, date_to, range) {
    const analyticsResponce = await getSpecificTimeRangeMostViewedData(
      auth.getAccount(),
      auth.getToken(),
      date_from,
      date_to,
      range
    );
    console.log("sharelink Most Viewed Data: ", analyticsResponce.data.data);
    if (
      analyticsResponce.data.status === 403 ||
      analyticsResponce.data.errors === "authentication missing" ||
      analyticsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (analyticsResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      analyticsResponce.data.status === 200 &&
      analyticsResponce.data.message === "success"
    ) {
      this.setState({
        mostViewedData: analyticsResponce.data.data,
        tableLoaderListing: false,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  handleOptionChange = (event) => {
    const selectedOption = event.target.value;

    let currentDate = new Date();
    let formattedCurrentDate = this.formatDate(currentDate);
    this.setState({
      selectedOption: selectedOption,
      tableLoaderChart: true,
      tableLoaderListing: true,
    });
    let today = new Date();
    const date = new Date(today);
    switch (selectedOption) {
      case "today":
        let oneDayBefore = new Date(currentDate);
        oneDayBefore.setDate(oneDayBefore.getDate() - 1);
        let formattedOneDayBefore = this.formatDate(oneDayBefore);
        this.getChartData(
          formattedOneDayBefore,
          formattedCurrentDate,
          selectedOption
        );
        this.getMostViewedData(
          formattedOneDayBefore,
          formattedCurrentDate,
          selectedOption
        );
        break;
      case "7_days":
        let sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
        let formattedSevenDaysAgo = this.formatDate(sevenDaysAgo);
        this.getChartData(
          formattedSevenDaysAgo,
          formattedCurrentDate,
          selectedOption
        );
        this.getMostViewedData(
          formattedSevenDaysAgo,
          formattedCurrentDate,
          selectedOption
        );
        break;
      case "30_days":
        let oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        let formattedOneMonthAgo = this.formatDate(oneMonthAgo);
        this.getChartData(
          formattedOneMonthAgo,
          formattedCurrentDate,
          selectedOption
        );
        this.getMostViewedData(
          formattedOneMonthAgo,
          formattedCurrentDate,
          selectedOption
        );
        break;
      case "this_year":
        let startOfYear = new Date();
        startOfYear.setMonth(0);
        startOfYear.setDate(1);
        startOfYear.setHours(0, 0, 0, 0);
        let formattedStartOfYear = this.formatDate(startOfYear);
        this.getChartData(
          formattedStartOfYear,
          formattedCurrentDate,
          selectedOption
        );
        this.getMostViewedData(
          formattedStartOfYear,
          formattedCurrentDate,
          selectedOption
        );
        break;
      // case '1_year':
      // let oneYearAgo = new Date();
      // oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
      // let formattedOneYearAgo = this.formatDate(oneYearAgo);
      //   newData = [20, 22, 25, 28, 30, 32, 35, 32, 35, 38, 40, 45, 48];
      //   for (let i = 11; i >= 0; i--) {
      //     date.setMonth(date.getMonth() - i);
      //     const month = date.toLocaleString('default', { month: 'short' });
      //     newLabels.push(month);
      //   }
      //   this.getChartData(formattedOneYearAgo,formattedCurrentDate);
      //   break;
      default:
        break;
    }
  };

  formatDate = (date) => {
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let day = String(date.getDate()).padStart(2, "0");
    let formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  redirectToViewAll = () => {
    window.location.replace("/sharelinks");
  };
  render() {
    return (
      <div className="nk-content " id="Analytics_Block">
        <div className="container-fluid">
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between" style={{ marginBottom: "20px" }}>
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  <PageTitle
                    name="Sharelink Analytics"
                    icon="icon ni ni-setting"
                  />
                </h3>
              </div>
            </div>
            <div className="row g-4">
              <div className="col-md-12">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <select
                    className="form-control"
                    value={this.state.selectedOption}
                    onChange={this.handleOptionChange}
                    style={{ width: "max-content" }}
                  >
                    <option value="today">Today</option>
                    <option value="7_days">Last 7 Days</option>
                    <option value="30_days">Last 30 Days</option>
                    <option value="this_year">This Year</option>
                    {/* <option value="1_year">Last Year</option> */}
                  </select>
                  <label style={{ margin: "0px 0px 0px 5px" }}>
                    Showing data for all links
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row g-4">
            <div className="col-lg-6 col-md-12">
              <div className="card card-bordered">
                <div className="card-inner">
                  <h5 className="card-title">Total clicks overtime</h5>
                  {this.state.tableLoaderChart === true ? (
                    tableLoaderSimple()
                  ) : (
                    <div style={{ height: "max-content", width: "100%" }}>
                      <Line
                        data={this.state.chartData}
                        options={this.state.chartOptions}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="card card-bordered">
                <div className="card-inner">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <h5 className="card-title">Top performing clicks</h5>
                    </div>
                    <div style={{ flex: 1, textAlign: "right" }}>
                      <button
                        type="button"
                        className="btn btn-md btn-primary"
                        onClick={this.redirectToViewAll}
                      >
                        <em className="icon ni ni-note-add"></em>
                        {"  "}View All
                      </button>
                    </div>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    {this.state.tableLoaderListing === true ? (
                      tableLoaderSimple()
                    ) : (
                      <table
                        id="sharelinkViews-table"
                        className="sharelink-table"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Link</th>
                            <th>Clicks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.mostViewedData
                            .sort((a, b) => b[1] - a[1])
                            .slice(0, 10)
                            .map((data, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <a
                                    href={data[4]}
                                    target="_blank"
                                    style={{ color: "#4853df" }}
                                  >
                                    {data[0]}
                                  </a>
                                </td>
                                <td>{data[1]}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SharelinkAnalytics);
