import React, { Component, Fragment } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  MuiThemeProvider,
} from "@material-ui/core";

export class DomainSuccess extends Component {
  first = (e) => {
    setTimeout(() => {
      this.props.firstStep();
    }, 1000);
  };
  render() {
    return (
      <div className="col-lg-12">
        <div className="nk-block">
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group" style={{ padding: "40px" }}>
              <h5>Domain Registration Completed</h5>
              <div
                className="alert alert-pro alert-success"
                style={{ marginTop: "30px" }}
              >
                <div className="alert-text">
                  <h6>Success</h6>
                  <p>
                    Your domain creation process is completed. Domain will be
                    available in 5 to 10 minutes.
                  </p>
                </div>
              </div>
              <div className="form-group text-right">
                <button
                  className="btn btn-lg btn-primary"
                  onClick={this.props.firstStep}
                >
                  <em className="icon ni ni-first"></em>
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DomainSuccess;
