import { Link } from "react-router-dom";
export default function SettingsError() {
  return (
    <div>
      <div className="example-alert">
        <div className="alert alert-warning alert-icon">
          <em className="icon ni ni-alert-circle"></em>
          <strong>Settings Missing, </strong>
          Your account is not activated, Please contact <strong>
            Admin
          </strong>.{" "}
        </div>
      </div>
      <br />
    </div>
  );
}
