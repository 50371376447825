import React, { Component, Fragment } from "react";
import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

export class DomainVerification extends Component {
  constructor() {
    super();
    this.state = {
      disabled: false,
      errorMessage: "",
      successMessage: "",
      tableLoader: false,
      dnsList: "",
    };
  }

  continue = (e) => {
    e.preventDefault();
    //PROCESS FORM//
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForm = () => {
    const { values } = this.props;

    this.props.submitDomain();
  };

  verificationResult = () => {
    console.log("dnsList.DNSData.dnsRecords", this.props);
    /*if (dnsList.ErrorMessage) {
      return (
        <div class="callout callout-danger">
          <h4>Error verifying the domain</h4>
          {dnsList.ErrorMessage.msg}
        </div>
      );
    } else if (dnsList.DNSData.dnsRecords) {
      return (
        <>
          {dnsList.DNSData.dnsRecords.map((dns) => (
            <p>{dns.type}</p>
          ))}
        </>
      );
    }*/
  };

  render() {
    const {
      values: { values, domain_title, domain_name, dnsList, a_ip },
    } = this.props;

    console.log("Verification dnsList: ", dnsList);

    return (
      <>
        <div className="col-lg-12">
          <div className="nk-block">
            <div className="card card-bordered card-stretch">
              <div className="card-inner-group" style={{ padding: "40px" }}>
                <h5>Connect your domain</h5>

                {this.props.values.errorMessage ? (
                  <div
                    className="alert alert-pro alert-danger"
                    style={{ marginTop: "30px" }}
                  >
                    <div className="alert-text">
                      <h6>Error</h6>
                      <p>{this.props.values.errorMessage}</p>
                    </div>
                  </div>
                ) : null}
                {this.props.values.successMessage ? (
                  <div
                    className="alert alert-pro alert-success"
                    style={{ marginTop: "30px" }}
                  >
                    <div className="alert-text">
                      <h6>Success</h6>
                      <p>{this.props.values.successMessage}</p>
                    </div>
                  </div>
                ) : null}
                {dnsList.DNSData ? (
                  <>
                    {dnsList.DNSData.dnsRecords.map((dns, index) => (
                      <>
                        {dns.type == 1 ? (
                          <>
                            {dns.address ==
                            process.env.REACT_APP_AGENT_PORTAL_DNS ? (
                              <>
                                <div class="alert alert-pro alert-success">
                                  <h4>Domain Verified</h4>
                                  Your domain is verified. Now complete the
                                  verification process by clicking the Complete
                                  Domain Registration button.
                                  <br />
                                  <br />
                                  {/*<button
                                    className="btn btn-lg btn-outline-dark"
                                    onClick={this.submitForm}
                                    style={{ marginRight: "10px" }}
                                  >
                                    Add Domain
                            </button>*/}
                                </div>
                                <p>
                                  <strong style={{ fontWeight: "bold" }}>
                                    A RECORD
                                  </strong>{" "}
                                  (@)
                                  <br />
                                  <br />
                                  Current IP address: {dns.address} <br />
                                  Required IP address:{" "}
                                  {process.env.REACT_APP_AGENT_PORTAL_DNS}
                                </p>
                              </>
                            ) : (
                              <>
                                <p>
                                  <strong style={{ fontWeight: "bold" }}>
                                    A RECORD entered incorrectly
                                  </strong>{" "}
                                  <br />
                                  Check that you've entered the required values,
                                  and verify your connection again.
                                </p>
                                <p>
                                  <strong style={{ fontWeight: "bold" }}>
                                    A RECORD
                                  </strong>{" "}
                                  (@)
                                  <br />
                                  Current IP address:{" "}
                                  <span style={{ color: "red" }}>
                                    {dns.address}
                                  </span>{" "}
                                  <br />
                                  Required IP address:{" "}
                                  {process.env.REACT_APP_AGENT_PORTAL_DNS}
                                </p>
                                <div class="callout callout-danger">
                                  <h4>Connection Incomplete</h4>
                                  Some providers take few minutes to update
                                  their settings. If you want to verify again,
                                  return one step back and click "Verify
                                  Connection" button.
                                  <br />
                                  <br />
                                  {/*<button
                                    className="btn btn-lg btn-outline-dark"
                                    onClick={this.submitForm}
                                    style={{ marginRight: "10px" }}
                                  >
                                    Add Domain
                            </button>*/}
                                </div>
                              </>
                            )}
                          </>
                        ) : null}
                      </>
                    ))}
                  </>
                ) : null}

                <hr />
                <div className="form-group text-right">
                  <button
                    className="btn btn-lg btn-default  btn-outline-dark"
                    onClick={this.back}
                    style={{ marginRight: "10px" }}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-lg btn-primary"
                    onClick={this.submitForm}
                  >
                    Complete Domain Registration
                    <em className="icon ni ni-done"></em>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DomainVerification;
