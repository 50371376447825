import ContentLoader from "react-content-loader";
export const tableLoaderListing = () => {
  return (
    <ContentLoader
      width="100%"
      speed={5}
      primarycolor="#f3f3f3"
      secondarycolor="#ecebeb"
    >
      <rect x="0" y="0" rx="4" width="24%" height="10" />
      <rect x="25%" y="0" rx="4" width="24%" height="10" />
      <rect x="50%" y="0" rx="4" width="24%" height="10" />
      <rect x="75%" y="0" rx="4" width="24%" height="10" />

      <rect x="0" y="20" rx="4" width="24%" height="10" />
      <rect x="25%" y="20" rx="4" width="24%" height="10" />
      <rect x="50%" y="20" rx="4" width="24%" height="10" />
      <rect x="75%" y="20" rx="4" width="24%" height="10" />

      <rect x="0" y="40" rx="4" width="24%" height="10" />
      <rect x="25%" y="40" rx="4" width="24%" height="10" />
      <rect x="50%" y="40" rx="4" width="24%" height="10" />
      <rect x="75%" y="40" rx="4" width="24%" height="10" />

      <rect x="0" y="60" rx="4" width="24%" height="10" />
      <rect x="25%" y="60" rx="4" width="24%" height="10" />
      <rect x="50%" y="60" rx="4" width="24%" height="10" />
      <rect x="75%" y="60" rx="4" width="24%" height="10" />

      <rect x="0" y="80" rx="4" width="24%" height="10" />
      <rect x="25%" y="80" rx="4" width="24%" height="10" />
      <rect x="50%" y="80" rx="4" width="24%" height="10" />
      <rect x="75%" y="80" rx="4" width="24%" height="10" />
    </ContentLoader>
  );
};
