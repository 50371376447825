import { React } from "react";
import { Component } from "react";
import { COMPANY_NAME } from "../../../config/config";

import {
  getAgreements,
  getSingleAgreement,
  deleteAgreement,
} from "./../../../config/admin_apis";

import $ from "jquery";

import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";

import TextField from "../../../components/InputFields/TextField";

import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import SettingIcon from "@material-ui/icons/Settings";
import Tooltip from "@material-ui/core/Tooltip";

import MutextField from "@material-ui/core/TextField";

import Typography from "@material-ui/core/Typography";

import HomeIcon from "@material-ui/icons/Home";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const auth = new HelperClass();

export default class AdminAgreements extends Component {
  constructor() {
    super();

    this.state = {
      apiUrl: "",
      apiKey: "",
      authKey: "",
      useraccountno: "",
      disabled: false,
      errorMessage: "",
      successMessage: "",
      errorMessaged: "",
      successMessaged: "",
      product_title: "",
      service_type: "did",
      mrc: "0.00",
      nrc: "0.00",
      rate: "0.00",
      product_id: "",
      e_product_title: "",
      e_service_type: "did",
      e_mrc: "",
      e_nrc: "",
      e_rate: "",
      sms_in: "0.00",
      sms_out: "0.00",
      e_sms_in: "0.00",
      e_sms_out: "0.00",
      agreement_view: "",
      delete_id: "",
      columns: [
        { name: "ID" },
        { name: "Title" },
        {
          name: "Published",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.publishText(value, tableMeta)}</div>;
            },
          },
        },
        { name: "Published Date" },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.statusText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Mandatory",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.signupText(value, tableMeta)}</div>;
            },
          },
        },
        { name: "Date Added" },
        {
          name: "Default",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  {/*<a
                    href="#"
                    onClick={() =>
                      this.viewAgreement(
                        tableMeta.rowData[0],
                        tableMeta.rowData[1]
                      )
                    }
                  >
                    <Tooltip title="View Agreement" placement="left">
                      <VisibilityIcon
                        style={{ cursor: "pointer", color: "#ffc107" }}
                      />
                    </Tooltip>
                  </a>
                  &nbsp;|&nbsp;*/}
                  <Tooltip title="Edit Agreement" placement="left">
                    <Link
                      to={
                        "/admin_agreements/editagreement/" +
                        tableMeta.rowData[0]
                      }
                    >
                      <EditIcon
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Link>
                  </Tooltip>
                  &nbsp;|&nbsp;
                  <a
                    href="#"
                    onClick={() => this.deleteAlert(tableMeta.rowData[0])}
                  >
                    <Tooltip title="Delete Agreement" placement="left">
                      <DeleteIcon
                        style={{
                          cursor: "pointer",
                          color: "#dc3545",
                        }}
                      />
                    </Tooltip>
                  </a>
                </div>
              );
            },
          },
        },
      ],
      tableData: [],
    };
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  async componentDidMount() {
    const clientsResponce = await getAgreements(
      auth.getAdminAccount(),
      auth.getAdminToken()
    );
    console.log("Agreement: ", clientsResponce.data.data);
    if (
      clientsResponce.data.status === 403 ||
      clientsResponce.data.errors === "authentication missing" ||
      clientsResponce.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (clientsResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error.",
      });
      //window.location.replace("/error");
    } else if (
      clientsResponce.data.status === 200 &&
      clientsResponce.data.message === "success"
    ) {
      this.setState({ tableData: clientsResponce.data.data, errorMessage: "" });
    } else {
      this.setState({
        errorMessage: "There is some error.",
      });
      //window.location.replace("/error");
    }
  }

  publishText = (value, tableMeta) => {
    let is_published = tableMeta.rowData[2];
    if (is_published === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  statusText = (value, tableMeta) => {
    let is_status = tableMeta.rowData[4];
    if (is_status === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  signupText = (value, tableMeta) => {
    let is_signup = tableMeta.rowData[5];
    if (is_signup === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  defaultText = (value, tableMeta) => {
    let is_default = tableMeta.rowData[7];
    if (is_default === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };
  viewAgreement = async (value, tableMeta) => {
    let agreement_id = value;
    const agreementResponce = await getSingleAgreement(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      agreement_id
    );

    console.log("status: ", agreementResponce.data.data);
    $("#btnloader").show();
    if (
      agreementResponce.data.status === 403 ||
      agreementResponce.data.errors === "authentication missing" ||
      agreementResponce.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (
      agreementResponce.data.status === 404 &&
      agreementResponce.data.message === "fail" &&
      agreementResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage: agreementResponce.data.note,
        successMessage: "",
        disabled: false,
      });
      $("#btnloader").hide();
    } else if (
      agreementResponce.data.status === 200 &&
      agreementResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        agreement_view: agreementResponce.data.data[0][5],
        disabled: false,
      });
      window.$("#modalViewAgreement").modal("show");
      $("#btnloader").hide();
    } else {
      this.setState({
        errorMessage: agreementResponce.data.message,
        successMessage: "",
        disabled: false,
      });
      $("#btnloader").hide();
      //window.location.replace("/error");
    }
  };

  assignHtml = (value) => {
    if (value) {
      return "";
    } else {
      return "";
    }
  };

  deleteAlert = async (value) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let id = value;
    this.setState({
      delete_id: id,
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  deleteAgreement = async () => {
    let agreement_id = this.state.delete_id;
    const agreementResponce = await deleteAgreement(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      agreement_id
    );

    console.log("status: ", agreementResponce.data.data);
    $("#btnloader").show();
    if (
      agreementResponce.data.status === 403 ||
      agreementResponce.data.errors === "authentication missing" ||
      agreementResponce.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (
      agreementResponce.data.status === 404 &&
      agreementResponce.data.message === "fail" &&
      agreementResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessaged: agreementResponce.data.note,
        successMessaged: "",
        disabled: false,
      });
      $("#btnloader").hide();
    } else if (
      agreementResponce.data.status === 200 &&
      agreementResponce.data.message === "success"
    ) {
      this.setState({
        errorMessaged: "",
        successMessaged: "Agreement deleted successfully.",
        disabled: false,
      });
      this.componentDidMount();
      setTimeout(function () {
        window.$("#modalDeleteAlert").modal("hide");
      }, 3000);
      $("#btnloader").hide();
    } else {
      this.setState({
        errorMessaged: agreementResponce.data.message,
        successMessaged: "",
        disabled: false,
      });
      $("#btnloader").hide();
      //window.location.replace("/error");
    }
  };

  editAgreement = (value, tableMeta) => {};

  validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };
  render() {
    $("#useraccountno").hide();
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* SETTINGS MODAL START */}
          {/* MODAL EDIT PROFILE START */}
          <div className="modal fade" tabIndex="-1" id="modalViewAgreement">
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">View Agreement</h5>
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body">
                  <>{this.state.agreement_view}</>
                </div>
              </div>
            </div>
          </div>
          {/* END MODAL EDIT PROFILE */}
          {/* MODAL EDIT PROFILE START */}
          <div className="modal fade" tabIndex="-1" id="modalDeleteAlert">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#"
                  className="close"
                  onClick={() => {
                    this.modalHideDel();
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                    <h4 className="nk-modal-title">Delete Agreement?</h4>
                    <div className="nk-modal-text">
                      {this.state.errorMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="caption-text">
                        Are you sure you want to delete this Agreement?
                      </div>
                    </div>
                    <div className="nk-modal-action">
                      <a
                        href="#"
                        className="btn btn-lg btn-mw btn-primary"
                        onClick={() => {
                          this.modalHideDel();
                        }}
                      >
                        CANCEL
                      </a>
                      {"  "}
                      <a
                        href="#"
                        className="btn btn-lg btn-mw btn-danger"
                        onClick={() => {
                          this.deleteAgreement();
                        }}
                      >
                        DELETE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="modalAddForm">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add Agreement</h5>
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body">
                  {/* Error Start */}
                  {this.state.errorMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <br />
                  <div className="row g-4">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <MutextField
                          required
                          id="product_title"
                          name="product_title"
                          type="text"
                          label="Service Title"
                          defaultValue={this.state.product_title}
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <MutextField
                          id="service_type"
                          name="service_type"
                          select
                          label="Select Service Type"
                          defaultValue={this.state.service_type}
                          onChange={this.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          helperText="Please select the service type e.g DID, TollFree or termination."
                          variant="outlined"
                          fullWidth
                        >
                          <option key="did" value="did">
                            DID
                          </option>
                          <option key="tfn" value="tfn">
                            Toll Free
                          </option>
                          <option key="term" value="term">
                            Termination
                          </option>
                          <option key="sms" value="sms">
                            SMS
                          </option>
                        </MutextField>
                      </div>
                    </div>
                    {this.state.service_type == "sms" ? (
                      <>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <MutextField
                              required
                              id="sms_in"
                              name="sms_in"
                              type="text"
                              label="SMS Inbound Rate"
                              defaultValue={this.state.sms_in}
                              onChange={this.handleChange}
                              variant="outlined"
                              onKeyPress={() => {
                                this.checkNumber();
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <MutextField
                              required
                              id="sms_out"
                              name="sms_out"
                              type="text"
                              label="SMS Outbound Rate"
                              defaultValue={this.state.sms_out}
                              onChange={this.handleChange}
                              variant="outlined"
                              onKeyPress={() => {
                                this.checkNumber();
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {this.state.service_type !== "term" &&
                    this.state.service_type !== "sms" ? (
                      <>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <MutextField
                              required
                              id="nrc"
                              name="nrc"
                              type="text"
                              label="NRC"
                              defaultValue={this.state.nrc}
                              onChange={this.handleChange}
                              variant="outlined"
                              onKeyPress={() => {
                                this.checkNumber();
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <MutextField
                              required
                              id="mrc"
                              name="mrc"
                              label="MRC"
                              defaultValue={this.state.mrc}
                              onChange={this.handleChange}
                              variant="outlined"
                              onKeyPress={() => {
                                this.checkNumber();
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {this.state.service_type !== "sms" ? (
                      <>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <MutextField
                              required
                              id="rate"
                              name="rate"
                              label="Rate"
                              defaultValue={this.state.rate}
                              onChange={this.handleChange}
                              variant="outlined"
                              type="text"
                              onKeyPress={() => {
                                this.checkNumber();
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <input
                      type="submit"
                      className="btn btn-primary btn-lg"
                      style={{
                        color: THEME_TEXT_COLOR,
                        float: "right",
                        marginTop: "15px",
                      }}
                      disabled={this.state.disabled}
                      value="Save"
                      onClick={() => this.formAddSubmit()}
                    />
                  </div>

                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
          {/* END MODAL ADD SERVICE */}
          {/* SETTINGS MODAL END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Agreements</h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <a
                              href="/admin_agreements/addagreement"
                              style={{
                                color: "#fff",
                              }}
                              className="btn btn-primary"
                            >
                              <em class="icon ni ni-plus-round"></em>
                              <span>Add New Agreement</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START TABLE HEAD */}
                      <DataTable
                        columns={this.state.columns}
                        tableData={this.state.tableData}
                      />
                      {/* END TABLE HEAD */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
