import React, { Component, Fragment } from "react";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { HTML5_FMT } from "moment";
import { ValidateEmail, normalize, ValidateDomain } from "../../config/utility";

export class Domain extends Component {
  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };

  constructor() {
    super();
    this.state = {
      errorMessage: "",
      dnsList: [],
    };
  }

  SubmitFirst = () => {
    const { values } = this.props;
    console.log(
      "this.state.domain_name: ",
      values.domain_title + " " + values.domain_name
    );
    if (values.domain_title === "" && values.domain_name === "") {
      this.setState({
        errorMessage: "Please enter the required values.",
      });
    } else {
      if (ValidateDomain(values.domain_name) === false) {
        this.setState({
          errorMessage: "Invalid domain format.",
        });
      } else {
        this.continue();
      }
    }
  };

  render() {
    const { values, handleChange, VerifyConnection } = this.props;

    return (
      <>
        <div className="nk-block">
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group" style={{ padding: "40px" }}>
              {this.state.errorMessage !== "" ? (
                <div className="example-alert" style={{ marginBottom: "15px" }}>
                  <div className="alert alert-pro alert-danger">
                    <div className="alert-text">
                      <h4>Error</h4>
                      <p>{this.state.errorMessage}</p>
                    </div>
                  </div>
                </div>
              ) : null}

              <h6>Domain</h6>

              <div className="form-group">
                <TextField
                  required
                  id="domain_title"
                  name="domain_title"
                  type="text"
                  label="Domain Title"
                  defaultValue={values.domain_title}
                  inputProps={{ maxLength: 100 }}
                  onChange={handleChange("domain_title")}
                  helperText="Enter the domain title. e.g ABC Company"
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div className="form-group">
                <TextField
                  required
                  id="domain_name"
                  name="domain_name"
                  type="text"
                  label="Domain"
                  defaultValue={values.domain_name}
                  inputProps={{ maxLength: 100 }}
                  onChange={handleChange("domain_name")}
                  helperText="Enter the domain you want to connect. e.g. example.com (without http:// or https://)"
                  variant="outlined"
                  fullWidth
                />
              </div>
              <hr />
              <div className="form-group text-right">
                <button
                  className="btn btn-lg btn-primary"
                  disabled={this.state.disabled}
                  onClick={this.SubmitFirst}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Domain;
