import React, { useEffect, useRef } from 'react';

function RichTextEditorComponent({ agreementContent, onContentChange }) {
  const editorRef = useRef(null);
  let editorInstance = null;

  useEffect(() => {
    const initializeEditor = async () => {
      try {
        const Editor = await window.RichTextEditor;

        if (!Editor) {
          console.error('RichTextEditor not initialized properly.');
          return;
        }
        if (!window.RTE_DefaultConfig) window.RTE_DefaultConfig = {};
        window.RTE_DefaultConfig.toolbar = "default";
        
        editorInstance = new Editor(editorRef.current);

        if (editorInstance && agreementContent) {
          editorInstance.setHTMLCode(agreementContent);
        }
        
        editorInstance.attachEvent("change", () => {
          const updatedContent = editorInstance.getHTMLCode();
          if (onContentChange) {
            onContentChange(updatedContent);
          }
        });

        // Directly assign the file upload handler if setConfig is not available
        if (window.rte_file_upload_handler) {
          editorInstance.file_upload_handler = window.rte_file_upload_handler;
        }
      } catch (error) {
        console.error('Error initializing editor:', error);
      }
    };

    initializeEditor();

    return () => {
      if (editorInstance) {
        if (typeof editorInstance.destroy === 'function') {
          editorInstance.destroy();
        }
        editorInstance = null;
      }
    };
  }, [onContentChange]);

  return <div ref={editorRef}></div>;
}

export default RichTextEditorComponent;
