import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";

import { accountVerification } from "../../../config/client_api_calls";

export default class Verification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
    };
  }

  async componentDidMount() {
    let apiHit = await accountVerification(
      this.props.match.params.verification_token
    );

    console.log("apiHit.data: ", apiHit.data);
    if (
      apiHit.data.status === 404 ||
      apiHit.data.message === "Invalid Credientials"
    ) {
      //window.location.replace("/error");
      this.setState({
        errorMessage: "Invalid Credentials.",
        successMessage: "",
      });
    } else if (
      apiHit.data.status === 200 &&
      apiHit.data.message === "success"
    ) {
      Cookies.set(
        "filer_id__",
        CryptoJS.AES.encrypt(
          apiHit.data.filer_id,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "client_name__",
        CryptoJS.AES.encrypt(
          apiHit.data.name,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "client_tkn__",
        CryptoJS.AES.encrypt(
          apiHit.data.token,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "client_acn__",
        CryptoJS.AES.encrypt(
          apiHit.data.accountno,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "client_eml__",
        CryptoJS.AES.encrypt(
          apiHit.data.email,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "client_comp_nme__",
        CryptoJS.AES.encrypt(
          apiHit.data.comp_name,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "client_user_type__",
        CryptoJS.AES.encrypt(
          apiHit.data.user_type,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "client_country__",
        CryptoJS.AES.encrypt(
          apiHit.data.country,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "client_parent_accountno__",
        CryptoJS.AES.encrypt(
          apiHit.data.parent_accountno,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "client_title__",
        CryptoJS.AES.encrypt(
          apiHit.data.title,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "client_phone__",
        CryptoJS.AES.encrypt(
          apiHit.data.phone,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "client_adress__",
        CryptoJS.AES.encrypt(
          apiHit.data.address,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "residentifier__",
        CryptoJS.AES.encrypt(
          apiHit.data.reseller_domain_identifier,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "restitle__",
        CryptoJS.AES.encrypt(
          apiHit.data.reseller_title,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "ressig__",
        CryptoJS.AES.encrypt(
          apiHit.data.reseller_signature,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "reslogo__",
        CryptoJS.AES.encrypt(
          apiHit.data.reseller_logo,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "restkn__",
        CryptoJS.AES.encrypt(
          apiHit.data.reseller_hash,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "resacct__",
        CryptoJS.AES.encrypt(
          apiHit.data.reseller_accountno,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      this.setState({
        errorMessage: "",
        successMessage: "Account activated successfully.",
      });
      setTimeout(function () {
        window.location.replace(
          "/" + apiHit.data.reseller_domain_identifier + "/client/dashboard"
        );
      }, 3000);
    } else {
      this.setState({
        errorMessage:
          "Your account cannot be activated this time. Please check the URL or try again later.",
        successMessage: "",
      });
    }
  }

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              {this.state.errorMessage !== "" ? (
                <div className="example-alert">
                  <div className="alert alert-pro alert-danger">
                    <div className="alert-text">
                      <h4>Error</h4>
                      <p>{this.state.errorMessage}</p>
                    </div>
                  </div>
                </div>
              ) : null}

              {this.state.successMessage !== "" ? (
                <div className="example-alert">
                  <div className="alert alert-pro alert-success">
                    <div className="alert-text">
                      <h4>Success</h4>
                      <p>{this.state.successMessage}</p>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="text-center" style={{ marginTop: "20%" }}>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
