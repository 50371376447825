import React, { Component, Fragment } from "react";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { LOGO_VERIFY } from "../../../config/config";

import HelperClass from "../../../config/helperClass";
import { tableLoader } from "../../ContentLoaders/table_loader";
import Skeleton from "@material-ui/lab/Skeleton";
import { Helmet, HelmetProvider } from "react-helmet-async";
const auth = new HelperClass();
export class Step3 extends Component {
  constructor() {
    super();
    this.state = {
      disabled: false,
      errorMessage: "",
      tableLoader: false,
      dnsList: [],
    };
  }

  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForms = () => {
    const { values } = this.props;
    if (values.no_of_employes === "") {
      this.setState({
        errorMessage: "Please select the number of emplyes.",
      });
    } else {
      this.continue();
    }
  };

  modalHideIns = () => {
    window.$("#instructionsModal").modal("hide");
  };

  render() {
    //const { VerifyConnection } = this.props;
    const { values, handleChange } = this.props;

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/csstheme.css?ver=3.0.3"
            />
          </Helmet>
        </HelmetProvider>
        <div className="nk-block nk-block-lg" style={{ width: "100%" }}>
          <div
            className="card card-bordered"
            style={{
              border: "none",
              paddingTop: "10%",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <form
              className="nk-stepper stepper-init is-alter nk-stepper-s1"
              action="#"
              id="stepper-create-profile"
            >
              {this.state.errorMessage !== "" ? (
                <div className="example-alert">
                  <div className="alert alert-pro alert-danger">
                    <div className="alert-text">
                      <h4>Error</h4>
                      <p>{this.state.errorMessage}</p>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row g-0 col-sep col-sep-md col-sep-xl">
                <div className="col-md-4 col-xl-4">
                  <div className="card-inner">
                    <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                      <li className="done">
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Company Info</div>
                            <div className="sub-text">
                              Company name and 499 Filer ID
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="done">
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Contact Info</div>
                            <div className="sub-text">
                              Other contact information
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="current">
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Other Info</div>
                            <div className="sub-text">
                              Number of employes in the company
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Account Password</div>
                            <div className="sub-text">
                              Account password to access the portal
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Preview Details</div>
                            <div className="sub-text">Review and Submit</div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Completed</div>
                            <div className="sub-text">You are done.</div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="card-inner">
                    <div className="nk-stepper-content">
                      <div className="nk-stepper-steps stepper-steps">
                        <div className="nk-stepper-step done"></div>
                        <div className="nk-stepper-step done"></div>
                        <div className="nk-stepper-step active">
                          <h5 className="title mb-3">Other Information</h5>
                          <div className="row g-3">
                            <div className="col-12">
                              <div className="form-group">
                                <label className="form-label">
                                  How many employees are in your company?
                                </label>
                                <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                  <li>
                                    <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                      <input
                                        type="radio"
                                        defaultChecked={
                                          values.no_of_employes === "1-2"
                                        }
                                        className="custom-control-input"
                                        id="cp1-package-choose-12"
                                        name="no_of_employes"
                                        defaultValue="1-2"
                                        onChange={handleChange}
                                        required
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="cp1-package-choose-12"
                                      >
                                        <span className="d-flex align-center">
                                          <span className="user-avatar sq bg-success-dim">
                                            <span className="icon ni ni-user"></span>
                                          </span>
                                          <span className="ms-2">
                                            <span className="lead-text">
                                              1 - 2
                                            </span>
                                            <span className="sub-text">
                                              Employes
                                            </span>
                                          </span>
                                        </span>
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                      <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="cp1-package-choose-34"
                                        name="no_of_employes"
                                        defaultValue="3-4"
                                        defaultChecked={
                                          values.no_of_employes === "3-4"
                                        }
                                        onChange={handleChange}
                                        required
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="cp1-package-choose-34"
                                      >
                                        <span className="d-flex align-center">
                                          <span className="user-avatar sq bg-success-dim">
                                            <span className="icon ni ni-user"></span>
                                          </span>
                                          <span className="ms-2">
                                            <span className="lead-text">
                                              3 - 4
                                            </span>
                                            <span className="sub-text">
                                              Employes
                                            </span>
                                          </span>
                                        </span>
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                      <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="cp1-package-choose-59"
                                        name="no_of_employes"
                                        defaultValue="5-9"
                                        onChange={handleChange}
                                        defaultChecked={
                                          values.no_of_employes === "5-9"
                                        }
                                        required
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="cp1-package-choose-59"
                                      >
                                        <span className="d-flex align-center">
                                          <span className="user-avatar sq bg-success-dim">
                                            <span className="icon ni ni-user"></span>
                                          </span>
                                          <span className="ms-2">
                                            <span className="lead-text">
                                              5 - 9
                                            </span>
                                            <span className="sub-text">
                                              Employes
                                            </span>
                                          </span>
                                        </span>
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                      <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="cp1-package-choose-1024"
                                        name="no_of_employes"
                                        defaultValue="10-24"
                                        defaultChecked={
                                          values.no_of_employes === "10-24"
                                        }
                                        onChange={handleChange}
                                        required
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="cp1-package-choose-1024"
                                      >
                                        <span className="d-flex align-center">
                                          <span className="user-avatar sq bg-success-dim">
                                            <span className="icon ni ni-user"></span>
                                          </span>
                                          <span className="ms-2">
                                            <span className="lead-text">
                                              10 - 24
                                            </span>
                                            <span className="sub-text">
                                              Employes
                                            </span>
                                          </span>
                                        </span>
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                      <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="cp1-package-choose-2549"
                                        name="no_of_employes"
                                        defaultValue="25-49"
                                        defaultChecked={
                                          values.no_of_employes === "25-49"
                                        }
                                        onChange={handleChange}
                                        required
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="cp1-package-choose-2549"
                                      >
                                        <span className="d-flex align-center">
                                          <span className="user-avatar sq bg-success-dim">
                                            <span className="icon ni ni-user"></span>
                                          </span>
                                          <span className="ms-2">
                                            <span className="lead-text">
                                              25 - 49
                                            </span>
                                            <span className="sub-text">
                                              Employes
                                            </span>
                                          </span>
                                        </span>
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                      <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="cp1-package-choose-5099"
                                        name="no_of_employes"
                                        defaultValue="50-99"
                                        defaultChecked={
                                          values.no_of_employes === "50-99"
                                        }
                                        onChange={handleChange}
                                        required
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="cp1-package-choose-5099"
                                      >
                                        <span className="d-flex align-center">
                                          <span className="user-avatar sq bg-success-dim">
                                            <span className="icon ni ni-user"></span>
                                          </span>
                                          <span className="ms-2">
                                            <span className="lead-text">
                                              50 - 99
                                            </span>
                                            <span className="sub-text">
                                              Employes
                                            </span>
                                          </span>
                                        </span>
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                      <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="cp1-package-choose-3"
                                        name="no_of_employes"
                                        defaultValue="100+"
                                        defaultChecked={
                                          values.no_of_employes === "100+"
                                        }
                                        onChange={handleChange}
                                        required
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="cp1-package-choose-3"
                                      >
                                        <span className="d-flex align-center">
                                          <span className="user-avatar sq bg-success-dim">
                                            <span className="icon ni ni-user"></span>
                                          </span>
                                          <span className="ms-2">
                                            <span className="lead-text">
                                              100+
                                            </span>
                                            <span className="sub-text">
                                              Employes
                                            </span>
                                          </span>
                                        </span>
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step text-center"></div>
                      </div>
                      <ul className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination">
                        <li className="step-prev">
                          <button
                            type="button"
                            className="btn btn-dim btn-primary"
                            onClick={this.back}
                          >
                            Back
                          </button>
                        </li>
                        <li className="step-next">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.submitForms}
                          >
                            Continue
                          </button>
                        </li>
                      </ul>
                      <div
                        className="text-center"
                        style={{ marginTop: "25px" }}
                      >
                        Already have an account ? <a href="">Sign in instead</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Step3;
