import React, { useRef, useState } from "react";

import styled from "styled-components";
import EmailEditor from "react-email-editor";

import sample from "./../../../uploads/pages/sample.json";

import { addSettings } from "../../../actions";
import {
  listPages,
  saveThemePage,
  getFileData,
  saveThemeHtml,
} from "./../../../config/api_calls";
import MutextField from "@material-ui/core/TextField";
import HelperClass from "./../../../config/helperClass";
import Link from "@material-ui/core/Link";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  height: 100%;
`;

const Bar = styled.div`
  flex: 1;
  background-color: #ffffff;
  color: #000;
  padding: 10px;
  display: flex;
  max-height: 70px;
  z-index: 10000;

  h1 {
    flex: 1;
    font-size: 16px;
    text-align: left;
    line-height: 30px;
  }
  button {
    padding: 10px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #000;
    color: #fff;
    border: 0px;
    text-align: center;
    cursor: pointer;
  }
  a.btn {
    padding: 10px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #000;
    color: #fff;
    border: 0px;
    cursor: pointer;
    text-align: center;
  }
  .nk-header {
    display: none !important;
  }
  .nk-sidebar nk-sidebar-fixed {
    display: none !important;
  }
`;
const auth = new HelperClass();
const Editor = (props) => {
  const [pagesData, setPageData] = useState([]);
  const [pageLink, setPageLink] = useState("about-us");

  const emailEditorRef = useRef(null);

  const componentDidMount = async () => {
    const servicesResponce = await listPages(
      auth.getAccount(),
      auth.getToken()
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let result = servicesResponce.data.data;
      var finalRes = [];
      for (var l = 0; l < result.length; l++) {
        var res = {};
        res["title"] = result[l][1];
        res["year"] = result[l][2];
        finalRes[l] = res;
      }
      setPageData(servicesResponce.data.data);
    } else {
      window.location.replace("/error");
    }
  };

  const addMenuHistory = (link) => {
    props.props.history.push(link);
  };

  const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign((design) => {
      console.log("saveDesign", design);
      alert(
        "Design JSON has been logged in your developer console. " + pageLink
      );
      save_file_design(design);
    });
  };

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log("exportHtml", html);
      alert("Output HTML has been logged in your developer console.");
      save_file_html(html);
    });
  };

  const onDesignLoad = (data) => {
    console.log("onDesignLoad", data);

    emailEditorRef.current.editor.setBodyValues({
      backgroundColor: "#ffffff",
      contentWidth: "1640px", // or percent "50%"

      backgroundImage: {
        center: true,
        cover: false,
        fullWidth: true,
        repeat: false,
        url: "",
      },

      /*fontFamily: {
        label: "Helvetica",
        value: "'open sans','Helvetica Neue', Helvetica, Arial, sans-serif",
      },
      preheaderText: "Hello World",*/
    });

    //LOAD IMAGE LIBRARY
  };

  const onLoad = async () => {
    componentDidMount();

    emailEditorRef.current.editor.addEventListener(
      "onDesignLoad",
      onDesignLoad
    );
    console.log("pageLink: ", pageLink);

    const getFile = await getFileData(
      auth.getAccount(),
      auth.getToken(),
      pageLink
    );
    if (
      getFile.data.status === 403 ||
      getFile.data.errors === "authentication missing" ||
      getFile.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (getFile.data.status === 404) {
      window.location.replace("/error");
    } else if (
      getFile.data.status === 200 &&
      getFile.data.message === "success"
    ) {
      var file_path = getFile.data.path;
    } else {
      window.location.replace("/error");
    }
    console.log("getFile ", file_path);
    //var data = require(file_path);
    emailEditorRef.current.editor.loadDesign(file_path);
    //console.log(getFile.data.status);
  };

  const loadPage = async (val) => {
    //console.log("pageLink: ", val);

    const getFile = await getFileData(
      auth.getAccount(),
      auth.getToken(),
      pageLink
    );
    var file_path = [];
    if (
      getFile.data.status === 403 ||
      getFile.data.errors === "authentication missing" ||
      getFile.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (getFile.data.status === 404) {
      window.location.replace("/error");
    } else if (
      getFile.data.status === 200 &&
      getFile.data.message === "success"
    ) {
      file_path = getFile.data.path;
      emailEditorRef.current.editor.addEventListener(
        "onDesignLoad",
        onDesignLoad
      );
    } else {
      window.location.replace("/error");
    }
    //console.log("getFile ", file_path);
    //var data = require(file_path);
    emailEditorRef.current.editor.loadDesign(file_path);
  };

  const save_file_design = async (jsondata) => {
    const saveResponce = await saveThemePage(
      auth.getAccount(),
      auth.getToken(),
      pageLink,
      jsondata
    );

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (saveResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      alert("File Saved Successfully.");
    } else {
      //window.location.replace("/error");
    }
  };

  const save_file_html = async (jsondata) => {
    const saveResponce = await saveThemeHtml(
      auth.getAccount(),
      auth.getToken(),
      pageLink,
      jsondata
    );
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (saveResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      alert("File Saved Successfully.");
    } else {
      //window.location.replace("/error");
    }
  };

  return (
    <div
      style={{
        zIndex: "10000",
      }}
    >
      <Container from="Editor">
        <Bar>
          <div className="col-lg-2">
            <h1>
              <Link
                color="inherit"
                onClick={() => addMenuHistory("/")}
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  className="logo-dark logo-img"
                  src="../../assets/images/logo.png"
                  alt="logo-dark"
                  style={{ paddingTop: "5px" }}
                />
              </Link>
            </h1>
          </div>
          <div className="col-lg-4">
            <MutextField
              id="page_link"
              name="page_link"
              select
              label="Select Page"
              value={pageLink}
              onChange={async (e) => {
                const val = e.target.value;
                setPageLink(val);
                loadPage(val);
              }}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              fullWidth
            >
              <option value="home">Home</option>
              {pagesData.map((pages) => (
                <option value={pages[2]}>{pages[1]}</option>
              ))}
            </MutextField>
          </div>
          <div className="col-lg-6 text-right">
            <a href="/themes" className="btn btn-primary">
              GO BACK
            </a>{" "}
            <button className="btn btn-primary" onClick={saveDesign}>
              SAVE DESIGN
            </button>{" "}
            <button className="btn btn-primary" onClick={exportHtml}>
              PUBLISH
            </button>
          </div>
        </Bar>

        <React.StrictMode>
          <EmailEditor
            id="editor"
            displayMode="web"
            ref={emailEditorRef}
            onLoad={onLoad}
            projectId={18487}
            appearance={{
              theme: "light",
            }}
            features={{
              undoRedo: true,
              textEditor: {
                spellChecker: true,
                tables: true,
              },
            }}
            options={{
              customCSS: [
                "http://templates.tcpaas.com/library/css/bootstrap.css",
                "http://templates.tcpaas.com/library/style.css",
                "http://templates.tcpaas.com/library/css/dark.css",
                "http://templates.tcpaas.com/library/css/font-icons.css",
                "http://templates.tcpaas.com/library/css/animate.css",
                "http://templates.tcpaas.com/library/css/magnific-popup.css",
                "http://templates.tcpaas.com/library/css/custom.css",
              ],
              customJS: [
                "http://templates.tcpaas.com/library/tools/accordionTool.js",
                "http://templates.tcpaas.com/library/tools/tabTool.js",
                "http://templates.tcpaas.com/library/js/jquery.js",
                "http://templates.tcpaas.com/library/js/plugins.min.js",
                "http://templates.tcpaas.com/library/js/functions.js",
              ],
            }}
          />
        </React.StrictMode>
      </Container>
    </div>
  );
};

export default Editor;
//
