import ContentLoader from "react-content-loader";
export const tableLoaderBlockImage = () => {
  return (
    <ContentLoader
      width="100%"
      speed={3}
      primarycolor="#f3f3f3"
      secondarycolor="#ecebeb"
    >
      <rect x="0" y="0" width="24%" height="90" />
      <rect x="25%" y="0" width="24%" height="90" />
      <rect x="50%" y="0" width="24%" height="90" />
      <rect x="75%" y="0" width="24%" height="90" />

      <rect x="0" y="100" width="24%" height="10" />
      <rect x="25%" y="100" width="24%" height="10" />
      <rect x="50%" y="100" width="24%" height="10" />
      <rect x="75%" y="100" width="24%" height="10" />
    </ContentLoader>
  );
};
