export function addSettings(payload) {
  return {
    type: "SET_SETTINGS",
    payload,
  };
}

export function addMsaInfo(payload) {
  return {
    type: "SETMSAINFO",
    payload,
  };
}

export function setHeader(payload) {
  return {
    type: "SETHEADER",
    payload,
  };
}

export function addSettingsAdmin(payload) {
  return {
    type: "SET_SETTINGS",
    payload,
  };
}

export const setLeftMenu = (menuItems) => {
  return {
    type: "SET_LEFT_MENU",
    payload: menuItems,
  };
};

export const setClientLeftMenu = (menuClientItems) => {
  return {
    type: "SET_CLIENT_LEFT_MENU",
    payload: menuClientItems,
  };
};

export const setQuotationSettings = (quotationsettings) => {
  return {
    type: "SET_QUOTATION_SETTNGS",
    payload: quotationsettings,
  };
};
