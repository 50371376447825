import { React } from "react";
import { Component } from "react";

import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { connect } from "react-redux";
import { addSettingsAdmin } from "../../../actions";
import { COMPANY_NAME } from "../../../config/config";
import {
  getClients,
  getOriginationSessions,
  listTerminationSessions,
  getSixMonthGrowth,
} from "./../../../config/admin_apis";
import $ from "jquery";
import HelperClass from "./../../../config/helperClass";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoaderBlock } from "../../../components/ContentLoaders/table_loader_block";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MutextField from "@material-ui/core/TextField";
import {
  LineChart,
  ComposedChart,
  Area,
  Bar,
  Line,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  ReferenceDot,
  Brush,
  Scatter,
} from "recharts";
const auth = new HelperClass();

var md5 = require("md5");

class AdminDashboard extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      reseller_accountno: "",
      clientData: [],
      customer_origination_sessions: [],
      customer_termination_sessions: [],
      six_month_growth: [],
      origination_names: [],
      termination_names: [],
      tableLoader: true,
      errorMessage: "",
    };
  }
  async componentDidMount() {
    await this.clientsView();

    this.getOriginationSessions();
    this.getTerminationSessions();
    this.getGrowth();
    $("#btnloader").hide();
  }

  async getOriginationSessions() {
    this.setState({ tableLoader: true });
    const servicesResponce = await getOriginationSessions(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      this.state.reseller_accountno
    );
    console.log("reportsData.data.data: ", servicesResponce.data.data);
    this.setState({
      tableLoader: true,
      errorMessage: "",
      customer_origination_sessions: [],
      origination_names: [],
    });
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.adminlogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({ tableLoader: false });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        customer_origination_sessions: servicesResponce.data.result,
        origination_names: servicesResponce.data.origination_names,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
      this.setState({ tableLoader: false });
    }
    $("#btnloader").hide();
  }

  async getTerminationSessions() {
    const servicesResponce = await listTerminationSessions(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      this.state.reseller_accountno
    );
    console.log("reportsData.data.data: ", servicesResponce.data.data);
    this.setState({
      tableLoader: true,
      errorMessage: "",
      customer_termination_sessions: [],
      termination_names: [],
    });
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.adminlogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({ tableLoader: false });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        customer_termination_sessions: servicesResponce.data.result,
        termination_names: servicesResponce.data.termination_names,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
      this.setState({ tableLoader: false });
    }
    //$("#viewBtn").show();
    $("#btnloader").hide();
  }

  async getGrowth() {
    const servicesResponce = await getSixMonthGrowth(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      this.state.reseller_accountno
    );
    this.setState({
      tableLoader: true,
      errorMessage: "",
      six_month_growth: [],
    });
    console.log("reportsData.data.data: ", servicesResponce.data.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.adminlogout();
    } else if (servicesResponce.data.status === 404) {
      if (servicesResponce.data.message === "missing_settings") {
        this.setState({
          errorMessage: "Missing Settings: This account is not activated.",
        });
      }
      //window.location.replace("/error");
      this.setState({ tableLoader: false });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        six_month_growth: servicesResponce.data.result,
        tableLoader: false,
        errorMessage: "",
      });
    } else {
      //window.location.replace("/error");
      this.setState({ tableLoader: false, errorMessage: "" });
    }
    //$("#viewBtn").show();
    $("#btnloader").hide();
  }

  clientsView = async () => {
    const loadSettings = await getClients(
      auth.getAdminAccount(),
      auth.getAdminToken()
    );
    //console.log("loadSettings.data.data: ", loadSettings.data.data);
    if (
      loadSettings.data.status === 403 ||
      loadSettings.data.errors === "authentication missing" ||
      loadSettings.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (loadSettings.data.status === 404) {
    } else if (
      loadSettings.data.status === 200 &&
      loadSettings.data.message === "success"
    ) {
      this.setState({
        clientData: loadSettings.data.data,
        reseller_accountno: loadSettings.data.data[0][1],
      });
    }
  };

  defaultColor = (value) => {
    let color = "#" + md5(value).slice(0, 6);
    return color;
  };

  setMarkUp = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  formSave = async () => {
    this.getOriginationSessions();
    this.getTerminationSessions();
    this.getGrowth();
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* btn modal start*/}
          {/* <Modal /> */}

          {/* btn modal end*/}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Dashboard</h3>
                  </div>
                </div>
              </div>

              <div className="row g-4">
                <div className="col-lg-6 col-xxl-6">
                  <div className="form-group" style={{ marginTop: "16px" }}>
                    <MutextField
                      id="reseller_accountno"
                      name="reseller_accountno"
                      select
                      label="Select Reseller"
                      value={this.state.reseller_accountno}
                      onChange={this.setMarkUp}
                      SelectProps={{
                        native: true,
                      }}
                      fullWidth
                    >
                      {this.state.clientData.map((clients) => (
                        <option value={clients[1]}>
                          {clients[4]} - {clients[2]}
                        </option>
                      ))}
                    </MutextField>
                  </div>
                </div>

                <div className="col-lg-6 col-xxl-6 text-left">
                  <button
                    type="button"
                    id="viewBtn"
                    className="btn btn-lg btn-primary"
                    onClick={() => {
                      this.formSave();
                    }}
                    style={{ marginTop: "20px" }}
                    disabled={this.state.disabled}
                  >
                    Apply
                  </button>
                  <button
                    type="button"
                    id="btnloader"
                    className="btn btn-lg btn-primary"
                    style={{ marginTop: "20px", display: "none" }}
                    disabled="disabled"
                  >
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Filtering...</span>
                    </div>
                  </button>
                </div>
              </div>
              {this.state.errorMessage !== "" ? (
                <div className="example-alert" style={{ paddingTop: "15px" }}>
                  <div className="alert alert-pro alert-danger">
                    <div className="alert-text">
                      <h4>Error</h4>
                      <p>{this.state.errorMessage}</p>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row g-gs" style={{ paddingTop: "15px" }}>
                <div className="col-md-6">
                  <div className="card card-bordered card-preview">
                    <div className="card-inner">
                      <div className="card-head">
                        <h6 className="title">
                          <em className="icon ni ni-line-chart-up"></em> 24 Hour
                          Origination Customer Sessions
                        </h6>
                      </div>
                      <div
                        className=""
                        style={{ width: "100%", height: "300px" }}
                      >
                        {this.state.tableLoader === true ? (
                          tableLoaderBlock()
                        ) : (
                          <ResponsiveContainer>
                            <LineChart
                              data={this.state.customer_origination_sessions}
                              margin={{ top: 5, bottom: 5 }}
                            >
                              <XAxis dataKey="name" tick={{ fontSize: 11 }} />
                              <YAxis tick={{ fontSize: 11 }} />
                              <Tooltip />
                              <CartesianGrid
                                stroke="#f5f5f5"
                                strokeDasharray="5 5"
                              />
                              <Legend verticalAlign="top" height={36} />
                              {this.state.origination_names.map((services) => (
                                <Line
                                  type="monotone"
                                  dot={false}
                                  dataKey={services}
                                  stroke={this.defaultColor(services)}
                                />
                              ))}
                            </LineChart>
                          </ResponsiveContainer>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card card-bordered card-preview">
                    <div className="card-inner">
                      <div className="card-head">
                        <h6 className="title">
                          <em className="icon ni ni-line-chart-up"></em> 24 Hour
                          Termination Customer Sessions
                        </h6>
                      </div>
                      <div
                        className=""
                        style={{ width: "100%", height: "300px" }}
                      >
                        {this.state.tableLoader === true ? (
                          tableLoaderBlock()
                        ) : (
                          <ResponsiveContainer>
                            <LineChart
                              data={this.state.customer_termination_sessions}
                              margin={{ top: 5, bottom: 5 }}
                            >
                              <XAxis dataKey="name" tick={{ fontSize: 11 }} />
                              <YAxis tick={{ fontSize: 11 }} />
                              <Tooltip />
                              <CartesianGrid
                                stroke="#f5f5f5"
                                strokeDasharray="5 5"
                              />
                              <Legend verticalAlign="top" height={36} />
                              {this.state.termination_names.map((services) => (
                                <Line
                                  type="monotone"
                                  dot={false}
                                  dataKey={services}
                                  stroke={this.defaultColor(services)}
                                />
                              ))}
                            </LineChart>
                          </ResponsiveContainer>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-gs">
                <div className="col-md-6">
                  <div className="card card-bordered card-preview">
                    <div className="card-inner">
                      <div className="card-head">
                        <h6 className="title">
                          <em className="icon ni ni-line-chart-up"></em> 6 Month
                          Growth
                        </h6>
                      </div>
                      <div
                        className=""
                        style={{ width: "100%", height: "300px" }}
                      >
                        {this.state.tableLoader === true ? (
                          tableLoaderBlock()
                        ) : (
                          <ResponsiveContainer>
                            <ComposedChart
                              data={this.state.six_month_growth}
                              margin={{
                                top: 20,
                                right: 20,
                                bottom: 20,
                                left: 20,
                              }}
                            >
                              <XAxis dataKey="name" />
                              <YAxis
                                orientation="right"
                                yAxisId="1"
                                tickFormatter={(tick) => `${tick}%`}
                                domain={[0, 100]}
                              />
                              <YAxis orientation="left" yAxisId="2" />
                              <Legend />
                              <CartesianGrid stroke="#f5f5f5" />
                              <Tooltip />

                              <Bar
                                dataKey="New Signups"
                                barSize={20}
                                fill="#28c619"
                                yAxisId="2"
                              />
                              <Line
                                type="monotone"
                                yAxisId="1"
                                dataKey="Growth"
                                stroke="#ff7300"
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(AdminDashboard);
