import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";
//import { Links } from "react-router-dom";

import { addSettings } from "../../../actions";
import { listMenus, deleteMenu } from "./../../../config/api_calls";
import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
const auth = new HelperClass();

class Cms extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      delete_id: "",
      defaultNrc: "",
      defaultMrc: "",
      new_nrc: "",
      new_mrc: "",
      rate_type: "",
      rate: "",
      update_nrc: "",
      update_mrc: "",
      update_rate: "",
      product_id: "",
      product_desc: "",
      mrc_rate_type: "Flat",
      service_title: "",
      service_type_edit: "",
      columnServices: [
        { name: "ID" },
        { name: "Title" },
        { name: "Menu Type" },
        { name: "Seq#" },
        { name: "Date Added" },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <a
                    onClick={() =>
                      this.addMenuHistory(
                        "/cms/Editmenu/" + btoa(tableMeta.rowData[0])
                      )
                    }
                    style={{ cursor: "pointer", color: "#006393" }}
                  >
                    <EditIcon
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </a>
                  {"  "}
                  <a href="#" onClick={() => this.deleteMenu(value, tableMeta)}>
                    <DeleteIcon
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </a>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };
  serviceSigning = async (value, tableMeta) => {};

  defaultText = (value, tableMeta) => {
    let is_default = tableMeta.rowData[5];
    if (is_default === 1) {
      return "Active";
    } else {
      return "Inactive";
    }
  };

  deleteMenu = async (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let id = tableMeta.rowData[0];
    this.setState({
      delete_id: id,
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  publishText = (value, tableMeta) => {
    let is_default = tableMeta.rowData[4];
    if (is_default === "1") {
      return "Yes";
    } else {
      return "No";
    }
  };

  async componentDidMount() {
    const servicesResponce = await listMenus(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("this.props.history: ", this.props);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      window.location.replace("/error");
    }
  }

  setMarkUp = async (value, tableMeta) => {
    await this.handleStatesDynamic(
      tableMeta.rowData[0],
      tableMeta.rowData[3].replace("$", ""),
      tableMeta.rowData[4].replace("$", ""),
      tableMeta.rowData[6].replace("$", ""),
      tableMeta.rowData[1],
      tableMeta.rowData[5]
    );
    console.log("setMarkupData: ", this.state.service_title);
    window.$("#modalForm").modal("show");
  };

  handleStatesDynamic = async (
    serviceId,
    update_nrc,
    update_mrc,
    update_rate,
    service_title,
    service_type_edit
  ) => {
    this.setState({
      serviceId: serviceId,
      update_nrc: update_nrc,
      update_mrc: update_mrc,
      update_rate: update_rate,
      service_title: service_title,
      service_type_edit: service_type_edit,
    });
  };

  showDetails = (value, tableMeta) => {
    this.setState({
      product_desc: tableMeta.rowData[7],
    });
    window.$("#modalFormDetail").modal("show");
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  calculatePercent = (percent, num) => {
    return (percent / 100) * num;
  };
  modalHide = () => {
    window.$("#modalFormDetail").modal("hide");
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  addToTrash = async (e) => {
    let delete_id = this.state.delete_id;

    if (delete_id === "") {
      return this.setState({
        errorMessage: "Please select a record to delete.",
      });
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await deleteMenu(
        auth.getAccount(),
        auth.getToken(),
        delete_id
      );

      console.log("saveResponce.data", saveResponce.data);

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        return this.setState({
          errorMessage: "The page does not exists.",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          successMessage: "Congratulations!",
        });
        this.setState({
          errorMessage: "",
          successMessage: "Page deleted Successfully.",
          disabled: false,
        });

        setTimeout(function () {
          window.$("#modalDeleteAlert").modal("hide");
        }, 3000);
        this.componentDidMount();
      } else {
        return this.setState({
          errorMessage: "There is some error while deleting the page.",
          disabled: false,
        });
      }
    }
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="Cms_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/cms")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <HomeIcon fontSize="inherit" className="" />
                        Home
                      </Link>
                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/cms")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Manage CMS
                      </Link>
                      <Typography color="textPrimary" className="">
                        Manage Menus
                      </Typography>
                    </Breadcrumbs>
                    {/*<h3 className="nk-block-title page-title">
                      <PageTitle name="Manage Pages" />
          </h3>*/}
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <Link
                              onClick={() =>
                                this.addMenuHistory("/cms/Addmenus")
                              }
                              className="btn btn-primary"
                              style={{ color: "#ffffff" }}
                            >
                              <em class="icon ni ni-plus-round"></em>
                              <span>Add Menu</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title="All Menus"
                        />
                      )}

                      {/* END TABLE */}

                      {/* Table 5th Col End */}
                      <div
                        className="modal fade"
                        tabIndex="-1"
                        id="modalDeleteAlert"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <a
                              href="#"
                              className="close"
                              onClick={() => {
                                this.modalHideDel();
                              }}
                            >
                              <em className="icon ni ni-cross"></em>
                            </a>
                            <div className="modal-body modal-body-lg text-center">
                              <div className="nk-modal">
                                <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-danger"></em>
                                <h4 className="nk-modal-title">Delete Menu?</h4>
                                <div className="nk-modal-text">
                                  <div className="caption-text">
                                    Are you sure you want to delete this menu?
                                  </div>
                                </div>
                                <div className="nk-modal-action">
                                  <a
                                    href="#"
                                    className="btn btn-lg btn-mw btn-primary"
                                    onClick={() => {
                                      this.modalHideDel();
                                    }}
                                  >
                                    CANCEL
                                  </a>
                                  {"  "}
                                  <a
                                    href="#"
                                    className="btn btn-lg btn-mw btn-danger"
                                    onClick={() => {
                                      this.addToTrash();
                                    }}
                                  >
                                    DELETE
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Cms);
