import axios from "axios";

import $ from "jquery";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { axiosConfig } from "./utility";

export const getQuotations = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_quotations/list_quotations",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listClients = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_quotations/list_clients",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_accountno: accountno
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSingleRfq = async (
  accountno,
  token,
  client_accountno,
  rfq_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_quotations/get_single_client_rfq",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            rfq_accountno: rfq_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteAnswerOption = async (accountno, token, id, question_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_quotations/delete_answer_option",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            id: id,
            question_id: question_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteQuestions = async (accountno, token, question_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "partner_quotations/delete_question",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            question_id: question_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listServicesWithPrice = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "partner_quotations/list_services_with_price",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listServicesWithPriceAgents = async (accountno, token, client_accountno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "partner_quotations/list_services_with_price_agents",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSingleQuotation = async (
  accountno,
  token,
  client_accountno,
  quote_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_quotations/get_single_quotation",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            quote_accountno: quote_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listSingleClient = async (accountno, token, client_accountno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "partner_quotations/get_single_client",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveQuotationAsDraft = async (
  accountno,
  token,
  discount_type,
  discount_value,
  discount_value_total,
  discount_reason,
  shipping_title,
  shipping_value,
  shipping_value_total,
  tax_type,
  tax_value,
  tax_value_total,
  tax_reason,
  quote_accountno,
  quote_title,
  quote_notes,
  quotation_notes,
  payment_terms,
  currency,
  contact_person,
  contact_email,
  validity,
  items_list,
  items_list_optional,
  client_accountno,
  rfq_accountno,
  service_grand_sub_total,
  service_grand_total,
  agreement_accountno,
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_quotations/save_quotation_as_draft",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            discount_type: discount_type,
            discount_value: discount_value,
            discount_value_total: discount_value_total,
            discount_reason: discount_reason,
            shipping_title: shipping_title,
            shipping_value: shipping_value,
            shipping_value_total: shipping_value_total,
            tax_type: tax_type,
            tax_value: tax_value,
            tax_value_total: tax_value_total,
            tax_reason: tax_reason,
            quote_accountno: quote_accountno,
            quote_title: quote_title,
            quote_notes: quote_notes,
            quotation_notes: quotation_notes,
            payment_terms: payment_terms,
            currency: currency,
            contact_person: contact_person,
            contact_email: contact_email,
            validity: validity,
            items_list: items_list,
            items_list_optional: items_list_optional,
            rfq_accountno: rfq_accountno,
            service_grand_sub_total: service_grand_sub_total,
            service_grand_total: service_grand_total,
            agreement_accountno: agreement_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveQuotationAndSent = async (
  accountno,
  token,
  discount_type,
  discount_value,
  discount_value_total,
  discount_reason,
  shipping_title,
  shipping_value,
  shipping_value_total,
  tax_type,
  tax_value,
  tax_value_total,
  tax_reason,
  quote_accountno,
  quote_title,
  quote_notes,
  quotation_notes,
  payment_terms,
  currency,
  contact_person,
  contact_email,
  validity,
  items_list,
  items_list_optional,
  client_accountno,
  rfq_accountno,
  service_grand_sub_total,
  service_grand_total,
  selectedAgreement
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_quotations/save_quotation_and_send",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            discount_type: discount_type,
            discount_value: discount_value,
            discount_value_total: discount_value_total,
            discount_reason: discount_reason,
            shipping_title: shipping_title,
            shipping_value: shipping_value,
            shipping_value_total: shipping_value_total,
            tax_type: tax_type,
            tax_value: tax_value,
            tax_value_total: tax_value_total,
            tax_reason: tax_reason,
            quote_accountno: quote_accountno,
            quote_title: quote_title,
            quote_notes: quote_notes,
            quotation_notes: quotation_notes,
            payment_terms: payment_terms,
            currency: currency,
            contact_person: contact_person,
            contact_email: contact_email,
            validity: validity,
            items_list: items_list,
            items_list_optional: items_list_optional,
            rfq_accountno: rfq_accountno,
            service_grand_sub_total: service_grand_sub_total,
            service_grand_total: service_grand_total,
            agreement_accountno: selectedAgreement
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveQuotationInformation = async (
  accountno,
  token,
  quote_accountno,
  payment_terms,
  currency,
  contact_person,
  contact_email,
  validity,
  client_accountno,
  agreement_accountno,
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_quotations/save_quotation_information",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            quote_accountno: quote_accountno,
            payment_terms: payment_terms,
            currency: currency,
            contact_person: contact_person,
            contact_email: contact_email,
            validity: validity,
            agreement_accountno: agreement_accountno
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAssignedQuotationSettings = async (
  accountno,
  token,
  quote_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_quotations/get_quotation_settings",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            quote_accountno: quote_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveQuotationComment = async (
  accountno,
  token,
  quote_accountno,
  client_accountno,
  quote_comment
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_quotations/save_quotation_comment",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            quote_accountno: quote_accountno,
            quote_comment: quote_comment,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSingleNote = async (
  accountno,
  token,
  client_accountno,
  quote_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_quotations/get_single_quotation_internal_note",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            quote_accountno: quote_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveQuotationNote = async (
  accountno,
  token,
  quote_accountno,
  client_accountno,
  quote_note
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_quotations/save_quotation_note",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            quote_accountno: quote_accountno,
            quote_note: quote_note,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const sendQuotationEmail = async (
  accountno,
  token,
  quote_accountno,
  quotation_email,
  quotation_email_content
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_quotations/send_quotation_email",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            quote_accountno: quote_accountno,
            quotation_email: quotation_email,
            quotation_email_content: quotation_email_content,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listQuotationAgreements = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_quotations/list_quotation_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const createDuplicateQuotation = async (
  accountno,
  token,
  client_accountno,
  quote_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_quotations/create_duplicate_quotation",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            quote_accountno: quote_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};


export const getRfqs = async (accountno, token, client_accountno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "partner_rfqs/get_agent_rfqs",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

/********* Sales Orders ********/

export const getSalesOrders = async (accountno, token, client_accountno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_orders/get_agent_sales_orders",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSingleSalesOrder = async (accountno, token, orderno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_orders/get_client_single_sales_order",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getPaymentMethod = async (accountno, token, orderno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_orders/get_sales_order_payment_method",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getPaymentLogs = async (accountno, token, orderno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_orders/get_sales_order_payment_logs",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const markOrderAsPaid = async (
  accountno,
  token,
  orderno,
  payment_notes
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_orders/mark_sales_order_paid",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
            payment_notes: payment_notes,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getOrderComments = async (accountno, token, orderno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_orders/get_sales_order_comments",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const sendOrderEmail = async (
  accountno,
  token,
  orderno,
  emailto,
  emailcc,
  emailbcc,
  emailsubject,
  emailcontent
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_orders/send_sales_order_email",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
            emailto: emailto,
            emailcc: emailcc,
            emailbcc: emailbcc,
            emailsubject: emailsubject,
            emailcontent: emailcontent,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const rejectSalesOrder = async (
  accountno,
  token,
  orderno,
  rejection_reason
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_orders/reject_sales_order",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
            rejection_reason: rejection_reason,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getOrderDocuments = async (accountno, token, orderno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_orders/get_sales_order_documents",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteOrderDocument = async (accountno, token, orderno, document_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_orders/delete_sales_order_documents",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
            document_id: document_id
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAssignedOrderSettings = async (
  accountno,
  token,
  orderno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_orders/get_order_settings",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getPaymentMethodForNew = async (accountno, token, orderno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_orders/get_sales_order_payment_method_for_new",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSingleOrder = async (
  accountno,
  token,
  client_accountno,
  quote_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_orders/get_single_order",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            orderno: quote_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveOrderAndSent = async (
  accountno,
  token,
  discount_type,
  discount_value,
  discount_value_total,
  discount_reason,
  shipping_title,
  shipping_value,
  shipping_value_total,
  tax_type,
  tax_value,
  tax_value_total,
  tax_reason,
  orderno,
  quote_title,
  quote_notes,
  quotation_notes,
  payment_terms,
  currency,
  contact_person,
  contact_email,
  validity,
  items_list,
  client_accountno,
  service_grand_sub_total,
  service_grand_total,
  order_paid,
  payment_details
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_orders/save_order_and_send",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            discount_type: discount_type,
            discount_value: discount_value,
            discount_value_total: discount_value_total,
            discount_reason: discount_reason,
            shipping_title: shipping_title,
            shipping_value: shipping_value,
            shipping_value_total: shipping_value_total,
            tax_type: tax_type,
            tax_value: tax_value,
            tax_value_total: tax_value_total,
            tax_reason: tax_reason,
            orderno: orderno,
            quote_title: quote_title,
            quote_notes: quote_notes,
            quotation_notes: quotation_notes,
            payment_terms: payment_terms,
            currency: currency,
            contact_person: contact_person,
            contact_email: contact_email,
            validity: validity,
            items_list: items_list,
            service_grand_sub_total: service_grand_sub_total,
            service_grand_total: service_grand_total,
            payment_status: order_paid,
            payment_details: payment_details
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveOrderAsDraft = async (
  accountno,
  token,
  discount_type,
  discount_value,
  discount_value_total,
  discount_reason,
  shipping_title,
  shipping_value,
  shipping_value_total,
  tax_type,
  tax_value,
  tax_value_total,
  tax_reason,
  orderno,
  quote_title,
  quote_notes,
  quotation_notes,
  payment_terms,
  currency,
  contact_person,
  contact_email,
  validity,
  items_list,
  client_accountno,
  service_grand_sub_total,
  service_grand_total,
  order_paid,
  payment_details
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_orders/save_order_as_draft",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            discount_type: discount_type,
            discount_value: discount_value,
            discount_value_total: discount_value_total,
            discount_reason: discount_reason,
            shipping_title: shipping_title,
            shipping_value: shipping_value,
            shipping_value_total: shipping_value_total,
            tax_type: tax_type,
            tax_value: tax_value,
            tax_value_total: tax_value_total,
            tax_reason: tax_reason,
            orderno: orderno,
            quote_title: quote_title,
            quote_notes: quote_notes,
            quotation_notes: quotation_notes,
            payment_terms: payment_terms,
            currency: currency,
            contact_person: contact_person,
            contact_email: contact_email,
            validity: validity,
            items_list: items_list,
            service_grand_sub_total: service_grand_sub_total,
            service_grand_total: service_grand_total,
            payment_status: order_paid,
            payment_details: payment_details
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveOrderInformation = async (
  accountno,
  token,
  orderno,
  payment_terms,
  currency,
  contact_person,
  contact_email,
  validity,
  client_accountno,
  agreement_accountno,
  quote_title
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "partner_orders/save_order_information",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            orderno: orderno,
            payment_terms: payment_terms,
            currency: currency,
            contact_person: contact_person,
            contact_email: contact_email,
            validity: validity,
            agreement_accountno: agreement_accountno,
            quote_title: quote_title,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};