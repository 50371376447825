import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import {
  getAgentSignup,
  regenerateAgentLink,
  cancelAgentLink,
} from "./../../../config/reseller_api_calls";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Fileupload } from "../../../components/Image/Fileupload";

const auth = new HelperClass();

class Upgrades extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      errorMessage: "",
      agentId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      signup_link: "",
      client_login_link: "",
      copied: false,
      copied_client: false,
      copied2: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      cancel_button: false,
      tableLoader: true,
      login_url: "",
      logo: "",
      signature_image: "",
    };
  }

  async componentDidMount() {
    const servicesResponce = await getAgentSignup(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("Hash Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        client_login_link: servicesResponce.data.login_url,
        login_url: servicesResponce.data.login_url,
        logo: servicesResponce.data.logo,
        signature_image: servicesResponce.data.signature,
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  }

  regenerate_link = async () => {
    this.setState({
      reg_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await regenerateAgentLink(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Regenerate.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while updating the signup link.",
        successMessage: "",
        reg_button: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        //signup_link: servicesResponce.data.data,
        errorMessage: "",
        successMessage: "Link generated successfully.",
        reg_button: false,
      });
      this.componentDidMount();
    } else {
      this.setState({
        errorMessage: "There is some error while updating the signup link.",
        successMessage: "",
        reg_button: false,
      });
      // window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  cancel_link = async () => {
    this.setState({
      cancel_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await cancelAgentLink(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("cancel_link: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while removing the signup link.",
        successMessage: "",
        cancel_button: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        errorMessage: "",
        successMessage: "Link removed successfully.",
        cancel_button: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while removing the signup link.",
        successMessage: "",
        cancel_button: false,
      });
      // window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      {" "}
                      <em className="icon ni ni-account-setting"></em> Upgrades
                    </h3>
                    <div className="nk-block-des text-soft">
                      <p> &nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-gs">
                <div className="col-xxl-6">
                  <div className="nk-block">
                    <div className="card card-bordered">
                      <div className="card-inner">
                        <h5 className="card-title">
                          <em className="icon ni ni-user"></em> Sub Agent /
                          Channel Partner Signup Link
                        </h5>
                        <h6 className="card-subtitle mb-2">&nbsp;</h6>

                        {this.state.tableLoader === true ? (
                          tableLoader()
                        ) : (
                          <>
                            {this.state.errorMessage !== "" ? (
                              <div
                                className="example-alert"
                                style={{ marginBottom: "15px" }}
                              >
                                <div className="alert alert-pro alert-danger">
                                  <div className="alert-text">
                                    <h4>Error</h4>
                                    <p>{this.state.errorMessage}</p>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.successMessage !== "" ? (
                              <div
                                className="example-alert"
                                style={{ marginBottom: "15px" }}
                              >
                                <div className="alert alert-pro alert-success">
                                  <div className="alert-text">
                                    <h4>Success</h4>
                                    <p>{this.state.successMessage}</p>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <p>
                              <div className="example-alert">
                                <div className="alert alert-light alert-icon">
                                  <em className="icon ni ni-alert-circle"></em>{" "}
                                  <strong>NOTE: </strong> Anyone with this link
                                  will be able to create a sub agent for this
                                  agent. Be very careful where and how you send
                                  this! It is also a good idea to make sure you
                                  have any supporting documents and contracts in
                                  place before you allow the agent to have its
                                  sub agent.{" "}
                                </div>
                              </div>
                            </p>
                            <div className="caption-text">
                              <div
                                className="example-alert"
                                style={{ marginBottom: "15px" }}
                              >
                                <div className="alert alert-light">
                                  {this.state.signup_link}
                                </div>
                              </div>
                              {this.state.reg_button === false ? (
                                <button
                                  className="btn btn-primary"
                                  style={{ marginRight: "15px" }}
                                  onClick={() => this.regenerate_link()}
                                >
                                  <em className="icon ni ni-swap"></em>{" "}
                                  Regenerate
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary"
                                  style={{ marginRight: "15px" }}
                                  disabled
                                >
                                  <em className="icon ni ni-swap"></em>{" "}
                                  Regenerate
                                  <div
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </button>
                              )}

                              {this.state.cancel_button === false ? (
                                <button
                                  className="btn btn-danger"
                                  style={{ marginRight: "15px" }}
                                  onClick={() => this.cancel_link()}
                                >
                                  <em className="icon ni ni-cross-round"></em>{" "}
                                  Cancel
                                </button>
                              ) : (
                                <button
                                  className="btn btn-danger"
                                  style={{ marginRight: "15px" }}
                                  disabled
                                >
                                  <em className="icon ni ni-cross-round"></em>{" "}
                                  Cancel
                                  <div
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </button>
                              )}

                              <CopyToClipboard
                                text={this.state.signup_link}
                                onCopy={() => this.setState({ copied: true })}
                              >
                                <button className="btn btn-success">
                                  <em className="icon ni ni-copy"></em> Copy to
                                  clipboard{" "}
                                  {this.state.copied ? (
                                    <span style={{ color: "white" }}>
                                      <em className="icon ni ni-done"></em>
                                    </span>
                                  ) : null}
                                </button>
                              </CopyToClipboard>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-6">
                  <div className="nk-block">
                    <div className="card card-bordered">
                      <div className="card-inner">
                        <h5 className="card-title">
                          <em className="icon ni ni-user"></em> Client Login
                          Link
                        </h5>
                        <h6 className="card-subtitle mb-2">&nbsp;</h6>

                        {this.state.tableLoader === true ? (
                          tableLoader()
                        ) : (
                          <>
                            <p>
                              <div className="example-alert">
                                <div className="alert alert-light alert-icon">
                                  <em className="icon ni ni-alert-circle"></em>{" "}
                                  <strong>NOTE: </strong> Any client of this
                                  agent can use the URL below to login in to
                                  their portal.{" "}
                                </div>
                              </div>
                            </p>
                            <div className="caption-text">
                              <div
                                className="example-alert"
                                style={{ marginBottom: "15px" }}
                              >
                                <div className="alert alert-light">
                                  {this.state.client_login_link}
                                </div>
                              </div>

                              <CopyToClipboard
                                text={this.state.client_login_link}
                                onCopy={() =>
                                  this.setState({ copied_client: true })
                                }
                              >
                                <button className="btn btn-success">
                                  <em className="icon ni ni-copy"></em> Copy to
                                  clipboard{" "}
                                  {this.state.copied_client ? (
                                    <span style={{ color: "white" }}>
                                      <em className="icon ni ni-done"></em>
                                    </span>
                                  ) : null}
                                </button>
                              </CopyToClipboard>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Upgrades);
