import { React } from "react";
import { Component } from "react";
import { COMPANY_NAME } from "../../../config/config";

import { connect } from "react-redux";

import { addSettings } from "../../../actions";

import { getReportsData } from "./../../../config/api_calls";

import { dateString } from "./../../../config/config";

import $ from "jquery";

import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Link } from "react-router-dom";

import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import TextField from "../../../components/InputFields/TextField";

import moment from "moment";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsServices,
} from "./../../../config/config";
const auth = new HelperClass();

class Reports extends Component {
  constructor() {
    var date = (date = new Date()),
      y = date.getFullYear(),
      m = date.getMonth();
    var datestring_today = new Date().toISOString().slice(0, 10);
    var datestring = new Date().toISOString().slice(0, 10);
    super();
    this.state = {
      tableData: [],
      tableLoader: true,
      start_date: datestring_today,
      end_date: datestring,
      columnsArray: {
        get_daily_orig_stats: {
          title: "Daily Origination Stats",
          data: [
            { name: "DAY" },
            { name: "REVENUE" },
            { name: "ACTUAL DURATION" },
            { name: "BILLED DURATION" },
            { name: "CALL ATTEMPTS" },
            { name: "COMPLETED CALLS" },
            { name: "OUT ASR" },
            { name: "SHORT CALLS" },
            { name: "SDP" },
            { name: "NRF" },
          ],
        },
        get_daily_did_stats: {
          title: "Daily DID Stats",
          data: [
            { name: "DAY" },
            { name: "REVENUE" },
            { name: "NRC CHARGE" },
            { name: "MRC CHARGE" },
            { name: "E911 CHARGE" },
            { name: "DELETES" },
            { name: "ORDERS" },
          ],
        },
        get_daily_term_stats: {
          title: "Daily Termination Stats",
          data: [
            { name: "DAY" },
            { name: "REVENUE" },
            { name: "ACTUAL DURATION" },
            { name: "BILLED DURATION" },
            { name: "CALL ATTEMPTS" },
            { name: "COMPLETED CALLS" },
            { name: "OUT ASR" },
            { name: "SHORT CALLS" },
            { name: "SDP" },
            { name: "NRF" },
          ],
        },
        get_daily_sms_stats: {
          title: "Daily SMS Stats",
          data: [
            { name: "DAY" },
            { name: "REVENUE" },
            { name: "INBOUND MESSAGES" },
            { name: "OUTBOUND MESSAGES" },
            { name: "INBOUND ATTEMPTS" },
            { name: "OUTBOUND ATTEMPTS" },
          ],
        },
        get_daily_revenue: {
          title: "Account Daily Revenue",
          data: [
            { name: "DAY" },
            { name: "REVENUE" },
            { name: "DID REVENUE" },
            { name: "VOICE REVENUE" },
            { name: "SMS REVENUE" },
            { name: "BILLED DURATION" },
            { name: "COMPLETED CALLS" },
            { name: "MESSAGES" },
            { name: "NRC" },
            { name: "MRC" },
            { name: "E911" },
            { name: "GENERIC CHARGES" },
          ],
        },
        get_aggregate_orig_stats: {
          title: "Account Origination Stats",
          data: [
            { name: "ACCOUNT" },
            { name: "REVENUE" },
            { name: "ACTUAL DURATION" },
            { name: "BILLED DURATION" },
            { name: "CALL ATTEMPTS" },
            { name: "COMPLETED CALLS" },
            { name: "OUT ASR" },
            { name: "SHORT CALLS" },
            { name: "SDP" },
            { name: "NRF" },
          ],
        },
        get_aggregate_term_stats: {
          title: "Account Termination Stats",
          data: [
            { name: "ACCOUNT" },
            { name: "REVENUE" },
            { name: "ACTUAL DURATION" },
            { name: "BILLED DURATION" },
            { name: "ATTEMPTS" },
            { name: "COMPLETED CALLS" },
            { name: "ASR" },
            { name: "SHORT CALLS" },
            { name: "SDP" },
            { name: "NRF" },
          ],
        },
        get_aggregate_sms_stats: {
          title: "Account SMS Stats",
          data: [
            { name: "ACCOUNT" },
            { name: "REVENUE" },
            { name: "INBOUND MESSAGES" },
            { name: "OUTBOUND MESSAGES" },
            { name: "INBOUND ATTEMPTS" },
            { name: "OUTBOUND ATTEMPTS" },
          ],
        },
        get_aggregate_did_stats: {
          title: "Account DID Stats",
          data: [
            { name: "ACCOUNT" },
            { name: "REVENUE" },
            { name: "NRC" },
            { name: "MRC" },
            { name: "E911" },
            { name: "DELETES" },
            { name: "ORDERS" },
          ],
        },
        get_aggregate_revenue_stats: {
          title: "Account Revenue",
          data: [
            { name: "ACCOUNT" },
            { name: "REVENUE" },
            { name: "DID REVENUE" },
            { name: "VOICE REVENUE" },
            { name: "SMS REVENUE" },
            { name: "BILLED DURATION" },
            { name: "COMPLETED CALLS" },
            { name: "MESSAGES" },
            { name: "NRC" },
            { name: "MRC" },
            { name: "E911" },
            { name: "GENERIC CHARGES" },
          ],
        },
      },
    };
  }

  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    const reportsData = await getReportsData(
      auth.getAccount(),
      auth.getToken(),
      this.props.props.match.params.api_url,
      this.state.start_date,
      this.state.end_date,
      "all"
    );
    console.log("start_date: ", this.state.start_date);
    console.log("end_date: ", this.state.end_date);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      console.log("2nd Last: ", reportsData.data);
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
      });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({ tableData: reportsData.data.data, tableLoader: false });
    } else {
      console.log("Last: ", reportsData.data);
      //window.location.replace("/error");
    }
    $("#viewBtn").show();
    $("#btnloader").hide();
  }
  async componentWillReceiveProps(prevProps, nextProps) {
    this.setState({ tableLoader: true });
    const reportsData = await getReportsData(
      auth.getAccount(),
      auth.getToken(),
      prevProps.props.match.params.api_url,
      this.state.start_date,
      this.state.end_date,
      "all"
    );

    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
      });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({ tableData: reportsData.data.data, tableLoader: false });
    } else {
      //window.location.replace("/error");
    }
  }

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    console.log("handleChange " + name + " " + value);
    this.setState({
      [name]: value,
    });
  };

  handleDateChange = async (date) => {
    await this.handleStatesDynamic(
      "start_date",
      date.toISOString().slice(0, 10)
    );
    console.log("start_date " + this.state.start_date);
  };

  handleDateChangeEnd = async (date) => {
    await this.handleStatesDynamic("end_date", date.toISOString().slice(0, 10));
    console.log("end_date " + this.state.end_date);
  };

  handleStatesDynamic = async (start_date, date) => {
    this.setState({
      [start_date]: date,
    });
  };

  handleStates = async (start_date, end_date) => {
    this.setState({
      start_date: start_date,
      end_date: end_date,
    });
  };

  formSave = async () => {
    let { start_date, end_date } = this.state;
    await this.handleStates(start_date, end_date);
    $("#viewBtn").hide();
    $("#btnloader").show();
    this.componentDidMount();
  };

  capitalizeFirstLetters = (str) => {
    return str.toLowerCase().replace(/^\w|\s\w/g, function (letter) {
      return letter.toUpperCase();
    });
  };

  render() {
    //  let tableTitle = this.props.props.match.params.api_url.replaceAll("_", " ");
    const Theme = {
      palette: {
        primary: {
          contrastText: "#FFFFFF",
          dark: "#000000",
          main: "#000000",
          light: "#000000",
        },
      },
    };
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <PageTitle name="Reports" icon="icon ni ni-file-docs" />
              <div className="row g-4">
                <div className="col-lg-5 col-xxl-5">
                  <div className="form-group">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        id="start_date"
                        name="start_date"
                        format="yyyy-MM-dd"
                        margin="normal"
                        label="Select Start Date"
                        value={this.state.start_date}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="col-lg-5 col-xxl-5">
                  <div className="form-group">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        id="end_date"
                        name="end_date"
                        format="yyyy-MM-dd"
                        margin="normal"
                        label="Select End Date"
                        value={this.state.end_date}
                        onChange={this.handleDateChangeEnd}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="col-lg-2 col-xxl-2 text-right">
                  <button
                    type="button"
                    id="viewBtn"
                    className="btn btn-lg btn-primary"
                    onClick={() => {
                      this.formSave();
                    }}
                    style={{ marginTop: "20px" }}
                    disabled={this.state.disabled}
                  >
                    Apply
                  </button>
                  <button
                    type="button"
                    id="btnloader"
                    className="btn btn-lg btn-primary"
                    style={{ marginTop: "20px", display: "none" }}
                    disabled="disabled"
                  >
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Filtering...</span>
                    </div>
                  </button>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-4">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={
                            this.state.columnsArray[
                              this.props.props.match.params.api_url
                            ].data
                          }
                          tableData={this.state.tableData}
                          title={
                            this.state.columnsArray[
                              this.props.props.match.params.api_url
                            ].title
                          }
                        />
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
