import { React } from "react";
import { Component } from "react";

export default class Services extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <p>Email Notifications</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
