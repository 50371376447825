import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  getClientFormWithSubmissions,
  viewGeneralSubmission,
  deleteSubmission,
  sendFormEmail,
  allClientRejections,
} from "./../../../config/forms_api_calls";
import $ from "jquery";
import { APP_LIVE_URL } from "../../../config/config";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import Tooltip from "@material-ui/core/Tooltip";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
import FormRenderer from "../../../components/formRender";
import { TableLoader } from "../../../components/ContentLoaders/newLoaders/TableLoader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import dateFormat from "dateformat";

const auth = new HelperClass();

class FormSubmit extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      tableDataSingle: [],
      serviceData: [],
      tableLoaderListing: true,
      tableLoader: true,
      errorMessage: "",
      successMessage: "",
      showModal: false,
      modalContent: null,
      formAccNo: "",
      successMessageSaveResponse: "",
      errorMessageSaveResponse: "",
      form_accountno: "",
      form_token: "",
      formData: "",
      form_title: "",
      form_desc: "",
      totalSubmissions: [],
      activeTab: "total_submissions",
      assignedLoader: false,
      errorMessageTotals: "",
      errorMessageTotalsTitle: "",
      emailErrorMessage: "",
      emailErrorMessageTitle: "",
      emailSuccessMessage: "",
      total_completed: 0,
      total_not_completed: 0,
      submission_id: "",
      allow_multiple: 0,
      emailto: "",
      emailtoname: "",
      emailsubject: "",
      emailcontent: "",
      email_button: false,
      extra_files: [],
      rejectGeneralErrorMessage: "",
      rejectGeneralSuccessMessage: "",
      reject_general_button: false,
      reject_general_reason: "",

      allGeneralRejectionsListing: [],
      rejectionLoader: false,
      generalColumnServices: [
        {
          name: "id",
          label: "Form Title",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_client_title(value)}</div>;
            },
          },
        },
        {
          name: "submitter_name",
          label: "Submitted By",
        },
        {
          name: "is_submitted",
          label: "Submitted",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_submitted(value)}</div>;
            },
          },
        },
        {
          name: "submitted_on",
          label: "Submitted On",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "sent_by_email",
          label: "Sent By",
        },
        {
          name: "pdf_name",
          label: "View PDF",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>{this.format_download_general(value, tableMeta)}</div>
              );
            },
          },
        },
        {
          name: "submission_status",
          label: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>{this.format_submission_status(value, tableMeta)}</div>
              );
            },
          },
        },

        {
          name: "id",
          label: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              const formAccNo =
                tableMeta.rowData[8] + "/" + tableMeta.rowData[9];
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            style={{ cursor: "pointer" }}
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-md dropdown-menu-right customStyle_dropdown-menu-md_forms">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <Tooltip title="View Details" placement="top">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.viewGeneralSubmission(value)
                                    }
                                  >
                                    <em class="icon ni ni-card-view"></em>
                                    <span>View Details</span>
                                  </a>
                                </Tooltip>
                              </li>
                              {tableMeta.rowData[2] === 0 ? (
                                <>
                                  {tableMeta.rowData[10] === 0 ? (
                                    <li>
                                      <Tooltip
                                        title="View Details"
                                        placement="top"
                                      >
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.addMenuHistory(
                                              "/" +
                                                auth.getResellerRegToken() +
                                                "/client/client_form_submit/" +
                                                formAccNo
                                            )
                                          }
                                        >
                                          <em class="icon ni ni-edit"></em>
                                          <span>Submit Form</span>
                                        </a>
                                      </Tooltip>
                                    </li>
                                  ) : (
                                    <li>
                                      <Tooltip
                                        title="Open external link a new tab"
                                        placement="top"
                                      >
                                        <a
                                          style={{ cursor: "pointer" }}
                                          href={tableMeta.rowData[11]}
                                          target="_blank"
                                          class="text-warning"
                                        >
                                          <em class="icon ni ni-link-alt"></em>
                                          <span>Link Only</span>
                                        </a>
                                      </Tooltip>
                                    </li>
                                  )}
                                </>
                              ) : null}
                              <li>
                                <Tooltip title="View Details" placement="top">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.viewFormRejections(value, tableMeta)
                                    }
                                  >
                                    <em class="icon ni ni-cross-circle"></em>
                                    <span>View Rejections</span>
                                  </a>
                                </Tooltip>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "form_accountno",
          options: {
            display: false,
          },
        },
        {
          name: "form_token",
          options: {
            display: false,
          },
        },
        {
          name: "link_form",
          options: {
            display: false,
          },
        },
        {
          name: "external_link",
          options: {
            display: false,
          },
        },
      ],
    };
  }

  async componentDidMount() {
    let form_accountno = this.props.props.match.params.form_accountno;
    let form_token = this.props.props.match.params.form_token;
    this.setState({
      formAccNo: form_accountno,
      form_token: form_token,
      form_accountno: form_accountno,
      assignedLoader: true,
    });
    const servicesResponce = await getClientFormWithSubmissions(
      auth.getClientAccount(),
      auth.getClientToken(),
      form_accountno,
      form_token
    );
    console.log("getClientFormWithSubmissions: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
        assignedLoader: false,
        errorMessageTotalsTitle: "No Submission",
        errorMessageTotals: "There are no submissions right now.",
      });
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        form_title: servicesResponce.data.form_title,
        totalSubmissions: servicesResponce.data.total_submissions,
        form_desc: servicesResponce.data.form_desc,
        total_completed: servicesResponce.data.total_completed,
        total_not_completed: servicesResponce.data.total_not_completed,
        allow_multiple: servicesResponce.data.data[0].allow_multiple,
        tableLoader: false,
        tableLoaderListing: false,
        assignedLoader: false,
      });
    } else {
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
        assignedLoader: false,
        errorMessageTotalsTitle: "No Submission",
        errorMessageTotals: "There are no submissions right now.",
      });
      //window.location.replace("/error");
    }
  }

  format_submission_status = (value, tableMeta) => {
    const is_submitted = tableMeta.rowData[2];
    if (is_submitted === 1) {
      if (value === 0) {
        return (
          <span className="badge badge-outline-primary">
            <em class="icon ni ni-done"></em> Pending Approval
          </span>
        );
      } else if (value === 1) {
        return (
          <span className="badge badge-outline-success">
            <em className="icon ni ni-caution"></em> Approved
          </span>
        );
      } else if (value === 2) {
        return (
          <span className="badge badge-outline-danger">
            <em className="icon ni ni-caution"></em> Rejected
          </span>
        );
      } else {
        return (
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-caution"></em> Pending Approval
          </span>
        );
      }
    } else {
      if (value === 2) {
        return (
          <span className="badge badge-outline-danger">
            <em className="icon ni ni-caution"></em> Rejected
          </span>
        );
      } else {
        return "---";
      }
    }
  };

  format_client_title = (value) => {
    return this.state.form_title;
  };

  format_submitted = (value) => {
    if (value === 1) {
      return (
        <span className="badge badge-outline-success">
          <em class="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
          <em className="icon ni ni-caution"></em> No
        </span>
      );
    }
  };

  format_download_general = (value, tableMeta) => {
    // console.log("format_download value: ", value);
    let form_token = tableMeta.rowData[9];
    if (value && value !== "") {
      return (
        <>
        <a
          onClick={() => this.downloadPdf(value)}
          title="Download PDF"
          style={{
            cursor: "pointer",
          }}
        >
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-download"></em>
          </span>
        </a>
        <a
        onClick={() =>
          this.addMenuHistory(
            "/" +
              auth.getResellerRegToken() +
              "/client/client_form_submission_single/" +
              form_token
          )
        }
        title="Submission View"
        style={{
          cursor: "pointer",
          marginLeft:"5px"
        }}
      >
        <span className="badge badge-outline-primary">
          <em class="icon ni ni-table-view"></em>
        </span>
      </a>
      </>
      );
    } else {
      return <span className="badge badge-outline-light">---</span>;
    }
  };

  changeActiveTab = (tab) => {
    this.setState({
      activeTab: tab,
      //assignedLoader: true,
    });
  };

  format_date = (value, tableMeta) => {
    let date = value;
    if (date && date !== "") {
      //return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
      return dateFormat(date, "mmmm dS, yyyy");
    }
    return "---";
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  closeModal = () => {
    this.setState({ modalContent: null, formAccNo: "" }, () => {
      window.$("#modalPreviewForm").modal("hide");
    });
  };

  handleSubmitForm = (formData) => {
    let response = JSON.stringify(formData);
    this.saveResponse(response, this.state.formAccNo);
  };

  viewGeneralSubmission = async (id) => {
    const { form_accountno } = this.state;
    this.setState({ viewLoader: true, extra_files: [] });
    const servicesResponse = await viewGeneralSubmission(
      auth.getClientAccount(),
      auth.getClientToken(),
      form_accountno,
      id
    );
    window.$("#modalViewGeneralSubmission").modal("show");
    console.log("getTotalSubmissions.data.data: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        errorMessageTotalsTitle: "No Submission",
        errorMessageTotals: "There are no submissions right now.",
        viewLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      let extra_files = [];
      if (servicesResponse.data.data && servicesResponse.data.data.length > 0) {
        if (servicesResponse.data.data[0].extra_files) {
          extra_files = JSON.parse(servicesResponse.data.data[0].extra_files);
        }
      }
      this.setState({
        viewGeneralSubmission: servicesResponse.data.data,
        extra_files: extra_files,
        viewLoader: false,
      });

      //
    }
  };

  viewFormRejections = async (id, tableMeta) => {
    const { form_accountno, form_title } = this.state;
    this.setState({
      rejectGeneralErrorMessage: "",
      rejectGeneralSuccessMessage: "",
      allGeneralRejectionsListing: [],
      rejectionLoader: true,
    });
    window.$("#modalRejectionGeneral").modal("show");
    const servicesResponse = await allClientRejections(
      auth.getClientAccount(),
      auth.getClientToken(),
      form_accountno,
      id
    );

    console.log("allGeneralRejections: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        approveGeneralErrorMessage: "Submission not found.",
        approveGeneralSuccessMessage: "",
        rejectionLoader: false,
      });
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "rejection_not_found"
    ) {
      this.setState({
        approveGeneralErrorMessage:
          "There are no rejections against this submission.",
        approveGeneralSuccessMessage: "",
        rejectionLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        approveGeneralErrorMessage: "",
        approveGeneralSuccessMessage: "",

        allGeneralRejectionsListing: servicesResponse.data.data,
        rejectionLoader: false,
      });
    } else {
      this.setState({
        rejectionLoader: false,
        approveGeneralErrorMessage:
          "There is some error while getting the rejections.",
        approveGeneralSuccessMessage: "",
      });
    }
  };

  deleteGeneralModal = async (id) => {
    this.setState({ submission_id: id });
    window.$("#modalDeleteGeneralAlert").modal("show");
  };

  modalHideDelGeneral = async () => {
    this.setState({ submission_id: "" });
    window.$("#modalDeleteGeneralAlert").modal("hide");
  };

  deleteGeneralSubmission = async (id) => {
    const { form_accountno, submission_id } = this.state;
    this.setState({ viewLoader: true });
    const servicesResponse = await deleteSubmission(
      auth.getClientAccount(),
      auth.getClientToken(),
      form_accountno,
      submission_id
    );

    console.log("getTotalSubmissions.data.data: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        errorMessageDelete: "There are no submissions right now.",
        isProcessing: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState(
        {
          successMessageDelte: "Submission deleted successfully.",
          isProcessing: false,
        },
        () => {
          setTimeout(() => {
            this.getTotalSubmissions();
            window.$("#modalDeleteGeneralAlert").modal("hide");
            this.setState({ successMessage: "" });
            //window.location.reload();
          }, 3000);
        }
      );
    } else {
      this.setState({
        errorMessageDelete: "Error failed to delete submission.",
        isProcessing: false,
      });
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  downloadPdf = async (d_f_name) => {
    //var url = d_f_name;
    if (d_f_name && d_f_name !== "") {
      let file_name = d_f_name;
      var url = APP_LIVE_URL + "files_data/form_response_uploads/" + d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage:
              "There is some error while downloading the attachment.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    } else {
      this.setState({
        errorMessage: "Attachement not found.",
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);
    }
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  fieldValidation = () => {
    let { emailtoname, emailto, emailsubject, emailcontent } = this.state;

    if (emailtoname === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "Name is required.",
      });
      return false;
    } else if (emailto === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "To email address is required.",
      });
      return false;
    } else if (this.validateEmailAdd(emailto) === false) {
      this.setState({
        emailErrorMessageTitle: "Invalid Format",
        emailErrorMessage: "Invalid email address format.",
      });
      return false;
    } else if (emailsubject === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "Subject is required.",
      });
      return false;
    } else if (emailcontent === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "Custom content is required.",
      });
      return false;
    } else {
      this.setState({
        emailErrorMessageTitle: "",
        emailErrorMessage: "",
      });
    }
    return true;
  };

  submitFormEmail = async () => {
    const { form_accountno, emailtoname, emailto, emailsubject, emailcontent } =
      this.state;

    if (this.fieldValidation() === true) {
      this.setState({
        email_button: true,
        emailErrorMessage: "",
        emailErrorMessageTitle: "",
        emailSuccessMessage: "",
      });
      const servicesResponce = await sendFormEmail(
        auth.getClientAccount(),
        auth.getClientToken(),
        form_accountno,
        emailtoname,
        emailto,
        emailsubject,
        emailcontent
      );
      console.log("submitOrderEmail: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        servicesResponce.data.status === 404 &&
        servicesResponce.data.message === "already_sent"
      ) {
        this.setState({
          emailErrorMessageTitle: "Already Sent",
          emailErrorMessage:
            "This form has already been sent to the email and is still not submitted.",
          email_button: false,
        });
      } else if (
        servicesResponce.data.status === 404 &&
        servicesResponce.data.message === "insert_error"
      ) {
        this.setState({
          emailErrorMessageTitle: "System Error",
          emailErrorMessage: "This is some error while sending the form.",
          email_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          emailSuccessMessage: "Email sent successfully.",
          payment_status: servicesResponce.data.data,
          emailErrorMessageTitle: "",
          emailErrorMessage: "",
          emailsubject: "",
          emailto: "",
          emailtoname: "",
          emailcontent: "",
          email_button: false,
        });
      } else {
        this.setState({
          emailErrorMessageTitle: "System Error",
          emailErrorMessage:
            "There is some error while processing your request.",
          email_button: false,
        });
      }
    }

    setTimeout(() => {
      this.setState({
        emailErrorMessage: "",
        emailSuccessMessage: "",
      });
    }, 4000);
  };

  render() {
    const { step } = this.state;
    const { email, VerifyUsernameButton } = this.state;
    const values = {
      email,
      VerifyUsernameButton,
    };
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div class="nk-content ">
        <div class="container-fluid">
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}
          <div class="nk-content-inner">
            <div class="nk-content-body">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between g-3">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      <a
                        onClick={() =>
                          this.addMenuHistory(
                            "/" + auth.getResellerRegToken() + "/client/forms"
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        Forms
                      </a>{" "}
                      /{" "}
                      <strong class="text-primary small">
                        {this.state.form_title}
                      </strong>
                    </h3>
                    <div class="nk-block-des text-soft">
                      <ul class="list-inline">
                        <li>
                          Form ID:{" "}
                          <span class="text-base">
                            {this.state.form_accountno}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <a
                      onClick={() =>
                        this.addMenuHistory(
                          "/" + auth.getResellerRegToken() + "/client/forms"
                        )
                      }
                      //href="#"
                      class="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                      <span>Back</span>
                    </a>
                    <a
                      onClick={() =>
                        this.addMenuHistory(
                          "/" + auth.getResellerRegToken() + "/client/forms"
                        )
                      }
                      //href="#"
                      class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                    </a>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="card card-bordered">
                  <div class="card-aside-wrap">
                    <div class="card-content">
                      <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                        <li class="nav-item">
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "total_submissions"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("total_submissions");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <em class="icon ni ni-file-text"></em>
                            <span>Form Submissions</span>
                          </a>
                        </li>

                        <li class="nav-item">
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "email_form"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("email_form");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <em class="icon ni ni-mail"></em>
                            <span>Email Form</span>
                          </a>
                        </li>
                        {/*<li class="nav-item nav-item-trigger d-xxl-none">
                                                        <a class="toggle btn btn-icon btn-trigger" dataTarget="userAside"><em class="icon ni ni-user-list-fill"></em></a>
                                                    </li>*/}
                      </ul>
                      <div class="tab-content">
                        <div
                          className={
                            this.state.activeTab === "email_form"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="email_forms"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              {(this.state.allow_multiple === 0 &&
                                this.state.total_completed === 0) ||
                              this.state.allow_multiple === 1 ? (
                                <div class="row g-gs">
                                  <div class="col-sm-12">
                                    <h5>Send Form</h5>
                                    {this.state.emailErrorMessage !== "" ? (
                                      <div class="alert alert-pro alert-danger">
                                        <div class="alert-text">
                                          <h6>
                                            {this.state.emailErrorMessageTitle}
                                          </h6>
                                          <p>{this.state.emailErrorMessage}</p>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.emailSuccessMessage !== "" ? (
                                      <div class="alert alert-pro alert-success">
                                        <div class="alert-text">
                                          <h6>Success</h6>
                                          <p>
                                            {this.state.emailSuccessMessage}
                                          </p>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="form-group mt-2">
                                      <label class="form-label" for="emailto">
                                        To Name <sup>*</sup>
                                      </label>
                                      <div class="form-control-wrap">
                                        <input
                                          type="text"
                                          class="form-control form-control-lg"
                                          id="emailtoname"
                                          name="emailtoname"
                                          value={this.state.emailtoname}
                                          onChange={this.handleChange}
                                          placeholder="Enter the full name"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="form-group mt-2">
                                      <label class="form-label" for="emailto">
                                        To <sup>*</sup>
                                      </label>
                                      <div class="form-control-wrap">
                                        <input
                                          type="text"
                                          class="form-control form-control-lg"
                                          id="emailto"
                                          name="emailto"
                                          value={this.state.emailto}
                                          onChange={this.handleChange}
                                          placeholder="Enter email address"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-12 mt-2">
                                    <div class="form-group">
                                      <label
                                        class="form-label"
                                        for="emailsubject"
                                      >
                                        Subject <sup>*</sup>
                                      </label>
                                      <div class="form-control-wrap">
                                        <input
                                          type="text"
                                          class="form-control form-control-lg"
                                          id="emailsubject"
                                          name="emailsubject"
                                          value={this.state.emailsubject}
                                          onChange={this.handleChange}
                                          placeholder="Email Subject"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-12 mt-2">
                                    <div class="form-group">
                                      <label
                                        class="form-label"
                                        for="emailcontent"
                                      >
                                        Custom Message <sup>*</sup>
                                      </label>
                                      <div class="form-control-wrap">
                                        <textarea
                                          class="form-control"
                                          id="emailcontent"
                                          name="emailcontent"
                                          value={this.state.emailcontent}
                                          onChange={this.handleChange}
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-12 mt-2 d-flex justify-content-end">
                                    <div class="nk-block-head-content">
                                      <ul class="nk-block-tools gx-3">
                                        {this.state.email_button === true ? (
                                          <button
                                            className="btn btn-primary"
                                            type="button"
                                            disabled
                                          >
                                            <span
                                              className="spinner-grow spinner-grow-sm"
                                              role="status"
                                              aria-hidden="true"
                                            ></span>
                                            <span> Processing... </span>
                                          </button>
                                        ) : (
                                          <li>
                                            <a
                                              onClick={() =>
                                                this.submitFormEmail()
                                              }
                                              style={{
                                                cursor: "pointer",
                                                color: "#fff",
                                              }}
                                              class="btn btn-primary"
                                            >
                                              <em class="icon ni ni-check-round-cut"></em>{" "}
                                              <span>Send Email</span>{" "}
                                            </a>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div class="row g-gs">
                                  <div class="col-sm-12">
                                    <div class="alert alert-pro alert-primary">
                                      <div class="alert-text">
                                        <h6>Form Submission Limit</h6>
                                        <p>
                                          You cannot send this form because it
                                          has already been submitted. Multiple
                                          submissions are not allowed.{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* EMAIL FORM */}
                        <div
                          className={
                            this.state.activeTab === "total_submissions"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemAgreements"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <h5 class="title mb-4">Forms Submissions</h5>

                                {this.state.assignedLoader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    <div
                                      className="tab-pane active"
                                      id="tabItemSigned"
                                    >
                                      {this.state.errorMessageTotals !== "" ? (
                                        <div className="example-alert example-alert-revoke">
                                          <div className="alert alert-pro alert-danger">
                                            <div className="alert-text">
                                              <h4>
                                                {
                                                  this.state
                                                    .errorMessageTotalsTitle
                                                }
                                              </h4>
                                              <p>
                                                {this.state.errorMessageTotals}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                      <div className="card card-bordered card-preview">
                                        <DataTableExtended
                                          columns={
                                            this.state.generalColumnServices
                                          }
                                          tableData={
                                            this.state.totalSubmissions
                                          }
                                          title=""
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* EMAIL FORM */}
                        <div class="nk-divider divider md"></div>
                      </div>
                    </div>
                    <div
                      class="card-aside card-aside-right user-aside"
                      data-content="userAside"
                      data-toggle-screen="xxl"
                      data-toggle-overlay="true"
                      data-toggle-body="true"
                    >
                      <div class="card-inner-group" data-simplebar>
                        <div class="card-inner">
                          <div class="user-card user-card-s2">
                            <div class="user-info">
                              {/*{this.state.form_status &&
                              this.state.form_status === 1 ? (
                                <div class="badge badge-outline-success badge-pill ucap">
                                  Active
                                </div>
                              ) : (
                                <div class="badge badge-outline-light badge-pill ucap">
                                  Inactive
                                </div>
                              )}*/}

                              <h5>{this.state.form_title}</h5>
                              <span class="sub-text"></span>
                            </div>
                          </div>
                        </div>

                        <div class="card-inner">
                          <h6 class="overline-title-alt mb-2">Totals</h6>
                          <div class="row text-center">
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">
                                  {this.state.total_completed}
                                </span>
                                <span class="sub-text">Submitted</span>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">
                                  {this.state.total_not_completed}
                                </span>
                                <span class="sub-text">Not Submitted</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*<div class="card-inner">
                          <h6 class="overline-title-alt mb-2">Additional</h6>
                          <div class="row g-3">
                            <div class="col-6">
                              <span class="sub-text">User ID:</span>
                              <span>UD003054</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Last Login:</span>
                              <span>15 Feb, 2019 01:02 PM</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">KYC Status:</span>
                              <span class="lead-text text-success">
                                Approved
                              </span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Register At:</span>
                              <span>Nov 24, 2019</span>
                            </div>
                          </div>
                        </div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* View General Submissions */}
              <div
                className="modal fade zoom"
                tabIndex="-1"
                id="modalViewGeneralSubmission"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-list-round"></em> Submission
                          Details{" "}
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* NRC DIVS START */}

                      {this.state.viewLoader === true ? (
                        FormLoader()
                      ) : (
                        <>
                          <div>
                            <div className="card card-bordered">
                              <div className="card-inner-group">
                                {this.state.viewGeneralSubmission &&
                                this.state.viewGeneralSubmission.length > 0 ? (
                                  <div className="card-inner">
                                    <div className="sp-plan-head">
                                      <h6 className="title">
                                        Form Submission Details
                                      </h6>
                                    </div>
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <table className="table table-bordered table-striped">
                                        {this.state.viewGeneralSubmission.map(
                                          (general, index) => (
                                            <tbody
                                              key={index}
                                              className="text-dark"
                                            >
                                              <tr>
                                                <td>Sent To Name</td>
                                                <td>
                                                  {general.submitter_name}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent To Email</td>
                                                <td>
                                                  {general.submitter_email}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent By</td>
                                                <td>
                                                  {general.sent_by_email &&
                                                  general.sent_by_email !== ""
                                                    ? general.sent_by_email
                                                    : "---"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent Date</td>
                                                <td>
                                                  {this.format_date(
                                                    general.sent_on
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Submitted</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1 ? (
                                                    <span class="badge badge-dot badge-success">
                                                      Yes
                                                    </span>
                                                  ) : (
                                                    <span class="badge badge-dot badge-danger">
                                                      No
                                                    </span>
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Submission Date</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1
                                                    ? this.format_date(
                                                        general.submitted_on
                                                      )
                                                    : "---"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>View PDF</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1 ? (
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.downloadPdf(
                                                          general.pdf_name
                                                        )
                                                      }
                                                    >
                                                      <span class="badge badge-outline-dark">
                                                        View
                                                      </span>
                                                    </a>
                                                  ) : (
                                                    "---"
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Extra Files</td>
                                                <td>
                                                  {this.state.extra_files &&
                                                  this.state.extra_files
                                                    .length > 0 ? (
                                                    <>
                                                      {this.state.extra_files.map(
                                                        (file, index) => (
                                                          <>
                                                            {file.file_name &&
                                                            file.file_name !==
                                                              "" ? (
                                                              <div
                                                                key={index}
                                                                className="col-md-12"
                                                                style={{
                                                                  paddingLeft:
                                                                    "0px",
                                                                }}
                                                              >
                                                                {file.field_name.trim()}{" "}
                                                                <span
                                                                  onClick={() =>
                                                                    this.downloadPdf(
                                                                      file.file_name
                                                                    )
                                                                  }
                                                                  class="badge badge-outline-primary"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  title="Download / View"
                                                                >
                                                                  <em class="icon ni ni-download"></em>{" "}
                                                                  {file.file_name.trim()}
                                                                </span>
                                                              </div>
                                                            ) : null}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  ) : (
                                                    "---"
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent Form Subject</td>
                                                <td>
                                                  {general.email_subject &&
                                                  general.email_subject !== ""
                                                    ? general.email_subject
                                                    : "---"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent Form Message</td>
                                                <td>
                                                  {general.email_content &&
                                                  general.email_content !== ""
                                                    ? general.email_content
                                                    : "---"}
                                                </td>
                                              </tr>
                                            </tbody>
                                          )
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* View General Submissions */}
              {/* START DELETE MODAL  */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalDeleteGeneralAlert"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a
                      style={{ cursor: "pointer" }}
                      className="close"
                      onClick={() => {
                        this.modalHideDel();
                      }}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>

                        <h4 className="nk-modal-title">Delete Submission?</h4>

                        <div className="nk-modal-text">
                          {this.state.errorMessageDelete !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessageDelete}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessageDelte !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessageDelte}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div className="caption-text">
                            Are you sure you want to delete this submission
                          </div>
                        </div>
                        <div className="nk-modal-action">
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn btn-lg btn-mw btn-primary"
                            onClick={() => {
                              this.modalHideDelGeneral();
                            }}
                          >
                            CANCEL
                          </a>
                          {"  "}
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn btn-lg btn-mw btn-danger"
                            onClick={() => {
                              this.deleteGeneralSubmission();
                            }}
                            disabled={
                              this.state.disabled || this.state.isProcessing
                            } // disable button when processing
                          >
                            {this.state.isProcessing ? (
                              <span>DELETING...</span>
                            ) : (
                              <span>DELETE</span>
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END DELETE MODAL  */}
              {/* START REJECTIONS MODAL */}
              <div class="modal fade" tabindex="-1" id="modalRejectionGeneral">
                <div
                  class="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div class="modal-content">
                    <a
                      href="#"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross"></em>
                    </a>
                    <div class="modal-header">
                      <h5 class="modal-title">
                        All rejections{" "}
                        <sup className="text-primary">
                          {this.state.form_title}
                        </sup>
                      </h5>
                    </div>
                    <div class="modal-body">
                      {this.state.rejectGeneralErrorMessage !== "" ? (
                        <div class="alert alert-pro alert-danger mb-2">
                          <div class="alert-text">
                            <h6>Error</h6>
                            <p>{this.state.rejectGeneralErrorMessage} </p>
                          </div>
                        </div>
                      ) : null}
                      {this.state.rejectGeneralSuccessMessage !== "" ? (
                        <div class="alert alert-pro alert-success mb-2">
                          <div class="alert-text">
                            <h6>Success</h6>
                            <p>{this.state.rejectGeneralSuccessMessage} </p>
                          </div>
                        </div>
                      ) : null}
                      <div id="revokeReasonItems">
                        {this.state.rejectionLoader === true ? (
                          FormLoader()
                        ) : (
                          <>
                            <div>
                              <div className="card card-bordered">
                                <div className="card-inner-group">
                                  {this.state.allGeneralRejectionsListing &&
                                  this.state.allGeneralRejectionsListing
                                    .length > 0 ? (
                                    <div className="card-inner">
                                      <div className="sp-plan-head">
                                        <h6 className="title">
                                          All Rejections
                                        </h6>
                                      </div>
                                      <div className="sp-plan-desc sp-plan-desc-mb">
                                        <table className="table table-bordered table-striped">
                                          <thead>
                                            <th>Form Title</th>
                                            <th>Rejection date</th>
                                            <th>Reason</th>
                                            <th>Submitted PDF</th>
                                          </thead>
                                          {this.state.allGeneralRejectionsListing.map(
                                            (general, index) => (
                                              <tbody
                                                key={`ind${index}`}
                                                className="text-dark"
                                              >
                                                <tr>
                                                  <td>
                                                    {this.state.form_title}
                                                  </td>
                                                  <td>
                                                    {this.format_date(
                                                      general.rejection_date
                                                    )}
                                                  </td>
                                                  <td>
                                                    {general.rejection_reason}
                                                  </td>
                                                  <td>
                                                    {general.is_submitted &&
                                                    general.is_submitted ===
                                                      1 ? (
                                                      <a
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          this.downloadPdf(
                                                            general.pdf_name
                                                          )
                                                        }
                                                      >
                                                        <span class="badge badge-outline-dark">
                                                          View
                                                        </span>
                                                      </a>
                                                    ) : (
                                                      "---"
                                                    )}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            )
                                          )}
                                        </table>
                                      </div>
                                    </div>
                                  ) : (
                                    <div class="alert alert-pro alert-primary">
                                      <div class="alert-text">
                                        <h6>No Rejection</h6>
                                        <p>
                                          Rejections are not available against
                                          this submission.
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END REJECTIONS MODAL */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FormSubmit);
