import { React } from "react";
import { Component } from "react";
import ReactDOM from "react-dom";

import SignatureCanvas from "react-signature-canvas";
import styles from "./styles.module.css";
export default class Clients extends Component {
  render() {
    return (
      <div>
        <SignatureCanvas
          clearOnResize={true}
          canvasProps={{ className: styles.sigPad }}
          velocityFilterWeight={0.7}
          throttle={50}
          ref={(ref) => {
            this.signaturePad = ref;
          }}
        />
      </div>
    );
  }
}
