import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import { addNewPage } from "./../../../config/api_calls";
import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import { Editor } from "@tinymce/tinymce-react";

import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";

const auth = new HelperClass();

class Cms extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      page_title: "",
      page_link: "",
      seqno: "",
      status: "1",
      is_published: "1",
      page_content: "",
      rate: "",
      update_nrc: "",
      update_mrc: "",
      update_rate: "",
      product_id: "",
      product_desc: "",
      mrc_rate_type: "Flat",
      service_title: "",
      service_type_edit: "",
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = tableMeta.rowData[9];
    if (is_default === "No") {
      return (
        <a
          href="#"
          onClick={() => this.setDefaultService(value, tableMeta)}
          disabled={
            this.props.is_msa_signed === "No" ||
            this.props.defaultSettings === "missing"
              ? true
              : false
          }
        >
          Set Default
        </a>
      );
    } else {
      return "Yes";
    }
  };

  setMarkUp = async (value, tableMeta) => {
    await this.handleStatesDynamic(
      tableMeta.rowData[0],
      tableMeta.rowData[3].replace("$", ""),
      tableMeta.rowData[4].replace("$", ""),
      tableMeta.rowData[6].replace("$", ""),
      tableMeta.rowData[1],
      tableMeta.rowData[5]
    );
    console.log("setMarkupData: ", this.state.service_title);
    window.$("#modalForm").modal("show");
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      page_content: content,
    });
    console.log("Content was updated:", content);
  };

  handleStatesDynamic = async (
    serviceId,
    update_nrc,
    update_mrc,
    update_rate,
    service_title,
    service_type_edit
  ) => {
    this.setState({
      serviceId: serviceId,
      update_nrc: update_nrc,
      update_mrc: update_mrc,
      update_rate: update_rate,
      service_title: service_title,
      service_type_edit: service_type_edit,
    });
  };

  showDetails = (value, tableMeta) => {
    this.setState({
      product_desc: tableMeta.rowData[7],
    });
    window.$("#modalFormDetail").modal("show");
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  calculatePercent = (percent, num) => {
    return (percent / 100) * num;
  };
  modalHide = () => {
    window.$("#modalFormDetail").modal("hide");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  formSave = async (e) => {
    e.preventDefault();
    let {
      page_title,
      seqno,
      status,
      is_published,
      page_content,
      page_link,
    } = this.state;

    if (page_title === "") {
      return this.setState({ errorMessage: "Please add the page title." });
    } else if (seqno === "") {
      return this.setState({ errorMessage: "Seq # is required." });
    } else if (page_link === "") {
      return this.setState({ errorMessage: "Page link is required." });
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await addNewPage(
        auth.getAccount(),
        auth.getToken(),
        page_title,
        seqno,
        status,
        is_published,
        page_content,
        page_link
      );

      console.log("saveResponce.data", saveResponce.data);

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        return this.setState({
          errorMessage: "The page with this link already exists.",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        window.$("#modalAlert").modal("show");

        this.setState({
          successMessage: "Congratulations!",
        });
        this.setState({
          errorMessage: "",
          successMessage: "Page added Successfully.",
          disabled: false,
        });

        setTimeout(function () {
          window.$("#modalAlert").modal("hide");
          window.location.replace("/cms/Pageslist");
        }, 3000);
      } else {
        return this.setState({
          errorMessage: "There is some error while adding the page.",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  render() {
    return (
      <div className="nk-content " id="Cms_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/cms/Pageslist")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Manage Pages
                  </Link>
                  <Typography color="textPrimary" className="">
                    Add Page
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-12">
              <div className="card card-bordered">
                <div className="card-inner">
                  {this.state.errorMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="modal fade" tabIndex="-1" id="modalAlert">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          onClick={() => {
                            this.modalHide();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4 className="nk-modal-title">Page Added</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                {this.state.successMessage}
                              </div>
                            </div>
                            <div className="nk-modal-action">
                              <a
                                href="#"
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHide();
                                }}
                              >
                                OK
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <form
                    method="post"
                    className="pageForm"
                    onSubmit={this.formSave}
                  >
                    <div className="row g-4">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <MutextField
                            required
                            id="page_title"
                            name="page_title"
                            type="text"
                            label="Page Title"
                            defaultValue={this.state.page_title}
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <MutextField
                            required
                            id="page_link"
                            name="page_link"
                            type="text"
                            label="Page Link"
                            defaultValue={this.state.page_link}
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <MutextField
                            id="seqno"
                            name="seqno"
                            label="Seq No"
                            value={this.state.seqno}
                            onChange={this.handleChange}
                            variant="outlined"
                            onKeyPress={() => {
                              this.checkNumber();
                            }}
                            fullWidth
                          />
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-group">
                          <MutextField
                            id="status"
                            name="status"
                            select
                            label="Select Status"
                            value={this.state.status}
                            onChange={this.handleChange}
                            SelectProps={{
                              native: true,
                            }}
                            helperText="Page Status"
                            variant="outlined"
                            fullWidth
                          >
                            <option key="1" value="1">
                              Active
                            </option>
                            <option key="0" value="0">
                              Inactive
                            </option>
                          </MutextField>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-group">
                          <MutextField
                            id="is_published"
                            name="is_published"
                            select
                            label="Is Published"
                            value={this.state.is_published}
                            onChange={this.handleChange}
                            SelectProps={{
                              native: true,
                            }}
                            helperText="Publish page"
                            variant="outlined"
                            fullWidth
                          >
                            <option key="1" value="1">
                              Publish
                            </option>
                            <option key="0" value="0">
                              Unpublish
                            </option>
                          </MutextField>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <h6>Page Content</h6>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <Editor
                            id="page_content"
                            name="page_content"
                            apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                            initialValue={this.state.page_content}
                            selector="textarea#full-featured"
                            init={{
                              height: 500,
                              menubar: true,
                              plugins: [
                                "print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                              ],
                              toolbar:
                                "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                            }}
                            onEditorChange={this.handleEditorChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group text-right">
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            disabled={this.state.disabled}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Cms);
