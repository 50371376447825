import { React } from "react";
import { Component } from "react";
import { COMPANY_NAME } from "../../../config/config";

import {
  getClients,
  saveResellerSettings,
  getResellerSettings,
} from "./../../../config/admin_apis";

import $ from "jquery";

import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";

import TextField from "../../../components/InputFields/TextField";

import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import SettingIcon from "@material-ui/icons/Settings";
import Tooltip from "@material-ui/core/Tooltip";
const auth = new HelperClass();

export default class AdminClients extends Component {
  constructor() {
    super();
    this.state = {
      apiUrl: "",
      apiKey: "",
      authKey: "",
      useraccountno: "",
      disabled: false,
      errorMessage: "",
      successMessage: "",
      columns: [
        { name: "ID" },
        { name: "Account #" },
        { name: "Name" },
        { name: "Title" },
        { name: "Company Name" },
        { name: "Email" },
        {
          name: "Registration Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              let dateSplit = value.split("T");
              return <div>{dateSplit[0]}</div>;
            },
          },
        },
        {
          name: "Settings",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <a
                    href="#"
                    onClick={() =>
                      this.settingView(
                        tableMeta.rowData[0],
                        tableMeta.rowData[1]
                      )
                    }
                  >
                    <Tooltip
                      title="Add/Edit Reseller API Settings"
                      placement="left"
                    >
                      <SettingIcon
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </a>
                </div>
              );
            },
          },
        },
      ],
      tableData: [],
    };
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  async componentDidMount() {
    const clientsResponce = await getClients(
      auth.getAdminAccount(),
      auth.getAdminToken()
    );

    if (
      clientsResponce.data.status === 403 ||
      clientsResponce.data.errors === "authentication missing" ||
      clientsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (clientsResponce.data.status === 404) {
      window.location.replace("/error");
    } else if (
      clientsResponce.data.status === 200 &&
      clientsResponce.data.message === "success"
    ) {
      this.setState({ tableData: clientsResponce.data.data });
    } else {
      window.location.replace("/error");
    }
  }

  validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  formSubmit = async () => {
    const { apiUrl, apiKey, authKey, useraccountno } = this.state;

    if (apiUrl === "") {
      return this.setState({ errorMessage: "Url is required" });
    } else if (this.validURL(apiUrl) === false) {
      return this.setState({ errorMessage: "Invalid Url" });
    } else if (apiKey === "") {
      return this.setState({ errorMessage: "Key is required" });
    } else if (authKey === "") {
      return this.setState({
        errorMessage: "Auth is required",
      });
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await saveResellerSettings(
        auth.getAdminAccount(),
        auth.getAdminToken(),
        useraccountno,
        apiUrl,
        apiKey,
        authKey
      );

      console.log("status: ", saveResponce.data.status);
      console.log("message: ", saveResponce.data.message);
      console.log("note: ", saveResponce.data.note);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: saveResponce.data.note,
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Settings Saved",
          disabled: false,
        });

        //$("#viewBtn").show();
        $("#btnloader").hide();

        setTimeout(function () {
          window.$("#modalForm").modal("hide");
        }, 3000);
      } else {
        window.location.replace("/error");
      }
    }
  };

  settingView = async (id, accountno) => {
    //$("#viewBtn").hide();
    $("#btnloader").show();
    this.setState({ useraccountno: accountno, successMessage: "" });

    const loadSettings = await getResellerSettings(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      accountno
    );
    if (
      loadSettings.data.status === 403 ||
      loadSettings.data.errors === "authentication missing" ||
      loadSettings.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (loadSettings.data.status === 404) {
    } else if (
      loadSettings.data.status === 200 &&
      loadSettings.data.message === "success"
    ) {
      //$("#viewBtn").show();
      $("#btnloader").hide();
      this.setState({
        apiUrl: loadSettings.data.data[0]["url"],
        apiKey: loadSettings.data.data[0]["key"],
        authKey: loadSettings.data.data[0]["auth"],
      });
    }
    window.$("#modalForm").modal("show");
  };

  render() {
    $("#useraccountno").hide();
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* SETTINGS MODAL START */}
          {/* MODAL EDIT PROFILE START */}
          <div className="modal fade" tabIndex="-1" id="modalForm">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Manage Settings</h5>
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body">
                  {/* Error Start */}
                  {this.state.errorMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <br />
                  <TextField
                    label="API URL"
                    id="apiUrl"
                    name="apiUrl"
                    value={this.state.apiUrl}
                    placeholder="URL"
                    change={this.handleChange}
                    type="url"
                    required={true}
                  />
                  <TextField
                    label="API KEY"
                    id="apiKey"
                    name="apiKey"
                    value={this.state.apiKey}
                    placeholder="KEY"
                    change={this.handleChange}
                    required={true}
                  />
                  <TextField
                    label="API AUTH"
                    id="authKey"
                    name="authKey"
                    value={this.state.authKey}
                    placeholder="AUTH"
                    change={this.handleChange}
                    required={true}
                  />
                  <input
                    id="useraccountno"
                    name="useraccountno"
                    value={this.state.useraccountno}
                    change={this.handleChange}
                    type="hidden"
                  />

                  <div className="form-group">
                    <input
                      type="submit"
                      className="btn btn-lg"
                      style={{
                        backgroundColor: "#0ed145",
                        color: THEME_TEXT_COLOR,
                        float: "right",
                      }}
                      disabled={this.state.disabled}
                      value="Save"
                      onClick={() => this.formSubmit()}
                    />
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
          {/* END MODAL EDIT PROFILE */}

          {/* SETTINGS MODAL END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Resellers</h3>
                  </div>
                  <div className="nk-block-head-content">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START TABLE HEAD */}
                      <DataTable
                        columns={this.state.columns}
                        tableData={this.state.tableData}
                      />
                      {/* END TABLE HEAD */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
