import ContentLoader from "react-content-loader";
export const tableLoaderAgreement = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={560}
      viewBox="0 0 400 560"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="8" rx="3" ry="3" width="88" height="2" />
      <rect x="0" y="12" rx="3" ry="3" width="88" height="2" />
      <rect x="0" y="18" rx="3" ry="3" width="410" height="2" />
      <rect x="0" y="22" rx="3" ry="3" width="410" height="2" />
      <rect x="0" y="26" rx="3" ry="3" width="410" height="2" />
      <rect x="0" y="30" rx="3" ry="3" width="410" height="2" />
      <rect x="0" y="34" rx="3" ry="3" width="380" height="2" />
      <rect x="0" y="38" rx="3" ry="3" width="178" height="2" />
    </ContentLoader>
  );
};
