import { Link } from "react-router-dom";
export default function Warning() {
  return (
    <div>
      <div className="example-alert">
        <div className="alert alert-warning alert-icon">
          <em className="icon ni ni-alert-circle"></em> Your
          {""}
          <strong> Master Service Agreement(MSA) </strong>is pending for
          e-signing. Click button{" "}
          <Link to="/msa_signing" className="alert-link">
            Sign Me
          </Link>{" "}
          for activating services.{" "}
        </div>
      </div>
      <br />
    </div>
  );
}
