import React, { Component } from "react";
import $ from "jquery";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { HTML5_FMT } from "moment";
import MutextField from "@material-ui/core/TextField";

const styles = {
  hidden: {
    display: "none",
  },
  importLabel: {
    color: "black",
  },
};
export class Questions extends Component {
  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };

  constructor() {
    super();
    this.state = {
      errorMessage: "",
      dnsList: [],
      activeStep: 0,
      formData: {
        step1Data: "",
        step2Data: "",
      },
    };
  }
  get_text() {
    const { values, submitRfq } = this.props;
    submitRfq();
  }

  submitFormQuestion = async () => {
    console.log("Control is Here");
    const { values, submitRfq } = this.props;
    await submitRfq();
  };

  render() {
    const { values, handleChange, theme_data, handleChangeRfq } = this.props;
    console.log("rfq_questions_rows: ", values.rfq_questions_rows);
    return (
      <>
        <div className="nk-stepper-step active">
          <h5 className="title mb-3">General Assesment</h5>
          <div className="row g-3">
            {values.rfq_questions_rows &&
            values.rfq_questions_rows.length > 0 ? (
              <>
                {values.rfq_questions_rows.map((rfq, bid) => (
                  <div className="col-sm-6" key={`question_${bid}`}>
                    <div className="form-group">
                      <label className="form-label">
                        {bid + 1}. {rfq.question_title}
                      </label>
                    </div>
                    {rfq.question_type === "radio" ? (
                      <>
                        {rfq.all_answers &&
                        rfq.all_answers.length > 0 ? (
                          <>
                            {rfq.all_answers.map((rfq_question, rid) => (
                              <div
                                className="form-group"
                                key={`options_${rid}`}
                              >
                                <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                  <input
                                    type="radio"
                                    className="custom-control-input"
                                    name={`selected_answer_${rfq.question_id}`}
                                    id={`question_${rid}_${bid}`}
                                    onChange={handleChangeRfq(rid)}
                                    defaultChecked={rfq_question.id === rfq[`selected_answer_${rfq.question_id}`] ? true : false}
                                    defaultValue={rfq_question.id}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`question_${rid}_${bid}`}
                                  >
                                    {rfq_question.question_options}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : null}
                      </>
                    ) : rfq.question_type === "checkbox" ? (
                      <>
                        {rfq.all_answers &&
                        rfq.all_answers.length > 0 ? (
                          <>
                            {rfq.all_answers.map((rfq_question, cid) => (
                              <div
                                className="form-group"
                                key={`options_${cid}`}
                              >
                                <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name={`selected_answer_${rfq.question_id}`}
                                    onChange={handleChangeRfq(cid)}
                                    id={`question_${cid}_${bid}`}
                                    defaultChecked={rfq_question.id === rfq.selected_answer ? true : false}
                                    defaultValue={rfq_question.id}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`question_${cid}_${bid}`}
                                  >
                                    {rfq_question.question_options}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : null}
                      </>
                    ) : rfq.question_type === "textfield" ? (
                      <>
                        <div className="form-group">
                          <input
                            id={`question_0_${bid}`}
                            className="form-control"
                            name={`selected_answer_${rfq.question_id}`}
                            defaultValue=""
                            onChange={handleChangeRfq(0)}
                            type="text"
                            placeholder=""
                            maxLength={100}
                          />
                        </div>
                      </>
                    ) : rfq.question_type === "textarea" ? (
                      <div className="form-group">
                        <div className="message_area">
                          <textarea
                            id={`question_0_${bid}`}
                            name={`selected_answer_${rfq.question_id}`}
                            onChange={handleChangeRfq(0)}
                            defaultValue=""
                            placeholder=""
                            maxLength={500}
                          ></textarea>
                        </div>
                      </div>
                    ) : rfq.question_type === "dropdown" ? (
                      <>
                        <div className="form-group">
                          <div className="form-control-wrap">
                            <div className="form-control-select">
                              <select
                                className="form-control form-control-lg"
                                id={`question_0_${bid}`}
                                name={`selected_answer_${rfq.question_id}`}
                                label="Select an Option"
                                defaultValue=""
                                onChange={handleChangeRfq(0)}
                              >
                                <option key="sets100" value="">
                                  Select an option{" "}
                                </option>
                                {rfq.all_answers.map(
                                  (rfq_question, rid) => (
                                    <option
                                      key={`sets${rid}`}
                                      value={rfq_question.id}
                                    >
                                      {rfq_question.question_options}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                ))}
              </>
            ) : null}
          </div>
        </div>
        <ul className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination d-flex justify-content-end" style={{ marginBottom: "50px" }}>
          <li className="step-next">
            <button
              className="btn btn-primary"
              onClick={this.submitFormQuestion}
            >
              Continue
            </button>
          </li>
        </ul>
      </>
    );
  }
}

export default Questions;
