import React, { useState, useEffect } from "react";

import {
  TextField,
  Checkbox,
  Radio,
  FormControlLabel,
  FormGroup,
  FormControl,
  Button,
  FormHelperText,
} from "@material-ui/core";
import { APP_LIVE_URL } from "../config/config";
import {
  DateTimePicker,
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import { CopyToClipboard } from "react-copy-to-clipboard";

const populatedynamicValues = (clientsData, value_name) => {
  if (!clientsData) return "";

  const valueMap = {
    fullname: clientsData[0].name,
    email: clientsData[0].email,
    phone: clientsData[0].phone,
    address: clientsData[0].address,
    appartment: clientsData[0].address2,
    city: clientsData[0].city,
    state: clientsData[0].state,
    zip: clientsData[0].zip,
    country: clientsData[0].country,
  };

  if (value_name === "full_address") {
    return [
      clientsData[0].address,
      clientsData[0].address2,
      clientsData[0].city,
      clientsData[0].state,
      clientsData[0].zip,
      clientsData[0].country,
    ]
      .filter(Boolean)
      .join(", ");
  }

  return valueMap[value_name] || "";
};

const FormRenderer = ({
  formData,
  formTitle,
  formDescription,
  onSubmit,
  groups,
  responseMessage,
  clientData,
}) => {
  //console.log("formData: ", formData);
  const [formState, setFormState] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const initialState = {};
    // Assuming questions is an array and you need to access its nested `questions` property
    formData.forEach(section => {
      section.questions.forEach(question => {
        if (question.dynamicValue) {
          initialState[question.id] = populatedynamicValues(clientData, question.dynamicValue);
        } else {
          initialState[question.id] = ""; // or any default value if needed
        }
      });
    });
    setFormState(initialState);
  }, [clientData, formData]);

  const hasUngroupedQuestions = formData.some((form) =>
    form.questions.some((q) => !groups?.flat().includes(q.id))
  );

  const ungroupedQuestions = formData
    .flatMap((form) => form.questions)
    .filter((question) => !groups.flat().includes(question.id));

  const handleChange = (questionId, value) => {
    console.log("questionId  value: " + questionId + "  " + value);
    setFormState({
      ...formState,
      [questionId]: value,
    });
    setFormErrors({
      ...formErrors,
      [questionId]: "",
    });
  };

  const handleChangeFixed = (questionId, value) => {
    setFormState({
      ...formState,
    });
  };

  const handleCheckboxChange = (questionId, option, isChecked) => {
    const currentOptions = formState[questionId] || [];
    if (isChecked) {
      setFormState({
        ...formState,
        [questionId]: [...currentOptions, option],
      });
    } else {
      setFormState({
        ...formState,
        [questionId]: currentOptions.filter((opt) => opt !== option),
      });
    }
    setFormErrors({
      ...formErrors,
      [questionId]: "",
    });
  };

  const handleFileChange = (questionId, event) => {
    if (event.target && event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        axios
          .post(
            `${process.env.REACT_APP_API_URL}forms/form_image_response`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            console.log("handleFileChange: ", response);
            const imageUrl = response.data.default;
            setFormState((prevFormState) => ({
              ...prevFormState,
              [questionId]: imageUrl,
            }));
            setFormErrors((prevFormErrors) => ({
              ...prevFormErrors,
              [questionId]: "",
            }));
          })
          .catch((error) => {
            console.error("There was an error uploading the image!", error);
          });
      }
    } else {
      console.error("No file selected or event target is undefined.");
    }
  };

  const handleSubmit = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const requiredQuestions = formData.flatMap((form) =>
      form.questions.filter((question) => question.required)
    );
    let hasErrors = false;

    requiredQuestions.forEach((question) => {
      if (!formState[question.id]) {
        hasErrors = true;
      }
    });

    if (hasErrors) {
      setErrorMessage("Please fill out all required fields.");
      setTimeout(() => {
        setErrorMessage("");
      }, 2500);
      return;
    }
    setLoading(true);
    //console.log("formData Before: ", formData);
    const formDataToSend = formData.map((form) => ({
      title: form.title,
      id: form.id,
      questions: form.questions.map((question) => ({
        ...question,
        //answer: formState[question.id],
        answer:
          question.answerType === "fixed"
            ? question.answer
            : formState[question.id],
      })),
      errorMessage: form.errorMessage,
    }));
    //console.log("formDataToSend: ", formDataToSend);
    onSubmit(formDataToSend);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  };

  const handleNextStep = () => {
    if (validateCurrentGroup()) {
      setCurrentStep(currentStep + 1);
    } else {
      setErrorMessage(
        "Please fill out all required fields in the current group."
      );
      setTimeout(() => {
        setErrorMessage("");
      }, 2500);
    }
  };

  const validateCurrentGroup = () => {
    const currentGroup = groups[currentStep];
    if (!currentGroup) return false;

    const hasEmptyRequiredFields = currentGroup.questions.some((questionId) => {
      const question = formData[0].questions.find((q) => q.id === questionId);
      if (question) {
        return question.required && !formState[question.id];
      }
    });

    return !hasEmptyRequiredFields;
  };

  const currentGroup = groups && groups.length > 0 ? groups[currentStep] : null;

  /*const populatedynamicValues = (clientsData, value_name) => {
    if(clientsData){
      if(value_name === "fullname"){
        return clientsData.name;
      }
      if(value_name === "email"){
        return clientsData.email;
      }
      if(value_name === "phone"){
        return clientsData.phone;
      }
      if(value_name === "address"){
        return clientsData.address;
      }
      if(value_name === "appartment"){
        return clientsData.address2;
      }
      if(value_name === "city"){
        return clientsData.city;
      }
      if(value_name === "state"){
        return clientsData.state;
      }
      if(value_name === "zip"){
        return clientsData.zip;
      }
      if(value_name === "country"){
        return clientsData.country;
      }
      if(value_name === "full_address"){
        let full_address = clientsData.address;
        if(clientsData.address2 && clientsData.address2 !== ""){
          full_address += clientsData.address2
        }
        if(clientsData.city && clientsData.city !== ""){
          full_address += ", " + clientsData.city
        }
        if(clientsData.state && clientsData.state !== ""){
          full_address += ", " + clientsData.state
        }
        if(clientsData.zip && clientsData.zip !== ""){
          full_address += ", " + clientsData.zip
        }
        if(clientsData.country && clientsData.country !== ""){
          full_address += ", " + clientsData.country
        }
        return full_address;
      }
    }
    return "";
  };*/

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="form-renderer-container">
        {currentGroup ? (
          <>
            <div className="form-section">
              <div className="nk-block">
                <div className="card card-bordered">
                  <div className="nk-kycfm">
                    <div
                      className="nk-kycfm-head"
                      style={{ background: "#fff" }}
                    >
                      <div className="nk-kycfm-title">
                        <h5 className="title">
                          {formTitle || "Untitled Form"}
                        </h5>
                        {currentStep === 0 && (
                          <p className="sub-title">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: formDescription,
                              }}
                            />
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="nk-kycfm-content">
                      {responseMessage && (
                        <div
                          dangerouslySetInnerHTML={{ __html: responseMessage }}
                        ></div>
                      )}
                      {errorMessage && (
                        <div class="alert alert-danger alert-icon mb-3">
                          <em class="icon ni ni-cross-circle"></em> Please fill
                          out all the required questions!
                        </div>
                      )}
                      <div className="mb-3">
                        <h5 className="title">{currentGroup.group_title}</h5>
                        <p className="sub-title">{currentGroup.group_desc}</p>
                      </div>
                      <div className="row g-gs">
                        {currentGroup.questions.map((questionId) => {
                          const question = formData[0].questions.find(
                            (q) => q.id === questionId
                          );
                          if (!question) {
                            return null;
                          }
                          const value = formState[question.id] || "";
                          /*const value = formState[question.id] !== undefined ? formState[question.id] : 
                            (question.dynamicValue && question.dynamicValue !== "" 
                               ? populatedynamicValues(clientsData, question.dynamicValue) 
                               : "");*/

                          return (
                            <div
                              key={question.id}
                              className={
                                question.answerType === "label"
                                  ? "question-container col-sm-12"
                                  : "question-container col-sm-6"
                              }
                            >
                              <div className="form-group question-text mb-0">
                                {question.answerType === "label" ? (
                                  <label
                                    className="form-label"
                                    style={{ fontSize: "1.275rem" }}
                                  >
                                    {question.questionText}{" "}
                                    {question.required && (
                                      <sup className="text-danger">*</sup>
                                    )}
                                  </label>
                                ) : (
                                  <label className="form-label">
                                    {question.questionText}{" "}
                                    {question.required && (
                                      <sup className="text-danger">*</sup>
                                    )}
                                  </label>
                                )}
                              </div>
                              {question.imageUrl &&
                                {
                                  /*<img
                                  src={question.imageUrl}
                                  alt="Admin Uploaded"
                                  className="question-image"
                                />*/
                                }}
                              {question.answerType === "input" && (
                                <div className="form-group mb-0">
                                  <div className="form-control-wrap">
                                    <input
                                      className={`form-control form-control-lg`}
                                      onChange={(e) =>
                                        handleChange(
                                          question.id,
                                          e.target.value
                                        )
                                      }
                                      value={value}
                                      type="text"
                                      maxLength={100}
                                    />
                                  </div>
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "fixed" && (
                                <>
                                  <div className="form-group mb-0">
                                    <div className="form-control-wrap">
                                      <input
                                        className={`form-control form-control-lg`}
                                        onChange={(e) =>
                                          handleChangeFixed(
                                            question.id,
                                            question.answer
                                          )
                                        }
                                        value={question.answer}
                                        type="text"
                                        maxLength={255}
                                      />
                                    </div>
                                    {formErrors[question.id] && (
                                      <FormHelperText error>
                                        {formErrors[question.id]}
                                      </FormHelperText>
                                    )}
                                  </div>
                                  <CopyToClipboard
                                    text={question.answer}
                                    onCopy={() => setCopied(true)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div class="form-note text-primary">
                                      <strong>
                                        Copy to Clipboard{" "}
                                        {copied ? (
                                          <span>
                                            <em className="icon ni ni-done"></em>
                                          </span>
                                        ) : null}
                                      </strong>
                                    </div>
                                  </CopyToClipboard>
                                </>
                              )}
                              {question.answerType === "label" && (
                                <div className="form-group mb-0">
                                  <h6></h6>
                                </div>
                              )}
                              {question.answerType === "textarea" && (
                                <div className="form-group">
                                  <div className="form-control-wrap">
                                    <textarea
                                      className={`form-control form-control-lg`}
                                      onChange={(e) =>
                                        handleChange(
                                          question.id,
                                          e.target.value
                                        )
                                      }
                                      placeholder=""
                                      value={value}
                                      maxLength={500}
                                    ></textarea>
                                  </div>
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "datetime" && (
                                <div className="form-group">
                                  <DateTimePicker
                                    variant="inline"
                                    inputVariant="outlined"
                                    fullWidth
                                    value={formState[question.id] || null}
                                    onChange={(date) =>
                                      handleChange(question.id, date)
                                    }
                                  />
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "date" && (
                                <div className="form-group">
                                  <DatePicker
                                    variant="inline"
                                    inputVariant="outlined"
                                    fullWidth
                                    value={formState[question.id] || null}
                                    onChange={(date) =>
                                      handleChange(question.id, date)
                                    }
                                  />
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "time" && (
                                <div className="form-group">
                                  <TimePicker
                                    variant="inline"
                                    inputVariant="outlined"
                                    fullWidth
                                    value={formState[question.id] || null}
                                    onChange={(time) =>
                                      handleChange(question.id, time)
                                    }
                                  />
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "dropdown" && (
                                <div className="form-group">
                                  <div className="form-control-wrap">
                                    <div className="form-control-select">
                                      <select
                                        className={`form-control form-control-lg`}
                                        onChange={(e) =>
                                          handleChange(
                                            question.id,
                                            e.target.value
                                          )
                                        }
                                        value={value}
                                      >
                                        <option value="">
                                          Select an option
                                        </option>
                                        {question.options.map(
                                          (option, index) => (
                                            <option key={index} value={option}>
                                              {option}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "checkbox" && (
                                <>
                                  <div className="mt-2 prettyprint ">
                                    <div className="row">
                                      {question.options.map((option, index) => (
                                        <div
                                          className="col-md-6 mb-1"
                                          key={`options_${index}`}
                                        >
                                          <div className="custom-control custom-control-md custom-checkbox custom-control-pro">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input vdrSelected"
                                              id={`question_checkbox_${question.id}_${index}`}
                                              checked={
                                                formState[
                                                  question.id
                                                ]?.includes(option) || false
                                              }
                                              onChange={(e) =>
                                                handleCheckboxChange(
                                                  question.id,
                                                  option,
                                                  e.target.checked
                                                )
                                              }
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor={`question_checkbox_${question.id}_${index}`}
                                            >
                                              {option}
                                            </label>
                                          </div>
                                          {formErrors[question.id] && (
                                            <FormHelperText error>
                                              {formErrors[question.id]}
                                            </FormHelperText>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              )}

                              {question.answerType === "radio" && (
                                <div className="form-pm-group">
                                  <ul className="buysell-pm-list">
                                    {question.options.map((option, index) => (
                                      <li
                                        className="buysell-pm-item"
                                        key={index}
                                      >
                                        <input
                                          className="buysell-pm-control"
                                          type="radio"
                                          name={`radio_${question.id}`}
                                          id={`radio_${question.id}_${index}`}
                                          onChange={(e) =>
                                            handleChange(question.id, option)
                                          }
                                          value={option}
                                        />
                                        <label
                                          className="buysell-pm-label"
                                          htmlFor={`radio_${question.id}_${index}`}
                                        >
                                          <span className="pm-name">
                                            {option}
                                          </span>
                                          <span className="pm-icon">
                                            <em className="icon ni ni-question"></em>
                                          </span>
                                        </label>
                                      </li>
                                    ))}
                                  </ul>
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "image_input" && (
                                <div className="form-group">
                                  <div
                                    className="form-control-group"
                                    id="fileContainer"
                                  >
                                    <input
                                      type="file"
                                      id={`add_picture_${question.id}`}
                                      className="file-block"
                                      name="add_picture"
                                      accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .xlx, .xlxs, .csv"
                                      onChange={(e) =>
                                        handleFileChange(question.id, e)
                                      }
                                    />
                                    {/* Display the uploaded file name or image preview if the URL exists in the state */}
                                    {formState[question.id] && (
                                      <div className="uploaded-file-preview">
                                        {formState[question.id].match(
                                          /\.(jpeg|jpg|png)$/i
                                        ) ? (
                                          <img
                                            src={`${APP_LIVE_URL}files_data/form_response_uploads/${
                                              formState[question.id]
                                            }`}
                                            alt="Uploaded preview"
                                            style={{
                                              maxWidth: "100px",
                                            }}
                                          />
                                        ) : (
                                          <p>
                                            {formState[question.id]
                                              .split("/")
                                              .pop()}
                                          </p> // Display file name for non-images
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              {currentStep > 0 && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  Back
                </Button>
              )}
              {currentStep < groups.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNextStep}
                  style={{ marginLeft: "10px" }}
                >
                  Continue
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  style={{ marginLeft: "10px" }}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </Button>
              )}
            </div>
          </>
        ) : (
          hasUngroupedQuestions && (
            <>
              <div className="form-section">
                <div className="nk-block">
                  <div className="card card-bordered">
                    <div className="nk-kycfm">
                      <div
                        className="nk-kycfm-head"
                        style={{ background: "#f5f6fa" }}
                      >
                        <div className="nk-kycfm-title">
                          <h5 className="title">
                            {formTitle || "Untitled Form"}
                          </h5>
                          <p className="sub-title">
                            {formDescription || "Untitled Form"}
                          </p>
                        </div>
                      </div>
                      <div className="nk-kycfm-content">
                        {responseMessage && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: responseMessage,
                            }}
                          ></div>
                        )}
                        {errorMessage && (
                          <div class="alert alert-danger alert-icon mb-3">
                            <em class="icon ni ni-cross-circle"></em> Please
                            fill out all the required questions!
                          </div>
                        )}
                        <div className="row g-gs">
                          {ungroupedQuestions.map((question) => (
                            <div
                              key={question.id}
                              className="question-container col-sm-6"
                            >
                              <div className="form-group question-text mb-0">
                                <label className="form-label">
                                  {question.questionText}{" "}
                                  {question.required && (
                                    <sup className="text-danger">*</sup>
                                  )}
                                </label>
                              </div>
                              {question.imageUrl && (
                                <img
                                  src={question.imageUrl}
                                  alt="Admin Uploaded"
                                  className="question-image"
                                />
                              )}
                              {question.answerType === "input" && (
                                <div className="form-group mb-0">
                                  <div className="form-control-wrap">
                                    <input
                                      className={`form-control form-control-lg`}
                                      onChange={(e) =>
                                        handleChange(
                                          question.id,
                                          e.target.value
                                        )
                                      }
                                      type="text"
                                      maxLength={100}
                                    />
                                  </div>
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "textarea" && (
                                <div className="form-group">
                                  <div className="form-control-wrap">
                                    <textarea
                                      className={`form-control form-control-lg`}
                                      onChange={(e) =>
                                        handleChange(
                                          question.id,
                                          e.target.value
                                        )
                                      }
                                      placeholder=""
                                      maxLength={500}
                                    ></textarea>
                                  </div>
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "datetime" && (
                                <div className="form-group">
                                  <DateTimePicker
                                    variant="inline"
                                    inputVariant="outlined"
                                    fullWidth
                                    value={formState[question.id] || null}
                                    onChange={(date) =>
                                      handleChange(question.id, date)
                                    }
                                  />
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "date" && (
                                <div className="form-group">
                                  <DatePicker
                                    variant="inline"
                                    inputVariant="outlined"
                                    fullWidth
                                    value={formState[question.id] || null}
                                    onChange={(date) =>
                                      handleChange(question.id, date)
                                    }
                                  />
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "time" && (
                                <div className="form-group">
                                  <TimePicker
                                    variant="inline"
                                    inputVariant="outlined"
                                    fullWidth
                                    value={formState[question.id] || null}
                                    onChange={(time) =>
                                      handleChange(question.id, time)
                                    }
                                  />
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "dropdown" && (
                                <div className="form-group">
                                  <div className="form-control-wrap">
                                    <div className="form-control-select">
                                      <select
                                        className={`form-control form-control-lg`}
                                        onChange={(e) =>
                                          handleChange(
                                            question.id,
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">
                                          Select an option
                                        </option>
                                        {question.options.map(
                                          (option, index) => (
                                            <option key={index} value={option}>
                                              {option}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "checkbox" && (
                                <>
                                  <div className="mt-2 prettyprint ">
                                    <div className="row">
                                      {question.options.map((option, index) => (
                                        <div
                                          className="col-md-6 mb-1"
                                          key={`options_${index}`}
                                        >
                                          <div className="custom-control custom-control-md custom-checkbox custom-control-pro">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input vdrSelected"
                                              id={`question_checkbox_${question.id}_${index}`}
                                              checked={
                                                formState[
                                                  question.id
                                                ]?.includes(option) || false
                                              }
                                              onChange={(e) =>
                                                handleCheckboxChange(
                                                  question.id,
                                                  option,
                                                  e.target.checked
                                                )
                                              }
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor={`question_checkbox_${question.id}_${index}`}
                                            >
                                              {option}
                                            </label>
                                          </div>
                                          {formErrors[question.id] && (
                                            <FormHelperText error>
                                              {formErrors[question.id]}
                                            </FormHelperText>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              )}

                              {question.answerType === "radio" && (
                                <div className="form-pm-group">
                                  <ul className="buysell-pm-list">
                                    {question.options.map((option, index) => (
                                      <li
                                        className="buysell-pm-item"
                                        key={index}
                                      >
                                        <input
                                          className="buysell-pm-control"
                                          type="radio"
                                          name={`radio_${question.id}`}
                                          id={`radio_${question.id}_${index}`}
                                          onChange={(e) =>
                                            handleChange(question.id, option)
                                          }
                                        />
                                        <label
                                          className="buysell-pm-label"
                                          htmlFor={`radio_${question.id}_${index}`}
                                        >
                                          <span className="pm-name">
                                            {option}
                                          </span>
                                          <span className="pm-icon">
                                            <em className="icon ni ni-question"></em>
                                          </span>
                                        </label>
                                      </li>
                                    ))}
                                  </ul>
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "image_input" && (
                                <div className="form-group">
                                  <div
                                    className="form-control-group"
                                    id="fileContainer"
                                  >
                                    <input
                                      type="file"
                                      id={`add_picture_${question.id}`}
                                      className="file-block"
                                      name="add_picture"
                                      accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .xlx, .xlxs, .csv"
                                      onChange={(e) =>
                                        handleFileChange(question.id, e)
                                      }
                                    />
                                    {/* Display the uploaded file name or image preview if the URL exists in the state */}
                                    {formState[question.id] && (
                                      <div className="uploaded-file-preview">
                                        {formState[question.id].match(
                                          /\.(jpeg|jpg|png)$/i
                                        ) ? (
                                          <img
                                            src={`${APP_LIVE_URL}files_data/form_response_uploads/${
                                              formState[question.id]
                                            }`}
                                            alt="Uploaded preview"
                                            style={{
                                              maxWidth: "100px",
                                            }}
                                          />
                                        ) : (
                                          <p>
                                            {formState[question.id]
                                              .split("/")
                                              .pop()}
                                          </p> // Display file name for non-images
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  style={{ marginLeft: "10px" }}
                >
                  Submit
                </Button>
              </div>
            </>
          )
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default FormRenderer;
