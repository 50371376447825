export default function TextField(props) {
  return (
    <div className="form-group">
      <label className="form-label" htmlFor="name">
        {props.label}
      </label>
      <input
        type={props.type}
        className={"form-control form-control-lg " + props.error}
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.change}
        placeholder={props.placeholder}
        type={props.type}
        disabled={props.disabled}
        required={props.required}
      />
    </div>
  );
}
