import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  getMenuData,
  updateMenu,
  listPages,
} from "./../../../config/api_calls";
import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import { Editor } from "@tinymce/tinymce-react";

import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";

const auth = new HelperClass();
//DRAGGABLE CONTENT

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 20,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "white" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#fafbfb" : "#fafbfb",
  padding: grid,
  width: "100%",
});

//DRAGGABLE CONTENT
class Editmenu extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      menu_title: "",
      menu_id: "",
      page_link: "",
      seqno: "",
      status: "1",
      is_published: "1",
      page_content: "",
      rate: "",
      update_nrc: "",
      update_mrc: "",
      update_rate: "",
      product_id: "",
      product_desc: "",
      mrc_rate_type: "Flat",
      service_title: "",
      service_type_edit: "",
      items: [],
      items_count: 0,
      menu_link: "",
      menu_item: "",
      pagesData: [],
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  async componentDidMount() {
    let menu_id = atob(this.props.props.match.params.id);

    const reportsData = await getMenuData(
      auth.getAccount(),
      auth.getToken(),
      menu_id
    );
    console.log("reportsData.data.data[0][4][0]", reportsData.data.data);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      this.setState({
        errorMessage: "Menu does not exist.",
        disabled: true,
      });
      //window.location.replace("/error");
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      var finalRes = [];
      if (reportsData.data.data[0][4][0]) {
        let results = reportsData.data.data[0][4][0];
        for (var l = 0; l < results.length; l++) {
          var res = {};
          res["content"] = results[l].menu_item;
          res["id"] = results[l].menu_link;
          finalRes[l] = res;
        }
      }

      this.setState({
        tableLoader: false,
        menu_id: reportsData.data.data[0][0],
        menu_title: reportsData.data.data[0][1],
        seqno: reportsData.data.data[0][2],
        status: reportsData.data.data[0][3],
        items: finalRes,
      });
    } else {
      this.setState({
        errorMessage: "Menu does not exist.",
        disabled: true,
      });
      //window.location.replace("/error");
    }
  }

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = tableMeta.rowData[9];
    if (is_default === "No") {
      return (
        <a
          href="#"
          onClick={() => this.setDefaultService(value, tableMeta)}
          disabled={
            this.props.is_msa_signed === "No" ||
            this.props.defaultSettings === "missing"
              ? true
              : false
          }
        >
          Set Default
        </a>
      );
    } else {
      return "Yes";
    }
  };

  setMarkUp = async (value, tableMeta) => {
    const servicesResponce = await listPages(
      auth.getAccount(),
      auth.getToken()
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let result = servicesResponce.data.data;
      var finalRes = [];
      for (var l = 0; l < result.length; l++) {
        var res = {};
        res["title"] = result[l][1];
        res["year"] = result[l][2];
        finalRes[l] = res;
      }

      this.setState({
        pagesData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      window.location.replace("/error");
    }
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      page_content: content,
    });
    console.log("Content was updated:", content);
  };

  handleStatesDynamic = async (
    serviceId,
    update_nrc,
    update_mrc,
    update_rate,
    service_title,
    service_type_edit
  ) => {
    this.setState({
      serviceId: serviceId,
      update_nrc: update_nrc,
      update_mrc: update_mrc,
      update_rate: update_rate,
      service_title: service_title,
      service_type_edit: service_type_edit,
    });
  };

  showDetails = (value, tableMeta) => {
    this.setState({
      product_desc: tableMeta.rowData[7],
    });
    window.$("#modalFormDetail").modal("show");
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  calculatePercent = (percent, num) => {
    return (percent / 100) * num;
  };
  modalHide = () => {
    window.$("#modalFormDetail").modal("hide");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  addMenuItems = () => {
    let items_count = this.state.items_count;
    this.setMarkUp();
    this.setState({
      menu_item: "",
      menu_link: "",
    });
    window.$("#modalAddItems").modal("show");
  };

  getItems = (count) =>
    Array.from({ length: count }, (v, k) => k).map((k) => ({
      id: `item-${k}`,
      content: `item ${k}`,
    }));

  itemSave = async (e) => {
    let { menu_item, menu_link } = this.state;

    if (menu_item === "") {
      return this.setState({ errorMessage: "Please add the page title." });
    } else if (menu_link === "") {
      return this.setState({ errorMessage: "Please select the page link." });
    } else {
      let items_count = this.state.items_count;
      items_count = items_count + 1;
      let old_items = this.state.items;

      var res = {};
      res["id"] = "item-" + menu_link;
      res["content"] = menu_item;
      old_items.push(res);

      this.setState({
        menu_item: menu_item,
        menu_link: menu_link,
        items: old_items,
      });
      window.$("#modalAddItems").modal("hide");
      console.log("this.state.items: ", this.state.items);
    }
  };

  formSave = async (e) => {
    e.preventDefault();
    let { menu_title, status, seqno } = this.state;

    if (menu_title === "") {
      return this.setState({ errorMessage: "Please add the menu title." });
    } else if (seqno === "") {
      return this.setState({ errorMessage: "Seq # is required." });
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await updateMenu(
        auth.getAccount(),
        auth.getToken(),
        menu_title,
        seqno,
        status,
        this.state.items,
        this.state.menu_id
      );

      console.log("this.state.items: ", this.state.items);
      console.log("this.state.menu_id: ", this.state.menu_id);
      console.log("saveResponce.data: ", saveResponce.data);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        return this.setState({
          errorMessage: "There is some error while updating the menu.",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        window.$("#modalAlert").modal("show");

        this.setState({
          successMessage: "Congratulations!",
        });
        this.setState({
          errorMessage: "",
          successMessage: "Menu updated Successfully.",
          disabled: false,
        });

        // $("#viewBtn").show();
        // $("#btnloader").hide();

        setTimeout(function () {
          window.$("#modalAlert").modal("hide");
          window.location.replace("/cms/Menuslist");
        }, 3000);
      } else {
        return this.setState({
          errorMessage: "There is some error while updating the menu.",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });
  }

  removeItem = async (e) => {
    this.state.items.splice(e, 1);
    this.setState({
      items: this.state.items,
    });
  };

  render() {
    return (
      <div className="nk-content " id="Cms_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/cms/Menuslist")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Manage Menus
                  </Link>
                  <Typography color="textPrimary" className="">
                    Edit Menu
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-12">
              <div className="card card-bordered">
                <div className="card-inner">
                  {this.state.errorMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="modal fade" tabIndex="-1" id="modalAlert">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          onClick={() => {
                            this.modalHide();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4 className="nk-modal-title">Menu updated</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                {this.state.successMessage}
                              </div>
                            </div>
                            <div className="nk-modal-action">
                              <a
                                href="#"
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHide();
                                }}
                              >
                                OK
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalAddItems"
                  >
                    <div className="modal-dialog modal-lg" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-plus-round"></em> Add
                              menu item
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          {this.state.errorMessage !== "" ? (
                            <div className="example-alert">
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div className="example-alert">
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <br />
                          {/* NRC DIVS START */}
                          <div className="row">
                            <div className="col-sm-12">
                              <MutextField
                                id="menu_item"
                                name="menu_item"
                                label="Menu Item"
                                variant="outlined"
                                fullWidth
                                value={this.state.menu_item}
                                onChange={this.handleChange}
                                helperText="e.g About Us"
                                type="text"
                              />
                            </div>
                            <div
                              className="col-lg-12"
                              style={{ marginTop: "20px" }}
                            >
                              <MutextField
                                id="menu_link"
                                name="menu_link"
                                select
                                label="Menu Link"
                                value={this.state.menu_link}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Select a link"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="" value=""></option>
                                {this.state.pagesData.map((pages) => (
                                  <option value={pages[2]}>{pages[2]}</option>
                                ))}
                              </MutextField>
                            </div>
                          </div>

                          {/* MRC DIVS END */}
                          <br />
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn-lg"
                              onClick={() => {
                                this.itemSave();
                              }}
                              style={{
                                backgroundColor: THEME_COLOR,
                                color: THEME_TEXT_COLOR,
                                float: "right",
                              }}
                              disabled={this.state.disabled}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                  <br />
                  <form
                    method="post"
                    className="pageForm"
                    onSubmit={this.formSave}
                  >
                    <div className="row g-4">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <MutextField
                            required
                            id="menu_title"
                            name="menu_title"
                            type="text"
                            label="Menu Title e.g Header menu"
                            value={this.state.menu_title}
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <MutextField
                            id="seqno"
                            name="seqno"
                            label="Seq No"
                            value={this.state.seqno}
                            onChange={this.handleChange}
                            variant="outlined"
                            onKeyPress={() => {
                              this.checkNumber();
                            }}
                            fullWidth
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <MutextField
                            id="status"
                            name="status"
                            select
                            label="Select Status"
                            value={this.state.status}
                            onChange={this.handleChange}
                            SelectProps={{
                              native: true,
                            }}
                            helperText="Menu Status"
                            variant="outlined"
                            fullWidth
                          >
                            <option key="1" value="1">
                              Active
                            </option>
                            <option key="0" value="0">
                              Inactive
                            </option>
                          </MutextField>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="card card-bordered">
                          <div
                            className="card-header border-bottom"
                            style={{ backgroundColor: "#ffffff" }}
                          >
                            <h5 className="card-title">Menu items</h5>
                          </div>
                          <div
                            className="card-body"
                            style={{
                              padding: "0px",
                              backgroundColor: "#fafbfb",
                            }}
                          >
                            {this.state.items.length > 0 ? (
                              <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      style={getListStyle(
                                        snapshot.isDraggingOver
                                      )}
                                    >
                                      {this.state.items.map((item, index) => (
                                        <Draggable
                                          key={item.id}
                                          draggableId={item.id}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                            >
                                              <em className="icon ni ni-menu"></em>
                                              {"  "}
                                              {item.content}
                                              <DeleteIcon
                                                onClick={() =>
                                                  this.removeItem(index)
                                                }
                                                style={{
                                                  float: "right",
                                                  cursor: "pointer",
                                                }}
                                                className="list-name"
                                                name="close"
                                              />
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            ) : (
                              <div
                                className="fw-normal"
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  lineHeight: "100px",
                                  minHeight: "100px",
                                }}
                              >
                                <p>This menu doesn’t have any items.</p>
                              </div>
                            )}
                          </div>
                          <div
                            className="card-footer border-top"
                            style={{ backgroundColor: "#ffffff" }}
                          >
                            <a
                              href="#"
                              onClick={() => {
                                this.addMenuItems();
                              }}
                            >
                              <em className="icon ni ni-plus-round"></em> Add
                              Menu Items
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group text-right">
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                          >
                            Save Menu
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Editmenu);
