import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";

import { listClients } from "./../../../config/api_calls";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import $ from "jquery";

import {
  listServicesGroups,
  listClientsQueues,
  assignServicesToCompany,
  assignBundlesToCompany,
  deleteQueueRequest,
} from "./../../../config/api_calls";
import { addSettings, addMsaInfo } from "../../../actions";
import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";

import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import MutextField from "@material-ui/core/TextField";
import dateFormat, { masks } from "dateformat";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsClients,
  COMPANY_NAME,
} from "./../../../config/config";
const auth = new HelperClass();
class Clients_queue extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      tableLoader: true,
      tableLoaderBundle: true,
      tableDataBundle: [],

      value: "",
      didArray: [],
      tfnArray: [],
      termArray: [],
      tfnTermArray: [],
      teamsArray: [],
      azTermArray: [],
      liveAgentArray: [],
      cnamArray: [],
      smsArray: [],

      did_service: "",
      tfn_service: "",
      term_service: "",
      sms_service: "",
      teams_service: "",
      tfn_term_service: "",
      az_term_service: "",
      liveagent_service: "",
      cnam_service: "",

      service_queue_id: "",
      bundle_queue_id: "",

      did_service_date: new Date().toISOString().slice(0, 10),
      tfn_service_date: new Date().toISOString().slice(0, 10),
      term_service_date: new Date().toISOString().slice(0, 10),
      sms_service_date: new Date().toISOString().slice(0, 10),
      teams_service_date: new Date().toISOString().slice(0, 10),
      tfn_term_service_date: new Date().toISOString().slice(0, 10),
      az_term_service_date: new Date().toISOString().slice(0, 10),
      liveagent_service_date: new Date().toISOString().slice(0, 10),
      cnam_service_date: new Date().toISOString().slice(0, 10),
      bundle_date: new Date().toISOString().slice(0, 10),

      client_id: "",

      serviceData: [],
      bundleData: [],
      serviceAssignedData: [],
      servicesQueue: [],
      bundlesQueue: [],
      company_id: "",
      company_title: "",
      errorMessage: "",
      successMessage: "",
      assignErrorMessage: "",
      assignSuccessMessage: "",
      disabled: false,
      nrc_did: "",
      mrc_did: "",
      rate_did: "",
      nrc_tfn: "",
      mrc_tfn: "",
      rate_tfn: "",
      rate_term: "",
      rate_smsin: 0,
      rate_smsout: 0,
      errorMessage: "",
      extra_details: [],
      packagesAssignedData: [],
      emptyService: 0,
      columnServices: [
        { name: "Service Title" },
        { name: "Client" },
        {
          name: "Date Added",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "Effective Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "ACTIONS",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.deleteServiceQueue(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Delete Request"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Delete Request</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
      columnBundleServices: [
        {
          name: "Service Title",
        },
        {
          name: "Client Name",
        },
        {
          name: "Date Added",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "Effective Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "Actions",

          options: {
            customBodyRender: (value) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            style={{ cursor: "pointer" }}
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <Tooltip title="Delete Request" placement="top">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.deleteBundleQueue(value)
                                    }
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em className="icon ni ni-trash"></em>
                                    <span>Delete Request</span>
                                  </a>
                                </Tooltip>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }
  async componentDidMount() {
    const clientsResponce = await listClientsQueues(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("clientsResponce.data.data: ", clientsResponce.data);
    if (
      clientsResponce.data.status === 403 ||
      clientsResponce.data.errors === "authentication missing" ||
      clientsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      clientsResponce.data.status === 404 ||
      clientsResponce.data.note === "missing"
    ) {
      // window.location.replace("/error");
      console.log("settings missing");
      this.setState({
        tableLoader: false,
        tableLoaderBundle: false,
      });
    } else if (
      clientsResponce.data.status === 200 &&
      clientsResponce.data.message === "success"
    ) {
      this.setState({
        tableData: clientsResponce.data.data.services_queue,
        tableDataBundle: clientsResponce.data.data.bundle_queue,
        tableLoader: false,
        tableLoaderBundle: false,
      });
    } else {
      window.location.replace("/error");
    }
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM TT");
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value;
    if (is_default === "No") {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-signin"></em> Inactive
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    }
  };

  deleteServiceQueue = async (value, tableMeta) => {
    //modelDeleteServiceQueue
    this.setState({
      assignErrorMessage: "",
      assignSuccessMessage: "",
      service_queue_id: value,
    });
    window.$("#modelDeleteServiceQueue").modal("show");
  };

  delServiceRequest = async () => {
    let { service_queue_id } = this.state;
    const servicesResponce = await deleteQueueRequest(
      auth.getAccount(),
      auth.getToken(),
      service_queue_id,
      "service"
    );
    console.log("delServiceRequest: ", servicesResponce.data);
    this.setState({
      assignErrorMessage: "",
      assignSuccessMessage: "",
      disabled: true,
    });

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        assignErrorMessage: "There is some error while deleting the request.",
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        assignErrorMessage: "There is some error while deleting the request.",
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        assignSuccessMessage: "request deleted successfully.",
        assignErrorMessage: "",
      });

      this.componentDidMount();
    } else {
      this.setState({
        assignErrorMessage: "There is some error while deleting the request.",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({
        assignSuccessMessage: "",
        assignErrorMessage: "",
        disabled: false,
      });
      window.$("#modelDeleteServiceQueue").modal("hide");
    }, 5000);
  };

  deleteBundleQueue = async (value, tableMeta) => {
    //modelDeleteServiceQueue
    this.setState({
      assignErrorMessage: "",
      assignSuccessMessage: "",
      bundle_queue_id: value,
    });

    window.$("#modelDeleteBundleQueue").modal("show");
  };

  delBundleRequest = async () => {
    let { bundle_queue_id } = this.state;
    const servicesResponce = await deleteQueueRequest(
      auth.getAccount(),
      auth.getToken(),
      bundle_queue_id,
      "bundle"
    );
    console.log("showServices: ", servicesResponce.data.data);
    this.setState({
      assignErrorMessage: "",
      assignSuccessMessage: "",
      disabled: true,
    });

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        assignErrorMessage:
          "There is some error while deleting the package request.",
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        assignErrorMessage:
          "There is some error while deleting the package request.",
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        assignSuccessMessage: "Request deleted successfully.",
        assignErrorMessage: "",
      });

      this.componentDidMount();
    } else {
      this.setState({
        assignErrorMessage: "There is some error while deleting the request.",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({
        assignSuccessMessage: "",
        assignErrorMessage: "",
        disabled: false,
      });
      window.$("#modelDeleteBundleQueue").modal("hide");
    }, 5000);
  };

  showDetails = async (value, tableMeta) => {
    let extra_details = tableMeta.rowData[4].split("###");
    this.setState({
      company_id: tableMeta.rowData[0],
      company_title: tableMeta.rowData[1],
      extra_details: extra_details,
    });

    console.log("Details: ", tableMeta.rowData[4]);
    window.$("#modalClientDetails").modal("show");
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;
    console.log(name);
    this.setState({
      [name]: value,
    });
    console.log("handleChange: ", name + " " + value);
  };

  formSave = async () => {
    let {
      did_service,
      tfn_service,
      term_service,
      sms_service,
      teams_service,
      tfn_term_service,
      az_term_service,
      liveagent_service,
      cnam_service,
      did_service_date,
      tfn_service_date,
      term_service_date,
      sms_service_date,
      teams_service_date,
      tfn_term_service_date,
      az_term_service_date,
      liveagent_service_date,
      cnam_service_date,
      company_id,
    } = this.state;
    /*await this.setPriceStates(
      did_service,
      tfn_service,
      term_service,
      sms_service
    );*/

    this.setState({
      assignErrorMessage: "",
      assignSuccessMessage: "",
      disabled: true,
    });

    const saveResponce = await assignServicesToCompany(
      auth.getAccount(),
      auth.getToken(),
      did_service,
      tfn_service,
      term_service,
      sms_service,
      teams_service,
      tfn_term_service,
      az_term_service,
      liveagent_service,
      cnam_service,
      did_service_date,
      tfn_service_date,
      term_service_date,
      sms_service_date,
      teams_service_date,
      tfn_term_service_date,
      az_term_service_date,
      liveagent_service_date,
      cnam_service_date,
      company_id
    );
    console.log("Assign Result: ", saveResponce.data.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        assignErrorMessage: saveResponce.data.note,
        assignSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        assignErrorMessage: "",
        assignSuccessMessage: "Services assigned successfully.",
        disabled: false,
      });

      $("#viewBtn").show();
      $("#btnloader").hide();

      setTimeout(function () {
        window.$("#modalFormDetail").modal("hide");
      }, 3000);
      this.componentDidMount();
    } else {
      this.setState({
        assignErrorMessage: "There is some error while assigning the services.",
        assignSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
  };

  assignBundles = async () => {
    let { bundle_date, company_id } = this.state;
    this.setState({
      assignErrorMessage: "",
      assignSuccessMessage: "",
      disabled: true,
    });
    var checkedValues = $("input:radio.vdrSelected:checked")
      .map(function () {
        return this.value;
      })
      .get();
    console.log("checkedValues: ", checkedValues[0]);
    if (checkedValues.length === 0) {
      this.setState({
        assignErrorMessage: "Please select the package.",
        assignSuccessMessage: "",
        disabled: false,
      });
    } else {
      this.setState({
        assignErrorMessage: "",
        assignSuccessMessage: "",
        disabled: true,
      });

      const saveResponce = await assignBundlesToCompany(
        auth.getAccount(),
        auth.getToken(),
        checkedValues[0],
        bundle_date,
        company_id
      );
      console.log("Assign Result: ", saveResponce.data.data);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        this.setState({
          assignErrorMessage: saveResponce.data.note,
          assignSuccessMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          assignErrorMessage: "",
          assignSuccessMessage: "Package assigned successfully.",
          disabled: false,
        });

        $("#viewBtn").show();
        $("#btnloader").hide();

        setTimeout(function () {
          window.$("#modalFormDetail").modal("hide");
        }, 3000);
        this.componentDidMount();
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success_delete"
      ) {
        this.setState({
          assignErrorMessage: "",
          assignSuccessMessage:
            "Previous request is discarded and new package assigned successfully.",
          disabled: false,
        });

        $("#viewBtn").show();
        $("#btnloader").hide();

        setTimeout(function () {
          window.$("#modalFormDetail").modal("hide");
        }, 3000);
        this.componentDidMount();
      } else {
        this.setState({
          assignErrorMessage:
            "There is some error while assigning the package.",
          assignSuccessMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <PageTitle
                name="Client Requests / Queues"
                icon="icon ni ni-users"
              />
              {/* PAGE TITLE END */}

              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          <ul className="nav nav-tabs">
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                data-toggle="tab"
                                href="#tabItem5"
                              >
                                <em className="icon ni ni-list-check"></em>
                                <span>Services Queue</span>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#tabItem6"
                              >
                                <em className="icon ni ni-list-check"></em>
                                <span>Packages Queue</span>
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div className="tab-pane active" id="tabItem5">
                              {/* START DATATABLE */}
                              {this.state.tableLoader === true ? (
                                tableLoader()
                              ) : (
                                <DataTable
                                  columns={this.state.columnServices}
                                  tableData={this.state.tableData}
                                  title="Services Requests / Queues"
                                />
                              )}

                              {/* END TABLE */}
                            </div>
                            <div className="tab-pane" id="tabItem6">
                              {this.state.tableLoaderBundle === true ? (
                                tableLoader()
                              ) : (
                                <>
                                  {this.state.tableDataBundle &&
                                  this.state.tableDataBundle.length > 0 ? (
                                    <DataTableExtended
                                      columns={this.state.columnBundleServices}
                                      tableData={this.state.tableDataBundle}
                                      title="Packages Requests / Queues"
                                    />
                                  ) : null}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
              {/* Delete Service Queue Confirmation */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modelDeleteServiceQueue"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">Delete Service Request?</h5>
                    </div>
                    <div className="modal-body">
                      {this.state.assignErrorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.assignErrorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.assignSuccessMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.assignSuccessMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <p>Are you sure you want to delete this request?</p>
                    </div>
                    <div
                      className="modal-footer bg-light"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <div className="text-right">
                        <a
                          style={{ cursor: "pointer", color: "#fff" }}
                          className="btn btn-success"
                          onClick={() => {
                            this.delServiceRequest();
                          }}
                          disabled={this.state.disabled}
                        >
                          Yes
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Delete Service Queue Confirmation */}
              {/* Delete Service Queue Confirmation */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modelDeleteBundleQueue"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">Delete Package Request?</h5>
                    </div>
                    <div className="modal-body">
                      {this.state.assignErrorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.assignErrorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.assignSuccessMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.assignSuccessMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <p>Are you sure you want to delete this request?</p>
                    </div>
                    <div
                      className="modal-footer bg-light"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <div className="text-right">
                        <a
                          style={{ cursor: "pointer", color: "#fff" }}
                          className="btn btn-success"
                          onClick={() => {
                            this.delBundleRequest();
                          }}
                          disabled={this.state.disabled}
                        >
                          Yes
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Delete Service Queue Confirmation */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Clients_queue);
