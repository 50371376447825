import React, { Component, Fragment } from "react";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { LOGO_VERIFY } from "../../../config/config";

import HelperClass from "../../../config/helperClass";
import { tableLoader } from "../../ContentLoaders/table_loader";
import Skeleton from "@material-ui/lab/Skeleton";
import { Helmet, HelmetProvider } from "react-helmet-async";
const auth = new HelperClass();
export class Step4 extends Component {
  constructor() {
    super();
    this.state = {
      disabled: false,
      errorMessage: "",
      tableLoader: false,
      dnsList: [],
    };
  }

  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForms = () => {
    const { values } = this.props;
    //console.log("values.password: ", values.password);
    //e.preventDefault();
    if (values.password === "") {
      this.setState({
        errorMessage: "Please enter the password.",
      });
    } else if (values.password.length < 8) {
      this.setState({
        errorMessage: "Use 8 characters or more for your password",
      });
      return false;
    } else if (values.confirm_password === "") {
      this.setState({
        errorMessage: "Confirm password is required.",
      });
      return false;
    } else if (values.confirm_password.length < 8) {
      this.setState({
        errorMessage: "Use 8 characters or more for your confirm password",
      });
      return false;
    } else if (values.password !== values.confirm_password) {
      this.setState({
        errorMessage: "Password and confirm password does not match.",
      });
      return false;
    } else {
      this.continue();
    }
    setTimeout(() => {
      this.setState({
        errorMessage: "",
      });
    }, 3000);
  };

  modalHideIns = () => {
    window.$("#instructionsModal").modal("hide");
  };

  render() {
    //const { VerifyConnection } = this.props;
    const { values, handleChange } = this.props;

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/csstheme.css?ver=3.0.3"
            />
          </Helmet>
        </HelmetProvider>
        <div className="nk-block nk-block-lg" style={{ width: "100%" }}>
          <div
            className="card card-bordered"
            style={{
              border: "none",
              paddingTop: "10%",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <form
              className="nk-stepper stepper-init is-alter nk-stepper-s1"
              action="#"
              id="stepper-create-profile"
            >
              {this.state.errorMessage !== "" ? (
                <div className="example-alert">
                  <div className="alert alert-pro alert-danger">
                    <div className="alert-text">
                      <h4>Error</h4>
                      <p>{this.state.errorMessage}</p>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row g-0 col-sep col-sep-md col-sep-xl">
                <div className="col-md-4 col-xl-4">
                  <div className="card-inner">
                    <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                      {/*<li className="done">
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Company Info</div>
                            <div className="sub-text">
                              Company name and 499 Filer ID
                            </div>
                          </div>
                        </div>
              </li>*/}
                      <li className="done">
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Contact Info</div>
                            <div className="sub-text">
                              Other contact information
                            </div>
                          </div>
                        </div>
                      </li>
                      {/*<li className="done">
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Other Info</div>
                            <div className="sub-text">
                              Number of employes in the company
                            </div>
                          </div>
                        </div>
            </li>*/}
                      <li className="current">
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Account Password</div>
                            <div className="sub-text">
                              Account password to access the portal
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Preview</div>
                            <div className="sub-text">Review and Submit</div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Completed</div>
                            <div className="sub-text">You are done.</div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="card-inner">
                    <div className="nk-stepper-content">
                      <div className="nk-stepper-steps stepper-steps">
                        <div className="nk-stepper-step done"></div>
                        <div className="nk-stepper-step done"></div>
                        <div className="nk-stepper-step done"></div>
                        <div className="nk-stepper-step active">
                          <h5 className="title mb-3">Account Password</h5>
                          <p>Password and Confirm password should be same.</p>
                          <div className="row g-3">
                            <div className="col-12">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="cp1-name-on-card"
                                >
                                  Password
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="password"
                                    minLength="8"
                                    maxLength="50"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    placeholder="Password"
                                    defaultValue={values.password}
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div class="form-note">
                                  Your password should be between 8 and 50
                                  characters long. This ensures a balance
                                  between security and usability.
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="cp1-card-number"
                                >
                                  Confirm Password
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="password"
                                    minLength="8"
                                    maxLength="50"
                                    className="form-control"
                                    id="confirm_password"
                                    name="confirm_password"
                                    placeholder="Password"
                                    defaultValue={values.confirm_password}
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div class="form-note">
                                  Confirm password should also be between 8 and
                                  50 characters long. This ensures a balance
                                  between security and usability. It must match
                                  above password.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nk-stepper-step text-center"></div>
                      </div>
                      <ul className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination">
                        <li className="step-prev">
                          <button
                            type="button"
                            className="btn btn-dim btn-primary"
                            onClick={this.back}
                          >
                            Back
                          </button>
                        </li>

                        {/*<li className="step-submit">*/}
                        <li className="step-next">
                          <button
                            type="button"
                            onClick={this.submitForms}
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </li>
                      </ul>
                      <div
                        className="text-center"
                        style={{ marginTop: "25px" }}
                      >
                        Already have an account ?{" "}
                        <a
                          href={`${auth.getResellerIdentifier()}/client/login`}
                        >
                          Sign in instead
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Step4;
