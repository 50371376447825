import { React } from "react";
import { Component } from "react";
import { COMPANY_NAME } from "../../../config/config";

import { addAgreement } from "./../../../config/admin_apis";

import $ from "jquery";

import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";

import TextField from "../../../components/InputFields/TextField";

import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import SettingIcon from "@material-ui/icons/Settings";
import Tooltip from "@material-ui/core/Tooltip";

import MutextField from "@material-ui/core/TextField";

import Typography from "@material-ui/core/Typography";

import HomeIcon from "@material-ui/icons/Home";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Editor } from "@tinymce/tinymce-react";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";

const auth = new HelperClass();

export default class AdminAgreements extends Component {
  constructor() {
    super();

    this.state = {
      apiUrl: "",
      apiKey: "",
      authKey: "",
      useraccountno: "",
      disabled: false,
      errorMessage: "",
      successMessage: "",
      agreement_title: "",
      agreement_content: "",
      agreement_instruction: "",
      agreement_type: "msa",
      is_default: 0,
      isactive: 0,
      issignup: 0,
      ismandatory: 0,
      ispublished: 0,
      tableData: [],
    };
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  formSave = async () => {
    window.scrollTo(0, 0);
    const {
      agreement_title,
      agreement_instruction,
      isactive,
      ismandatory,
      ispublished,
      agreement_content,
      agreement_type,
      is_default,
    } = this.state;

    var validated = true;

    if (agreement_title === "") {
      validated = false;
    } else if (agreement_instruction === "") {
      validated = false;
    } else if (agreement_content === "") {
      validated = false;
    }
    if (validated === false) {
      return this.setState({
        errorMessage: "Please enter the required fields.",
      });
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await addAgreement(
        auth.getAdminAccount(),
        auth.getAdminToken(),
        agreement_title,
        agreement_instruction,
        isactive,
        ismandatory,
        ispublished,
        agreement_content,
        agreement_type,
        is_default
      );

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.adminlogout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: saveResponce.data.note,
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Agreement added successfully.",
          disabled: false,
        });
        setTimeout(function () {
          window.location.replace("/admin_agreements");
        }, 3000);
      } else {
        this.setState({
          errorMessage: saveResponce.data.message,
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      agreement_content: content,
    });
    //console.log("Content was updated:", content);
  };

  render() {
    $("#useraccountno").hide();
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* END MODAL ADD SERVICE */}
          {/* SETTINGS MODAL END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        to={"/"}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <HomeIcon fontSize="inherit" className="" />
                        Home
                      </Link>
                      <Link
                        to={"/admin_agreements"}
                        color="inherit"
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Manage Agreements
                      </Link>
                      <Typography color="textPrimary" className="">
                        Add Agreements
                      </Typography>
                    </Breadcrumbs>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <h5
                            class="card-title"
                            style={{ marginBottom: "30px" }}
                          >
                            Add New Agreement
                          </h5>
                          <form
                            method="post"
                            className="pageForm"
                            id="pageForm"
                          >
                            <div className="row g-4">
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <MutextField
                                    required
                                    id="agreement_title"
                                    name="agreement_title"
                                    type="text"
                                    label="Agreement Title"
                                    defaultValue={this.state.agreement_title}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <MutextField
                                    required
                                    id="agreement_instruction"
                                    name="agreement_instruction"
                                    type="text"
                                    label="Agreement Instructions"
                                    defaultValue={
                                      this.state.agreement_instruction
                                    }
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <MutextField
                                    id="isactive"
                                    name="isactive"
                                    select
                                    label="Select Status"
                                    value={this.state.isactive}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    helperText="Agreement Status"
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="1" value="1">
                                      Active
                                    </option>
                                    <option key="0" value="0">
                                      Inactive
                                    </option>
                                  </MutextField>
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="form-group">
                                  <MutextField
                                    id="ismandatory"
                                    name="ismandatory"
                                    select
                                    label="Mandatory"
                                    value={this.state.ismandatory}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    helperText="Agreement is mandatory"
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="1" value="1">
                                      Yes
                                    </option>
                                    <option key="0" value="0">
                                      No
                                    </option>
                                  </MutextField>
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="form-group">
                                  <MutextField
                                    id="ispublished"
                                    name="ispublished"
                                    select
                                    label="Is Published"
                                    value={this.state.ispublished}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    helperText="Publish agreement"
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="1" value="1">
                                      Publish
                                    </option>
                                    <option key="0" value="0">
                                      Not Publish
                                    </option>
                                  </MutextField>
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="form-group">
                                  <MutextField
                                    id="agreement_type"
                                    name="agreement_type"
                                    select
                                    label="Agreement Type"
                                    value={this.state.agreement_type}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    helperText="Publish agreement"
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="1" value="msa">
                                      MSA
                                    </option>
                                    <option key="0" value="nda">
                                      NDA
                                    </option>
                                  </MutextField>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <MutextField
                                    id="is_default"
                                    name="is_default"
                                    select
                                    label="Set Default"
                                    value={this.state.is_default}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    helperText="Make it default"
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="1" value="1">
                                      Yes
                                    </option>
                                    <option key="0" value="0">
                                      No
                                    </option>
                                  </MutextField>
                                </div>
                              </div>
                              <div className="col-lg-4"></div>
                              <div style={{ clear: "both" }}></div>

                              <div className="col-lg-12">
                                <h6>Agreement Content</h6>
                              </div>

                              <div className="col-lg-12">
                                <div className="form-group">
                                  <Editor
                                    id="agreement_content"
                                    name="agreement_content"
                                    apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                    initialValue={this.state.agreement_content}
                                    selector="textarea#full-featured"
                                    init={{
                                      height: 500,
                                      menubar: true,
                                      plugins: [
                                        "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                      ],
                                      toolbar:
                                        "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                    }}
                                    onEditorChange={this.handleEditorChange}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <table className="table table-striped">
                                  <tbody>
                                    <tr>
                                      <th scope="row">Client Place Holders</th>
                                      <td>
                                        <ul class="preview-list">
                                          <li class="preview-item">
                                            <span class="badge badge-outline-gray">
                                              [[CLIENT_SIGNOR_TITLE]]
                                            </span>
                                          </li>
                                          <li class="preview-item">
                                            <span class="badge badge-outline-gray">
                                              [[CLIENT_SIGNOR_NAME]]
                                            </span>
                                          </li>
                                          <li class="preview-item">
                                            <span class="badge badge-outline-gray">
                                              [[CLIENT_COMPANY_NAME]]
                                            </span>
                                          </li>
                                          <li class="preview-item">
                                            <span class="badge badge-outline-gray">
                                              [[CLIENT_EMAIL]]
                                            </span>
                                          </li>
                                          <li class="preview-item">
                                            <span class="badge badge-outline-gray">
                                              [[CLIENT_ADDRESS]]
                                            </span>
                                          </li>
                                          <li class="preview-item">
                                            <span class="badge badge-outline-gray">
                                              [[CONTRACT_DATE]]
                                            </span>
                                          </li>
                                          <li class="preview-item">
                                            <span class="badge badge-outline-gray">
                                              [[CLIENT_SIGN]]
                                            </span>
                                          </li>
                                          <li class="preview-item">
                                            <span class="badge badge-outline-gray">
                                              [[CLIENT_499_FILER_ID]]
                                            </span>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">User Place Holders</th>
                                      <td>
                                        <ul class="preview-list">
                                          <li class="preview-item">
                                            <span class="badge badge-outline-gray">
                                              [[VENDOR_SIGNOR_TITLE]]
                                            </span>
                                          </li>
                                          <li class="preview-item">
                                            <span class="badge badge-outline-gray">
                                              [[VENDOR_SIGNOR_NAME]]
                                            </span>
                                          </li>
                                          <li class="preview-item">
                                            <span class="badge badge-outline-gray">
                                              [[VENDOR_SIGNOR_ADDRESS]]
                                            </span>
                                          </li>

                                          <li class="preview-item">
                                            <span class="badge badge-outline-gray">
                                              [[VENDOR_SIGNOR_SIGN]]
                                            </span>
                                          </li>
                                          <li class="preview-item">
                                            <span class="badge badge-outline-gray">
                                              [[VENDOR_SIGNOR_COMPANY_NAME]]
                                            </span>
                                          </li>
                                          <li class="preview-item">
                                            <span class="badge badge-outline-gray">
                                              [[VENDOR_CONTRACT_DATE]]
                                            </span>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group text-right">
                                  <button
                                    type="button"
                                    onClick={() => this.formSave()}
                                    className="btn btn-lg btn-primary"
                                    disabled={this.state.disabled}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
