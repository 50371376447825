import React, { Component, Fragment } from "react";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { LOGO_VERIFY } from "./../../config/config";
import { verfiyDomain } from "../../config/api_calls";
import HelperClass from "./../../config/helperClass";
import { tableLoader } from "../ContentLoaders/table_loader";
import Skeleton from "@material-ui/lab/Skeleton";
const auth = new HelperClass();
export class DomainConnect extends Component {
  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForms = (e) => {
    e.preventDefault();
    this.setState({
      tableLoader: true,
      disabled: true,
    });
    this.props.VerifyConnection();
    setTimeout(() => {
      this.setState({
        tableLoader: false,
        disabled: false,
      });
      this.continue();
    }, 5000);
  };

  constructor() {
    super();
    this.state = {
      disabled: false,
      errorMessage: "",
      tableLoader: false,
      dnsList: [],
    };
  }

  modalHideIns = () => {
    window.$("#instructionsModal").modal("hide");
  };

  render() {
    //const { VerifyConnection } = this.props;
    const {
      values: { domain_title, domain_name, dnsList, a_ip },
    } = this.props;

    return (
      <>
        <div className="col-lg-12">
          <div className="nk-block">
            <div className="card card-bordered card-stretch">
              <div className="card-inner-group" style={{ padding: "40px" }}>
                {this.state.errorMessage !== "" ? (
                  <div
                    className="example-alert"
                    style={{ marginBottom: "15px" }}
                  >
                    <div className="alert alert-pro alert-danger">
                      <div className="alert-text">
                        <h4>Error</h4>
                        <p>{this.state.errorMessage}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                <h6>Domain</h6>

                <p>
                  {domain_title}
                  <br />
                  {domain_name}
                </p>
              </div>
            </div>
          </div>

          <div className="nk-block">
            <div className="card card-bordered card-stretch">
              <div className="card-inner-group" style={{ padding: "40px" }}>
                <h6>Connect your domain</h6>
                {this.state.tableLoader === true ? (
                  <div style={{ textAlign: "center", padding: "20px" }}>
                    <p>Verifying Connection</p>
                    <img
                      src={LOGO_VERIFY}
                      alt="logo"
                      style={{ height: "200px" }}
                    />
                  </div>
                ) : (
                  <p>
                    To connect your domain, you need to log in to your provider
                    account and change your settings. Follow the provider
                    step-by-step instructions to get started.
                    <br />
                    <br />
                    <button
                      className="btn btn-success"
                      data-toggle="modal"
                      data-target="#instructionsModal"
                    >
                      View Instructions
                    </button>
                    <br />
                    <br />
                    <span>
                      Verify the connection to make sure your domain is set up
                      correctly.
                    </span>
                  </p>
                )}

                <hr />
                <div className="form-group text-right">
                  <button
                    className="btn btn-lg btn-default  btn-outline-dark"
                    disabled={this.state.disabled}
                    onClick={this.back}
                    style={{ marginRight: "10px" }}
                  >
                    Back
                  </button>

                  {this.state.tableLoader === true ? (
                    <button
                      className="btn btn-lg btn-primary"
                      style={{ marginRight: "15px" }}
                      disabled
                    >
                      <em className="icon ni ni-swap"></em> Verifying Connection
                      <div
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </button>
                  ) : (
                    <button
                      className="btn btn-lg btn-primary"
                      disabled={this.state.disabled}
                      onClick={this.submitForms}
                    >
                      Verify Connection
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" tabIndex="-1" id="instructionsModal">
          <div className="modal-dialog  modal-lg" role="document">
            <div className="modal-content">
              <a
                style={{ cursor: "pointer" }}
                className="close"
                onClick={() => {
                  this.modalHideIns();
                }}
              >
                <em className="icon ni ni-cross"></em>
              </a>
              <div className="modal-body modal-body-lg">
                <div className="nk-modal">
                  <h4 className="nk-modal-title">
                    Set up your existing domain to connect
                  </h4>
                  <div className="nk-modal-text">
                    <p>
                      If you've purchased your domain through a third party
                      provider, then you need to edit the following DNS
                      settings:
                    </p>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        Your A record should point to our IP address, which is{" "}
                        {process.env.REACT_APP_AGENT_PORTAL_DNS}.
                      </li>
                      {/*<li className="list-group-item">
                        Your www CNAME record should point to
                        "flowroute.tcpaas.com"
              </li>*/}
                    </ul>
                    <br />
                    <p>
                      Editing these domain settings might have an effect on
                      other things, such as email forwarding. You can contact
                      your domain provider to find out how any change might
                      effect your DNS settings.
                    </p>
                    <p>
                      Some domain providers, such as Hover, GoDaddy, Google, and
                      1&1 IONOS, automatically set up your domains for you in
                      different ways.{" "}
                    </p>
                    <div class="callout callout-default">
                      <h4>Note</h4>
                      You can only have one A record associated with your
                      primary domain.If your domain is already associated with
                      an A record when you edit DNS settings to point your A
                      record to our IP address, then remove the old A record.
                    </div>
                  </div>
                  <div className="nk-modal-action">{"  "}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DomainConnect;
